export class AtomicDictionary<K, V> {
    private _dictionary: Map<K, V>;

    constructor(initialData?: { [key: string]: V }) {
        this._dictionary = new Map<K, V>();
        if (initialData) {
            for (const key in initialData) {
                this._dictionary.set(key as unknown as K, initialData[key]);
            }
        }
    }

    public get(key: K): V | undefined {
        return this._dictionary.get(key);
    }

    public set(key: K, value: V): void {
        this._dictionary.set(key, value);
    }

    public delete(key: K): void {
        this._dictionary.delete(key);
    }

    public has(key: K): boolean {
        return this._dictionary.has(key);
    }

    public clear(): void {
        this._dictionary.clear();
    }

    public keys(): IterableIterator<K> {
        return this._dictionary.keys();
    }

    public values(): IterableIterator<V> {
        return this._dictionary.values();
    }

    public entries(): IterableIterator<[K, V]> {
        return this._dictionary.entries();
    }

    public write(callback: (dict: Map<K, V>) => void): void {
        callback(this._dictionary);
    }

    // Method to access the dictionary as an array of entries
    public get dictionary(): [K: V] {
        return Object.fromEntries(this._dictionary)
    }
}
