import { convertFileNameToMediaImageType, convertMediaImageTypeToFileName, MediaImageType } from "./media_image_type";

export class RepositoryToUrlMap {
    static getLayersFolder(projectId: string): string {
        let url = `/projects/${projectId}/Layers`;
        return url;
    }

    static constructUrl(
        projectId: string,
        layerId: string,
        mediaType: MediaImageType,
        state?: string,
        imageFormat?: string
    ): string {
        // Base URL construction
        let mediaTypeName = convertMediaImageTypeToFileName(mediaType) ?? mediaType
        let filename = mediaTypeName
        if (imageFormat) {
            filename = `${mediaTypeName}.${imageFormat}`
        }
        let layersFolder = this.getLayersFolder(projectId)
        let url = `${layersFolder}/${layerId}/${filename}`;
        if (state) {
            url += `?state=${state}`;
        }
        return url;
    }
    
    static parsePath(filePath: string): {projectId: string, mediaType: MediaImageType, state: string, layerId: string } | null {
        const pattern = /\/projects\/([0-9A-F-]+)\/Layers\/([0-9A-F-]+)\/([^\/?]+)(?:\/([^\/?]+))?/;
    
        // Separate the path from the query string
        const [path, queryString] = filePath.split('?');
        
        const match = path.match(pattern);
    
        if (match) {
            const [, projectId, layerId, mediaType] = match;
            console.log(`ProjectId: ${projectId}, LayerId: ${layerId}, MediaType: ${mediaType}`);
            
            // Parse query parameters
            const queryParams: {[key: string]: string} = {};
            if (queryString) {
                const searchParams = new URLSearchParams(queryString);
                searchParams.forEach((value, key) => {
                    queryParams[key] = value;
                });
            }
    
            let parsedResult = {
                projectId: projectId,
                layerId: layerId,
                mediaType: convertFileNameToMediaImageType(mediaType), 
                state: queryParams["state"]
            };
            
            return parsedResult;
        } else {
            return null;
        }
    }
}