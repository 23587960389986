import { fabric } from 'fabric'
import { useContext, useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
// import { defaultEditorConfig } from '../../../../src/common/constants'
// import BZEditor from './BZEditor'
// import { EditorContext } from '../../../../src'
// import { EditorConfig, FabricCanvas } from '../../../../src/common/interfaces'
// import React = require('react')
import { FabricCanvas } from './common/interfaces'
import { EditorContext, useEditorContext } from '.'
import EditorEventManager from './EditorEventManager'

import './objects'
import './PixiFilterBackend'

import api from '@/services/api'
import { uniqueFilename } from '@/utils/unique'
import { DRAGGABLE_TYPE, ObjectType } from './common/constants'
import { nanoid } from 'nanoid'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from './objects/media-repository/media_image_type'
import useAppContext from '@/hooks/useAppContext'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { loadImageFromURL } from './utils/image-loader'
import { Size } from '@/scenes/engine/objects/media-repository/size'
import { useAppDispatch } from '@/store/store'
import { setTemplateId } from '@/store/slices/templates/actions'
import { useTour } from '@reactour/tour'
import { handleTutorialResize } from '@/utils/tutorial'

// interface ICanvas {
//   config: EditorConfig
// }

const defaultEditorConfig = {
  clipToFrame: true,
  scrollLimit: 200,
}

function Canvas({ config }: { config: Object }) {
  const dispatch = useAppDispatch()
  const containerRef = useRef(null)
  const context = useContext(EditorContext)
  const { setEditor } = context
  const { objDragging, setObjDragging, isOpenInspector } = useAppContext()

  const { editor, canvas } = useEditorContext()
  const [isDragging, setIsDragging] = useState(false)
  const { isOpen, currentStep } = useTour() 

  useEffect(() => {
    const editorConfig = Object.assign(defaultEditorConfig, config)
    const container = containerRef.current as unknown as HTMLDivElement

    const { clientHeight, clientWidth } = container

    // Yadid
    // @ts-ignore
    fabric.initFilterBackend()

    var webglBackend: fabric.FilterBackend
    try {
      fabric.textureSize = 2048
      // @ts-ignore
      webglBackend = new fabric.PixiFilterBackend({ tileSize: fabric.textureSize })
    } catch (e) {
      console.log(e)
    }
    fabric.filterBackend = webglBackend

    const canvas = new fabric.Canvas('canvas', {
      backgroundColor: '#F2F2F2',
      //backgroundColor: null,
      // height: clientHeight,
      // width: clientWidth,
      preserveObjectStacking: true,
      fireRightClick: true,
      isDrawingMode: false,
      selection: true,
      uniScaleKey: ''
    }) as FabricCanvas

    const editor = new EditorEventManager({
      context: context,
      canvas: canvas,
      config: editorConfig,
    })
    setEditor(editor)
    context.setCanvas(canvas)

    const resizeObserver = new ResizeObserver(entries => {
      const { width = clientWidth, height = clientHeight } = (entries[0] && entries[0].contentRect) || {}
      const currZoom = editor.handlers.canvasHandler.canvas.getZoom()      
      editor.handlers.canvasHandler.resize(width, height)
      editor.handlers.frameHandler.updateCanvasWithFrame(canvas)
      editor.handlers.zoomHandler.zoomToRatio(currZoom)
      editor.handlers.canvasHandler.canvas.forEachObject(obj => obj.setCoords())
      handleTutorialResize()
    })
    resizeObserver.observe(container)
    return () => {
      editor.destroy()
      if (container) {
        resizeObserver.unobserve(container)
      }
      setEditor(null)
      context.setCanvas(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let container = containerRef.current
    if (container) {
      container.addEventListener('dragenter', handleDragIn)
      container.addEventListener('dragover', handleDragIn)
      container.addEventListener('dragleave', handleDragOut)
      container.addEventListener('dragexit', handleDragOut)
      document.onpaste = e => {
        var files = e.clipboardData.files
        for (let index = 0; index < files.length; index++) {
          var file = files[index]
          addFile(file)
        }
      }
    }
    return () => {
      if (container) {
        container.removeEventListener('dragenter', handleDragIn)
        container.removeEventListener('dragover', handleDragIn)
        container.removeEventListener('dragleave', handleDragOut)
        container.removeEventListener('dragexit', handleDragOut)
      }
    }
  }, [canvas])

  useEffect(() => {
    if(!canvas) {return}
    editor.handlers.zoomHandler.setIsOpenInspector(isOpenInspector)
    let offset = editor.handlers.frameHandler.FRAME_PADDING_INSPECTOR  / 2 - editor.handlers.frameHandler.FRAME_PADDING_ADD_MENU / 2
    canvas.relativePan(new fabric.Point(isOpenInspector ? -offset : offset, 0))
    if(isOpenInspector) {
      editor.handlers.scrollbarHandler.updateScrollPosition()
    }
  }, [isOpenInspector])

  const handleDragIn = e => {
    if (!isDragging) {
      setIsDragging(true)
    }
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOut = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = async (
    e: any,
  ) => {
    let file = null

    const clientX = e.nativeEvent.offsetX
    const clientY = e.nativeEvent.offsetY

    e.preventDefault()
    e.stopPropagation()
    if (objDragging.type === ObjectType.BAZAART_TEXT) {
      if(objDragging.item) {
        setObjDragging({
          ...objDragging,
          dropped: true
        })
      }
      return
    }
    if (objDragging.type === 'Template') {
      dispatch(setTemplateId(objDragging.item.id))
      return
    }
    if (objDragging.item && e.dataTransfer.files && e.dataTransfer.files.length === 0) {
      // await fetch(objDragging.item.image)
      //   .then(res => res.blob())
      //   .then(blob => {
      //     file = new File([blob], 'newFile.png', blob)
      //   })
      addImageToCanvas(objDragging.type, objDragging.item, clientX, clientY)
    } else if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      file = e.dataTransfer.files[0]
      setObjDragging({
        item: objDragging.item ?? null,
        type: ObjectType.BAZAART_IMAGE,
      })
    }

    if (file) {
      addFile(file, clientX, clientY)
      setIsDragging(false)
    }
  }
  const addImageToCanvas = async (type, item, clientX, clientY) => {
    const {
      left,
      top,
      width: widthFrame,
      height: heightFrame,
    } = editor.handlers.frameHandler.get().getBoundingRect()
    let cx = left + widthFrame / 2
    let cy = top + heightFrame / 2
    if (!clientX || !clientY) {
      clientX = cx
      clientY = cy
    }
    if (!(left <= clientX && clientX <= left + widthFrame && top <= clientY && clientY <= top + heightFrame)) {
      // alert('unvalid position')
      return
    }

    let guid = nanoid()
    let assetStateId = nanoid()
    let latestImage
    let lowQualityImage
    let width
    if (item.image) { // Graphics
      latestImage = getResizeUrl({ size: '1280x1280', url: item.image.slice(8) })
      if(latestImage?.href) {
        latestImage = latestImage.href
      }     
      lowQualityImage = item.thumbnail ? item.thumbnail : getResizeUrl({ size: '0x32', url: item.image.slice(8) })
      width = 0.35
    } else if (item.urls) { // stocks
      latestImage = item.urls.regular
      lowQualityImage = item.urls.thumb
      width = 0.6
    } else if (item.url) { // Uploads
      latestImage = item.url
      lowQualityImage = item.url
      width = 0.6
    }

    let resizedLatestImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBlobToMaxEdgeSize(latestImage, 1280)
    
    // NOTE: for now we dont load thumbnail, image already not so big, and thumbnail stuck the filters images load with low qulity 
    // let resizedLowQualityImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBlobToMaxEdgeSize(lowQualityImage, 32)
    // await MediaImageRepository.getInstance().storeImageBlobString(
    //   guid,
    //   assetStateId,
    //   MediaImageType.thumbnail,
    //   resizedLowQualityImage
    // )

    await MediaImageRepository.getInstance().storeImageBlobString(guid,assetStateId,MediaImageType.latest, resizedLatestImage)

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(resizedLatestImage)
    let frame = editor.handlers.frameHandler.get()
    let layerSize = maskInfo.size
    let canvasAspectRatio = frame.width / frame.height
    let layerAspectRatio = layerSize.width / layerSize.height

    if (layerAspectRatio < canvasAspectRatio) {
      width = (width * layerAspectRatio) / canvasAspectRatio
    }
    let object = {
      type: type,
      centerPoint: {
        y: (clientY - top) / heightFrame,
        x: (clientX - left) / widthFrame,
      },
      sizeOnCanvas: {
        width: width,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
    }
    let object_added = await editor.handlers.objectsHandler.add(object)
    
    await MediaImageRepository.getInstance().storeImageBlobString(guid, assetStateId, MediaImageType.original, resizedLatestImage)
    MediaImageRepository.getInstance().storeImageBlobString(guid, assetStateId, MediaImageType.mask, maskInfo.blob)
    editor.handlers.objectsHandler.replaceImageSource(resizedLatestImage)
  }

  const addFile = (file, clientX?, clientY?) => {
    var reader = new FileReader()
    const updatedFileName = uniqueFilename(file.name)
    const updatedFile = new File([file], updatedFileName)
    let uploadedFile
    reader.onload = (function (theFile) {
      return async function (e) {
        uploadedFile = await api.updateUploadFile({ name: e.target.result })
        let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing
        uploadedFile.url = await imageProcessing.resizeBlobToMaxEdgeSize(uploadedFile.url, 1280)
      }
    })(updatedFile)

    reader.readAsDataURL(updatedFile)

    setTimeout(async () => {
      let guid = nanoid()
      let assetStateId = nanoid()
      let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(
        uploadedFile.url
      )

      await MediaImageRepository.getInstance().storeImageBlobString(
        guid,
        assetStateId,
        MediaImageType.latest,
        uploadedFile.url
      )
      await MediaImageRepository.getInstance().storeImageBlobString(
        guid,
        assetStateId,
        MediaImageType.original,
        uploadedFile.url
      )
      await MediaImageRepository.getInstance().storeImageBlobString(
        guid,
        assetStateId,
        MediaImageType.thumbnail,
        uploadedFile.url
      )
      await MediaImageRepository.getInstance().storeImageBlobString(
        guid,
        assetStateId,
        MediaImageType.mask,
        maskInfo.blob
      )
      let layerSize = maskInfo.size
      const {
        left,
        top,
        width: widthFrame,
        height: heightFrame,
      } = editor.handlers.frameHandler.get().getBoundingRect()
      let cx = left + widthFrame / 2
      let cy = top + heightFrame / 2
      if (!clientX || !clientY) {
        clientX = cx
        clientY = cy
      }
      let canvasAspectRatio = widthFrame / heightFrame
      let layerAspectRatio = layerSize.width / layerSize.height
      let width = objDragging.type === ObjectType.BAZAART_STICKER ? 0.35 : 0.6

      if (layerAspectRatio < canvasAspectRatio) {
        width = (width * layerAspectRatio) / canvasAspectRatio
      }

      const object = {
        type: objDragging.type,
        centerPoint: {
          y: (clientY - top) / heightFrame,
          x: (clientX - left) / widthFrame,
        },
        sizeOnCanvas: {
          width: width,
        },
        transformation: {
          horizontalFlip: false,
          verticalFlip: false,
        },
        boundingBox: { y: 0, width: 1, height: 1, x: 0 },
        absoluteRotation: 0,
        bazaartGuid: guid,
        layerAssetStateId: assetStateId,
        hasTransparency: maskInfo.hasTransparency,
      }

      if (left <= clientX && clientX <= left + widthFrame && top <= clientY && clientY <= top + heightFrame) {
        editor.handlers.objectsHandler.add(object)
      } else {
        // alert('unvalid position')
      }
      // e.dataTransfer.clearData()
    }, 500)
  }
  // template
  // const updateTemplate = (templateId, templateCategory?) => {
  //   if (templateId) {
  //     setIsLoadedJson(false)
  //     api.getCreationById(templateId).then(async data => {
  //       if (!data) return
  //       if (templateCategory) {
  //         data.config.templateCategory = templateCategory
  //       }
  //       data.config['templateId'] = templateId

  //       dispatch(setTemplateConfig(data.config))
  //       const fonts = []

  //       if (data.config.fonts) {
  //         data.config.fonts.forEach(font => {
  //           fonts.push({
  //             name: font.keySystemName,
  //             url: font.keyFileURL,
  //             options: { weight: '400' },
  //           })
  //         })
  //       }
  //       const filteredFonts = fonts.filter(f => !!f.url)
  //       if (filteredFonts.length > 0) {
  //         await loadFonts(filteredFonts)
  //       }
  //       editor.handlers.transactionHandler.save()
  //       await editor.importFromJSON(data, false)

  //       const eventProperties = {
  //         Source: 'Template',
  //         'Template id': templateId,
  //         'Category id': 0,
  //         'Category Name': 'none',
  //       }
  //       amplitude.track('Editor Open', eventProperties)
  //       setIsLoadedJson(true)
  //     })
  //     const eventProperties = {
  //       'Template ID': templateId,
  //     }
  //     if (templateCategory) {
  //       eventProperties['Template Category'] = templateCategory
  //     }
  //     amplitude.track('Template Click', eventProperties)
  //   }
  // }

  return (
    <div
      id="uibox-editor-container"
      ref={containerRef}
      style={{
        boxSizing: 'border-box',
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
        background: 'transparent',
        fontFamily: 'Poppins-SemiBold' /* default font of add new text on cavans  */
      }}
      onDragOver={e => e.preventDefault()}
      onDrop={e => handleDrop(e)}
      onContextMenu={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <canvas id="canvas"></canvas>
    </div>
  )
}

export default Canvas