import { SyncManager } from "./SyncManager";

export class FolderObject {
    readonly uniqueIdentifier: string;
    private name: string;
    private projectIds: string[] = [];
    private coverProjectId?: string;
    private sortByModified?: boolean;
    private sortNewestToOldest?: boolean;

    constructor(name: string) {
        this.uniqueIdentifier = uuidv4();
        this.name = name;
    }

    get localizedName(): string {
        if (this.isFavorites) {
            return "Favorites"
        } else {
            return this.name;
        }
    }

    get coverUrl(): string | null {
        return null
    }

    get isFavorites(): boolean {
        return this instanceof FavoritesFolderObject;
    }

    get isRecycleBin(): boolean {
        return false;
    }

    // TODO Dror: implement this.
    // addProjects(projects: DraftFeedItem[]): void {
    //     for (const project of projects) {
    //         this.addProject(project.draftGuid);
    //     }
    // }

    addProjectIds(projectIds: string[]): void {
        projectIds.forEach(projectId => this.addProject(projectId));
    }

    addProject(projectId: string): void {
        if (this.projectIds.includes(projectId)) {
            return;
        }

        if (!this.coverProjectId) {
            this.updateCover(projectId);
        }
        this.projectIds.push(projectId);
    }

    removeProjects(projectIds: string[]): void {
        const shouldReplaceCover = this.coverProjectId ? projectIds.includes(this.coverProjectId) : false;

        this.projectIds = this.projectIds.filter(projectId => !projectIds.includes(projectId));

        if (shouldReplaceCover) {
            this.replaceCover();
        }
    }

    private replaceCover(): void {
        if (this.projectIds.length === 0) {
            this.clearCover();
            return;
        }

        this.coverProjectId = this.projectIds[0];
    }

    private clearCover(): void {
        this.coverProjectId = undefined;
    }

    updateCover(projectId: string): void {
        this.coverProjectId = projectId;
    }

    sort(sortByModified?: boolean, sortNewestToOldest?: boolean): void {
        if (sortByModified !== undefined) {
            this.sortByModified = sortByModified;
        }
        if (sortNewestToOldest !== undefined) {
            this.sortNewestToOldest = sortNewestToOldest;
        }
    }

    rename(newName: string): void {
        this.name = newName;
    }

    // Encoding and decoding functions should be implemented if required
    // Converts the instance properties to a JSON string
    toJSON(): any {
        return {
            uniqueIdentifier: this.uniqueIdentifier,
            name: this.name,
            projectIds: this.projectIds,
            coverProjectId: this.coverProjectId,
            sortByModified: this.sortByModified,
            sortNewestToOldest: this.sortNewestToOldest
        };
    }

    // Static method to create a FolderObject from a JSON string
    static fromJSON(data: any): FolderObject {
        const json = (typeof data === 'string') ? JSON.parse(data) : data;
        const folderObject = new FolderObject(json.name);
        folderObject.projectIds = json.projectIds || [];
        folderObject.coverProjectId = json.coverProjectId;
        folderObject.sortByModified = json.sortByModified;
        folderObject.sortNewestToOldest = json.sortNewestToOldest;
        return folderObject;
    }
    
}

export class FavoritesFolderObject extends FolderObject {
    // Implementation based on your specific requirements
}
function uuidv4(): string {
    throw new Error("Function not implemented.");
}

