import Shadow from './Shadow';
import { useEditorContext } from '@/scenes/engine';
import * as PIXI from 'pixi.js';
import { fabric } from 'fabric'

const ShadowText = () => {
  const { activeObject, canvas } = useEditorContext();

  const radianToDegrees = (radians: number): number => {
    let degrees = (radians * 180) / Math.PI
    return degrees
  }

  const applyEffectLayerShadow = async (isReset: boolean, shadowValue: number, distanceValue: number, angleValue: number, colorValue: string, blurValue: number) => {
    const offset = getOffset(angleValue, distanceValue);

    if (isReset) {
        // @ts-ignore
      activeObject.set({ shadow: {} });
    } else {
      const colorHex = new PIXI.Color(colorValue);
      colorHex.setAlpha(shadowValue)
      const rgba = colorHex.toRgbaString()
      activeObject.shadow = {
        color: rgba,
        // @ts-ignore
        distance: distanceValue,
        angle: (angleValue * Math.PI) / 180,
        offsetX: Math.floor(offset.x),
        offsetY: Math.floor(offset.y),
        blur: Math.floor(blurValue),
        is3D: false,
      };
    }
    canvas.renderAll();
  };

  const updateEffect = (finalValue = false) => {
  }

  const updateOptions = (object: any) => { 
    let shadow: fabric.IShadowOptions = object.shadow;

    if (!shadow) {
      return {
        opacity: null,
        distance: null,
        angle: null,
        blur: null,
        color: null,
        promise: () => { }
      }
    }

    function getRadiusAndAngle(offsetX, offsetY) {
      const radius = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
      const radians = Math.atan2(offsetY, offsetX); // Angle in radians
      let angle = radianToDegrees(radians);
      if (angle < 0) {
        angle += 360;
      }
      return { radius, angle };
    }

    let {radius, angle} = getRadiusAndAngle(shadow.offsetY, shadow.offsetX)
    let color = fabric.Color.fromRgba(shadow.color);
    
    return {
      opacity: color.getAlpha(),
      distance: radius,
      angle: angle,
      blur: shadow.blur,
      color: color.toHex(),
      promise: () => { }
    }
  }

  const getOffset = (angleValue: number, distanceValue: number) => {
    const radians = (angleValue * Math.PI) / 180;
    return { x: Math.cos(radians) * distanceValue, y: Math.sin(radians) * distanceValue };
  };

  const calcMinForKey = (key: string): any => {
    switch (key) {
      case 'opacity': return 0.1;
      case 'distance': return 0;
      case 'angle': return 0;
      case 'blur': return 0;
      case 'color': return '#000000';
    }
  }

  const calcMaxForKey = (key: string): any => {
    switch (key) {
      case 'opacity': return 1;
      case 'distance': return 100;
      case 'angle': return 360;
      case 'blur': return 100;
      case 'color': return '#ffffff';
    }
  }
  
  const defaultForKey = (key: string): any => {
    switch (key) {
      case 'opacity': return 0.4;
      case 'distance': return 0.06 * 100;
      case 'angle': return 45;
      case 'blur': return 0.08 * 0.32 * 100;
      case 'color': return '#000000';
    }
  }

  return <Shadow 
    applyEffectLayerShadow={applyEffectLayerShadow} 
    updateOptions={updateOptions} 
    updateEffect={updateEffect} 
    calcMinForKey={calcMinForKey}
    calcMaxForKey={calcMaxForKey}
    defaultForKey={defaultForKey}
    />;
};

export default ShadowText;
