import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useTranslation } from 'react-i18next'

function ModalProjectNotSupport() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()


  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.PROJECT_NOT_SUPPORT}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Can’t open this project'),
        desc: t('Some features of this project are currently not supported on Bazaart web. You can still edit it on your mobile device.'),
      }}
      action={{
        primary: {
          name: t('Ok'),
          handle: () => setShowModalType(null)
        },
      }}
    ></ModalCustom>
  )
}

export default ModalProjectNotSupport