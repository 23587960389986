import { CSSProperties, useEffect, useState } from 'react'
import { LabelLarge, ParagraphXSmall } from 'baseui/typography'
import { styled, useStyletron } from 'baseui'
import { lightTheme } from '@/customTheme'
import Icons from '@/scenes/Editor/components/Icons'
import SliderCustom from '../SliderCustom'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { useEditorContext } from '@/scenes/engine'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { fabric } from 'fabric'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { loadImageFromURL } from '@/scenes/engine/utils/image-loader'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import uniqueId from '@/utils/unique'
import { LayerFilterRenderer } from '@/scenes/engine/utils/layerFilterRenderer'
import { ResizeMode, ResizeOptions } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { ThrottleManager } from '@/firebase/services/sync/dataTypes/ThrottleManager'

const PackItem = styled('div', (props: any) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: '8px',
    position: 'relative',
    boxSizing: 'border-box',
    ':hover div': {
      display: 'block',
    },
  }
})
const Outline0 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '8px',
  border: `1px solid ${props.$active ? 'rgba(0, 0, 0, 0.1)' : 'transparent'}`,
}))
const Outline1 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '8px',
  border: `2px solid ${props.$active ? '#f04a66' : 'transparent'}`,
}))
const Outline2 = styled('div', (props: { $active: boolean }) => ({
  position: 'absolute',
  inset: '2px',
  borderRadius: '6px',
  border: `2px solid ${props.$active ? '#fff' : 'transparent'}`,
}))
const Overlay = styled('div', (props: any) => ({
  position: 'absolute',
  inset: 0,
  borderRadius: '8px',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  display: 'none',
  ...props.style,
}))

function GridCustom({
  itemsPerLine,
  listItem,
  handleSelectItem,
  name,
  actionToggle,
  seeAll,
  style,
  activeIdExternal,
  setActiveIdExternal,
  filterObject = null,
  hasPremium = false,
}: {
  itemsPerLine: number
  // TODO: Blending.tsx listItem is hardcoded
  listItem: any[]
  handleSelectItem: Function
  actionToggle?: Function
  name: string
  seeAll?: boolean
  style?: CSSProperties
  activeIdExternal?: string
  setActiveIdExternal?: Function
  filterObject?: any
  hasPremium?: boolean
}) {
  const dispatch = useAppDispatch()
  const [maxItem, setMaxItem] = useState(listItem.length)
  const SIZE = itemsPerLine === 3 ? '92px' : itemsPerLine === 4 ? '67px' : '52px'
  const [intensity, setIntensity] = useState(100)
  const [activeId, setActiveId] = useState(null)
  const [filterBaseItem, setFilterBaseItem] = useState(null)
  const [isOpenFilterIntensity, setIsOpenFilterIntensity] = useState(false)
  const preloadTextureThrottle = new ThrottleManager<void>();

  // const hasPremium = useSelector(selectUserIsPremium)

  // const [filterObject, setFilterObject] = useState(null)

  // useEffect(() => {
  //   setFilterObject(background ? background : activeObject)
  // }, [background, activeObject])

  useEffect(() => {
    const loadImageForType = async (type: MediaImageType): Promise<string> => {
      let image = await MediaImageRepository.getInstance().getImage(
        filterObject.id,
        filterObject.layerAssetStateId,
        type
      )
      return image;
    }

    const preloadTexture = async () => {
      let imageUrl = 
        await loadImageForType(MediaImageType.fitted) ??
        await loadImageForType(MediaImageType.original) ?? 
        await loadImageForType(MediaImageType.latest) ?? 
        await loadImageForType(MediaImageType.thumbnail);
        
      if (!imageUrl) {
        return
      }

      let resizeOptions: ResizeOptions = {
        allowUpsampling: false,
        exportType: 'image/png',
        pad: false,
        resizeMode: ResizeMode.aspectFill
      }
      
      let resizedBase64Image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBlobToMaxEdgeSize(
        imageUrl, 
        LayerFilterRenderer.getInstance().filterEdgeSize,
        null,
        resizeOptions
      );
      const resizedImage: any = await loadImageFromURL(resizedBase64Image)
      const fabricImage: any = new fabric.StaticImage(resizedImage, {
        scaleX: 1.0,
        scaleY: 1.0,
      })
      
      LayerFilterRenderer.getInstance().clear();
      setFilterBaseItem(fabricImage);
    }
    
    setFilterBaseItem(null);
    preloadTextureThrottle.throttle(100, async () => {
      if (!filterObject){
        return null;
      }
      return preloadTexture();
    });
  }, [filterObject, filterObject && filterObject._originalElement && filterObject._originalElement.currentSrc])

  useEffect(() => {
    if (!filterObject) {
      return
    }
    // @ts-ignore
    if (!filterObject.filter) {
      // @ts-ignore
      filterObject.filter = 'Normal'
      setActiveId('Normal')
    }
    
    // @ts-ignore
    setActiveId(filterObject.filter)
    setIntensity(filterObject.filterIntensity ? Math.round(filterObject.filterIntensity * 100) : 100)
    // @ts-ignore
  }, [filterObject && filterObject.filter])

  
  useEffect(() => {
    if (!filterObject) {
      return
    }
    setIntensity(filterObject.filterIntensity ? Math.round(filterObject.filterIntensity * 100) : 100)
  }, [filterObject && filterObject.filterIntensity])

  useEffect(() => {
    if (!filterObject) {
      return
    }
    setIntensity(
      filterObject.filter === activeIdExternal ? Math.round(filterObject.filterIntensity * 100) : 100
    )
  }, [activeIdExternal])

  useEffect(() => {
    if (seeAll) {
      setMaxItem(listItem.length)
    } else {
      setMaxItem(4)
    }
  }, [seeAll, listItem])

  const handleToggle = () => {
    if (!!actionToggle) {
      actionToggle()
    } else {
      setMaxItem(pre => (pre === 4 ? listItem.length : 4))
    }
  }

  const handleSelect = item => {
    if (isOpenFilterIntensity) {
      return
    }
    if (
      (item.name && item.name.startsWith('CI') ? item.name : `${item.id}_${item.store}`) !== activeIdExternal
    ) {
      // @ts-ignore
      // filterObject.filterIntensity = 1
    }
    !!setActiveIdExternal &&
      setActiveIdExternal(item.name && item.name.startsWith('CI') ? item.name : `${item.id}_${item.store}`)
    handleSelectItem(item, false, true)
  }

  const { t } = useTranslation()
  const [css, theme] = useStyletron()

  

  return (
    <div style={{ width: '300px', paddingRight: '8px', paddingBottom: '18px', ...style }} className="mt-50">
      {name && (
        <div
          style={{
            width: '292px',
            height: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '8px',
            
          }}
        >
          <p style={{ ...lightTheme.typography.Small14Semibold,color:lightTheme.colors.text.text_black_title }}>{name}</p>
          {!seeAll && (
            <ParagraphXSmall
              onClick={() => handleToggle()}
              $style={{
                boxSizing: 'border-box',
                cursor: 'pointer',
                padding: '0 8px',
                borderRadius: '12px',
                ':hover': {
                  background: 'rgb(229, 229, 229)',
                },
                ...lightTheme.typography.Small12medium
              }}
            >
              {maxItem === 4 ? t('See all') : t('See less')}
            </ParagraphXSmall>
          )}
        </div>
      )}
      {listItem && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '8px',
            width: '292px',
            rowGap: '38px',
            position: 'relative',
          }}
        >
          {listItem.length
            ? listItem.slice(0, maxItem > listItem.length ? listItem.length : maxItem).map((item, index) => {
                let isActive =
                  item && item.store && item.id
                    ? activeId === `${item.id}_${item.store}`
                    : activeId === item.name
                
                return (
                  <div
                    key={item.id ?? uniqueId()}
                    onClick={e => {
                      if (hasPremium || index < 3) {
                        handleSelect(item)
                      } else {
                        dispatch(
                          setOpenModalTryPremium({
                            isOpen: true,
                            source: 'BtSubscriptionFilters',
                            callback: () => handleSelect(item),
                          })
                        )
                        const eventProperties = {
                          Source: 'BtSubscriptionFilters',
                          Type: 'Standard',
                        }
                        customAmplitude('Premium Prompt', eventProperties)
                        // @ts-ignore
                        window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
                        return
                      }
                      const eventProperties = {
                        Tool: 'bazaart.style.filter',
                      }
                      if (
                        item.description !== 'Normal' &&
                        (item.name && item.name.startsWith('CI')
                          ? item.name
                          : `${item.id}_${item.store}` !== activeIdExternal)
                      ) {
                        customAmplitude('Selected tool', eventProperties)
                      }
                      if (
                        (item.name && item.name.startsWith('CI') ? item.name : `${item.id}_${item.store}`) !==
                        activeIdExternal
                      ) {
                        // @ts-ignore
                        // filterObject.filterIntensity = 1
                      }
                      !!setActiveIdExternal &&
                        setActiveIdExternal(
                          item.name && item.name.startsWith('CI') ? item.name : `${item.id}_${item.store}`
                        )
                      handleSelectItem(item, false, true)
                    }}
                    className={css({
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      borderRadius: '8px',
                      position: 'relative',
                      boxSizing: 'border-box',
                      ':hover div': {
                        display: 'block',
                      },
                      width: SIZE,
                      height: SIZE,
                    })}
                  >
                    <WrapImage item={{ ...item, activeObj: filterObject, fabricImage: filterBaseItem }} />
                    {item.description && (
                      <p
                        style={{
                          position: 'absolute',
                          margin: 0,
                          top: `calc(100% + 8px)`,
                          left: 0,
                          right: 0,
                          textAlign: 'center',
                          fontSize: lightTheme.typography.Small11medium.fontSize,
                          fontWeight: lightTheme.typography.Small11medium.fontWeight,
                          lineHeight: lightTheme.typography.Small11medium.lineHeight,
                          color: lightTheme.colors.grayScale600,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item.description}
                      </p>
                    )}
                    <Overlay className="overlay"></Overlay>
                    <Overlay
                      className="overlay"
                      style={{
                        backgroundColor: isActive && item.name !== 'Normal' ? 'rgba(0,0,0,0.5)' : null,
                        display: isActive && item.name !== 'Normal' ? 'block' : 'none',
                      }}
                    ></Overlay>
                    <Outline0 $active={!isActive} />
                    <Outline1 $active={isActive} />
                    <Outline2 $active={isActive} />
                    {item.description !== 'Normal' && isActive && (
                      <StatefulPopover
                        content={
                          <div
                            style={{
                              width: '292px',
                              height: '110px',
                              background: '#fff',
                              borderRadius: '16px',
                              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1 )',
                              padding: '24px',
                            }}
                          >
                            <SliderCustom
                              icon={<></>}
                              name={t('Intensity')}
                              minValue={0}
                              maxValue={100}
                              listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                              onChangeValue={value => {
                                setIntensity(value)
                                // @ts-ignore
                                item.filterIntensity = value / 100
                                handleSelectItem(item, true, false)
                              }}
                              value={intensity}
                              onFinalChangeValue={value => {
                                handleSelectItem(item, true, true)
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                        }
                        overrides={{
                          Body: {
                            style: ({ $theme }) => ({
                              zIndex: '100',
                            }),
                          },
                        }}
                        placement={PLACEMENT.bottom}
                        popoverMargin={28}
                        returnFocus
                        autoFocus={false}
                        stateReducer={(a, b, c) => {
                          setIsOpenFilterIntensity(b.isOpen)
                          return b
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            inset: 0,
                            background: 'transparent',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '8px',
                          }}
                        >
                          <Icons.AdjustBar />
                        </div>
                      </StatefulPopover>
                    )}
                    {!hasPremium && index >= 3 ? (
                      <div style={{ position: 'absolute', top: '4px', right: '4px' }}>
                        <Icons.PremiumIcon />
                      </div>
                    ) : null}
                  </div>
                )
              })
            : null}
        </div>
      )}
    </div>
  )
}

export default GridCustom

function WrapImage({ item }) {
  const { editor } = useEditorContext()
  const [isLoaded, setIsLoaded] = useState(false)
  const [srcImage, setSrcImage] = useState(null)

  useEffect(() => {
    const abortController = new AbortController()
    const signal = abortController.signal
    async function getImageFilter() {
      if (item.display_image) {
        let src = item.fabricImage?._originalElement?.src ?? item.display_image
        setSrcImage(src)
        return
      }
      

      if (!item.filterPacks) {
        return
      }
      
      if (!item.fabricImage){
        setSrcImage(null);
        return;
      }

      let fabricImage: any = new fabric.StaticImage(item.fabricImage._originalElement, {
        scaleX: item.fabricImage.scaleX,
        scaleY: item.fabricImage.scaleY,
      });
      fabricImage.useTextureOf(item.fabricImage)

      fabricImage.filter = item.filterName ? item.filterName : `${item.id}_${item.store}`
      fabricImage.filterIntensity = 1.0
      await LayerFilterRenderer.getInstance().render(
        fabricImage, 
        editor.handlers.frameHandler.getSize(),
        item.filterPacks
      )
      if (signal.aborted) {
        return;
      }

      if (fabricImage._element.lazyLoadingSrc) {
        let base64 = await fabricImage._element.lazyLoadingSrc;
        setSrcImage(base64)
      } else {
        setSrcImage(item.fabricImage._originalElement.src)
      }
    }

    item.activeObj && item.activeObj instanceof fabric.Image && getImageFilter()
    return () => {
      abortController.abort()
    }
  }, [editor, item])

  return (
    <>
      {srcImage ? (
        <LazyLoadImage
          width={'100%'}
          height={'100%'}
          src={srcImage}
          alt=""
          style={{ borderRadius: '8px', objectFit: 'cover' }}
          afterLoad={() => {
            setIsLoaded(true)
          }}
        />
      ) : null}
      <PackItem
        style={{
          width: '67px',
          height: '67px',
          background: '#f2f2f2',
          position: 'absolute',
          inset: 0,
          display: !isLoaded ? 'block' : 'none',
          borderRadius: '8px',
        }}
      />
    </>
  )
}
