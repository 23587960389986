import { Rectangle } from "@/scenes/engine/objects/media-repository/rectangle";
import { LayerEffect } from "./LayerEffect";
import { Size } from "@/scenes/engine/objects/media-repository/size";

export class CanvasLayerShadowEffect implements LayerEffect {
    
    // in iOS implementation the blur is implemented as two layers being composited on ontop of another
    // 1. The shadow image is the original image but with a color overlay and some kind of blur. 
    // This image is always centered so that the spread of the blur fits entirely without being cropped
    // 2. The image itself is composited in some kind of an offset based on the distance and angle parameters.
    applyEffectBounds(item: any, bounds: Rectangle): Rectangle {
        const shadow = item.effects.shadow
        if (!shadow) {
          return bounds;
        }
        const maxEdge = Math.max(bounds.width, bounds.height);
        const blur = maxEdge * shadow.blur
        const distance = shadow.distance
    
        const offsetWidth = Math.cos(shadow.angle) * distance * maxEdge
        const offsetHeight = Math.sin(shadow.angle) * distance * maxEdge
    
        let targetWidth = bounds.width + Math.abs(offsetWidth) + blur + Math.max(blur - Math.abs(offsetWidth), 0)
        let targeHeight = bounds.height + Math.abs(offsetHeight) + blur + Math.max(blur - Math.abs(offsetHeight), 0)
    
        let x = bounds.x + Math.min(offsetWidth - blur, 0)
        let y = bounds.y + Math.min(offsetHeight - blur, 0)
    
        let rect = new Rectangle(x, y, targetWidth, targeHeight);
        return rect
    }

    revertEffectBounds(item: any, canvasSize: Size, isSymmetric: boolean, boundsWithEffects: Rectangle): Rectangle {
        let widthWithShadow = boundsWithEffects.width;
        let heightWithShadow = boundsWithEffects.height;

        let shadow = item.effects?.shadow
        let w = widthWithShadow, h = heightWithShadow;
        let x = boundsWithEffects.x, y = boundsWithEffects.y;

        if (!shadow || (shadow.distance == 0 && shadow.blur == 0)) {
         let rect = new Rectangle(x, y, w, h);
         return rect;
        }

        let offsetX = shadow.distance * Math.cos(shadow.angle)
        let offsetY = shadow.distance * Math.sin(shadow.angle)

        let actualWidth = item.sizeOnCanvas.width * canvasSize.width;
        let actualHeight = item.sizeOnCanvas.height * canvasSize.height;
        let isWide = actualWidth > actualHeight;
        

        // reversing this equation: 
        // newRect = CGRect(x: rect.origin.x-padding, y: rect.origin.y-padding, width: rect.size.width + 2*padding, height: rect.size.height + 2*padding)
        if (isSymmetric){ 
            if (isWide){
                // widthWithShadow = w + 2*(shadow.blur + offsetX)*w
                // widthWithShadow = w(1 + 2*(shadow.blur + offsetY))
                w = widthWithShadow / (1 + 2*(shadow.blur + offsetX))

                // heightWithShadow = h + 2*(shadow.blur + offsetY)*w
                // h = heightWithShadow - 2*(shadow.blur + offsetY)*w
                h = heightWithShadow - 2*(shadow.blur + offsetY)*w

                x = boundsWithEffects.x - (shadow.blur + offsetX) * w
                y = boundsWithEffects.y - (shadow.blur + offsetY) * w
            } else {
                h = heightWithShadow / (1 + 2*(shadow.blur + offsetY))
                w = widthWithShadow - 2*(shadow.blur + offsetX)*h
                
                x = boundsWithEffects.x - (shadow.blur + offsetX) * h
                y = boundsWithEffects.y - (shadow.blur + offsetY) * h
            }
            
            let signX = offsetX < 0 ? -1 : 1
            let signY = offsetY < 0 ? -1 : 1  
            let rect = new Rectangle(x * signX, y * signY, w, h);
            return rect;
        }

        // originalImage.width + originalImage.width * blur + originalImage.width * cos(angle) * distance + originalImage.width * max(blur - abs(offset.width), 0) = img.width
        // originalImage.height + originalImage.width * blur + originalImage.width * sin(angle) * distance + originalImage.width * max(blur - abs(offset.height), 0) = img.height
        if (isWide) {
            // replacing max with conditions
            // let blur = shadow.blur
            // originalImage.width + originalImage.width * blur + originalImage.width * cos(angle) * distance + max(blur - abs(offset.width), 0) = img.width
            // originalImage.width * (1 + blur + cos(angle) * distance)=  img.width
            
            // originalImage.width = img.width / (1 + blur + cos(angle) * distance)
            // originalImage.height = img.height - (originalImage.width * blur + originalImage.width * sin(angle) * distance)
            
            // w = originalWidth / (1 + shadow.blur * Math.cos(shadow.angle) * shadow.distance)
            // h = originalHeight - (w * shadow.blur + w * Math.sin(shadow.angle) * shadow.distance)

            w = widthWithShadow / (1 + shadow.blur + Math.abs(offsetX) + Math.max(shadow.blur - Math.abs(offsetX), 0));
            h = heightWithShadow - (w * shadow.blur + w * Math.abs(offsetY) + w * Math.max(shadow.blur - Math.abs(offsetY), 0))
        } else {
            h = heightWithShadow / (1 + shadow.blur + Math.abs(offsetY) + Math.max(shadow.blur - Math.abs(offsetY), 0));
            w = widthWithShadow - (h * shadow.blur + h * Math.abs(offsetX) + h * Math.max(shadow.blur - Math.abs(offsetX), 0))
        }

        let maxEdge = Math.max(w, h);
        let signX = offsetX < 0 ? -1 : 1
        let signY = offsetY < 0 ? -1 : 1  

        let offsetWidth = Math.abs(offsetX) * maxEdge
        let offsetHeight = Math.abs(offsetY) * maxEdge
        let blur = shadow.blur * maxEdge

        let oldCenter = {
            x: w / 2,
            y: h / 2
        }

        let newCenter = {
            x: (Math.min(offsetWidth - blur, 0) + widthWithShadow / 2),
            y: (Math.min(offsetHeight - blur, 0) + heightWithShadow/ 2)
        };

        x = newCenter.x - oldCenter.x;
        y = newCenter.y - oldCenter.y;

        let rect = new Rectangle(x * signX, y * signY, w, h);
        return rect;
    }
}