import { DeviceCloudMetadata } from "./DeviceCloudMetadata";
import { DraftSyncVersion } from "./DraftSyncVersion";
import { FolderObject } from "./FolderObject";
import { ProjectFileMetaData } from "./ProjectFileMetaData";
import { UserDevice } from "./UserDevice";
import { VersionScreenshotInfo } from "./VersionScreenshotInfo";

export enum DecodableKeys {
    deviceCloudMetadata = "DeviceCloudMetadata",
    draftSyncVersion = "DraftSyncVersion",
    projectFileMetaData = "ProjectFileMetaData",
    userDevice = "UserDevice",
    versionScreenshotInfo = "VersionScreenshotInfo",
    folderObject = "FolderObject"
}

export type DecodableValues = DeviceCloudMetadata | DraftSyncVersion | ProjectFileMetaData | UserDevice | VersionScreenshotInfo | FolderObject;

export class SyncDecoder<T extends DecodableValues> {
    
    convertArray(buffer: ArrayBuffer): T[] {
        let result = this.convert(buffer)
        return this.isArray(result) ? result : [result]
    }

    convertSingle(buffer: ArrayBuffer): T | null {
        let result = this.convert(buffer)
        if (!result){ return null;}
        return this.isArray(result) ? result[0] : result
    }

    convert(buffer: ArrayBuffer): T | T[] {
        let converted = this.arrayBufferToJson(buffer)
        if (this.isArray(converted)) {
            let convertedArray = converted as any[]
            let array = [];
            for (let value of convertedArray) {
                let obj = (typeof value === 'string') ? JSON.parse(value as string) : value;
                let parsedObject = this.parse(obj)
                if (!!parsedObject){
                    array.push(parsedObject)
                }
            }
            return array
        } 
        return this.parse(converted)
    }

    private arrayBufferToJson(buffer: ArrayBuffer): any {
        // Step 1: Convert ArrayBuffer to String
        const textDecoder = new TextDecoder('utf-8');
        const jsonString = textDecoder.decode(buffer);
    
        // Step 2: Parse String to JSON
        const jsonObject = JSON.parse(jsonString);
        return jsonObject;
    }

    private isArray(value: any): value is any[] {
        return Array.isArray(value);
    }

    private parse(object: any): T | null {
        if (Object.keys(object).length == 0) {
            return object as T
        }
        let key = Object.keys(object)[0]
        let value = object[key];
        let className = key.split('.').pop();
        let convertObject = this.convertSingleValue(className, value);
        return convertObject
    }

    private convertSingleValue(key: string, value: any): T | null{
        if (key === DecodableKeys.deviceCloudMetadata) {
            return DeviceCloudMetadata.fromJSON(value) as T;
        }
        if (key === DecodableKeys.draftSyncVersion) {
            return DraftSyncVersion.fromJSON(value) as T;
        }
        if (key === DecodableKeys.projectFileMetaData) {
            return ProjectFileMetaData.fromJSON(value) as T;
        }
        if (key === DecodableKeys.userDevice) {
            return UserDevice.fromJSON(value) as T;
        }
        if (key === DecodableKeys.versionScreenshotInfo) {
            return VersionScreenshotInfo.fromJSON(value) as T;
        }
        if (key === DecodableKeys.folderObject) {
            return FolderObject.fromJSON(value) as T;
        }
        console.error(`not implemented conversionn for type ${key}`);
        return null;
    }
}