import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND, SHAPE } from 'baseui/button'
import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import Icons from '../Icons'
import { lightTheme } from '@/customTheme'

function ModalCustom({
  isOpen,
  onClose,
  content,
  action,
}: {
  isOpen: boolean
  onClose: Function
  content: {
    header: string
    desc: string
  }
  action: {
    primary: {
      name: string
      handle: Function
    }
    secondary?: { name: string; handle: Function }
  }
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      animate
      closeable={true}
      focusLock={false}
      returnFocus={false}
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            display: 'flex',
            flexDirection: 'column',
            zIndex: 10000,
            inset: 0,
            pointerEvents: 'none'
          }),
        },
        Dialog: {
          style: ({ $theme, $isVisible, $isOpen }) => ({
            width: '460px',
            // height: '190px',
            padding: '24px',
            ...lightTheme.modal.DialogStyle({ $theme, $isOpen, $isVisible })
          }),
        },
        Backdrop: {
          style: ({ $theme }) => ({
            ...lightTheme.modal.BackdropStyle
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: 'none'
          })
        }
      }}
    >
      <ModalHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'center',
          margin: '0',
          minHeight: '48px',
          paddingBottom: '16px',
        }}
      >
        <p style={{...lightTheme.typography.Header20bold}}>{content.header}</p>

        <ButtonCustom
          shape={SHAPE.square}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          onClick={() => onClose(false)}
        >
          <Icons.Close size={24} />
        </ButtonCustom>
      </ModalHeader>
      <ModalBody style={{ display: 'flex', flexDirection: 'row', gap: '10px', margin: '0', fontWeight: 500, color: 'rgba(102, 102, 102, 1)' }}>
        {content.desc}
      </ModalBody>
      <ModalFooter
        style={{
          height: '72px',
          margin: '0',
          padding: '0',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'end',
          gap: '8px',
        }}
      >
        {action.secondary && (
          <ButtonCustom
            kind={KIND.secondary}
            type={SizeButton.STANDARD}
            onClick={() => {
              action.secondary.handle()
            }}
            style={{
              width: 'fit-content',
              ...lightTheme.typography.Small14Semibold,
              color: lightTheme.colors.contentPrimary,
              borderRadius: '360px'
            }}
          >
            <>{action.secondary.name}</>
          </ButtonCustom>
        )}
        {action.primary && (
          <ButtonCustom
            kind={KIND.primary}
            type={SizeButton.STANDARD}
            onClick={() => {
              action.primary.handle()
            }}
            style={{
              width: 'fit-content',
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
              borderRadius: '360px',
              minWidth: '80px'
            }}
          >
            <>{action.primary.name}</>
          </ButtonCustom>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ModalCustom