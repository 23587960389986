import { StorageReference, StorageError } from "firebase/storage";
import { DownloadTask } from "./DownloadTask";

export class DownloadTaskWithDisconnectionHandling extends DownloadTask {
    private isOnline: boolean;

    constructor(storageRef: StorageReference) {
        super(storageRef);
        this.isOnline = navigator.onLine;

        // Listen for online and offline events
        window.addEventListener('online', this.handleOnline.bind(this));
        window.addEventListener('offline', this.handleOffline.bind(this));
    }

    private handleOnline() {
        this.isOnline = true;
        console.log('Internet connection restored.');
    }

    private handleOffline() {
        this.isOnline = false;
        console.log('Internet connection lost.');

        // Trigger completion and error callbacks
        // @ts-ignore
        const storageError: StorageError = {
            name: 'NetworkError',
            message: 'Internet connection lost during download.',
            code: 'network-disconnected',
            customData: {
                serverResponse: null
            },
            serverResponse: null, // Ensure serverResponse is included
            status: 0,
            status_: undefined
        };
        this.errorCallbacks.forEach(callback => callback(storageError));

        this.completeCallbacks.forEach(callback => callback());
    }

    startDownload(fileName: string, completion?: (fileURL: string | null, error: Error | null) => void): void {
        if (!this.isOnline) {
            console.error('Cannot start download: no internet connection.');
            if (completion) {
                completion(null, new Error('Cannot start download: no internet connection.'));
            }
            return;
        }

        super.startDownload(fileName, completion);
    }

    cancel(): boolean {
        // Clean up event listeners on cancel
        window.removeEventListener('online', this.handleOnline.bind(this));
        window.removeEventListener('offline', this.handleOffline.bind(this));
        return super.cancel();
    }
}
