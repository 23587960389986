import Icons from '../../../Icons'
import { Button, KIND } from 'baseui/button'
import { StatefulPopover, PLACEMENT, ACCESSIBILITY_TYPE, STATE_CHANGE_TYPE } from 'baseui/popover'
import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import SliderCustom from '@/components/SliderCustom'
import { styled } from 'baseui'
import * as amplitude from '@amplitude/analytics-browser'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { lightTheme } from '@/customTheme'
import { StaticText } from 'fabric/fabric-impl'

const WrapPopover = styled('div', props => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  gap: '16px',
  position: 'absolute',
  width: '292px',
  height: '188px',
  background: '#ffffff',
  border: '1px solid #e5e5e5',
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.15)',
  borderRadius: '16px',
  right: '-34px',
  zIndex: '1000',
}))
const LineSpacing = styled('div', props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '12px',
  width: '244px',
  height: '62px',
  background: '#ffffff',
  color: '#666666',
}))
interface Options {
  charSpacing: number
  lineHeight: number
}

function Spacing() {
  const activeObject = useEditorContext().activeObject as StaticText
  const [options, setOptions] = useState<Options>({ charSpacing: 0, lineHeight: 0 })
  const [preOptions, setPreOptions] = useState<Options>({ charSpacing: 0, lineHeight: 0 })
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const [activeObjectState, setActiveObjectState] = useState({
    selectionStart: 0,
    selectionEnd: 0,
    isTextEditing: false,
  })
  const editor = useEditorContext().editor
  useEffect(() => {
    updateOptions(activeObject)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject])

  useEffect(() => {
    if (activeObject) {
      updateOptions(activeObject)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeObject])

  const updateOptions = (object: any) => {
    if (object) {
      const { charSpacing, lineHeight } = object
      setOptions({ ...options, charSpacing: charSpacing, lineHeight: lineHeight })
      setPreOptions({ ...options, charSpacing: charSpacing, lineHeight: lineHeight })
    }
  }

  const handleChange = (type: string, value: number, finalValue = false) => {
    if (editor) {
      if (activeObject.isEditing) {
        setActiveObjectState({
          selectionStart: activeObject.selectionStart,
          selectionEnd: activeObject.selectionEnd,
          isTextEditing: activeObject.isEditing,
        })
        activeObject.exitEditing()
      }
      if (type === 'charSpacing') {
        setOptions({ ...options, [type]: value })
        const charSpacingValue = value
        let sliderOptions: any = { isSliderUpdate: finalValue }
        if (finalValue) {
          sliderOptions = {
            isSliderUpdate: finalValue,
            property: ['charSpacing'],
            value: [preOptions.charSpacing],
          }
          setPreOptions({ ...preOptions, [type]: charSpacingValue })
          if(activeObjectState.isTextEditing) {
            activeObject.enterEditing()
            activeObject.setSelectionStart(activeObjectState.selectionStart)
            activeObject.setSelectionEnd(activeObjectState.selectionEnd)
          }
        }
        editor.handlers.objectsHandler.updateActive({ [type]: charSpacingValue }, null, sliderOptions)
      } else {
        setOptions({ ...options, [type]: value })
        const lineHeightValue = value
        console.log('lineHeightValue', lineHeightValue)
        let sliderOptions: any = { isSliderUpdate: finalValue }
        if (finalValue) {
          sliderOptions = {
            isSliderUpdate: finalValue,
            property: ['lineHeight'],
            value: [preOptions.lineHeight],
          }
          setPreOptions({ ...preOptions, [type]: lineHeightValue })
          if(activeObjectState.isTextEditing) {
            activeObject.enterEditing()
            activeObject.setSelectionStart(activeObjectState.selectionStart)
            activeObject.setSelectionEnd(activeObjectState.selectionEnd)
          }
        }
        editor.handlers.objectsHandler.updateActive({ [type]: lineHeightValue }, null, sliderOptions)
      }
    }
  }

  const { t } = useTranslation()

  return (
    <StatefulPopover
      stateReducer={(a, b, c) => {
        setIsOpenPopover(b.isOpen)
        return b
      }}
      focusLock
      placement={PLACEMENT.bottom}
      content={({ close }) => (
        <WrapPopover>
          <LineSpacing>
            <SliderCustom
              icon={<Icons.LetterSpacing size={24} />}
              name={t('Letter spacing')}
              minValue={1}
              maxValue={100}
              logicMinimum={0}
              logicMaximum={1000}
              listValues={[1, 10, 20, 40, 60, 100]}
              onChangeValue={(uiValue, logicValue) => {
                handleChange('charSpacing', logicValue)
              }}
              onFinalChangeValue={(uiValue, logicValue) => {
                const eventProperties = {
                  Tool: 'bazaart.style.charSpacing',
                  'Layer Type': activeObject.type,
                }
                customAmplitude('Selected tool', eventProperties)
                handleChange('charSpacing', logicValue, true)
              }}
              value={Math.round(options.charSpacing)}
              style={{marginTop: 0}}
            />
          </LineSpacing>
          <LineSpacing>
            <SliderCustom
              icon={<Icons.LineSpacing size={24} />}
              name={t('Line spacing')}
              minValue={1}
              maxValue={100}
              logicMinimum={1}
              logicMaximum={100}
              listValues={[1, 10, 20, 40, 60, 100]}
              onChangeValue={(value, logicValue) => {
                handleChange('lineHeight', logicValue / 10)
              }}
              onFinalChangeValue={(value, logicValue) => {
                const eventProperties = {
                  Tool: 'bazaart.style.lineHeight',
                  'Layer Type': activeObject.type,
                }
                customAmplitude('Selected tool', eventProperties)
                handleChange('lineHeight', logicValue / 10, true)
              }}
              value={Math.round(options.lineHeight * 10)}
              style={{marginTop: 0}}
            />
          </LineSpacing>
        </WrapPopover>
      )}
      autoFocus={false}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            zIndex: 110,
          }),
        },
      }}
    >
      <Button
        kind={KIND.tertiary}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              width: '67px',
              height: '40px',
              border: isOpenPopover ? 'none' : '1px solid #E5E5E5',
              padding: 0,
              ':hover': {
                border: 'none',
                background: isOpenPopover ? lightTheme.colors.grayScale100 : '#F2F2F2',
              },
              background: isOpenPopover ? lightTheme.colors.grayScale100 : '#fff',
            }),
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icons.Spacing size={24} />
          <Icons.Drop />
        </div>
      </Button>
    </StatefulPopover>
  )
}

export default Spacing
