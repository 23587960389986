import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

function ModalNoInternet() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.NO_INTERNET}
      onClose={() => {}}
      content={{
        header: t('Looks like there’s no internet'),
        desc: t(`Connect to the internet to ${location.pathname.includes('projects') ? 'open' : 'save'} this project.`),
      }}
      action={{
        primary: {
          name: t('Ok'),
          handle: () => setShowModalType(null)
        },
      }}
    ></ModalCustom>
  )
}

export default ModalNoInternet