import {Point} from "./point";
import {Size} from "./size";

export class Rectangle {
    x: number;
    y: number;
    height: number;
    width: number;

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.height = height;
        this.width = width;
    }

    center(): Point {
        let point = new Point(this.x + this.width / 2, this.y + this.height / 2);
        return point;
    }

    multiply(size: Size): Rectangle {
        let newRectangle = new Rectangle(
            Math.floor(this.x * size.width),
            Math.floor(this.y * size.height),
          Math.ceil(this.width * size.width),
          Math.ceil(this.height * size.height),
        );
        return newRectangle;
    }

    aspectRatio(): number {
        return this.height / this.width;
    }

    isLandscape(): boolean {
        return this.width >= this.height;
    }

    maxX(): number {
        return this.x + this.width;
    }

    maxY(): number {
        return this.y + this.height;
    }
}
