export class RecycleBinManager {
    static shared = new RecycleBinManager();

    addedById: { [key: string]: { exist: () => boolean } };

    private constructor() {
        this.addedById = {};
    }

    addItem(draftId: string) {
        this.addedById[draftId] = {
            exist: () => true
        };
    }

    removeItem(draftId: string) {
        delete this.addedById[draftId];
    }

    itemExists(draftId: string): boolean {
        return this.addedById[draftId]?.exist() || false;
    }

    cleanup(localOnly: boolean) {
        // TODO Dror: implement me
    }

    didDeleteProjects(projectIds: string[]) {

    }
    
    uploadingProject(projectId: string) {

    }

    didLoggedOutFromCloud() {

    }
}
