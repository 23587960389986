import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill={lightTheme.colors.blackGray}
      d="M18.702 16.75H5.828C2.528 16.75 0 14.342 0 11.452 0 9.09 1.32 7.157 3.467 6.748c.112-2.63 2.584-4.396 4.992-3.792C9.658 1.236 11.544 0 13.999 0c3.95 0 7.008 3.058 7.008 7.297C22.866 8.068 24 9.862 24 11.86c0 2.714-2.342 4.889-5.298 4.889Zm0-2.064c1.794 0 3.188-1.264 3.188-2.825 0-1.618-1.078-2.78-2.76-3.077-.13-.019-.186-.093-.168-.232.047-.456.084-1.023.056-1.562-.14-2.668-2.184-4.917-5.038-4.917-2.259 0-3.588 1.208-4.518 2.826-.065.102-.139.13-.26.092-2.054-.622-3.987.363-3.894 3.077.009.14-.056.214-.186.214-1.86 0-3.021 1.32-3.021 3.17 0 1.738 1.598 3.225 3.736 3.225l12.865.01Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      d="M12.005 13.498a.958.958 0 0 1-.958-.938V9.93l.121-1.617-.576.697-.894.912a.91.91 0 0 1-.632.27c-.456 0-.837-.335-.837-.809a.77.77 0 0 1 .26-.595l2.772-2.585c.26-.233.483-.326.744-.326.25 0 .483.093.734.326l2.771 2.585a.77.77 0 0 1 .26.595c0 .474-.39.809-.836.809a.91.91 0 0 1-.632-.27l-.894-.912-.567-.697.112 1.617v2.63a.956.956 0 0 1-.948.938Z"
    />
  </svg>
)
export default SvgComponent