import { DateProvider } from "@/firebase/services/DateProvider";
import { FileSystemRecord, VirtualFileSystem } from "./VirtualFileSystem";
import { MediaImageType } from "./media_image_type"
import { RepositoryToUrlMap } from "./repositoryToUrlMap";

export class ImageFileSystemRecord extends FileSystemRecord {
  
  get layerId(): string  {
    return this.addtionalMetadata.layerId as string
  }

  get stateId(): string  {
    return this.addtionalMetadata.stateId as string
  }

  get mediaType(): MediaImageType  {
    return this.addtionalMetadata.mediaType as MediaImageType
  }

  static from(record: FileSystemRecord): ImageFileSystemRecord{
    let mediaRecord = new ImageFileSystemRecord(record.blobUrl, 
        record.modificationDate, 
        record.createdDate, 
        record.size, 
        record.systemFileNumber, 
        record.addtionalMetadata?.layerId, 
        record.addtionalMetadata?.stateId, 
        record.addtionalMetadata?.mediaType,
        record.format
      );
      return mediaRecord;
    }
  /**
   *
   */
  constructor(
    blobUrl: string,
    modificationDate: Date,
    createdDate: Date,
    size: number,
    systemFileNumber: number,
    layerId: string,
    stateId: string,
    mediaType: MediaImageType,
    format: string
  ) {

    let metadata = {
      layerId: layerId,
      stateId: stateId,
      mediaType: mediaType
    }

    super(blobUrl,
      modificationDate,
      createdDate, 
      size,
      systemFileNumber,
      false,
      metadata);

    this.format = format
  }
}

export class ImageFilesystem {
    private defaultFormat = 'png';

  // thanks - https://www.typescriptlang.org/docs/handbook/utility-types.html
   // project_id | layer_id | state_id | asset_type | modified_date | created_date | blob_url (edited) 

    projectId: string;

    constructor(projectId: string) { 
      this.projectId = projectId;
    }

    private getUrlWithExtension(originalLayer: string, imgType: MediaImageType, layerStateId: string){
      let url = RepositoryToUrlMap.constructUrl(this.projectId, originalLayer, imgType as MediaImageType, layerStateId)
      let extension = VirtualFileSystem.getInstance().getFileExtensionForUrl(url) ?? this.defaultFormat;
      let urlWithExtension = RepositoryToUrlMap.constructUrl(this.projectId, originalLayer, imgType as MediaImageType, layerStateId, extension)
      return urlWithExtension
    }
    
    async duplicate(originalLayer: string, newLayer: string, layerStateId: string) {
      let urls = Object.entries(MediaImageType).map(([key, value]) => {
        let src = this.getUrlWithExtension(originalLayer, value as MediaImageType, layerStateId)
        let target = this.getUrlWithExtension(newLayer, value as MediaImageType, layerStateId)
        return {src, target}
      })

      let promises = urls.map(async (url) => {
        await VirtualFileSystem.getInstance().copy(url.src, url.target)
      })
      return Promise.all(promises);
    }

    removeImage(layerId: string, layerStateId: string, imgType: MediaImageType) {
        let url = this.getUrlWithExtension(layerId, imgType as MediaImageType, layerStateId)
        VirtualFileSystem.getInstance().delete(url)
    }

    getRecord(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<FileSystemRecord | null> {
      let url = this.getUrlWithExtension(layerId, imgType as MediaImageType, layerStateId)
      let record = VirtualFileSystem.getInstance().get(url)
      return Promise.resolve(record);
    }

    async getImage(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<string | null> {
      let record = await this.getRecord(layerId, layerStateId, imgType)
      return Promise.resolve(record?.blobUrl);
    }

    async getImagePath(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<string | null> {
      let url = this.getUrlWithExtension(layerId, imgType as MediaImageType, layerStateId)
      return url
    }

    setImage(layerId: string, layerStateId: string, imgType: MediaImageType, image: string): Promise<boolean> {
      let url = RepositoryToUrlMap.constructUrl(this.projectId, layerId, imgType as MediaImageType, layerStateId, this.defaultFormat)
      return new Promise(async (resolve, reject) => {
        let size = await VirtualFileSystem.getInstance().extractSizeFromBlobUrl(image);
        let systemFileNumber = VirtualFileSystem.getInstance().generateRandom12DigitNumber();
        let creationDate =  DateProvider.getUTCDate()
        let record = new ImageFileSystemRecord(image,
          creationDate,
          creationDate,
          size,
          systemFileNumber,
          layerId,
          layerStateId,
          imgType,
          this.defaultFormat
        )

        VirtualFileSystem.getInstance().write(url, record)
        return resolve(true);
      })
    }
}
