import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : lightTheme.colors.blackGray}
      fillRule="evenodd"
      d="m2.832 6 4.17-3.996A1.148 1.148 0 1 0 5.414.347L.344 5.205A1.1 1.1 0 0 0 0 6c0 .298.124.584.344.795l5.07 4.858a1.148 1.148 0 1 0 1.588-1.657L2.832 6Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
