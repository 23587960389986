import Background from './Background'
import Photos from './Photos'
import Text from './Text'
import Templates from './Templates'
import Delete from './Delete'
import Locked from './Locked'
import UnLocked from './Unlocked'
import Bold from './Bold'
import Italic from './Italic'
import TextColor from './TextColor'
import FillColor from './FillColor'
import Images from './Images'
import TextAlignCenter from './TextAlignCenter'
import TextAlignRight from './TextAlignRight'
import TextAlignLeft from './TextAlignLeft'
import TextAlignJustify from './TextAlignJustify'
import Underline from './Underline'
import Opacity from './Opacity.'
import Duplicate from './Duplicate'
import CopyIcon from './CopyIcon'
import PasteIcon from './PasteIcon'
import ToFront from './ToFront'
import Forward from './Forward'
import ToBack from './ToBack'
import Backward from './Backward'
import Undo from './Undo'
import Redo from './Redo'
import ChevronLeft from './ChevronLeft'
import Spacing from './Spacing'
import CopyStyle from './CopyStyle'
import TimeFast from './TimeFast'
import Layers from './Layers'
import Back from './Back'
import UploadsIcon from './Uploads'
import UploadsIconLarge from './UploadsIconLarge'
import Adjust from './Adjust'
import Close from './Close'
import AA from './AA'
import LetterSpacing from './LetterSpacing'
import LineSpacing from './LineSpacing'
import Drop from './Drop'
import FillText from './FillText'
import Curve from './Curve'
import Outline from './Outline'
import Shadow from './Shadow'
import HighLight from './HighLight'
import FlipH from './FlipH'
import FlipV from './FlipV'
import Stretch from './Stretch'
import Distort from './Distort'
import BackRight from './BackRight'
import Scissors from './Scissors'
import Crop from './Crop'
import Erase from './Erase'
import Magic from './Magic'
import Exposure from './Exposure'
import Contrast from './Contrast'
import Saturation from './Saturation'
import Vibrance from './Vibrance'
import Warmth from './Warmth'
import Tint from './Tint'
import Fade from './Fade'
import Sharpness from './Sharpness'
import Blur from './Blur'
import Distance from './Distance'
import Angle from './Angle'
import UploadsImage from './UploadsImage'
import DropImage from './DropImage'
import DropImageSmall from './DropImageSmall'
import Resize from './Resize'
import Portrait from './Portrait'
import Square from './Square'
import Circle from './Circle'
import PortraitSmall from './PortraitSmall'
import Landscape from './Landscape'
import WallPaper from './WallPaper'
import CustomSize from './CustomSize'
import InstagramIcon from './InstagramIcon'
import FacebookIcon from './FacebookIcon'
import VideoPortrait from './VideoPortrait'
import VideoSquare from './VideoSquare'
import VideoLandscape from './VideoLandscape'
import InspectorArrow from './InspectorArrow'
import AmazonProduct from './AmazonProduct'
import AmazonHeader from './AmazonHeader'
import EbayIcon from './EbayIcon'
import Edit from './Edit'
import ShopifyIcon from './ShopifyIcon'
import Replace from './Replace'
import BringFront from './BringFront'
import BringBack from './BringBack'
import AdjustBar from './AdjustBar'
import DepopIcon from './DepopIcon'
import EstyIcon from './EstyIcon'
import Pinterest from './Pinterest'
import YoutubeIcon from './YoutubeIcon'
import ZoomIcon from './ZoomIcon'
import TiktokIcon from './TiktokIcon'
import WattpadIcon from './WattpadIcon'
import Linkedin from './Linkedin'
import Lemon from './Lemon'
import Twitter from './Twitter'
import Whatsapp from './Whatsapp'
import Snapchat from './Snapchat'
import Poshmask from './Poshmask'
import DefaultAvatar from './DefaultAvatar'
import FbIcon from './FbIcon'
import GGIcon from './GGIcon'
import AppleIcon from './Apple'
import WhiteCloseIcon from './WhiteCloseIcon'
import PremiumIcon from './PremiumIcon'
import CheckIcon from './CheckIcon'
import PaypalIcon from './PaypalIcon'
import ReplaceImage from './ReplaceImage'
import BrushIcon from './BrushIcon'
import CardIcon from './CardIcon'
import CVVIcon from './CVVIcon'
import BrushSoftnessIcon from './BrushSoftnessIcon'
import RestoreIcon from './RestoreIcon'
import HelpIcon from './HelpIcon'
import Dots from './Dots'
import DotsIcon from './DotsIcon'
import ArrowRight from './ArrowRight'
import ArrowLeft from './ArrowLeft'
import RedArrowRight from './RedArrowRight'
import FillIcon from './FillIcon'
import FitIcon from './FitIcon'
import GroupIcon from './GroupIcon'
import UnGroupIcon from './UnGroupIcon'
import PlusIcon from './PlusIcon'
import NormalBrush from './NormalBrush'
import SoftBrush from './SoftBrush'
import SofterBrush from './SofterBrush'
import HardBrush from './HardBrush'
import ProjectsIcon from './ProjectsIcon'
import HouseIcon from './HouseIcon'
import ProjectIcon from './ProjectIcon'
import DownloadIcon from './DownloadIcon'
import EmptyProjectsIcon from './EmptyProjectsIcon'
import CursorIcon from './CursorIcon'
import GoodWorkIcon from './GoodWorkIcon'
import LockIcon from './LockIcon'
import UnLockIcon from './UnLockIcon'
import CustomArrow from './CustomArrow'
import ArrowMedium from './ArrowMedium'
import MobileIcon from './MobileIcon'
import AppStoreIcon from './AppStoreIcon'
import GGPlayIcon from './GGPlayIcon'
import SaveProjectIcon from './SaveProjectIcon'
import SavedIcon from './SavedIcon'
import NowPremiumIcon from './NowPremiumIcon'


class Icons {
  static Background = Background
  static BackRight = BackRight
  static Photos = Photos
  static Text = Text
  static Templates = Templates
  static Delete = Delete
  static Locked = Locked
  static UnLocked = UnLocked
  static Bold = Bold
  static Italic = Italic
  static TextColor = TextColor
  static FillColor = FillColor
  static Images = Images
  static TextAlignRight = TextAlignRight
  static TextAlignCenter = TextAlignCenter
  static TextAlignLeft = TextAlignLeft
  static TextAlignJustify = TextAlignJustify
  static Underline = Underline
  static Opacity = Opacity
  static Duplicate = Duplicate
  static CopyIcon = CopyIcon
  static PasteIcon = PasteIcon
  static ToFront = ToFront
  static Forward = Forward
  static ToBack = ToBack
  static Backward = Backward
  static Undo = Undo
  static Redo = Redo
  static ChevronLeft = ChevronLeft
  static Spacing = Spacing
  static CopyStyle = CopyStyle
  static TimeFast = TimeFast
  static Layers = Layers
  static Back = Back
  static UploadsIcon = UploadsIcon
  static UploadsIconLarge = UploadsIconLarge
  static Adjust = Adjust
  static Close = Close
  static AA = AA
  static LetterSpacing = LetterSpacing
  static LineSpacing = LineSpacing
  static Drop = Drop
  static FillText = FillText
  static Curve = Curve
  static Outline = Outline
  static Shadow = Shadow
  static HighLight = HighLight
  static FlipH = FlipH
  static FlipV = FlipV
  static Stretch = Stretch
  static Distort = Distort
  static Scissors = Scissors
  static Crop = Crop
  static Erase = Erase
  static Magic = Magic
  static Exposure = Exposure
  static Contrast = Contrast
  static Saturation = Saturation
  static Vibrance = Vibrance
  static Warmth = Warmth
  static Tint = Tint
  static Fade = Fade
  static Sharpness = Sharpness
  static Blur = Blur
  static Distance = Distance
  static Angle = Angle
  static UploadsImage = UploadsImage
  static DropImage = DropImage
  static DropImageSmall = DropImageSmall
  static Resize = Resize
  static Portrait = Portrait
  static Square = Square
  static Circle = Circle
  static Landscape = Landscape
  static PortraitSmall = PortraitSmall
  static WallPaper = WallPaper
  static CustomSize = CustomSize
  static InstagramIcon = InstagramIcon
  static AmazonProduct = AmazonProduct
  static AmazonHeader = AmazonHeader
  static EbayIcon = EbayIcon
  static ShopifyIcon = ShopifyIcon
  static FacebookIcon = FacebookIcon
  static VideoPortrait = VideoPortrait
  static VideoSquare = VideoSquare
  static VideoLandscape = VideoLandscape
  static InspectorArrow = InspectorArrow
  static Edit = Edit
  static Replace = Replace
  static BringFront = BringFront
  static BringBack = BringBack
  static AdjustBar = AdjustBar
  static DepopIcon = DepopIcon
  static EstyIcon = EstyIcon
  static Pinterest = Pinterest
  static YoutubeIcon = YoutubeIcon
  static ZoomIcon = ZoomIcon
  static TiktokIcon = TiktokIcon
  static WattpadIcon = WattpadIcon
  static Linkedin = Linkedin
  static Lemon = Lemon
  static Twitter = Twitter
  static Whatsapp = Whatsapp
  static Snapchat = Snapchat
  static Poshmask = Poshmask
  static DefaultAvatar = DefaultAvatar
  static FbIcon = FbIcon
  static GGIcon = GGIcon
  static AppleIcon = AppleIcon
  static WhiteCloseIcon = WhiteCloseIcon
  static PremiumIcon = PremiumIcon
  static CheckIcon = CheckIcon
  static PaypalIcon = PaypalIcon
  static ReplaceImage = ReplaceImage
  static BrushIcon = BrushIcon
  static CardIcon = CardIcon
  static CVVIcon = CVVIcon
  static BrushSoftnessIcon = BrushSoftnessIcon
  static RestoreIcon = RestoreIcon
  static HelpIcon = HelpIcon
  static Dots = Dots
  static DotsIcon = DotsIcon
  static ArrowRight = ArrowRight
  static ArrowLeft = ArrowLeft
  static RedArrowRight = RedArrowRight
  static FillIcon = FillIcon
  static FitIcon = FitIcon
  static GroupIcon = GroupIcon
  static UnGroupIcon = UnGroupIcon
  static PlusIcon = PlusIcon
  static NormalBrush = NormalBrush
  static SoftBrush = SoftBrush
  static SofterBrush = SofterBrush
  static HardBrush = HardBrush
  static ProjectsIcon = ProjectsIcon
  static HouseIcon = HouseIcon
  static ProjectIcon = ProjectIcon
  static DownloadIcon = DownloadIcon
  static EmptyProjectsIcon = EmptyProjectsIcon
  static CursorIcon = CursorIcon
  static GoodWorkIcon = GoodWorkIcon
  static LockIcon = LockIcon
  static UnLockIcon = UnLockIcon
  static CustomArrow = CustomArrow
  static ArrowMedium = ArrowMedium
  static MobileIcon = MobileIcon
  static AppStoreIcon = AppStoreIcon
  static GGPlayIcon = GGPlayIcon
  static SaveProjectIcon = SaveProjectIcon
  static SavedIcon = SavedIcon
  static NowPremiumIcon = NowPremiumIcon
}

export default Icons