import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { URLLockManager } from '@/scenes/Editor/components/Navbar/URLLockManager'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'; // Import useHistory for navigation


function ModalProjectAlreadyOpen() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()
  const [lockManager] = useState(() => URLLockManager.getInstance());
  const location = useLocation();
  const history = useHistory(); // Initialize the history hook

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.PROJECT_ALREADY_OPEN}
      onClose={() => {
        lockManager.takeOverTab(location.pathname)
        setShowModalType(null)
      }}
      content={{
        header: t('Can’t open this project'),
        desc: t('Project is already open on a different Tab.'),
      }}
      action={{
        primary: {
          name: t('Open Here'),
          handle: () => {
            lockManager.takeOverTab(location.pathname)
            setShowModalType(null)
          }
        },
        secondary: {
          name: t('Close'),
          handle: () => {
            setShowModalType(null)
            history.push('/create/projects'); // Redirect to a different URL
          },
        }
      }}
    ></ModalCustom>
  )
}

export default ModalProjectAlreadyOpen