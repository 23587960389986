import { URL } from "url";
import { MediaImageRepository } from "../media-repository/media_image_repository";
import { MediaImageType } from "../media-repository/media_image_type";
import { PromiseResolver, PromiseResultWithParams } from "./PromiseResolver";
import { DEFAULT_FONT } from "../../common/constants";

interface AssetLoadMap {
    [key: string]: PromiseResolver<string, MediaResult, string>
}

export type MediaTypeURL = { mediaItem: MediaImageType, url: URL };

export class MediaResult  { 
    mediaItem: MediaImageType;
    url: URL;
    object: any;
    asset: any;
    mediaBase64: string;
};

export class RemoteAssetProviderCancellationToken {
    private isCancelledValue: boolean = false;
    
    hasFinished: boolean = false;
    abortToken: AbortController; 

    constructor() {
        this.abortToken = new AbortController();
    }
    

    isCancelled() : boolean {
        return this.isCancelledValue;
    }

    cancel() {
        this.abortToken.abort();
        this.isCancelledValue = true;
    }
}

export class CompositedRemoteAssetProviderCancellationToken extends RemoteAssetProviderCancellationToken {
    private canellationTokens: RemoteAssetProviderCancellationToken[] = [];
    readonly id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    isCancelled() : boolean {
        return this.canellationTokens.some ( (c) => { c.isCancelled() })  || false
    }

    cancel() {
        this.canellationTokens.forEach ( (c) => { c.cancel() })
        this.canellationTokens = [];
    }

    addCancellationToken(cancellationToken: RemoteAssetProviderCancellationToken){
        this.canellationTokens.push(cancellationToken);
    }
}

export class RemoteResourceProvider {
    templateId: string
    layerId: string
    layerAssetStateId: string
    object: any
    remoteAssetProviderCancellationToken: RemoteAssetProviderCancellationToken;

    constructor(templateId: string, layerId: string, layerAssetStateId: string, object: any) {
        this.templateId = templateId;
        this.layerId = layerId;
        this.layerAssetStateId = layerAssetStateId;
        this.object = object;
        this.remoteAssetProviderCancellationToken = new RemoteAssetProviderCancellationToken();
    }

    isCancelled() {
        return this.remoteAssetProviderCancellationToken.isCancelled();
    }
    
    cancel() {
        this.remoteAssetProviderCancellationToken.cancel();
    }

    downloadRequiredAsset(): Promise<MediaResult> | Promise<PromiseResultWithParams<MediaResult, string>>{
        return Promise.resolve(null);
    }

    downloadAllAssets(): Promise<MediaResult[]> {
        return null;
    }
}

export class RemoteAssetProvider extends RemoteResourceProvider {
    layerAssets: any[];

    constructor(templateId: string, layerId: string, layerAssetStateId: string, object: any, layetAssets: any[]) {
        super(templateId, layerId, layerAssetStateId, object);
        this.layerAssets = layetAssets;
    }
    
    downloadRequiredAsset(): Promise<MediaResult> {
        return this.download([MediaImageType.latest]).then((status)=>{
            let result = status.find((s) => s?.mediaItem == MediaImageType.latest)
            return result
        })
    }

    downloadAllAssets(): Promise<MediaResult[]> {
        let assetTypes = [MediaImageType.latest, MediaImageType.original, MediaImageType.mask, MediaImageType.thumbnail, MediaImageType.bzrtBgMask];
        // let assetTypes = [MediaImageType.latest];
        return this.download(assetTypes).then((result)=>{
            return result
        });
    }

    private async provideAssetMap(assetTypes: MediaImageType[]): Promise<MediaTypeURL[]> {
        let assetMap = assetTypes.map((assetType) => {
            // @ts-ignore
            let assetUrl = this.layerAssets.findLast(
                x => x.layer === this.layerId && x.type === assetType
            )?.asset
            return { mediaItem: assetType, url: assetUrl } as MediaTypeURL;
        });
        return assetMap;
    }

    private async download(assetTypes: MediaImageType[]): Promise<MediaResult[]> {
        let assetMap = await this.provideAssetMap(assetTypes);

        let downloadPromises: Promise<MediaResult>[] = [];
        let that = this;
        
        for (let a of assetMap) {
            if (that.isCancelled() || this.remoteAssetProviderCancellationToken.abortToken.signal.aborted) {
                return null;
            }

            if (!a.url) {
                continue;
            }
            let remoteAssetProviderCancellationToken = this.remoteAssetProviderCancellationToken;
            // @ts-ignore
            let downloadOperation = MediaImageRepository.getInstance().getDataBlob(a.url, this.remoteAssetProviderCancellationToken.abortToken.signal).then((remoteBase64)=>{
                
                if (that.isCancelled()) {
                    return null;
                }

                return MediaImageRepository.getInstance().storeImageBlobString(
                    this.layerId,
                    this.layerAssetStateId,
                    a.mediaItem,
                    remoteBase64
                ).then(function (success) {
                    remoteAssetProviderCancellationToken.hasFinished = true;
                    if (!success){
                        return null;
                    }

                    let result = new MediaResult();
                    result.asset = a;
                    result.mediaItem = a.mediaItem as MediaImageType;
                    result.mediaBase64 = remoteBase64;
                    result.url = a.url;
                    result.object = that.object;
                    return result;
                });
            })
            
            downloadPromises.push(downloadOperation);
        }

        const allAssetsPromise = Promise.all(downloadPromises);
        return allAssetsPromise;
    }
}

export class RemoteTextLoader extends RemoteResourceProvider {
    fonts: any[];
    resolver: PromiseResolver<string, MediaResult, string>;

    private static assetLoadMap: AssetLoadMap = {};

    constructor(templateId: string, layerId: string, layerAssetStateId: string, object: any, fonts: any[]) {
        super(templateId, layerId, layerAssetStateId, object);
        this.fonts = fonts;

        if (!RemoteTextLoader.assetLoadMap[templateId]) {
            RemoteTextLoader.assetLoadMap[templateId] = new PromiseResolver()
        }
        this.resolver = RemoteTextLoader.assetLoadMap[templateId];
    }

    setDefaultFont(object) {
        if (!object || !object.textProperties || !object.textProperties.attributedText) {
            console.error('Invalid object structure');
            return;
        }
        
        object.textProperties.attributedText.runs.forEach(run => {
            if (run.attributes && run.attributes.NSFont) {
            run.attributes.NSFont.systemName = DEFAULT_FONT;
            } else {
            console.warn('Missing NSFont attribute in a run');
            }
        });
        console.log('Font updated successfully');
    }

    downloadRequiredAsset(): Promise<PromiseResultWithParams<MediaResult, string>> {
        let mediaResult = new MediaResult();
        mediaResult.object = this.object;

        if (!this.fonts || !Array.isArray(this.fonts)) {
            let resultWithParams = new PromiseResultWithParams(mediaResult, "");
            return Promise.resolve(resultWithParams);
        }

        let layerFonts = this.object.textProperties.attributedText.runs.map( (r => r.attributes.NSFont.systemName.toLowerCase()) );
        if (!layerFonts || layerFonts.length === 0) {
            let resultWithParams = new PromiseResultWithParams(mediaResult, "");
            return Promise.resolve(resultWithParams);
        }

        let allFontNames = this.fonts.map (f => f.name.toLowerCase());

        let fontNameToDownload = layerFonts.find ((layerFont) => {
            return allFontNames.indexOf(layerFont) > -1
        });

        if (!fontNameToDownload) {
            console.error(`couldn\'t locate fonts ${layerFonts}`)
            this.setDefaultFont(mediaResult.object)
            let resultWithParams = new PromiseResultWithParams(mediaResult, "");
            return Promise.resolve(resultWithParams);
        }
        
        let fontToDownload = this.fonts.find(f=> f.name.toLowerCase() == fontNameToDownload);
        let promiseSupplier = ((params: MediaResult) => this.download(fontToDownload, params));
        
        return this.resolver.add(fontNameToDownload, mediaResult, promiseSupplier);
    }

    download(font: any, mediaResult: MediaResult): Promise<string> {
        let that = this;
        return new Promise((resolve, reject) => {
            try {
                if (that.remoteAssetProviderCancellationToken.isCancelled()) {
                    resolve(null);
                    return;
                }
                let fontFace = new FontFace(font.name, `url(${font.url})`, font.options);
                fontFace.load().then(() => {
                    resolve(font.name);
                }).catch(err => {
                    console.error(err);
                    resolve(null);
                });
                if (fontFace && fontFace.family) {
                    document.fonts.add(fontFace)
                }
            }
            catch {
                resolve(null);
            }
        });
    }
}