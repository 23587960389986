// InspectorDataProvider.tsx
import useAppContext from '@/hooks/useAppContext';
import React, { createContext, useState, useCallback, useContext, useMemo } from 'react';

export enum InspectorPanelType {
    Canvas,
    Resize,
    Sticker,
    OutlineText,
    OutlineImage,
    ShadowText,
    ShadowImage,
    Adjust,
    Text,
    FontFamily,
    Image,
    Eraser,
    Fill,
    Filter,
    MagicBg,
    MagicBgCategory,
    RemoveObjects,
    Enhance,
    MultiElement
}

export interface InspectorPanelInputData {
    object?: fabric.Object;
    state?: Record<string, any>;
    setState?: (value: any) => void;
    props?: Record<string, any>;
}

export interface InspectorPanelData {
    inspectorType: InspectorPanelType;
    isVisible: boolean;
    inputData?: InspectorPanelInputData;
    addWithAnimation: boolean;
    closeWithAnimation: boolean;
}

interface InspectorContextType {
    panelsData: InspectorPanelData[];
    setPanelsData: React.Dispatch<React.SetStateAction<InspectorPanelData[]>>;
    addInspector: (type: InspectorPanelType, data?: InspectorPanelInputData, addWithAnimation?: boolean) => void;
    hideInspector: (type?: InspectorPanelType, addWithAnimation?: boolean) => void;
    updateInspector: (type: InspectorPanelType, data: InspectorPanelInputData) => void;
    removeInspectorInternal: (type: InspectorPanelType) => void;
    isOpenInspector: boolean;
    setIsOpenInspector: (value: boolean) => void;
}

const InspectorContext = createContext<InspectorContextType | undefined>(undefined);

export const useInspector = (): InspectorContextType => {
    const context = useContext(InspectorContext);
    if (!context) {
        throw new Error('useInspector must be used within an InspectorProvider');
    }
    return context;
};

export const InspectorDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [panelsData, setPanelsData] = useState<InspectorPanelData[]>([
        { inspectorType: InspectorPanelType.Canvas,
        isVisible: true,
        addWithAnimation: false,
        closeWithAnimation: true,
        }
    ]);
    const {
        isOpenInspector,
        setIsOpenInspector,
      } = useAppContext()

    const addInspector = useCallback((type: InspectorPanelType, data?: InspectorPanelInputData, addWithAnimation: boolean = true) => {   
        const existingIndex = panelsData.map(panel => panel.inspectorType).lastIndexOf(type);

        if (existingIndex != -1) {
            // If the type exists, pop back until the found index
            for (let i = existingIndex; i < panelsData.length; i++) {
                hideInspector(panelsData[i].inspectorType, false)
            }
        }
        const newPanelsData = existingIndex !== -1 
        ? panelsData.slice(0, existingIndex) 
        : panelsData;
        
        setPanelsData([...newPanelsData, { inspectorType: type, isVisible: true, inputData: data, addWithAnimation: addWithAnimation, closeWithAnimation: true}]);  
    }, [panelsData, setPanelsData]);


    const hideInspector = useCallback((type: InspectorPanelType, closeWithAnimation: boolean = true) => {
        if (type) {
            setPanelsData(prev =>
                prev.map(item =>
                item.inspectorType === type ? { ...item, isVisible: false, closeWithAnimation: closeWithAnimation } : item
                )
            );
            return
        }

        setPanelsData(prev => {
            // Find the index of the last item in the array
            const index = prev.length - 1;
        
            if (index < 0) return prev; // If the array is empty, return the original array
        
            // Create a new array with the last item's visibility updated to false
            return prev.map((item, idx) =>
            idx === index ? { ...item, isVisible: false, closeWithAnimation: closeWithAnimation } : item
            );
        });    
    }, [panelsData, setPanelsData]);

    const removeInspectorInternal = useCallback((type: InspectorPanelType) => {
        setPanelsData(prev => {
            const index = prev.map(item => item.inspectorType).lastIndexOf(type);
            if (index === -1) return prev; // If not found, return the original array
        
            // Create a new array without the last occurrence of the specified type
            return [...prev.slice(0, index), ...prev.slice(index + 1)];
        });
    }, [panelsData, setPanelsData]);

    const updateInspector = useCallback((type: InspectorPanelType, inputData: InspectorPanelInputData) => {
        setPanelsData(prevPanelsData => {
            return prevPanelsData.map(panel => {
                if (panel.inspectorType === type) {
                    return { ...panel, inputData: inputData };
                }
                return panel;
            });
        });
    }, [panelsData, setPanelsData]);

    const contextValue = useMemo(
        () => ({
            panelsData,
            setPanelsData,
            addInspector,
            hideInspector,
            updateInspector,
            removeInspectorInternal,
            isOpenInspector,
            setIsOpenInspector
        }),
        [panelsData, setPanelsData, addInspector, hideInspector, updateInspector, removeInspectorInternal, isOpenInspector, setIsOpenInspector]
    );

    return (
        <InspectorContext.Provider value={contextValue}>
            {children}
        </InspectorContext.Provider>
    );
};
