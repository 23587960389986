import { useContext, useEffect, useRef, useState } from 'react'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import useAppContext from '@/hooks/useAppContext'
import MagicBgObjectHandler from '@/scenes/engine/handlers/remove-handler/MagicBgObjectHandler'
import { useAppDispatch } from '@/store/store'
import { ObjectType } from '@/scenes/engine/common/constants'
import api from '@/services/api'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageRepositoryProcessing, ResizeMode } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { removeLocalStorage } from '@/utils/localStorage'
import { useSelector } from 'react-redux'
import { selectMagicCategories } from '@/store/slices/magicBgTool/selector'
import { setMagicCategories } from '@/store/slices/magicBgTool/action'
import { customAmplitude } from '@/utils/customAmplitude'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import SuggestedButtons from './MagicBgToolSubComponent/SuggestedButtons'
import Categories from './MagicBgToolSubComponent/Categories'
import { CategoryDetailProps } from "./MagicBgToolSubComponent/CategoryDetail"
import { useTranslation } from 'react-i18next'
import { Point } from '@/scenes/engine/objects/media-repository/point'
import { StaticImage } from 'fabric/fabric-impl'
import { InspectorPanelType, useInspector } from '../InspectorDataProvider'

function MagicBgTool({ isDoneButtonAvailable, setIsDoneButtonAvailable }) {
  const removeEditor = useContext(RemoveEditorContext).editor
  const { setisOpenPixelManipulationObject, isOpenPixelManipulationObject, toolType, setRemovingBg, setRemoveBgSuccess, removingBg, setRemoveBgAbortController, cancelPixelManipulation} = useAppContext()
  const { activeObject, editor } = useContext(EditorContext)
  const { canvas } = useContext(RemoveEditorContext)
  const { t } = useTranslation()

  const [categoryActive, setCategoryActive] = useState(null)
  const [suggestedImages, setSuggestedImages] = useState([])
    
  const [caption, setCaption] = useState(null)
  const [categories, setCategories] = useState([])
  
  const [filteredCategories, setFilteredCategories] = useState([])
  
  const [base64ImageMask, setBase64ImageMask] = useState(null)
  const [base64ImageWithBg, setBase64ImageWithBg] = useState(null)
  const [base64ImageWithoutBg, setBase64ImageWithoutBg] = useState(null)
  
  const [paddedCrop, setPaddedCrop] = useState(null)
  const [imageWithoutBgHtmlElement, setImageWithoutBgHtmlElement] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const { addInspector } = useInspector();
  
  let imageProcessing = new MediaImageRepositoryProcessing()
  const fallbackCategories = ["Minimal", "Desert", "Snow", "Garden"]
  const edgeSize = 1024
  const regExBase64Prefix = new RegExp('data:image/png;base64,', "g")
  const regExBase64JpgPrefix = new RegExp('data:image/jpeg;base64,', "g")
  const dispatch = useAppDispatch()
  const magicCategories = useSelector(selectMagicCategories)
  const ratioFrame = editor.handlers.frameHandler.get().width / editor.handlers.frameHandler.get().height;
  const didMagicBgResultSetOnLoad = useRef(false);

  const handleClickOnSuggetion = async (src: string): Promise<void> => {
    customAmplitude('Magic Backgrounds - Selected Scene')
    removeEditor.handlers.transactionRemoveHandler.save()
    await removeEditor.handlers.pixelManipulationObjectHandler.addNewImage(src)
    setActiveItem(src)
  }
  const handleNavigation = () => { console.log("handle navigation"); setCategoryActive(null)}

  useEffect(() => {
    if (categoryActive) {
      const props: CategoryDetailProps = {
          handleNavigation: handleNavigation,
          categoryActive: categoryActive,
          base64ImageWithoutBg: base64ImageWithoutBg,
          handleClickOnSuggetion: handleClickOnSuggetion,
          caption: caption,
          image: base64ImageWithBg,
          imageMask: base64ImageMask,
          ratioFrame: ratioFrame,
          activeItem: activeItem,
          setActiveItem: setActiveItem,
          paddedCrop: paddedCrop,
          imageWithoutBgHtmlElement: imageWithoutBgHtmlElement
      }
      addInspector(InspectorPanelType.MagicBgCategory, { props })
      setCategoryActive(null)
    }
  }, [categoryActive])

  useEffect(() => {
    removeEditor.handlers.setPixelToolType('MagicBg')
    setisOpenPixelManipulationObject(true)
    setIsDoneButtonAvailable(false)
  }, [])

  useEffect(() => {
    removeEditor.handlers.pixelManipulationObjectHandler.isOpen = isOpenPixelManipulationObject
    if (!isOpenPixelManipulationObject || !removeEditor || toolType !== 'MagicBg') {
      return
    }
    ;(async () => {
    let magicBgObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as MagicBgObjectHandler
      magicBgObjectHandler.clonedObject = await createCloneActiveObject()
      initMagicBg()
      await setUpOriginalImage()
      await initImageWithBgProcessing()
      await removeBg(magicBgObjectHandler.clonedObject) // Remove bg of clone object on main canvas
      await setupImageOnCanvas() // Add removed bg object to magic bg canvas
      deleteCloneObject(magicBgObjectHandler.clonedObject) // Delete clone object on main canvas and object on magic bg canvas
      removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject // Reverse active object to original object
      changeRemoveBgProgressText()
      processMagicBackground()
    })()
  }, [isOpenPixelManipulationObject, toolType])

  const initMagicBg = () => {
    removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject
    const mainFrame = editor.handlers.canvasHandler.canvas.getObjects().find(obj => obj.type === ObjectType.FRAME) // Main canvas frame
    let mainFrameBoundingBox = mainFrame.getBoundingRect()
    canvas.setWidth(mainFrameBoundingBox.width).setHeight(mainFrameBoundingBox.height)
    removeEditor.handlers.pixelManipulationObjectHandler.frameMainCanvas = mainFrame
  }

  const setUpOriginalImage = async () => { 
    let magicBgObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as MagicBgObjectHandler
    magicBgObjectHandler.addChessBg()
    await setupImageOnCanvas()
    let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
    canvasRemoveTool.style.zIndex = '2'
  }

  const createCloneActiveObject = async (): Promise<fabric.Object> => {
    let cloneObj: fabric.Object
    const frame = editor.handlers.frameHandler.get()
    cloneObj = await new Promise((resolve, reject) => {
      editor.handlers.objectsHandler.duplicate(activeObject, frame, (clonedObjects) => {
        const clonedObject = clonedObjects[0]
        resolve(clonedObject)
      }, true, 0)
    })
    return cloneObj
  }

  const setupImageOnCanvas = async () => {
    let magicBgObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as MagicBgObjectHandler
    await magicBgObjectHandler.setupImage()
    await magicBgObjectHandler.addImageToCanvas()
    magicBgObjectHandler.setInitialPosition()
    canvas.renderAll()
  }

  const initImageWithBgProcessing = async () => {
    let magicBgObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as MagicBgObjectHandler

    const imageWithBg = await magicBgObjectHandler.getImageWithBg()
    let htmlImageWithBg = await imageProcessing.loadImage(imageWithBg)
    const imageWithBgPadded = await imageProcessing.resizeBase64ToMaxEdgeSize(imageWithBg, edgeSize, {
      allowUpsampling: true,
      exportType:'image/jpeg',
      pad: true,
      resizeMode: ResizeMode.aspectFit
    });
    setBase64ImageWithBg(imageWithBgPadded.replace(regExBase64JpgPrefix,''))
    const paddedCrop = calculatePaddedCrop(htmlImageWithBg, edgeSize);
    setPaddedCrop(paddedCrop);
  }

  const removeBg = async (cloneObj) => {
    let magicBgObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as MagicBgObjectHandler
    setRemovingBg(true)
    setRemoveBgSuccess(false)
    if(!cloneObj.hasTransparency){
      let abortController = new AbortController()
      setRemoveBgAbortController(abortController)
      let removeSucces = await editor.handlers.objectsHandler.removeBg(cloneObj, abortController.signal)
      if(!removeSucces) {
        removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
          removeEditor.handlers.pixelManipulationObjectHandler.reset()
          canvas.clear()
          cancelPixelManipulation(true)
          if(editor.handlers.zoomHandler.preZoomBeforeErase) {
            editor.handlers.zoomHandler.zoomToRatio(editor.handlers.zoomHandler.preZoomBeforeErase)
          }
          if(editor.handlers.scrollbarHandler.preVptBeforeErase) {
            editor.handlers.canvasHandler.canvas.setViewportTransform(editor.handlers.scrollbarHandler.preVptBeforeErase)
          }
          editor.handlers.zoomHandler.preZoomBeforeErase = null
          editor.handlers.scrollbarHandler.preVptBeforeErase = null
          editor.handlers.scrollbarHandler.updateScrollPosition()
        })
        return
      }
    }
    cloneObj.setCoords()
    magicBgObjectHandler.activeObject = cloneObj
  }

  const calculatePaddedCrop = (htmlImageWithBg, edgeSize) => {
    let aspectRatio = htmlImageWithBg.width / htmlImageWithBg.height
    let paddedCrop: Rectangle;
    if (htmlImageWithBg.width > htmlImageWithBg.height) {
      let newHeight = edgeSize / aspectRatio
      let y = (edgeSize - newHeight) / 2
      paddedCrop = new Rectangle(0, y, edgeSize ,newHeight);
    } else {
      let newWidth = edgeSize * aspectRatio
      let x = (edgeSize - newWidth) / 2
      paddedCrop = new Rectangle(x, 0, newWidth, edgeSize);
    }
    return paddedCrop
  }

  const changeRemoveBgProgressText = () => {
    const textRemoveBg = document.getElementById('text-remove-bg')
    textRemoveBg.innerText = t('Analyzing your photo...')
  }

  const processMagicBackground = async () => {
    let magicBgObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as MagicBgObjectHandler

    const { imageWithoutBg, resizedImage } = await processImageWithoutBackground();
    setImageWithoutBgHtmlElement(await imageProcessing.loadImage(resizedImage));

    let imageWithoutBgPadded = await resizeImage(imageWithoutBg, edgeSize, true)
    
    const base64ImageMask = await extractMask(imageWithoutBgPadded) 
    const base64NoPaddedMask = await extractMask(imageWithoutBg)

    magicBgObjectHandler.base64_image_mask = base64NoPaddedMask;
    setBase64ImageMask(base64ImageMask.replace(regExBase64Prefix,''))
    setBase64ImageWithoutBg(imageWithoutBg)

    let caption = await generateCaptionForImage(imageWithoutBg)
    setCaption(caption)
  }

  const generateCaptionForImage = async (imageBase64) => {
    let trimedImageWithoutBg = await imageProcessing.trim(imageBase64)
    let imageForCaption = await imageProcessing.resizeBase64ToMaxEdgeSize(trimedImageWithoutBg, 128, {
      allowUpsampling: true,
      exportType:'image/png',
      pad: false,
      resizeMode: ResizeMode.aspectFit
    });
    const captionData = await api.captionImage(imageForCaption.replace(regExBase64Prefix,''))
    return captionData;
  }

  const extractMask = async (imageBase64) => {
    const mask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(imageBase64, null, true);
    return mask.base64
  }

  const processImageWithoutBackground = async (pad = false) => {
    const currentBackground = canvas.backgroundColor;
    canvas.setBackgroundColor(null, canvas.renderAll.bind(canvas));
    let imageWithoutBg = canvas.toDataURL({ format: 'image/png' });
    let resizedImage = await resizeImage(imageWithoutBg, edgeSize, false)
    canvas.setBackgroundColor(currentBackground, canvas.renderAll.bind(canvas));
    return { imageWithoutBg, resizedImage };
  }

  const resizeImage = async (image, edge, pad: boolean): Promise<string> => {
    return await imageProcessing.resizeBase64ToMaxEdgeSize(image, edge, {
      allowUpsampling: true,
      exportType:'image/png',
      pad: pad,
      resizeMode: ResizeMode.aspectFit
    });
  }

  const deleteCloneObject = (clonedObject) => {
    canvas.remove(canvas.getObjects()[0])
    editor.handlers.canvasHandler.canvas.remove(clonedObject)
  }

  useEffect(()=>{
    if(!filteredCategories.length) return
    async function getSuggestedResult(prompt: string) {
      if(suggestedImages.length >= 4) return
      api.magicBg(
        base64ImageWithBg ,
        base64ImageMask,
        prompt.replace('{object_name}', caption[0])
      ).then(async data => {
        let results = await postProcessMagicBgResult(data);
        if (!isDoneButtonAvailable) {
          if (!didMagicBgResultSetOnLoad.current) {
            didMagicBgResultSetOnLoad.current = true
            removeEditor.handlers.pixelManipulationObjectHandler.addNewImage(results)
            setActiveItem(results)
          }
          setRemoveBgSuccess(true)
          setIsDoneButtonAvailable(true)
          setTimeout(() => {
            const textRemoveBg = document.getElementById('text-remove-bg')
            textRemoveBg.innerText = t('Just a sec...')
          });
        }
        setSuggestedImages(pre => {
          return [...pre, results]
        })
        // TODO : put image reuslt on the suggestion options
      }).catch(err => {
        // console.log('err', err)
        // setIsLoadedJsonSuccess(true)
      })
    }
    filteredCategories.forEach((category, index) => {
      if(index < 4) {
        getSuggestedResult(category.prompt)
      }
    })

  }, [filteredCategories])

  async function postProcessMagicBgResult(inputBase64Image: string): Promise<string> {
    let base64Image = `data:image/png;base64,${inputBase64Image}`
    let image = await imageProcessing.loadImage(base64Image);
    let croppedImage = await imageProcessing.cropHtmlImage(image, paddedCrop)
    let compositedImage = await imageProcessing.composite(croppedImage, imageWithoutBgHtmlElement, new Point(0, 0))
    let base64Result = compositedImage.src;
    let blob = imageProcessing.base64ToBlobUrl(base64Result)
    return blob
  }

  useEffect(() => {
    if(caption){
      api.getMagicBgPromptsBySubject(caption[0]).then(data => {
        if(data.length > 0){
          setFilteredCategories(data)  
        }
        else{
          const filtered = categories.map(category => category.data).flat().filter(category => fallbackCategories.includes(category.title))
          setFilteredCategories(filtered)
        }
      })
    }
  }, [caption])

  useEffect(() => {
    if(categories.length) return
    if(magicCategories.length) {
      setCategories(magicCategories)
      return
    }
    const abortController = new AbortController()
    const signal = abortController.signal
    async function getMagicBgPromptClassifications() {
      const promise = await api.getMagicBgPromptClassifications().then(data => {
        return data.map(
          async category =>
            await api.getMagicBgPromptsByClassification(category.id).then(data => {
              return {
                title: category.title,
                data: data,
              }
            })
        )
      })
      const res = await Promise.all(promise)
      dispatch(setMagicCategories(res))
      setCategories(res)
    }
    if(!signal.aborted) {
      getMagicBgPromptClassifications()
    }

    return () => {
      abortController.abort()
      removeLocalStorage('category')
    }
  }, [])

  useEffect(() => {
    const handleHistoryChange = (data: any) => {
      // @ts-ignore
      let bgObject = removeEditor.handlers.canvasRemoveHandler.canvas.getObjects().find(x=> x.id === 'suggestion-image')
      if(bgObject){
        let src = (bgObject as unknown as  StaticImage).getElement().src
        setActiveItem(src)
      } else {
        setActiveItem(null)
      }
      
    }
    if (removeEditor) {
      removeEditor.on('remove-history:changed', handleHistoryChange)
    }
    return () => {
      if (removeEditor) {
        removeEditor.off('remove-history:changed', handleHistoryChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeEditor])

  return (
    <div style={{ position: 'relative', opacity: removingBg ? 0.5 : 1 }}>
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '50px', height: 'calc(100% - 160px)' }}
      >
        <SuggestedButtons
          suggestedListOutside={suggestedImages.length > 4 ? suggestedImages.slice(0, 4) : suggestedImages}
          handleClickOnSuggetion={handleClickOnSuggetion}
          base64ImageWithoutBg={base64ImageWithoutBg}
          ratioFrame={ratioFrame}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          paddedCrop={paddedCrop}
          imageWithoutBgHtmlElement={imageWithoutBgHtmlElement}
        />
        
        <Categories 
          categories={categories} 
          onClickCategory={(item)=>{
            setCategoryActive(item)
          }} /> 
              
      </div>
    </div>
  )
}

export default MagicBgTool