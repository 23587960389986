import { SyncManager } from '@/firebase/services/sync/SyncManager'
import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ModalDeleteProject() {
    const { showModalType, setShowModalType } = useAppContext()
    const { t } = useTranslation()
  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.DELETE_PROJECT}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Delete project'),
        desc: t("It cannot be undone."),
      }}
      action={{
        primary: {
          name: t('Delete'),
          handle: () => {
            SyncManager.shared.deleteProjects(SyncManager.shared.idDeleteProject)
            setShowModalType(null)
          },
        },
        secondary: {
          name: t('Cancel'),
          handle: () => {
            SyncManager.shared.idDeleteProject = null
            setShowModalType(null)
          },
        },
      }}
    />
  )
}

export default ModalDeleteProject