import { DraftSyncVersion } from "./DraftSyncVersion";
import { UserDevice } from "./UserDevice";

export class DeviceCloudMetadata {
    device: UserDevice;
    draftSyncVersions: DraftSyncVersion[];

    constructor(device: UserDevice, draftSyncVersions: DraftSyncVersion[]) {
        this.device = device;
        this.draftSyncVersions = draftSyncVersions;
    }

    ///////////////////////////
    ///////// coding //////////
    ///////////////////////////
    //MARK: coding

    static fromJSON(json: any): DeviceCloudMetadata {
        const device = UserDevice.fromJSON(json.device);
        const draftSyncVersions = json.draftSyncVersions.map((item: any) => DraftSyncVersion.fromJSON(item));
        return new DeviceCloudMetadata(device, draftSyncVersions);
    }

    toJSON(): any {
        return {
            'bazaart.DeviceCloudMetadata': {
                'device': this.device,
                'draftSyncVersions': this.draftSyncVersions.map(version => version)
            }
        };
    }

    encode(): any {
        return this.toJSON();
    }

    static decode(data: any): DeviceCloudMetadata {
        return this.fromJSON(data);
    }
}

