import { lightTheme } from '@/customTheme'
import { useEditorContext } from '@/scenes/engine'
import { ThemeProvider, styled } from 'baseui'
import ContextMenuItem from './ContextMenuItem'
import Icons from '../Icons'
import PositionContextMenu from './PositionContextMenu'
import { NAVBAR_HEIGHT, ObjectType, ScaleType } from '@/scenes/engine/common/constants'
import { PopoverType } from '@/constants/app-options'
import useAppContext from '@/hooks/useAppContext'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { useEffect, useState } from 'react'
import { customAmplitude } from '@/utils/customAmplitude'
import { useSelector } from 'react-redux'
import { selectHasClipboardImage } from '@/store/slices/editor/selector'
import { useAppDispatch } from '@/store/store'
import { setHasClipboardImage } from '@/store/slices/editor/action'
import {fabric} from 'fabric'
import { useTranslation } from 'react-i18next'
import AutoScroll from '@/components/AutoScroll'


const DivideBorder = styled('div', (props: any) => ({
  height: '1px',
  margin: '8px 0',
  background: lightTheme.colors.grayScale100,
  flexShrink: 0,
}))   

const CONTEXT_MENU_HEIGHT = 466

const ContextMenu = () => {
  const { contextMenuDetails, setContextMenuDetails, activeObject, canvas, editor } = useEditorContext()
  const { setPopoverActive, setIsReplacingImage } = useAppContext()
  const [visible, setVisible] = useState(true)
  const [preVisble, setPreVisible] = useState(true)
  const [isOpenPositionMenu, setIsOpenPositionMenu] = useState(false)
  const [showPositionGroup, setShowPositionGroup] = useState(false)
  const dispatch = useAppDispatch()

  const hasClipboardImage = useSelector(selectHasClipboardImage)

  const isMac = navigator.userAgent.indexOf('Mac OS X') !== -1

  useEffect(() => {
    const handleCanvasChange = () => {
      setPreVisible(visible)
      setVisible(false)
    }
    const handleCanvasChanged = () => {
      setVisible(preVisble)
    }
    editor.on('canvas:modified', handleCanvasChange)

    return () => {
      editor.off('canvas:modified', handleCanvasChanged)
    }

  }, [editor])
   
  useEffect(() => {
    setShowPositionGroup(() => {
      const filteredObjs = canvas.getObjects().filter(obj => obj.type && obj.type !== ObjectType.FRAME && obj.type !== ObjectType.BAZAART_BG)
      if(activeObject instanceof fabric.Group && activeObject.type === ObjectType.ACTIVE_SELECTION) {
        return activeObject.getObjects().length === filteredObjs.length ? false : true
      }
      return filteredObjs.length > 1
    })
  }, [canvas, activeObject])

  const onCopyButton = () => {
    navigator.clipboard.writeText(''); // Reset navigator clipboard
    dispatch(setHasClipboardImage('internal'))
    editor.handlers.objectsHandler.copy()
    setContextMenuDetails(null)
  }
  const onPasteButton = async (e) => {
    if(hasClipboardImage === 'external') {
      editor.handlers.objectsHandler.add(editor.handlers.objectsHandler.clipboard)
    } else if (hasClipboardImage === 'internal'){
      await editor.handlers.objectsHandler.paste({ x: e.clientX, y: e.clientY })
    }
    setContextMenuDetails(null)
  }
  const onDuplicateButton = () => {
    editor.handlers.objectsHandler.clone()
    setContextMenuDetails(null)
  }
  const onDeleteButton = () => {
    editor.handlers.objectsHandler.delete()
    setContextMenuDetails(null)
  }
  const onReplaceButton = () => {
    setPopoverActive(PopoverType.PHOTOS)
    setIsReplacingImage(true)
    setContextMenuDetails(null)
  }
  const onFlipButton = () => {
    editor.handlers.objectsHandler.update({ flipX: !activeObject.flipX })
    // setContextMenuDetails(null)
  }
  const onMirrorButton = () => {
    editor.handlers.objectsHandler.update({ flipY: !activeObject.flipY })
    // setContextMenuDetails(null)
  }
  const onFillCanvasButton = () => {
    editor.handlers.objectsHandler.scale(ScaleType.FILL)
    const eventProperties = {
      Tool: 'bazaart.fill_to_canvas',
    }
    customAmplitude('Selected tool', eventProperties)
    setContextMenuDetails(null)
  }
  const onFitCanvasButton = () => {
    editor.handlers.objectsHandler.scale(ScaleType.FIT)
    const eventProperties = {
      Tool: 'bazaart.fit_to_canvas',
    }
    customAmplitude('Selected tool', eventProperties)
    setContextMenuDetails(null)
  }
  const onUngroupButton = () => {
    editor.handlers.objectsHandler.ungroup()
  }
  const onGroupButton = () => {
    editor.handlers.objectsHandler.group()
  }

  const { t } = useTranslation()
  const CHECK_MAX_HEIGHT = window.innerHeight - CONTEXT_MENU_HEIGHT - NAVBAR_HEIGHT
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="context-menu"
        style={{
          position: 'absolute',
          left: contextMenuDetails?.left,
          top: contextMenuDetails?.top,
          zIndex: contextMenuDetails ? 1000 : -1,
          backgroundColor: '#ffffff',
          borderRadius: '16px',
          filter: 'drop-shadow(0 0 12px rgba(0, 0, 0, 0.15))',
          padding: `16px 0px 16px 0px`,
          display: 'flex',
          gap: '4px',
          flexDirection: 'column',
          width: CHECK_MAX_HEIGHT < 0 ? '247px' : '235px',
          maxHeight: CHECK_MAX_HEIGHT < 0 ? window.innerHeight - NAVBAR_HEIGHT : 'fit-content',
        }}
      >
          <AutoScroll style={{ 
            // padding: `0 ${CHECK_MAX_HEIGHT < 0 ? '16px' : '16px'} 0 0`,
            padding: `0px 16px 0px 16px`,
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            overflowY: CHECK_MAX_HEIGHT < 0 ? 'scroll' : 'hidden',
            marginRight: CHECK_MAX_HEIGHT < 0 ? '4px' : '0',
            boxSizing: 'border-box' }}>
            {!activeObject ? (
            <ContextMenuItem
              startEnhancer={<Icons.PasteIcon />}
              content={t("Paste")}
              endEnhancer={`${isMac ? '⌘' : 'Ctrl +'} V`}
              handleClick={async (e) => await onPasteButton(e)}
              disabled={!hasClipboardImage}
            />
          ) : (
            <>
              <ContextMenuItem
                startEnhancer={<Icons.CopyIcon />}
                content={t("Copy")}
                endEnhancer={`${isMac ? '⌘' : 'Ctrl +'} C`}
                handleClick={() => onCopyButton()}
              />
              <ContextMenuItem
                startEnhancer={<Icons.PasteIcon />}
                content={t("Paste")}
                endEnhancer={`${isMac ? '⌘' : 'Ctrl +'} V`}
                handleClick={e => onPasteButton(e)}
                disabled={!hasClipboardImage}
              />
              <ContextMenuItem
                startEnhancer={<Icons.Duplicate />}
                content={t("Duplicate")}
                endEnhancer={`${isMac ? '⌘' : 'Ctrl +'} D`}
                handleClick={() => onDuplicateButton()}
              />
              <ContextMenuItem
                startEnhancer={<Icons.Delete />}
                content={t("Delete")}
                endEnhancer={`${isMac ? 'DEL' : 'DELETE'}`}
                handleClick={() => onDeleteButton()}
              />
              <DivideBorder />
              {activeObject.type === ObjectType.BAZAART_IMAGE ? (
                <ContextMenuItem
                  startEnhancer={<Icons.Replace />}
                  content={t("Replace")}
                  handleClick={() => onReplaceButton()}
                />
              ) : null}
              {}
              {activeObject.type === ObjectType.GROUP ? (
                <>
                  <ContextMenuItem
                    startEnhancer={<Icons.UnGroupIcon />}
                    content={t("Ungroup")}
                    endEnhancer={`${isMac ? '⬆ ⌘' : 'Ctrl + Shift +'} G`}
                    handleClick={() => onUngroupButton()}
                  />
                  <DivideBorder />
                </>
              ) : null}
              {activeObject.type === ObjectType.ACTIVE_SELECTION ? (
                <>
                  <ContextMenuItem
                    startEnhancer={<Icons.GroupIcon />}
                    content={t("Group")}
                    endEnhancer={`${isMac ? '⌘' : 'Ctrl +'} G`}
                    handleClick={() => onGroupButton()}
                  />
                  <DivideBorder />
                </>
              ) : null}
              {showPositionGroup ? (
                <>
                  <StatefulPopover
                    content={({ close }) => <PositionContextMenu close={close} style={{
                      // marginLeft: CHECK_MAX_HEIGHT < 0 ? '32px' : '4px'
                      marginLeft: '4px'
                    }}/>}
                    placement={PLACEMENT.right}
                    overrides={{
                      Body: {
                        style: ({ $theme }) => ({
                          zIndex: 1000,
                        }),
                      },
                    }}
                    triggerType="hover"
                    autoFocus={false}
                    onOpen={() => {
                      customAmplitude('Selected tool', { Tool: 'bazaart.position' })
                      setIsOpenPositionMenu(true)
                    }}
                    onClose={() => {
                      setIsOpenPositionMenu(false)
                    }}
                  >
                    <div onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}>
                      <ContextMenuItem
                        startEnhancer={<Icons.Forward />}
                        content={t('Position')}
                        endEnhancer={<Icons.ArrowRight fill={lightTheme.colors.grayScale600}/>}
                        handleClick={e => {
                        }}
                        style={{
                          background: isOpenPositionMenu
                            ? `${lightTheme.colors.grayScale50} !important`
                            : `${lightTheme.colors.grayScale0} !important`,
                          pointerEvents: isOpenPositionMenu ? 'none' : 'auto',
                        }}
                      />
                    </div>
                  </StatefulPopover>
                  <DivideBorder />
                </>
              ) : null}
              <ContextMenuItem
                startEnhancer={<Icons.FlipH />}
                content={t("Flip")}
                handleClick={() => onFlipButton()}
              />
              <ContextMenuItem
                startEnhancer={<Icons.FlipV />}
                content={t("Mirror")}
                handleClick={() => onMirrorButton()}
              />
              {activeObject.type === ObjectType.BAZAART_IMAGE ? (
                <>
                <DivideBorder />
                <ContextMenuItem
                  startEnhancer={<Icons.FillIcon />}
                  content={t("Fill canvas")}
                  handleClick={() => onFillCanvasButton()}
                />
                <ContextMenuItem
                  startEnhancer={<Icons.FitIcon />}
                  content={t("Fit to canvas")}
                  handleClick={() => onFitCanvasButton()}
                />
                </>
              ) : null}
            </>
          )}
          </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default ContextMenu
