import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { SignInManager } from '@/scenes/Editor/components/Navbar/components/SignInManager'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { setUser, setUserIsPremium } from '@/store/slices/user/actions'
import { useAppDispatch } from '@/store/store'
import { useTranslation } from 'react-i18next'
import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'

function ModalConfirmSignOut() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.CONFIRM_SIGN_OUT}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Sign out') + '?',
        desc: t('After signing out, you won’t be able to edit the project.'),
      }}
      action={{
        primary: {
          name: t('Sign out'),
          handle: () => {
            setShowModalType(null)
            SignInManager.getInstance().logout()
            dispatch(setUser(null))
            dispatch(setUserIsPremium(false))

            const identifyEvent = new amplitude.Identify()
            identifyEvent.set('isSignup', false)
            identifyEvent.set('isPremium', false)
            amplitude.identify(identifyEvent)
            Sentry.setUser(null)
            history.push('/create')
          }
        },
        secondary: {
            name: t('Cancel'),
            handle: () => {
              setShowModalType(null)
            },
          },
      }}
    ></ModalCustom>
  )
}

export default ModalConfirmSignOut