import { TemplateConfig } from "@/interfaces/editor";
import { CloudManager } from "./CloudManager";
import { DraftSyncVersion } from "./DraftSyncVersion";
import { EmptyResponse } from "./CloudResponse";
import { SyncStatus } from "./SyncStatus";
import { UserDevice } from "./UserDevice";
import { AsyncTaskGroup } from "./dataTypes/AsyncTaskGroup";
import { loadImageFromURL } from "@/scenes/engine/utils/image-loader";

export enum MergeStatus {
    None,
    Added,
    Removed,
    ContentChanged,
    SizeChanged,
    Empty,
}

export namespace MergeStatus {
    export function hasFeedChange(status: MergeStatus): boolean {
        return status !== MergeStatus.None && status !== MergeStatus.ContentChanged;
    }

    export function shouldLoadScreenshot(status: MergeStatus): boolean {
        return status === MergeStatus.Added || status === MergeStatus.ContentChanged || status === MergeStatus.SizeChanged;
    }
}

interface SyncItemDelegate {
    syncItemDidUpdate(): void;
}

export class DraftSyncItem {
    draftId: string;
    delegate?: SyncItemDelegate;
    private syncVersionsByDevice: { [key: string]: DraftSyncVersion } = {};
    _local?: DraftSyncVersion;
    private _mostUpdatedVersion?: DraftSyncVersion;
    private _deviceCloudVersion?: DraftSyncVersion;
    private mostUpdatedVersionBeforeMerge?: DraftSyncVersion;
    private deviceCloudVersionBeforeMerge?: DraftSyncVersion;

    constructor(id: string);
    constructor(version: DraftSyncVersion);
    constructor(idOrVersion: string | DraftSyncVersion) {
        if (typeof idOrVersion === 'string') {
            this.draftId = idOrVersion;
        } else {
            this.draftId = idOrVersion.draftId;
            this.append(idOrVersion);
        }
    }

    get local(): DraftSyncVersion | undefined {
        return this._local;
    }

    get mostUpdatedVersion(): DraftSyncVersion | undefined {
        return this._mostUpdatedVersion ?? this.mostUpdatedVersionBeforeMerge;
    }

    set mostUpdatedVersion(version: DraftSyncVersion | undefined) {
        this._mostUpdatedVersion = version;
    }

    get deviceCloudVersion(): DraftSyncVersion | undefined {
        return this._deviceCloudVersion ?? this.deviceCloudVersionBeforeMerge;
    }

    set deviceCloudVersion(version: DraftSyncVersion | undefined) {
        this._deviceCloudVersion = version;
    }

    get createDate(): Date | undefined {
        return this.allVersions()[0]?.createDate;
    }

    get customFonts(): string[] {
        const fonts = new Set<string>();
        this.allVersions().forEach(version => {
            version.customFonts.forEach(font => fonts.add(font));
        });
        return Array.from(fonts);
    }

    private getScreenshotVersion(): DraftSyncVersion | undefined {
        if (this.local && !this.isOutDated()) {
            return this.local;
        } else {
            const versions = this.allVersions().sort((a, b) => b.roundedTimestamp - a.roundedTimestamp);
            return versions.find(v => !SyncStatus.isDeleted(v.syncStatus)) ?? versions[0];
        }
    }

    async getCachedScreenshotUrl(): Promise<string> | undefined {
        return await CloudManager.shared.getCachedScreenshotUrl(this.getScreenshotVersion());
    }

    async getScreenshotUrl(version?: DraftSyncVersion, completion?: (url?: string) => void) {
        const screenshotVersion = version ?? this.getScreenshotVersion();
        if (!screenshotVersion) {
            completion?.(undefined);
            return;
        }
        const url = await CloudManager.shared.getScreenshotUrl(screenshotVersion);
        completion?.(url);
    }

    async preFetchScreenshot(): Promise<EmptyResponse> {
        const dId = this.draftId;
        return new Promise<EmptyResponse>((resolve) => {
            this.preloadScreenshot(success => {
                resolve(success ? EmptyResponse.success() : EmptyResponse.sendError(`sync: failed to preload screenshot of: ${dId}`));
            });
        });
    }

    private async preloadScreenshot(completion?: (success: boolean) => void) {
        const version = this.getScreenshotVersion();
        if (!version) {
            completion?.(false);
            return;
        }
        if (version.isLocal) {
            completion?.(true);
            return;
        }
        const url = await CloudManager.shared.getCachedScreenshotUrl(version);
        if (url) {
            loadImageFromURL(url).then(async image => {
                if (!image) {
                    await CloudManager.shared.setCachedScreenshotUrl(undefined, version);
                    this.preloadScreenshot(completion);
                    return;
                }
                completion(true);
            }).catch(() => {
                completion?.(false);
            });
            return;
        }
        
        this.getScreenshotUrl(version, url => {
            loadImageFromURL(url).then(image => {
                completion(!!image);
            }).catch(() => {
                completion?.(false);
            });
            return;
        });
    }

    append(version: DraftSyncVersion) {
        if (version.isLocal) {
            this._local = version;
        } else {
            if (version.isCurrentDeviceVersion) {
                if (this._deviceCloudVersion && this._deviceCloudVersion.statusUpdateDate > version.statusUpdateDate) {
                    console.log("sync: shouldn't be in here 🤔");
                } else {
                    this._deviceCloudVersion = version;
                }
            } else {
                this.syncVersionsByDevice[version.deviceOwnerId] = version;
            }
        }
    }

    clearCloudVersionBeforeAppendingNewData() {
        this.mostUpdatedVersionBeforeMerge = this._mostUpdatedVersion;
        this.deviceCloudVersionBeforeMerge = this._deviceCloudVersion;
        this._mostUpdatedVersion = undefined;
        this._deviceCloudVersion = undefined;
        this.syncVersionsByDevice = {};
    }

    clearLocalVersionBeforeAppendingNewData() {
        this._local = undefined;
    }

    private allCloudVersions(): DraftSyncVersion[] {
        return Object.values(this.syncVersionsByDevice).concat(this._deviceCloudVersion ?? []);
    }

    private allVersions(): DraftSyncVersion[] {
        return this.allCloudVersions().concat(this._local ?? []);
    }

    hasDevice(device: UserDevice | null): boolean {
        if (!device) return !this.shouldRemoveFromMetadata();
        if (device.isCurrentDevice) return !!this._local;
        const version = this.syncVersionsByDevice[device.id];
        return !!version && !SyncStatus.isDeleted(version.syncStatus);
    }

    merge(prefetchScreenshotGroup?: AsyncTaskGroup): MergeStatus {
        const prevVersion = this.mostUpdatedVersionBeforeMerge ?? this._mostUpdatedVersion;
        const prevDeviceCloudVersion = this.deviceCloudVersionBeforeMerge ?? this._deviceCloudVersion;
        const deviceVersionFromCloud = this._deviceCloudVersion;

        this.mostUpdatedVersionBeforeMerge = undefined;
        this.deviceCloudVersionBeforeMerge = undefined;

        if (this._local) {
            this._deviceCloudVersion = prevDeviceCloudVersion;
        }

        const cloudVersions = this.allCloudVersions().sort((a, b) => b.roundedTimestamp - a.roundedTimestamp);
        const mostUpdatedNewVersion = cloudVersions.find(v => !v.isInConflict) ?? cloudVersions[0] ?? this._local;

        if (!mostUpdatedNewVersion) {
            return MergeStatus.Empty;
        }

        this._mostUpdatedVersion = mostUpdatedNewVersion;

        const updateScreenshot = async () => {
            if (!prefetchScreenshotGroup) return;
            if (SyncStatus.isDeleted(mostUpdatedNewVersion.syncStatus)) return;
            let screenshotUrl = mostUpdatedNewVersion.screenshotUrl
            if (mostUpdatedNewVersion.isCurrentDeviceVersion) return;
            
            
            if (screenshotUrl) {
                await CloudManager.shared.setCachedScreenshotUrl(screenshotUrl.toString(), mostUpdatedNewVersion);
            }
            
            (async () => {
                await prefetchScreenshotGroup.enter();
                await this.preFetchScreenshot();
                await prefetchScreenshotGroup.leave();
            })();
        };

        if (!prevVersion) {
            (async () => {
                await updateScreenshot();
            })();
            return MergeStatus.Added;
        }

        if (SyncStatus.isDeleted(mostUpdatedNewVersion.syncStatus) && !SyncStatus.isDeleted(prevVersion.syncStatus) && !this._local) {
            return MergeStatus.Removed;
        }

        if (this._local && deviceVersionFromCloud && this._local > deviceVersionFromCloud) {
            for (const version of Object.values(this.syncVersionsByDevice)) {
                if (!SyncStatus.isDeleted(version.syncStatus) && !version.isInConflict && version > deviceVersionFromCloud) {
                    this._deviceCloudVersion.isInConflict = true;
                    break;
                }
            }
        }

        if (mostUpdatedNewVersion.size !== prevVersion.size) {
            updateScreenshot();
            return MergeStatus.SizeChanged;
        }

        const timestampChanged = prevVersion.roundedTimestamp !== mostUpdatedNewVersion.roundedTimestamp;
        const ownerChanged = prevVersion.updateDeviceId !== mostUpdatedNewVersion.updateDeviceId;
        const screenshotChanged = prevVersion.screenshotUrl?.toString() !== mostUpdatedNewVersion.screenshotUrl?.toString();
        const hasNoChanges = !timestampChanged && !screenshotChanged && !ownerChanged;
        const hasStatusChange = prevVersion.statusUpdateDate.getTime() !== mostUpdatedNewVersion.statusUpdateDate.getTime();

        (async () => {
            const needToFetchScreenshot = !mostUpdatedNewVersion.isCurrentDeviceVersion && !hasNoChanges;
            if (needToFetchScreenshot) {
                if (mostUpdatedNewVersion.syncStatus === SyncStatus.Updated) {
                    await CloudManager.shared.setCachedScreenshotUrl(mostUpdatedNewVersion.screenshotUrl.toString(), mostUpdatedNewVersion);
                }
                await this.preFetchScreenshot();
            }
            if (hasStatusChange) {
                this.delegate?.syncItemDidUpdate();
            }
        })();

        return hasNoChanges ? MergeStatus.None : MergeStatus.ContentChanged;
    }

    updateFromDraft(draft?: TemplateConfig) {
        if (!draft && !this._local?._draft) return;
        this._local = DraftSyncVersion.fromDraft(draft ?? this._local!._draft);
    }

    updateStatus(status: SyncStatus, updateDeviceId: string = null, updateItem = true) {
        const createDeviceCloudVersion = !this._deviceCloudVersion && status !== SyncStatus.WaitingForUpload;
        const updateDeviceCloudVersion = status === SyncStatus.Uploading || (this._deviceCloudVersion && status === SyncStatus.WaitingForUpload);

        if (createDeviceCloudVersion || updateDeviceCloudVersion) {
            const conflict = this._deviceCloudVersion?.isInConflict ?? false;
            this._deviceCloudVersion = this._local?.deviceCloudVersionCopy();
            if (this._deviceCloudVersion) {
                this._deviceCloudVersion.isInConflict = conflict;
            }
        }

        if (SyncStatus.isDeleted(status) && this._deviceCloudVersion) {
            this._deviceCloudVersion.isInConflict = false;
        }

        if (this._deviceCloudVersion){
            this._deviceCloudVersion.syncStatus = status;
        }
        

        if (updateDeviceId && this._deviceCloudVersion) {
            this._deviceCloudVersion.updateDeviceId = updateDeviceId;
        }

        if (SyncStatus.isDeleted(status)) {
            this._local = undefined;
        }

        // TODO Dror: add to analytics
        // if (createDeviceCloudVersion) {
        //     BtAnalytics.sharedInstance().logEvent(SYNC_CREATE_PROJECT);
        // } else if (status === SyncStatus.Deleted) {
        //     BtAnalytics.sharedInstance().logEvent(SYNC_DELETE_PROJECT);
        // } else if (status === SyncStatus.Updated) {
        //     BtAnalytics.sharedInstance().logEvent(SYNC_UPLOAD_PROJECT);
        // }

        if (status !== SyncStatus.WaitingForUpload) {
            console.log(`sync: set status: ${SyncStatus.toString(status)} to project: ${this.draftId}`);
        }

        this.merge();

        if (updateItem) {
            this.delegate?.syncItemDidUpdate();
        }
    }

    didStartUploading() {
        this.delegate?.syncItemDidUpdate();
    }

    didStartDownloading() {
        this.delegate?.syncItemDidUpdate();
    }

    hasLocal(): boolean {
        return !!this._local;
    }

    hasCloudVersion(): boolean {
        return Object.keys(this.syncVersionsByDevice).length > 0 || !!this._deviceCloudVersion;
    }

    shouldRemoveFromMetadata(): boolean {
        if (this.hasLocal()) return false;
        const versions = this.allCloudVersions();
        if (versions.length === 0) return true;
        return versions.every(v => SyncStatus.isDeleted(v.syncStatus));
    }

    shouldRemoveFromList(): boolean {
        return this.shouldRemoveFromMetadata() || this.isDeleted();
    }

    isLocalOnly(): boolean {
        return this.hasLocal() && !this.hasCloudVersion();
    }

    shouldUploadToCloud(): boolean {
        if (!this._local) return false;
        if (!this._deviceCloudVersion && this.hasCloudVersion()) return true;
        if (!this._deviceCloudVersion) return false;
        if (this._deviceCloudVersion.syncStatus !== SyncStatus.Updated) return true;
        return this._local > this._deviceCloudVersion;
    }

    shouldDeleteFromCloud(): boolean {
        if (!this._deviceCloudVersion) return false;
        if (this._deviceCloudVersion.syncStatus === SyncStatus.Deleted) return false;
        if (this._deviceCloudVersion.syncStatus === SyncStatus.Deleting) return true;
        if (!this._local && this.allCloudVersions.length === 1) {
            console.warn(`sync: missing local version of: ${this._deviceCloudVersion}`);
            return this._deviceCloudVersion.syncStatus !== SyncStatus.Updated;
        }
        return false;
    }

    isSynched(): boolean {
        if (!this._local) return false;
        if (this.isOutDated()) return false;
        if (!this._deviceCloudVersion && this.hasCloudVersion()) return true;
        if (!this._deviceCloudVersion) return false;
        if (this._deviceCloudVersion.syncStatus !== SyncStatus.Updated) return false;
        return this._deviceCloudVersion.roundedTimestamp === this._local!.roundedTimestamp;
    }

    isDeleted(): boolean {
        return this.allCloudVersions().some(v => SyncStatus.isDeleted(v.syncStatus));
    }

    isOutDated(): boolean {
        return this._local && this.hasCloudVersion() && this.mostUpdatedVersion! > this._local && !SyncStatus.isDeleted(this.mostUpdatedVersion!.syncStatus);
    }

    needsUpdate(): boolean {
        return !this.hasLocal() || this.isOutDated();
    }

    isUploading(): boolean {
        return this.mostUpdatedVersion?.syncStatus === SyncStatus.Uploading || this.mostUpdatedVersion?.syncStatus === SyncStatus.WaitingForUpload;
    }

    isCurrentVersionIsUploading(): boolean {
        return this._deviceCloudVersion?.syncStatus === SyncStatus.Uploading || this._deviceCloudVersion?.syncStatus === SyncStatus.WaitingForUpload;
    }

    isUploadingNewUpdate(): boolean {
        if (!this.isCurrentVersionIsUploading()) return false;
        if (this._deviceCloudVersion?.updateDeviceId === UserDevice.current.id) return true;
        const versionDate = this._deviceCloudVersion?.modifiedDate;
        const draftDate = this._local?._draft?.draftModificationDate ?? this._local?._draft?.draftCreationDate;
        if (versionDate && draftDate && versionDate < draftDate) {
            return true;
        }
        return false;
    }

    hasConflict(): boolean {
        return this.mostUpdatedVersion?.isInConflict === true;
    }

    isDownloading(): boolean {
        return CloudManager.shared.isDownloading(this.draftId);
    }

    getProgress(): number {
        return CloudManager.shared.progressPercent(this.draftId);
    }

    isInProgress(): boolean {
        return this.getProgress() >= 0;
    }

    hasMultipleVersions(): boolean {
        return this.allCloudVersions().length > 1;
    }

    //MARK: coding

    static supportsSecureCoding = true;

    static shouldAutoEncode(): boolean {
        return false;
    }
}