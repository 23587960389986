import { PopoverType, panelListItems } from '@/constants/app-options'
import PanelListItem from '../../Panels/PanelListItem'
import useAppContext from '@/hooks/useAppContext'
import Graphics from '../../Panels/PanelItems/Graphics'
import { useHistory, useLocation } from 'react-router-dom'

function AddGraphics({idFromDeeplink}) {
  const panelListItem = panelListItems.find(x => x.name === 'Graphics')

  const { activePanel, popoverActive, setPopoverActive } = useAppContext()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory()

  const handleCloseTemplatePanel = () => {
    setPopoverActive(null)
    if(idFromDeeplink) {
      searchParams.delete('po');
      searchParams.delete('id');
      history.push({ search: searchParams.toString() });
    }
  }

  return (
    <div>
      <div
        onClick={() => {
          setPopoverActive(popoverActive === PopoverType.GRAPHICS ? null : PopoverType.GRAPHICS)
        }}
        id="popover-graphic"
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.GRAPHICS}
        />
      </div>
      <Graphics close={handleCloseTemplatePanel} isOpen={popoverActive === PopoverType.GRAPHICS} idFromDeeplink={idFromDeeplink} />
    </div>
  )
}

export default AddGraphics
