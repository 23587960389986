import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

function ModalProjectOpenFailed() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.PROJECT_OPEN_FAILED}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Can’t open this project'),
        desc: t('Please contact Bazaart support.'),
      }}
      action={{
        primary: {
          name: t('Ok'),
          handle: () => {
            setShowModalType(null)
            if(history.location.pathname.includes('projects')) {
              history.replace('/create/projects')
            } else {
              history.push('/create')
            }
          }
        },
      }}
    ></ModalCustom>
  )
}

export default ModalProjectOpenFailed
