// @ts-ignore
import FaviconError from '@assets/icons/favicon-eror.ico'
// @ts-ignore
import Favicon from '@assets/icons/favicon.ico'
import { Cookies, useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

const ItemType = {
  Session: 1,
  Cookie: 2
};

export class URLLockManager {
    private localStorageTimeout = 15000 * 1000; 
    sessionGuid: string | null
    cookies = new Cookies();
    currentUrl: string | null
  
    private constructor() {
      this.sessionGuid = this.createGUID();

      window.addEventListener('beforeunload', (event) => {
        let cookie = this.getItem(ItemType.Cookie, this.currentUrl)
        if (this.sessionGuid === cookie?.guid) {  
          this.cookies.remove(this.getCookieName(this.currentUrl), {path: '/'})
          this.cookies.update();
        }
      });

      window.addEventListener('storage', (event) => {
        if (event.key !== 'tab-broadcast') {
          return
        }
        const data = JSON.parse(event.newValue);
        if (!data || data.action !== 'relinquish-control') {
          return;
        }
        if (this.currentUrl == data.url) {
          this.updateFavicon(data.sessionGuid !== this.sessionGuid)
        }
      });
    }

    updateFavicon(isError: boolean) {
      if (isError) {
        document.getElementById('favicon').setAttribute('href', FaviconError)
      } else {
        document.getElementById('favicon').setAttribute('href', Favicon)
      }
    }

    private static instance: URLLockManager

    static getInstance(): URLLockManager {
      if (!this.instance) {
        this.instance = new URLLockManager()
      }
      return this.instance
    }
    
    clear() {
      if (this.currentUrl && this.sessionGuid === this.getItem(ItemType.Cookie, this.currentUrl)?.guid){
        this.cookies.remove(this.getCookieName(this.currentUrl), {path: '/'})
        this.cookies.update();
      }
      this.currentUrl = null;
    }

    private getCookieName(name: string): string{
      let prefix = "url_lock"
      let cookieName = `${prefix}_${name}`;
      return encodeURIComponent(cookieName);
    }

    // New method to handle URL changes
    handleURLChange(url: string) {
      if (!this.currentUrl) {
        this.updateFavicon(false);
        this.currentUrl = url;
        return;
      }

      let storedCookie = this.getItem(ItemType.Cookie, url)
      if (!storedCookie) { // no cookie, meaning we are now longer viewing a duplicate tab
        this.updateFavicon(false);
      } else if (this.sessionGuid === storedCookie.guid){
        this.cookies.remove(this.getCookieName(this.currentUrl), {path: '/'})  
        this.cookies.update();
      }
      this.currentUrl = url
    }

    takeOverTab(url: string) {
      this.setItem(ItemType.Session, url, this.sessionGuid);
  
      var newTabObj = {
        guid: this.sessionGuid,
        timestamp: new Date().getTime(),
      };
      this.setItem(ItemType.Cookie, url, JSON.stringify(newTabObj));
  
      this.broadcastToOtherTabs(this.sessionGuid, url);
      this.updateFavicon(false)
    }

    private broadcastToOtherTabs(currentSessionGuid: string, url: string) {
      // Broadcast to other tabs to show the popup
      window.localStorage.setItem('tab-broadcast', JSON.stringify({
        action: 'relinquish-control',
        sessionGuid: currentSessionGuid,
        url: url,
      }));
  
      // Clean up after broadcasting
      setTimeout(() => {
        window.localStorage.removeItem('tab-broadcast');
      }, 500);
    }
  
    isTabDuplicated(url: string): boolean {
      var tabObj = this.getItem(ItemType.Cookie, url);
  
      //console.log("In testTab");
      let sessionGuid = this.getItem(ItemType.Session, url)?.guid || this.sessionGuid;
      this.setItem(ItemType.Session, url, this.sessionGuid);
        
      // If no or stale tab object, our session is the winner.  If the guid matches, ours is still the winner
      if (
        tabObj === null ||
        tabObj.timestamp < new Date().getTime() - this.localStorageTimeout ||
        tabObj.guid === sessionGuid
      ) {
       
        var newTabObj = {
          guid: sessionGuid,
          timestamp: new Date().getTime()
        };
        this.setItem(ItemType.Cookie, url,JSON.stringify(newTabObj));
        
        return false;
      } else {
        return true;
      }
    }

    private createGUID() {
      const s4 = function() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      };
      return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    }

    private getItem(itemtype, url): any {
      var val = "";
      switch (itemtype) {
        case ItemType.Session:
          val = window.name;
          break;
        case ItemType.Cookie:
          val = this.cookies.get(this.getCookieName(url))
          if (val == undefined) val = null;
          break;
      }
      return val;
    }
  
    private setItem(itemtype, url, val): any {
      switch (itemtype) {
        case ItemType.Session:
          window.name = val;
          break;
        case ItemType.Cookie:

          this.cookies.set(this.getCookieName(url), val, { path: '/' })
          this.cookies.update();
          break;
      }
    } 
  }