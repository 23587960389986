import { useEffect, useRef } from 'react'
import BackgroundImage from '@assets/images/bg_login.jpg'
import Icons from '../../../Icons'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { lightTheme } from '@/customTheme'
import { useAppDispatch } from '@store/store'
import { setOpenModalTryPremium, setUser } from '@/store/slices/user/actions'
import { SignInManager } from '../SignInManager'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { useHistory, useLocation } from 'react-router-dom'
import LogoIcon from '@assets/images/logo_only.svg'
import { clearTutorial } from '@/utils/tutorial'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import useAppContext from '@/hooks/useAppContext'
import { useSelector } from 'react-redux'
import { selectOpenModalTryPremium } from '@/store/slices/user/selectors'

function SignIn({ isOpen, close, setLoggin, handleOpenTutorial }) {
  const inputRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const editor = useEditorContext().editor
  const removeEditor = useRemoveEditorContext().editor
  const { setisOpenPixelManipulationObject, setToolType, setIsOpenPixelManipulationWithAnimation,  } = useAppContext()
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const { cancelPixelManipulation } = useAppContext()
  useEffect(() => {
    SignInManager.getInstance().setupUI(inputRef.current)
  }, []) // Note the empty dependency array

  const location = useLocation()

  useEffect(() => {
    if(isOpen){
      customAmplitude('Sign up Prompt', {
        Source: openModalTryPremium.source ?? location.pathname.includes('create') ? 'Create Header_CTA' : 'Header_CTA',
      })
    }
  }, [isOpen]) // Note the empty dependency array

  const login = async social => {
    // setOpenModalTryPremium({
    //   ...openModalTryPremium,
    //   isOpen: false
    // })
    // setIsLoadedJsonSuccess(false)
    let user
    if (social === 'facebook') {
      user = await SignInManager.getInstance().loginWithFacebook()
    } else if (social === 'google') {
      user = await SignInManager.getInstance().loginWithGoogle()
    } else if (social === 'apple') {
      user = await SignInManager.getInstance().loginWithApple()
    } else {
      return
    }
    setLoggin(!!user)
    if (!!user) {
      dispatch(setUser(user))
      if (user.userIsPremium) {
        dispatch(
          setOpenModalTryPremium({
            ...openModalTryPremium,
            isOpen: false,
          })
        )
        openModalTryPremium.callback && openModalTryPremium.callback()
      } else {
        if (openModalTryPremium.source) {
          dispatch(
            setOpenModalTryPremium({
              ...openModalTryPremium,
              isOpen: true,
            })
          )
        }
      }
      // handle login from good work modal
      if(openModalTryPremium.source === 'Tutorial') {
        let showedRemoveObjectTutorial = localStorage.getItem('showedRemoveObjectTutorial') ? JSON.parse(localStorage.getItem('showedRemoveObjectTutorial')) : false
        if(showedRemoveObjectTutorial && showedRemoveObjectTutorial.from !== '/') {
          history.push(showedRemoveObjectTutorial.from)
        } else {
          removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
            removeEditor.handlers.pixelManipulationObjectHandler.reset()
            removeEditor.handlers.canvasRemoveHandler.canvas.clear()
            const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
            cursorPreview.style.display = 'none'
            editor.handlers.scrollbarHandler.updateScrollPosition()
            cancelPixelManipulation()
            clearTutorial(editor.handlers)
          })
        }
      }
    } else {
      handleOpenTutorial()
    }
    // setIsLoadedJsonSuccess(true)
    close()
  }

  const dispatch = useAppDispatch()
  const { t } = useTranslation()  
  return (<>
    <div
      id="signin"
      style={{
        width: '100vw',
        height: '100vh',
        maxWidth: '920px ',
        maxHeight: '652px',
        flexDirection: 'row',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'absolute',
        display: 'flex'
      }}
    >
      <div
        ref={inputRef}
        style={{
          width: '50%',
          padding: '102px 64px',
          background: 'rgba(255, 255, 255, 1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <img 
         alt="logo"
         style={{
           width: 'fite-content',
           height: '54px',
         }}
        src={LogoIcon}/>

        <h2 style={{ marginBottom: 0, marginTop: '32px', ...lightTheme.typography.Header26bold }}>
          {t('Welcome to Bazaart')}
        </h2>
        <h3 style={{ marginBottom: 0, marginTop: '8px', ...lightTheme.typography.Small14regular }}>
          {t('Join a million+ people who create professional-looking designs every month.')}
        </h3>

        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.secondary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            color: lightTheme.colors.blackGray,
            borderRadius: '100px',
            marginBottom: 0,
            marginTop: '32px',
          }}
          onClick={async () => {
            await login('google')
          }}
        >
          <Icons.GGIcon style={{ position: 'absolute', left: '12px' }} />
          <>{t('Continue with Google')}</>
        </ButtonCustom>
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.secondary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            background: 'rgba(57, 117, 234, 1)',
            color: '#fff',
            borderRadius: '100px',
            marginBottom: 0,
            marginTop: '16px',
            ':hover': {
              background: 'rgba(51, 105, 211, 1)',
            },
          }}
          onClick={async () => {
            await login('facebook')
          }}
        >
          <Icons.FbIcon
            style={{
              position: 'absolute',
              left: '12px',
            }}
          />
          <>{t('Continue with Facebook')}</>
        </ButtonCustom>
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.secondary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            background: lightTheme.colors.blackGray,
            color: '#fff',
            borderRadius: '100px',
            marginBottom: 0,
            marginTop: '16px',
            ':hover': {
              background: 'rgba(26, 26, 26, 1)',
            },
          }}
          onClick={async () => {
            await login('apple')
          }}
        >
          <Icons.AppleIcon style={{ position: 'absolute', left: '12px' }} />
          <>{t('Continue with Apple')}</>
        </ButtonCustom>
        <h4
          style={{
            marginBottom: 0,
            marginTop: '16px',
            ...lightTheme.typography.Small12medium,
            color: 'rgba(153, 153, 153, 1)',
            fontWeight: 400,
            padding: '0 16px',
          }}
        >
          {t('By signing up, you agree to our')}{' '}
          <span
            style={{ fontWeight: 500, cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.bazaart.me/terms/#page', '_blank')
            }}
          >
            {t('Terms of service')}
          </span>{' '}
          {t('and')}{' '}
          <span
            style={{ fontWeight: 500, cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.bazaart.me/terms/#privacy', '_blank')
            }}
          >
            {t('Privacy policy')}
          </span>
        </h4>
      </div>
      <div style={{ width: '50%' }}>
        <img src={BackgroundImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          close()
          handleOpenTutorial()
        }}
      >
        <Icons.WhiteCloseIcon size={24} />
      </div>
    </div>
  </>)
}

export default SignIn