import { Rectangle } from "@/scenes/engine/objects/media-repository/rectangle";
import { LayerEffect } from "./LayerEffect";
import { Size } from "@/scenes/engine/objects/media-repository/size";

export class CanvasLayerOutlineEffect implements LayerEffect {
    
    applyEffectBounds(item: any, bounds: Rectangle): Rectangle {
        let outline = item.effects?.outline;
        if (!outline) {
        return new Rectangle(0, 0, bounds.width, bounds.height);
        }
        let maxEdge = Math.max(bounds.width, bounds.height)
        let targetWidth = bounds.width + maxEdge * 2 * outline.thickness
        let targetHeight = bounds.height + maxEdge * 2 * outline.thickness

        let rect = new Rectangle(bounds.x - outline.thickness * maxEdge, bounds.y - outline.thickness * maxEdge, targetWidth, targetHeight);
        return rect;
    }

    revertEffectBounds(item: any, canvasSize: Size, isSymmetric: boolean, boundsWithEffects: Rectangle): Rectangle {
        let thickness = item.effects?.outline?.thickness;
        let w = boundsWithEffects.width, h = boundsWithEffects.height;

        let actualWidth = item.sizeOnCanvas.width * canvasSize.width;
        let actualHeight = item.sizeOnCanvas.height * canvasSize.height;
        let isWide = actualWidth > actualHeight;

        if (!thickness) {
            return boundsWithEffects;
        }
        
        // following this logic - 
        // originalImage.width + max(originalImage.width, originalImage.height) * 2 * thickness = img.width
        // originalImage.height + max(originalImage.width, originalImage.height) * 2 * thickness = img.height
        
        if (isWide) {
            // replacing max with contion
            // originalImage.width * (1 + 2 * thickness) = img.width
            // originalImage.height + originalImage.width * 2 * thickness = img.height
            
            w = boundsWithEffects.width / (1 + 2 * thickness)
            h = boundsWithEffects.height - w * 2 * thickness
        } else {
            h = boundsWithEffects.height / (1 + 2 * thickness)
            w = boundsWithEffects.width - h * 2 * thickness
        }
        
        let maxEdge = Math.max(w, h);
        let rect = new Rectangle(boundsWithEffects.x + maxEdge * thickness, boundsWithEffects.y + maxEdge * thickness, w, h);
        return rect;
    }
}