import { CloudFileTransfer } from "./CloudFileTransfer";
import { CloudProjectFileTransfer } from "./CloudProjectFileTransfer";
import { AtomicDictionary } from "./dataTypes/AtomicDictionary";

class CloudProgress {
    filesProgress = new AtomicDictionary<string, { percent: number; size: number }>();
    isCanceled = false;

    constructor(files: CloudFileTransfer[]) {
        files.forEach(file => this.updateProgress(file));
    }

    get totalSize(): number {
        return Object.values(this.filesProgress.dictionary).reduce((total, entry) => total + entry.size, 0);
    }

    get percent(): number {
        let totalSize = 0;
        let currentSize = 0;
        for (const entry of this.filesProgress.dictionary) {
            totalSize += entry.size;
            currentSize += entry.size * entry.percent;
        }
        if (totalSize === 0) {
            return 1;
        }
        return currentSize / totalSize;
    }

    updateProgress(cloudFile: CloudFileTransfer): void {
        if (!(cloudFile instanceof CloudProjectFileTransfer)) {
            throw new Error("Can't get file size synchronously");
        }
        let projectFile = cloudFile as CloudProjectFileTransfer;
        this.filesProgress.set(projectFile.relativePath, { percent: projectFile.progressPercent, size: projectFile.size?.valueOf() ?? 1 });
    }
}

export { CloudProgress };
