import { TemplateConfig } from "@/interfaces/editor";
import { BtDiskFileUtils } from "./BtDiskFileUtils";
import { CloudResponse, EmptyResponse } from "./CloudResponse";
import { BtDraftMigrator } from "./BtDraftMigrator";
import { UserDevice } from "./UserDevice";
import { VirtualFileSystem } from "@/scenes/engine/objects/media-repository/VirtualFileSystem";
import { DateProvider } from "../DateProvider";
import { BtDraftsManager } from "./BtDraftsManager";


export class CloudTempProject {
    static get baseUrl(): URL {
        // const url = new URL(FileManager.default.temporaryDirectory.toString());
        const url = new URL('https://bazaart.com');
        url.pathname = `${url.pathname}sync/projects`;
        return url;
    }

    get projectUrl(): URL {
        return new URL(`${CloudTempProject.baseUrl}/${this.projectId}`);
    }

    get configLayersPath(): string {
        return `${this.getDraftPathForProjectId(this.projectId)}/layers_config.json`;
    }

    get finalProjectDest(): string {
        return this.getDraftPathForProjectId(this.projectId)
    }

    get configProjectPath(): string {
        return `${this.getDraftPathForProjectId(this.projectId)}/project_config.json`;
    }

    get originalProject(): string | null {
        let project = VirtualFileSystem.getInstance().get(this.configProjectPath)
        return project?.blobUrl;
    }

    getDraftPathForProjectId(projectId: string): string | null {
        return `/projects/${projectId}`;
    }
    
    getDraftPath(project: TemplateConfig | null): string | null {
        if (!project) {
            return null;
        }
        return this.getDraftPathForProjectId(project.draftGuid);
    }

    projectId: string;
    
    constructor(projectId: string) {
        this.projectId = projectId;
    }

    async prepareForUpload(): Promise<void> {
        const originalProject = this.originalProject;
        if (!originalProject) {
            console.log(`sync: has no, or couldn't load the local project ${this.projectId}`);
            return;
        }

        const currentDraftPath = this.getDraftPathForProjectId(this.projectId);

        if (currentDraftPath) {
            await this.prepareProject(currentDraftPath)
        }
    }

    private async prepareProject(currentDraftPath: string){
        BtDiskFileUtils.linkFiles([currentDraftPath], [this.projectUrl.pathname]);
        let allLayers = await this.loadLayers();
        
        // @ts-ignore
        let layerIds = allLayers?.flatMap((a) => Object.values(a)).map((l) => l.bazaartGuid) ?? []
        await BtDiskFileUtils.filterDeletedLayers(this.projectUrl.pathname, layerIds)
        await BtDiskFileUtils.keepOnlyLatestVersion(this.projectUrl.pathname)
    }

    clear(): void {
        try {
            BtDiskFileUtils.deleteFiles([this.projectUrl.pathname]);
        } catch (error) {
            console.warn(`sync: failed to delete temp projects folder: ${error.message}`);
        }
    }

    async saveToProjects(updateVersion: boolean, afterProject: TemplateConfig | null): Promise<CloudResponse<[TemplateConfig, any[]]> | EmptyResponse> {
        let newProject = await this.loadProject();

        if (!newProject) {
            return EmptyResponse.sendError(`sync: failed to load temp downloaded project: ${this.projectId}`);
        }

        this.deleteEmptyDirectories();

        const currentDraftPath = this.getDraftPathForProjectId(newProject.draftGuid);
        if (this.originalProject) {
            let renamedDraftPath = `/projects/_${this.projectId}`;

            try {
                await BtDiskFileUtils.moveFiles([currentDraftPath], [renamedDraftPath]);
            } catch (error) {
                return EmptyResponse.sendError(`sync: failed to rename existing project folder: ${this.projectId}. error: ${error.message}`);
            }

            try {
                await BtDiskFileUtils.moveFiles([this.projectUrl.pathname], [currentDraftPath]);
            } catch (error) {
                await BtDiskFileUtils.moveFiles([renamedDraftPath], [currentDraftPath]);
                return EmptyResponse.sendError(`sync: failed to move downloaded project from temp to projects folder. project: ${this.projectId}. error: ${error.message}`);
            }

            try {
                await BtDiskFileUtils.deleteFiles([renamedDraftPath]);
            } catch (error) {
                console.warn(`sync: failed to delete old project directory of: ${renamedDraftPath}. error: ${error.message}`);
            }

        } else {
            const destPath = this.getDraftPath(newProject);
            if (!destPath) {
                return EmptyResponse.sendError(`sync: failed to get destination path for new fetched project`);
            }
            try {
                await BtDiskFileUtils.moveFiles([this.projectUrl.pathname], [destPath]);
            } catch (error) {
                return EmptyResponse.sendError(`sync: failed to move downloaded project from temp to projects folder. project: ${this.projectId}. error: ${error.message}`);
            }
        }

        // if (updateVersion) {
        //     BtDraftsManager.saveDraft(newProject);
        // } else if (afterProject) {
        //     BtDraftsManager.updateDrafts(newProject, afterProject);
        // } else {
        //     BtDraftsManager.updateDrafts(newProject);
        // }
        // BtDraftsManager.updateScreenshotCache(newProject);
        let migrator = new BtDraftMigrator();
        const migrationResult = await migrator.asyncMigrate(newProject);
        if (!migrationResult.success) {
            return EmptyResponse.sendError(`sync: failed to migrate downloaded project: ${this.projectId}`);
        }

        newProject = await this.loadProject();
        if (newProject.draftDate){
            newProject.draftDate = new Date(newProject.draftDate)
        }
        if (newProject.screenshotDate){
            newProject.screenshotDate = new Date(newProject.screenshotDate)
        }
        if (newProject.draftModificationDate){
            newProject.draftModificationDate = new Date(newProject.draftModificationDate)
        }
        if (newProject.draftCreationDate){
            newProject.draftCreationDate = new Date(newProject.draftCreationDate)
        }
        

        const newLayers = await this.loadLayers();
        let projectInfo = [newProject, newLayers]
        let response = {
            succeed: true,
            value: projectInfo
        } as CloudResponse<[TemplateConfig, any[]]>
        return response
    }

    async loadProject(): Promise<TemplateConfig | null> {
        let blobString = VirtualFileSystem.getInstance().get(this.configProjectPath)?.blobUrl
        if (!blobString){
            return Promise.resolve(BtDraftsManager.createDraft(this.projectId));
        }
        let json = await this.loadJsonContent(blobString)
        if (!json){
            let t = VirtualFileSystem.getInstance().get(this.configProjectPath)?.blobUrl
        }
        let project = json['BtDraftObject'] as TemplateConfig
        return project
    }

    async loadLayers(): Promise<any[] | null> {
        let blobString = VirtualFileSystem.getInstance().get(this.configLayersPath)?.blobUrl
        if (!blobString){
            blobString = VirtualFileSystem.getInstance().get(this.configLayersPath)?.blobUrl
            return Promise.resolve(null);
        }
        let json = await this.loadJsonContent(blobString)
        return json as any[]
    }

    async loadJsonContent(blobUrl): Promise<any> {
        try {
            const response = await fetch(blobUrl);
            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }
            // Parse the response as JSON
            const json = await response.json();
            return json;
        } catch {
            return null;
        }
    }

    private deleteEmptyDirectories(): void {
        try {
            const emptyDirectories = BtDiskFileUtils.allEmptyDirectories(this.projectUrl.pathname);
            if (emptyDirectories.length > 0) {
                console.log(`sync: deleting ${emptyDirectories.length} empty directories`);
                BtDiskFileUtils.deleteFiles(emptyDirectories.map(dir => dir));
            }
        } catch (error) {
            console.warn(`sync: failed to delete empty project directories of: ${this.projectUrl}. error: ${error.message}`);
        }
    }

    static relativeToProjectsPath(path: string, deviceId?: string): string {
        if (deviceId !== undefined && deviceId !== UserDevice.current.id) {
            throw new Error("Can't get local path of other devices");
        }

        const base = CloudTempProject.baseUrl.pathname;
        let relative = path.replace(base, "");
        relative = relative.replace(CloudTempProject.baseUrl.toString(), "");
        let relativeNoSuffixSlash = relative.replace(/^\/+/, '');
        return relativeNoSuffixSlash;
    }

    deconstructor() {
        this.clear();
    }
}
