import { StorageReference } from "firebase/storage";
import { ProcessType } from "./ProcessType";
import { CloudProcess } from "./CloudProcess";
import { CloudProgress } from "./CloudProgress";
import { getMetadata } from 'firebase/storage';
import { EmptyResponse } from "./CloudResponse";
import { BtDiskFileUtils } from "./BtDiskFileUtils";
import { CloudUrl, StorageTask } from "./CloudUrl";

class CloudFileTransfer {
    relativePath: string;
    type: ProcessType;
    shouldDelete: boolean;
    deviceId?: string;
    linkToRelativeFilePath?: string;
    fileModifiedDate?: Date;

    private running: boolean = false;
    task?: StorageTask;

    progressPercent: number = 0;

    constructor(
        relativePath: string,
        type: ProcessType,
        modifiedDate?: Date,
        deviceId?: string,
        shouldDelete: boolean = false,
        linkRelativePath?: string
    ) {
        this.relativePath = relativePath;
        this.type = type;
        this.shouldDelete = shouldDelete;
        this.deviceId = deviceId;
        this.linkToRelativeFilePath = linkRelativePath;
        this.fileModifiedDate = modifiedDate;
    }

    get localUploadUrl(): URL {
        throw new Error("implement at subclass");
    }

    get localDownloadUrl(): URL {
        throw new Error("implement at subclass");
    }

    get localLinkUrl(): URL | null {
        throw new Error("implement at subclass");
    }

    get cloudUrl(): CloudUrl.ExtendedStorageReference {
        throw new Error("implement at subclass");
    }

    async getFileSize(): Promise<number> {
        if (this.type === ProcessType.Download) {
            try {
                const metadata = await getMetadata(this.cloudUrl);
                return metadata.size;
            } catch {
                return 0;
            }
        } else {
            // TODO Dror: implement this logic for local blob files:
            return 0;
        }
    }

    async run(retry: number = 1, process?: CloudProcess, progress?: CloudProgress): Promise<EmptyResponse> {
        const canProceed = process ? await process.canProceed : true;

        if (!canProceed) {
            const err = process?.error;
            const code = err ? err.errorCode : undefined;
            let errorResponse =  EmptyResponse.sendError(err?.error || `sync: ${this.type.toString()} process was canceled: ${this.relativePath}`);
            errorResponse.errorCode = code
            return errorResponse;
        }

        if (this.shouldDelete) {
            try {
                if (this.type === ProcessType.Upload) {
                    if (this.isHardLink) {
                        return EmptyResponse.success();
                    }
                    await CloudUrl.deleteFile(this.cloudUrl);
                } else {
                    await BtDiskFileUtils.deleteFiles([this.localDownloadUrl.pathname]);
                }
            } catch (error) {
                if (retry > 0) {
                    return this.run(retry - 1, process, progress);
                }
                const msg = `sync: on ${this.type.toString()}, failed to delete file: ${this.relativePath}, error: ${error.message}`;
                if (process) {
                    process.didFailed(EmptyResponse.sendError(msg));
                }
                return EmptyResponse.sendError(msg);
            }
            return EmptyResponse.success();
        }

        if (this.isHardLink) {
            if (this.type === ProcessType.Upload) {
                return EmptyResponse.success();
            } else if (this.localLinkUrl) {
                if (this.localDownloadUrl) {
                    try {
                        await BtDiskFileUtils.deleteFiles([this.localDownloadUrl.pathname]);
                    } catch (error) {
                        const msg = `sync: on ${this.type.toString()}, failed to delete file before link: ${this.relativePath}, error: ${error.message}`;
                        if (process) {
                            process.didFailed(EmptyResponse.sendError(msg));
                        }
                        return EmptyResponse.sendError(msg);
                    }
                }

                if (!this.localLinkUrl) {
                    const msg = `sync: on ${this.type.toString()}, failed to link file: ${this.relativePath}, error: ${this.localLinkUrl.pathname} doesn't exist`;
                    if (process) {
                        process.didFailed(EmptyResponse.sendError(msg));
                    }
                    return EmptyResponse.sendError(msg);
                }

                try {
                    await BtDiskFileUtils.linkFiles([this.localLinkUrl.pathname], [this.localDownloadUrl.pathname]);
                } catch (error) {
                    const msg = `sync: on ${this.type.toString()}, failed to link file: ${this.relativePath}, error: ${error.message}`;
                    if (process) {
                        process.didFailed(EmptyResponse.sendError(msg));
                    }
                    return EmptyResponse.sendError(msg)
                }

                if (this.fileModifiedDate) {
                    if (!this.resetDate(this.localDownloadUrl, this.fileModifiedDate)) {
                        return EmptyResponse.sendError(`sync: failed to reset file date: ${this.relativePath}`)
                    }
                }
            } else {
                throw new Error("shouldn't reach here");
            }
            return EmptyResponse.success();
        }

        return new Promise<EmptyResponse>(async (resolve) => {
            const taskCompletion = (error: Error | null) => {
                if (error) {
                    if (retry > 0) {
                        this.run(retry - 1, process, progress).then(resolve);
                        return;
                    }

                    const errorMessage = `sync: failed to ${this.type.toString()} file: ${this.relativePath}, error: ${error.message}`;
                    if (process) process.didFailed(EmptyResponse.sendError(errorMessage) );
                    resolve(EmptyResponse.sendError(errorMessage));
                } else {
                    if (this.fileModifiedDate) {
                        if (!this.resetDate(this.localDownloadUrl, this.fileModifiedDate)) {
                            resolve(EmptyResponse.sendError(`sync: failed to reset file date: ${this.relativePath}`))
                        } else {
                            resolve(EmptyResponse.success());
                        }
                    } else {
                        resolve(EmptyResponse.success());
                    }
                }
            };

            let shouldSkip = false;
            if (this.type === ProcessType.Upload) {
                let file = BtDiskFileUtils.readFile(this.localDownloadUrl.pathname) 
                if (!file) {
                    shouldSkip = true;
                } else {
                    let uploadTask = await CloudUrl.putFile(this.cloudUrl, file, { customMetadata: { retry: retry.toString() } }, (snapshot, error) => {
                        if (error){
                            console.log(`failed to upload ${this.localDownloadUrl.pathname} to ${this.cloudUrl} with error ${error}`);
                            taskCompletion(error);
                        } else {
                            taskCompletion(null);
                        }
                    });
                    // @ts-ignore
                    this.task = uploadTask.task ? uploadTask.task : uploadTask;
                    if (!this.task){
                        console.log(`failed to write ${this.localDownloadUrl.pathname} to ${this.cloudUrl}`)
                    }
                }
            } else {
                await BtDiskFileUtils.createFolderIfNone(this.localDownloadUrl.pathname)
                this.task = CloudUrl.downloadFile(this.cloudUrl, this.localDownloadUrl.pathname, async (blobUrl, err) => {
                    if (err) {
                        taskCompletion(err)
                        return
                    }
                    
                    let success = await BtDiskFileUtils.writeFile(this.localDownloadUrl.pathname, blobUrl)
                    if (success) {
                        taskCompletion(null)
                    } else {
                        let err = {
                            name: 'failed to write file',
                            message: 'failed to write file',
                            stack: ''
                        }
                        taskCompletion(err)
                    }
                });

                if (!this.task) {
                    console.log(`failed to download ${this.cloudUrl} to ${this.localDownloadUrl.pathname}`)
                }
            }
            if (shouldSkip){
                taskCompletion(null)
            } else {
                this.task.on('state_changed', (snapshot) => {
                    this.progressPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    if (progress) progress.updateProgress(this);
                }, (error) => {
                    taskCompletion(error)
                });
            }
        });
    }

    get isHardLink(): boolean {
        return !!this.linkToRelativeFilePath;
    }

    get description(): string {
        if (this.shouldDelete) {
            return `sync: deleting file: ${this.relativePath}, from ${this.type === ProcessType.Upload ? "cloud" : "device"}`;
        }
        const path = this.linkToRelativeFilePath || this.relativePath;
        const linkText = this.linkToRelativeFilePath ? "link to " : "";
        return `sync: ${this.type.toString()} ${linkText}file: ${path}, from ${this.type === ProcessType.Upload ? "cloud" : "device"}`;
    }

    resetDate(url: URL, date: Date): boolean {
        return BtDiskFileUtils.resetDate(url.pathname, date)
    }
}

export { CloudFileTransfer };
