import { lightTheme } from '@/customTheme'
import Icons from '@/scenes/Editor/components/Icons'
import { ThemeProvider } from 'baseui'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'

const DropZone = ({
  children,
  handleDropFiles,
  isParentOpen,
}: {
  children: JSX.Element | JSX.Element[]
  handleDropFiles: Function
  isParentOpen: boolean
  parentIsDashboard?: boolean
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [dragCounter, setDragCounter] = useState(0)
  const dropRef = useRef<HTMLDivElement>()

  useEffect(() => {
    let div = dropRef.current
    if (div) {
      div.addEventListener('dragenter', handleDragIn)
      div.addEventListener('dragleave', handleDragOut)
      div.addEventListener('dragover', handleDrag)
      div.addEventListener('drop', handleDrop)
    }
    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn)
        div.removeEventListener('dragleave', handleDragOut)
        div.removeEventListener('dragover', handleDrag)
        div.removeEventListener('drop', handleDrop)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentOpen])

  useEffect(() => {
    if (!isParentOpen) {
      setDragCounter(0)
      setIsDragging(false)
    }
  }, [isParentOpen])

  const handleDrag = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragIn = e => {
    const isFromUpload = e.srcElement.closest('.item-list-upload')
    
    e.preventDefault()
    e.stopPropagation()
    setDragCounter(pre => ++pre)

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0 && isParentOpen && !isFromUpload) {
      setIsDragging(true)
    }
  }
  const handleDragOut = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter(pre => --pre)

    if (dragCounter > 0) return
    
    setIsDragging(false)
  }
  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDropFiles(e.dataTransfer.files)
      e.dataTransfer.clearData()
      setDragCounter(0)
    }
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
        }}
        ref={dropRef}
      >
        {isDragging && (
          <div
            style={{
              backgroundColor: '#FFEBEF',
              margin: '24px',
              marginTop: 0,
              border: '1px dashed #FF0560',
              borderRadius: '8px',
              zIndex: 9999,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#FF0560',
              pointerEvents: 'none',
              height: '100%',
            }}
          >
            <Icons.DropImage />
            <div style={{ ...lightTheme.typography.Small14Semibold, margin: '16px 0', color: '#FF0560' }}>
            {t('Drop to upload your file')}
            </div>
            <p
              style={{
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '24px',
                margin: '0 0 40px 0',
                width: '244px',
                textAlign: 'center',
              }}
            >
              {t('Files for upload should be .jpg or .png and 10 MB or less.')}
            </p>
          </div>
        )}
        {!isDragging && children}
      </div>
    </ThemeProvider>
  )
}

export default DropZone
