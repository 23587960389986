import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : lightTheme.colors.blackGray}
      d="M9.978 2.283a3 3 0 0 1 4.04 0l7 6.43c.624.568.98 1.374.98 2.218v8.568a3 3 0 0 1-3 3.001h-2.994a1 1 0 0 1-1-1v-6.995a1 1 0 0 0-1-1H10a1 1 0 0 0-1 1V21.5a1 1 0 0 1-1 1H5.002a3 3 0 0 1-3-3.001v-8.568c0-.845.355-1.65.98-2.218l6.996-6.43Z"
    />
  </svg>
)
export default SvgComponent
