import { IEditorContext } from '.'
import { ObjectType } from './common/constants'
import { EditorOptions } from './common/interfaces'
import Handlers from './handlers'
import EventManager from './utils/EventManager'
import objectToFabric from './utils/objectToFabric'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { fabric } from 'fabric'
import { nanoid } from 'nanoid'
import { CompositedRemoteAssetProviderCancellationToken, RemoteAssetProvider, RemoteResourceProvider, RemoteTextLoader } from './objects/asset-providers/RemoteAssetProvider'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { findSizeId } from '../Editor/components/Navbar/components/Resize'
import { customAmplitude } from '@/utils/customAmplitude'
import { Size } from './objects/media-repository/size'
import { FrameOptions } from './objects'
import api from '@/services/api'

class EditorEventManager extends EventManager {
  public handlers: Handlers
  public context: IEditorContext
  public canvasImageRenderer: CanvasImageRenderer

  compositedRemoteAssetProviderCancellationToken: CompositedRemoteAssetProviderCancellationToken
  constructor(props: EditorOptions) {
    super()
    this.context = props.context
    this.handlers = new Handlers({ ...props, editorEventManager: this })
    this.canvasImageRenderer = CanvasImageRenderer.getInstance();
  }

  public destroy = () => {
    this.handlers.destroy()
  }

  public undo = () => {
    const eventProperties = {
      Tool: 'bazaart.undo',
    }
    customAmplitude('Selected tool', eventProperties)
    this.handlers.transactionHandler.undo()
  }
  public redo = () => {
    const eventProperties = {
      Tool: 'bazaart.redo',
    }
    customAmplitude('Selected tool', eventProperties)
    this.handlers.transactionHandler.redo()
  }

  renderLayer(newElement: any, object: any) {
    if (!newElement) {
      console.log('UNABLE TO LOAD OBJECT: ', object)
      return null;
    }
    newElement.set('lockScalingFlip', true)
    if (newElement.type === ObjectType.STATIC_TEXT) {
      newElement.set('minScaleLimit', 0.9)
    }

    // element.set('bazaartGuid', object.bazaartGuid)
    // if (this.config.clipToFrame) {
    const frame = this.handlers.frameHandler.get()
    newElement.clipPath = frame

    // @ts-ignore
    let objIdx = this.handlers.canvasHandler.canvas.getObjects().map((obj) => obj.bazaartGuid).indexOf(object.bazaartGuid)
    let placeholderObj = this.handlers.canvasHandler.canvas.getObjects()[objIdx];
    this.handlers.canvasHandler.canvas.remove(placeholderObj);

    this.handlers.canvasHandler.canvas.add(newElement)
    if (newElement.type === ObjectType.BAZAART_BG) {
      this.emit('background:changed', {})
    }

    newElement.moveTo(objIdx)
  }

  async preloadAssets(
    templateObjects: any,
    templateLatestAssets: any,
    template: any,
    frameOptions: FrameOptions,
    cancellationToken: CompositedRemoteAssetProviderCancellationToken,
    hasClear = true): Promise<{ postProcessAssets: any, addedElementsById: any }> {

    let postProcessAssets = []
    let addedElementsById = {}
    let promises: Promise<boolean>[] = [];
    let fonts = this.getFonts(template);

    for (const object of templateObjects) {
      if (cancellationToken.isCancelled()) {
        break;
      }

      let assetStateId = nanoid()
      object.layerAssetStateId = assetStateId;

      let remoteAssetProvider: RemoteResourceProvider
      if (object.itemType == ObjectType.BAZAART_TEXT) {
        remoteAssetProvider = new RemoteTextLoader(template.templateId, object.bazaartGuid, assetStateId, object, fonts);
      } else {
        remoteAssetProvider = new RemoteAssetProvider(template.templateId, object.bazaartGuid, assetStateId, object, templateLatestAssets);
      }
      cancellationToken.addCancellationToken(remoteAssetProvider.remoteAssetProviderCancellationToken);


      let promise = remoteAssetProvider.downloadRequiredAsset().then((complexResult) => {
        if (!complexResult || cancellationToken.isCancelled()) {
          return;
        }
        let result = complexResult.parameters ?? complexResult;
        if (!result) {
          return;
        }

        postProcessAssets.push(result);

        if (result.object.itemType != ObjectType.BAZAART_TEXT) {
          // let placeholderObj = this.handlers.canvasHandler.canvas.getObjects()[objIdx];
          // this.handlers.canvasHandler.canvas.remove(placeholderObj);
          if (result.object.type === ObjectType.BAZAART_BG) {
            this.handlers.frameHandler.setBackgroundIdentifier(result.object);
          }
        }

        return objectToFabric.run(result.object, frameOptions).then(newElement => {
          if (cancellationToken.isCancelled()) {
            return null;
          }

          newElement.isIntializedNormalizeMask = false
          newElement.isIntializedNormalizedImage = false

          this.renderLayer(newElement, result.object)
          addedElementsById[newElement.bazaartGuid] = newElement
        }).catch(function(err){
            console.error(`failed to load layer with error ${err}`);
        });

      });
      promises.push(promise);
    }

    return Promise.all(promises).then((results) => {
      if (cancellationToken.isCancelled()) {
        return { postProcessAssets: [], addedElementsById: {} }
      }
      this.handlers.frameHandler.removePlaceholderBgImage();
      this.handlers.zoomHandler.zoomToFit()

      if (template?.config?.hasOpacity) {
        this.handlers.frameHandler.setTransparentBg(false)
      }
      if (hasClear) {
        this.handlers.transactionHandler.clear()
      }

      return { postProcessAssets: postProcessAssets, addedElementsById: addedElementsById }
    });
  }

  async loadAllAssets(template, postProcessAssets, addedElementsById, cancellationToken: CompositedRemoteAssetProviderCancellationToken) {
    let maskTypes: MediaImageType[] = [MediaImageType.mask, MediaImageType.fittedMask, MediaImageType.bzrtBgMask];
    let assets = await api.listAllTemplateAsset(template.templateId, cancellationToken.abortToken.signal);

    let nonTextAssets = postProcessAssets.filter(a => a.object.itemType != ObjectType.BAZAART_TEXT);
    var promises = [];
    for (let itemAsset of nonTextAssets) {
      if (cancellationToken.isCancelled()) {
        return null;
      }
      let remoteAssetProvider = new RemoteAssetProvider(template.templateId, itemAsset.object.bazaartGuid, itemAsset.layerAssetId, itemAsset, assets);
      cancellationToken.addCancellationToken(remoteAssetProvider.remoteAssetProviderCancellationToken);

      let promise = remoteAssetProvider.downloadAllAssets().then((allLoadedAssets) => {
        if (!allLoadedAssets || cancellationToken.isCancelled()) {
          return;
        }

        allLoadedAssets.forEach(downloadedItem => {

          if (cancellationToken.isCancelled()) {
            return null;
          }

          let object = downloadedItem?.object?.object;

          if (!object) {
            return null;
          }

          if (downloadedItem.asset.mediaItem == MediaImageType.original || downloadedItem.asset.mediaItem == MediaImageType.latest) {
            MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.normalizePngImage(downloadedItem.mediaBase64, cancellationToken.abortToken.signal).then((normalizedImage) => {
              MediaImageRepository.getInstance().storeImageBlobString(
                object.bazaartGuid,
                object.layerAssetStateId,
                downloadedItem.asset.mediaItem,
                normalizedImage
              )
            })
          }

          if (downloadedItem.asset.mediaItem == MediaImageType.original) {
            this.handlers.objectsHandler.setIntializedNormalized(addedElementsById[object.bazaartGuid])
          }

          if (maskTypes.includes(downloadedItem.asset.mediaItem)) {
            //we need to extact binary mask(white/black) from tranparent mask (black/tranparent)
            MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(downloadedItem.mediaBase64, null, false, cancellationToken.abortToken.signal).then((decodedMask) => {
              MediaImageRepository.getInstance().storeImageBlobString(
                object.bazaartGuid,
                object.layerAssetStateId,
                downloadedItem.asset.mediaItem,
                decodedMask.blob
              )

              // the boundingBox here is not right because it's created for zip structure with fitted mask
              // so we need to extracting the boundingBox from the mask manually 
              if (downloadedItem.asset.mediaItem == MediaImageType.mask && object.boundingBox.width == 1 && object.boundingBox.height == 1) {
                MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(decodedMask.blob).then((maskElement) => {
                  MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractBoundingBox(decodedMask.blob, true, null, cancellationToken.abortToken.signal).then((boundingBox) => {

                    let relativeBB = boundingBox;
                    relativeBB.x /= maskElement.width;
                    relativeBB.width /= maskElement.width;
                    relativeBB.y /= maskElement.height;
                    relativeBB.height /= maskElement.height;

                    addedElementsById[object.bazaartGuid].boundingBox = relativeBB;
                  })
                });
              }
            })
            this.handlers.objectsHandler.setIntializedNormalizeMask(addedElementsById[object.bazaartGuid])
          }
        })
      });
      promises.push(promise);
    }
    return Promise.all(promises)
  }

  getFonts(config) {
    let fonts = [];
    if (config.fonts) {
      config.fonts.forEach(font => {
        fonts.push({
          name: font.keySystemName,
          url: font.keyFileURL,
          options: { weight: '400' },
        })
      })
    }
    const filteredFonts = fonts.filter(f => !!f.url)
    return filteredFonts;
  }

  async importFromJSON(template, abortSignal?: AbortSignal, isTemplate: boolean = true, hasClear = true) {
    if (abortSignal?.aborted) {
      return;
    }

    let compositedRemoteAssetProviderCancellationToken = this.compositedRemoteAssetProviderCancellationToken;
    compositedRemoteAssetProviderCancellationToken?.cancel()

    compositedRemoteAssetProviderCancellationToken = new CompositedRemoteAssetProviderCancellationToken(template.config.draftGuid as string);

    abortSignal?.addEventListener('abort', () => {
      compositedRemoteAssetProviderCancellationToken?.cancel();
    })

    this.compositedRemoteAssetProviderCancellationToken = compositedRemoteAssetProviderCancellationToken;

    const frameParams = template.config.originalCanvasSize
    const { sizeId, icon } = findSizeId(frameParams.width, frameParams.height, true)
    this.handlers.frameHandler.update({ ...frameParams, sizeId, icon }, false)


    const frameOptions = this.handlers.frameHandler.getOptions()

    let templateObjects = template.objects.map(t => Object.values(t)[0]).filter(x => x.isHidden != true);
    for (let obj of templateObjects) {
      // @ts-ignore
      let placeholderElement = new fabric.Rect({ opacity: 0, bazaartGuid: obj.bazaartGuid })
      this.handlers.canvasHandler.canvas.add(placeholderElement);
      if (obj.center !== undefined) {
        obj.centerPoint = obj.center;
        delete obj.center;
      }
    }

    if (isTemplate) {
      await this.loadTemplate(template, templateObjects, frameOptions, compositedRemoteAssetProviderCancellationToken, hasClear)
    } else {
      await this.loadProject(template, templateObjects, frameOptions, compositedRemoteAssetProviderCancellationToken, hasClear)
    }
  }

  private async loadProject(
    template,
    templateObjects,
    frameOptions,
    cancellationToken: CompositedRemoteAssetProviderCancellationToken,
    hasClear = true) {
    if (template?.config?.hasOpacity) {
      this.handlers.frameHandler.setTransparentBg(false)
    }
    for (let object of templateObjects) {
      if (object.itemType != ObjectType.BAZAART_TEXT) {
        if (object.type === ObjectType.BAZAART_BG) {
          this.handlers.frameHandler.setBackgroundIdentifier(object);
        } 
        // ignore latest property for text  layers
        object.isLatest = true;
      }
      await objectToFabric.run(object, frameOptions).then(async newElement => {
        if (cancellationToken.isCancelled()) {
          return null;
        }
        this.renderLayer(newElement, object)
      }).catch(function(err){
        console.error(`failed to load layer with error ${err}`);
    });;
    }
  }

  private async loadTemplate(
    template,
    templateObjects,
    frameOptions,
    compositedRemoteAssetProviderCancellationToken: CompositedRemoteAssetProviderCancellationToken,
    hasClear = true
  ) {

    this.preloadAssets(templateObjects, template.latestAssets, template.config, frameOptions, compositedRemoteAssetProviderCancellationToken, hasClear).then((preloadedAssets) => {

      setTimeout(() => {
        if (compositedRemoteAssetProviderCancellationToken.isCancelled()) {
          return;
        }
        this.handlers.objectsHandler.createReplaceableLayer()
      }, 100);

      let { postProcessAssets, addedElementsById } = preloadedAssets

      setTimeout(() => {
        this.loadAllAssets(template.config, postProcessAssets, addedElementsById, compositedRemoteAssetProviderCancellationToken);
      }, 1000);

      if (compositedRemoteAssetProviderCancellationToken.isCancelled()) {
        this.handlers.frameHandler.removePlaceholderBgImage()
      }
    });
  }

  public exportPNG = async (preventWatermark: boolean = false) => {
    return this.handlers.designHandler.toDataURL(null, preventWatermark)
  }
}

export default EditorEventManager
