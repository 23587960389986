import { DateProvider } from "@/firebase/services/DateProvider";

export class FileSystemRecord {
    modificationDate: Date;
    createdDate: Date;
    undoDate: Date;
    blobUrl: string;
    size: number;
    systemFileNumber: number;
    isFolder: boolean;
    addtionalMetadata: any;
    format?: string;


    /**
     *
     */
    constructor(
        blobUrl: string,
        modificationDate: Date,
        createdDate: Date,
        size: number,
        systemFileNumber: number,
        isFolder: boolean,
        addtionalMetadata: any
    ) {
        this.blobUrl = blobUrl;
        this.size = size;
        this.modificationDate = modificationDate;
        this.createdDate = createdDate;
        this.undoDate = modificationDate;
        this.isFolder = isFolder;
        this.systemFileNumber = systemFileNumber
        this.addtionalMetadata = addtionalMetadata
    }
}

export class VirtualFileSystem {

    private _map: Map<string, FileSystemRecord>;
    private static instance: VirtualFileSystem

    public static getInstance(): VirtualFileSystem {
      if (!VirtualFileSystem.instance) {
        VirtualFileSystem.instance = new VirtualFileSystem()
      }
      return VirtualFileSystem.instance
    }

    private constructor() {
        this._map = new Map();
    }

    exist(url: string): boolean{
        return this._map.get(url) != null;
    }

    write(url: string, record: FileSystemRecord){
        this.createFolderIfNone(url)
        this._map.set(url, record);
    }

    getFileExtensionForUrl(url: string): string | null {
        let urlWithoutQuery = url.split('?')[0];
        let matchingUrls = this.list(urlWithoutQuery)
        if (matchingUrls.length == 0){
            return null;
        }
        
        let urlComponents = matchingUrls[0][0].split('.');
        if (urlComponents.length == 1) {
            return null;
        }
        
        let extensionWithQueryString = urlComponents[urlComponents.length - 1];
        let extension = extensionWithQueryString.split('?')[0];
        return extension
    }

    async writeBlob(url: string, blob: Blob) {
        let blobUrl = await this.parseURI(blob);
        return this.writeBlobUrl(url, blobUrl);
    }

    async writeBlobUrl(url: string, blobUrl: string) {
        let creationDate =  DateProvider.getUTCDate();
        
        let size = await this.extractSizeFromBlobUrl(blobUrl);
        let record = new FileSystemRecord(blobUrl, creationDate, creationDate, size, this.generateRandom12DigitNumber(), false, {})
        let urlComponent = url.split('.');
        if (urlComponent.length > 1) {
            record.format = urlComponent[urlComponent.length - 1].split('?')[0];
        }
        this.write(url, record);
    }

    // Method to link an existing file with a new path
    async link(sourcePath: string, destinationPath: string): Promise<boolean> {
        return this.copyIsHardCopy(sourcePath, destinationPath, false);
    }

    // Method to copy a file from one path to another
    async copy(sourcePath: string, destinationPath: string): Promise<boolean> {
        return this.copyIsHardCopy(sourcePath, destinationPath, true);
    }

    private async copyIsHardCopy(sourcePath: string, destinationPath: string, isHardCopy: boolean): Promise<boolean> {
        const record = this._map.get(sourcePath);
        if (!record) {
            return false; // The source file doesn't exist
        }
    
        // Check if the source is a folder
        if (record.isFolder) {
            // Ensure the destination folder exists
            if (!this.createFolderIfNone(destinationPath)) {
                return false; // Failed to create the destination folder
            }
    
            // Recursively copy all subfiles and subfolders
            const subItems = this.list(sourcePath);
            for (const [subPath, subRecord] of subItems) {
                // Ensure relative paths are correctly constructed to avoid endless recursion
                const relativePath = subPath.substring(sourcePath.length);
                const newDestinationPath = destinationPath + relativePath;
    
                // Skip the folder itself to avoid copying it into itself
                if (newDestinationPath === destinationPath) {
                    continue;
                }
    
                const success = await this.copyIsHardCopy(subPath, newDestinationPath, isHardCopy);
                if (!success) {
                    return false; // Failed to copy a subfile or subfolder
                }
            }
            return true; // Successfully copied the folder and its contents
        } else {
            // If it's a file, handle as before
            let blobUrl = record.blobUrl;
            let systemFileNumber = record.systemFileNumber;
            if (isHardCopy) {
                blobUrl = await this.copyBlobUrl(blobUrl);
                systemFileNumber = this.generateRandom12DigitNumber();
            }
    
            const newRecord = new FileSystemRecord(
                blobUrl,
                record.modificationDate,
                record.createdDate,
                record.size,
                systemFileNumber,
                record.isFolder,
                {}
            );
            let urlComponent = destinationPath.split('.');
            if (urlComponent.length > 1) {
                newRecord.format = urlComponent[urlComponent.length - 1].split('?')[0];
            }

            this.write(destinationPath, newRecord);
            return true; // Successfully copied the file
        }
    }
    

    // Method to move a file or folder from one path to another
    move(sourcePath: string, destinationPath: string): boolean {
        const record = this._map.get(sourcePath);
        if (!record) {
            return false; // The source path doesn't exist
        }
    
        // Ensure the source is a folder if we're moving recursively
        if (!record.isFolder) {
            return this.moveSingleFile(sourcePath, destinationPath);
        }
    
        // Check if the destination folder already exists
        if (this._map.has(destinationPath)) {
            return false; // The destination already exists
        }
    
        // Move all subfiles and subfolders
        for (const [key, subRecord] of this.list(sourcePath)) {
            const relativePath = key.substring(sourcePath.length);
            const newDestinationPath = destinationPath + relativePath;
            this.write(newDestinationPath, subRecord);
            this._map.delete(key);
        }
    
        // Remove the original folder
        this._map.delete(sourcePath);
        return true; // Successfully moved
    }
    
    // Helper method to move a single file
    private moveSingleFile(sourcePath: string, destinationPath: string): boolean {
        const record = this._map.get(sourcePath);
        if (!record) {
            return false; // The source file doesn't exist
        }
    
        let filename = sourcePath.split('/').pop();
        let destinationFile = destinationPath + '/' + filename;
    
        if (this._map.has(destinationFile)) {
            return false; // The destination already exists
        }
    
        this.write(destinationFile, record);
        this._map.delete(sourcePath);
        return true; // Successfully moved
    }
    
    
    get(path: string): FileSystemRecord | null {
        return this._map.get(path);
    }

    // Method to delete a file or folder at a given path
    delete(path: string, includeMemory:boolean = true): boolean {
        let allSubRecords = this.list(path);
        for (let [subPath, record] of allSubRecords) {
            if (!record.isFolder && includeMemory) {
                window.URL.revokeObjectURL(record.blobUrl);
            }
            this._map.delete(subPath)
        }
        return true;
    }

    // Method to create a folder if it doesn't exist
    createFolderIfNone(filePath: string): boolean {
        let fileComponents = filePath.split('.');
        let folderPath = fileComponents[0];
        if (!folderPath.startsWith('/')) {
            folderPath = '/' + folderPath;
        }
    
        let parts = folderPath.split('/');
        if (fileComponents.length > 1) {
            parts.pop();
        }

        let currentPath = '';
        for (let i = 0; i < parts.length; i++) {
            if (parts[i] === '') continue; // Skip empty parts due to leading slash
    
            currentPath = currentPath ? currentPath + '/' + parts[i] : parts[i];
    
            // Ensure current path starts with a '/'
            if (!currentPath.startsWith('/')) {
                currentPath = '/' + currentPath;
            }
    
            // Check if the current path already exists
            if (this._map.has(currentPath)) {
                const record = this._map.get(currentPath);
                if (record && !record.isFolder) {
                    return false; // If the existing path is not a folder, cannot proceed
                }
            } else {
                // Create a new folder record for the current path
                const folderRecord = new FileSystemRecord(
                    currentPath,
                    DateProvider.getUTCDate(),
                    DateProvider.getUTCDate(),
                    0, // Size for a folder is 0
                    this.generateRandom12DigitNumber(),
                    true, // It is a folder
                    {}
                );
                this._map.set(currentPath, folderRecord);
            }
        }
        return true; // Successfully created all necessary folders
    }

      // Method to list all subfiles available under a given URL/path
    list(path: string): [url: string, record: FileSystemRecord][] {
        const result: [url: string, record: FileSystemRecord][] = [];
        for (const key of this._map.keys()) {
            if (key.startsWith(path)) {
                result.push([key, this._map.get(key)]);
            }
        }
        return result;
    }

    resetDate(filePath: string, newDate: Date): boolean {
        let record = this._map.get(filePath);
        if (!record) {
            return false;
        }
        record.createdDate = newDate;
        record.modificationDate = newDate;
        return true;
    }

    async extractSizeFromBlobUrl(blobUrl: string): Promise<number> {
        let blob = await this.extractBlobFromUrl(blobUrl)
        let size = this.getBlobSizeInKB(blob);
        return size;
    }

    private async extractBlobFromUrl(blobUrl: string): Promise<Blob> {
        try {
            // Fetch the Blob URL
            const response = await fetch(blobUrl);

            // Check if the response is OK
            if (!response.ok) {
                throw new Error(`Failed to fetch Blob: ${response.statusText}`);
            }

            // Extract the Blob from the response
            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error("Error extracting Blob from URL:", error);
            throw error; // Re-throw error for further handling
        }
    }

    private getBlobSizeInKB(blob: Blob): number {
        const sizeInBytes = blob.size; // Size in bytes
        const sizeInKB = sizeInBytes / 1024; // Convert to kilobytes
        return sizeInKB;
    }

    // Function to copy a Blob
    private async copyBlobUrl(originalBlobUrl: string): Promise<string> {
        let originalBlob = await this.blobUrlToBlob(originalBlobUrl);
        // Use Blob's arrayBuffer method to read its data
        const arrayBuffer = await originalBlob.arrayBuffer();
        
        // Create a new Blob with the same data
        const copiedBlob = new Blob([arrayBuffer], { type: originalBlob.type });
        
        let copiedBlobUrl = this.parseURI(copiedBlob);
        return copiedBlobUrl;
    }

    private async parseURI(blob: Blob): Promise<string> {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((res, rej) => {
          reader.onload = e => {
            let base64Result = e.target.result as string
            res(base64Result)
          }
        })
    }

    // Function to convert a Blob URL back to a Blob object
    async blobUrlToBlob(blobUrl: string): Promise<Blob> {
        // Fetch the Blob from the URL
        const response = await fetch(blobUrl);
        
        // Convert the response to a Blob
        const blob = await response.blob();
        
        return blob;
    }

    generateRandom12DigitNumber(): number {
        let randomNumber = '';
        for (let i = 0; i < 12; i++) {
            const digit = Math.floor(Math.random() * 10); // Generate a random digit from 0 to 9
            randomNumber += digit.toString();
        }
        return parseInt(randomNumber);
    }
}


