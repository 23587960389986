import { Button, KIND, SHAPE, SIZE } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import Tooltip from '@/components/Tooltip'
import { PLACEMENT } from 'baseui/popover'
import { StyleObject } from 'styletron-standard'
import { lightTheme } from '@/customTheme'
export interface PropsButtonCustom {
  // size?: SIZE['compact'] | SIZE['default'] | SIZE['large'] | SIZE['mini']
  kind?: KIND[keyof KIND]
  shape?: SHAPE[keyof SHAPE]
  onClick?: any //function hanlde click button
  type: SizeButton
  tooltip?: {
    content: string | React.ReactNode
    placement?: PLACEMENT[keyof PLACEMENT]
  } // give content if want display tooltip
  style?: StyleObject //custom style for button
  children: JSX.Element | JSX.Element[] | string
  startEnhancer?: JSX.Element
  endEnhancer?: JSX.Element
  transparent?: boolean
  disabled?: boolean
}
function ButtonCustom({
  kind,
  shape,
  onClick,
  type,
  tooltip,
  style,
  children,
  startEnhancer,
  endEnhancer,
  transparent,
  disabled
}: PropsButtonCustom) {
  const sizeCustom =
    type === SizeButton.SMALL
      ? '32px'
      : type === SizeButton.STANDARD
      ? '40px'
      : type === SizeButton.LARGE
      ? '48px'
      : type === SizeButton.EXTRA_SMALL
      ? '24px'
      : '32px'
  return (
    <Tooltip info={tooltip}>
      <Button
        disabled={disabled}
        kind={kind ?? 'minimal'}
        shape={shape}
        onClick={onClick ? onClick : null}
        startEnhancer={startEnhancer}
        endEnhancer={endEnhancer}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              ...$theme,
              ...lightTheme.typography.Small14Semibold,
              border: kind === KIND.tertiary ? `1px solid ${$theme.colors.grayScale100}` : 'none',
              width: shape === SHAPE.square ? sizeCustom : '100%',
              height: sizeCustom,
              background: transparent ? lightTheme.colors.grayScale50 : kind === 'primary' ? lightTheme.colors.primary : $theme.background,
              borderRadius: (kind === 'primary' || kind === 'secondary') ? '100px' : $theme.borderRadius,
              padding: (kind === 'primary' || kind === 'secondary') ? '0px 16px' : $theme.padding,
              outline: 'none',
              ':hover': {
                border: kind === KIND.tertiary ? `1px solid ${$theme.colors.grayScale50}` : 'none',
                background: transparent ? lightTheme.colors.grayScale100 : $theme.background,
              },
              ':active': {
                background: transparent ? lightTheme.colors.grayScale150 : $theme.background,
              },
              color: disabled ? "#ccc" : kind !== KIND.primary ? lightTheme.colors.contentPrimary : '#fff',
              ...style,
            }),
          },
          StartEnhancer: {
            style: ({ $theme }) => ({
              ...$theme,
              marginRight: '8px',
              fill: disabled ? "#ccc !important" : lightTheme.colors.contentPrimary,
            }),
          },
          EndEnhancer: {
            style: ({ $theme }) => ({
              ...$theme,
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
          },
        }}
      >
        {children}
      </Button>
    </Tooltip>
  )
}

export default ButtonCustom
