import { MediaImageRepository } from "@/scenes/engine/objects/media-repository/media_image_repository";
import { VirtualFileSystem } from "@/scenes/engine/objects/media-repository/VirtualFileSystem"

export class ScreenshotProvider {
    static async saveScreenshot(projectId, base64: string): Promise<boolean> {
        let imageBlobString = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.base64ToBlobUrl(base64);
        await VirtualFileSystem.getInstance().writeBlobUrl(this.screenshotProjectUrl(projectId, true), imageBlobString)
        await VirtualFileSystem.getInstance().writeBlobUrl(this.screenshotProjectUrl(projectId, false), imageBlobString)
        return true;
    }

    static screenshotFilename(): string {
        const screenshotName = "screenshot_thumbnail.jpg"
        return screenshotName
    }

    static highResFilename(): string {
        const screenshotName = "screenshot_hires.jpg"
        return screenshotName
    }

    static screenshotRelativeToProject(projectId: String, isThumbnail: boolean = true): string {
        let fileName = isThumbnail ? this.screenshotFilename() : this.highResFilename()
        return `/${projectId}/${fileName}`
    }

    static screenshotProjectUrl(projectId: String, isThumbnail: boolean = true): string  {
        return `/projects${this.screenshotRelativeToProject(projectId, isThumbnail)}`
    }
}