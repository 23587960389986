export interface FontModel {
    // Define the properties of FontModel here
    isCustomFont: boolean;
    fileURL: URL;
}
  
export class FontsManager {
    static shared = new FontsManager()
    private constructor() {

    }

    isHiddenCustomFont(font: any): boolean {
        return false
    }

    getFonts(predicate: (font: FontModel) => boolean): FontModel[] {
        return this.allFonts().filter(predicate);
    }

    allFonts(): FontModel[] {
        // Implementation of allFonts() that returns an array of FontModel
        return [];
    }
      
    async fetchLocalCustomFonts(){

    }
}