class NetworkStatusService {
    private static instance: NetworkStatusService;
    private isOnline: boolean = navigator.onLine;
    private listeners: Array<(isOnline: boolean) => void> = [];
  
    private constructor() {
      this.updateNetworkStatus = this.updateNetworkStatus.bind(this);
      window.addEventListener("online", this.updateNetworkStatus);
      window.addEventListener("offline", this.updateNetworkStatus);
    }
  
    public static getInstance(): NetworkStatusService {
      if (!NetworkStatusService.instance) {
        NetworkStatusService.instance = new NetworkStatusService();
      }
      return NetworkStatusService.instance;
    }
  
    private updateNetworkStatus() {
      this.isOnline = navigator.onLine;
      this.notifyListeners();
    }
  
    public addListener(callback: (isOnline: boolean) => void) {
      this.listeners.push(callback);
      callback(this.isOnline); // Initial call to set the current status
    }
  
    public removeListener(callback: (isOnline: boolean) => void) {
      this.listeners = this.listeners.filter((listener) => listener !== callback);
    }
  
    private notifyListeners() {
      this.listeners.forEach((callback) => callback(this.isOnline));
    }
  
    public getStatus() {
      return this.isOnline;
    }
  }
  
  export default NetworkStatusService;
  