import { StaticText } from 'fabric/fabric-impl';
import { ObjectType, PROPERTIES_TO_EXPORT } from '../common/constants'
import { ImageToTextTransformation, OutlineConfig, ShadowConfig, TextProperties } from '../common/interfaces';
import { MediaImageRepository } from '../objects/media-repository/media_image_repository';
import { allMediaImageTypeValues } from '../objects/media-repository/media_image_type';
import { fabric } from 'fabric'
import { FabricFontMetrics, FontMetrics, getFabricFontMetrics, normalize } from './utils';
import { CanvasLayerShadowEffect } from '@/interfaces/CanvasLayerShadowEffect';
import { Size } from '../objects/media-repository/size';
import { Rectangle } from '../objects/media-repository/rectangle';
import { Point } from '../objects/media-repository/point';

class ExportObject {
  private imageOrientationUp = 0;
  private unsetAdjustment = -101;
  private defaultImageFormat = "PNG"

  async setItemMetadataMedia(layer: any) {
    layer.imagesOrientation = layer.imagesOrientation ? layer.imagesOrientation : {};
    layer.imagesFormat = layer.imagesFormat ? layer.imagesFormat : {};
    layer.imagesLastUpdated = layer.imagesLastUpdated ? layer.imagesLastUpdated : {};
    const defaultDate = new Date();

    for (let mediaType of allMediaImageTypeValues){
      let recrod = await MediaImageRepository.getInstance().getRecord(layer.bazaartGuid, layer.layerAssetStateId, mediaType);
      if (recrod) {
        layer.imagesOrientation[mediaType] = this.imageOrientationUp;
        layer.imagesLastUpdated[mediaType] = recrod.createdDate ?? defaultDate;
        layer.imagesFormat[mediaType] = recrod.format ?? this.defaultImageFormat;
      }
    }
    delete layer['layerAssetStateId']
  };

  async setAdjustments(layer: any) {
    let adjustments = layer.adjustments
    layer.adjustments = {
      blur: adjustments && "blur" in adjustments ? adjustments.blur: this.unsetAdjustment,
      brightness: adjustments && "brightness" in adjustments ? adjustments.brightness: this.unsetAdjustment,
      contrast: adjustments && "contrast" in adjustments ? adjustments.contrast: this.unsetAdjustment,
      exposure: adjustments && "exposure" in adjustments ? adjustments.exposure: this.unsetAdjustment,
      fade: adjustments && "fade" in adjustments ? adjustments.fade: this.unsetAdjustment,
      highlights: adjustments && "highlights" in adjustments ? adjustments.highlights: this.unsetAdjustment,
      saturation: adjustments && "saturation" in adjustments ? adjustments.saturation: this.unsetAdjustment,
      shadows: adjustments && "shadows" in adjustments ? adjustments.shadows : this.unsetAdjustment,
      sharpness: adjustments && "sharpness" in adjustments ? adjustments.sharpness: this.unsetAdjustment,
      temperature: adjustments && "temperature" in adjustments ? adjustments.temperature: this.unsetAdjustment,
      tint: adjustments && "tint" in adjustments ? adjustments.tint: this.unsetAdjustment,
      vibrance: adjustments && "vibrance" in adjustments ? adjustments.vibrance: this.unsetAdjustment,
    }
  }
  
  async setOutline(layer: any) {

  }

  async setShadow(layer: any) {
    
  }

  async setFilter(layer: any) {
    
  }

  
  async run(item, options, isExport?: boolean) {
    let object
    switch (item.type) {
      case ObjectType.STATIC_IMAGE:
      case ObjectType.BAZAART_IMAGE:
      case ObjectType.BAZAART_BG:
      case ObjectType.BAZAART_STICKER:
      case ObjectType.BAZAART_SHAP:
      case ObjectType.BACKGROUND_IMAGE:
      case ObjectType.BAZAART_DRAWING:
        object = await this[ObjectType.STATIC_IMAGE](item, options, isExport)
        break
      case ObjectType.STATIC_TEXT:
      case ObjectType.BAZAART_TEXT:
        object = await this[ObjectType.STATIC_TEXT](item, options, isExport)
        break
      case ObjectType.STATIC_VECTOR:
        object = await this[ObjectType.STATIC_VECTOR](item, options, isExport)
        break
      case ObjectType.STATIC_PATH:
        object = await this[ObjectType.STATIC_PATH](item, options, isExport)
        break
      case ObjectType.BAZAART_OVERLAY:
        object = await this[ObjectType.BAZAART_OVERLAY](item, options, isExport)
        break
      case ObjectType.GROUP.toLowerCase():
        object = await this[ObjectType.GROUP](item, options, isExport)
        break
    }
    return object
  }

  private convertTextShadowProperties(item): ShadowConfig | null  {
    if (!item.shadow) {
      return null;
    }

    function getRadiusAndAngle(offsetX, offsetY) {
      const radius = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
      const angle = Math.atan2(offsetY, offsetX); // Angle in radians
      return { radius, angle };
    }

    let maxEdge = Math.max(item.width, item.height);

    let { radius, angle} = getRadiusAndAngle(item.shadow.offsetX, item.shadow.offsetY);
    let distance = radius / maxEdge
    let blur = item.shadow.blur / maxEdge
    let color = fabric.Color.fromRgba(item.shadow.color);

    let shadow = {
      distance: distance,
      angle: angle,
      blur: blur,
      opacity: color.getAlpha(),
      color: '#' + color.toHex(),
      is3D: false
    }
    return shadow;
  }

  private convertTextOutlineProperties(item): OutlineConfig | null {
    if (!item.strokeWidth) {
      return null;
    }
    let maxEdge = Math.max(item.width, item.height);
    let thickness = item.strokeWidth / maxEdge
    let outline = {
      thickness: thickness,
      color: item.stroke,
    }
    return outline;
  }

  private extractCharSpacing(item: StaticText): number {
    return (item.charSpacing / 1000) * item.fontSize;
  }

  private extractLineHeight(item: StaticText, fontLineHeight: number): number {
    let presetLineHeight = fabric.Text.prototype._fontSizeMult;
    let absoluteLineHeight = item.lineHeight * item.fontSize * presetLineHeight;
    return absoluteLineHeight;
  }

  private extractImageToTextTransformation(textbox: StaticText): {transformation: ImageToTextTransformation, metrics: FabricFontMetrics} {
    let metrics = getFabricFontMetrics(textbox.fontSize, textbox.fontFamily, textbox.text);
    // var scaledTextWidth = metrics.width * textbox.scaleX;
    // var horizontalOffset = (textbox.width - scaledTextWidth) / 2;
    // var scaledTextHeight = metrics.textHeight * textbox.scaleY;
    // var verticalOffset = (textbox.height - scaledTextHeight) / 2;
    let transformation: ImageToTextTransformation = {
      originRelativeToWidth: {
        x: 0.0, 
        y: 0.0,
      },
      sizeRelativeToWidth: {
        width: 1, // scaledTextWidth / textbox.width
        height: 1 // scaledTextHeight / textbox.height
      },
      fontRelativeToWidth: (textbox.fontSize / textbox.width)
    }
    return {transformation, metrics};
  } 

  private convertAttributedTextProperty(item: StaticText): TextProperties | null {
      const alignFromOption = () => {
        if (item.textAlign == 'left') {
          return 0
        }
        if (item.textAlign == 'center') {
          return 1
        }
        if (item.textAlign == 'right') {
          return 2
        }
        return 3; // justifiy
      }

      const NSalignFromOption = () => {
        if (item.textAlign == 'left') {
          return 0
        }
        if (item.textAlign == 'right') {
          return 1
        }
        if (item.textAlign == 'center') {
          return 2
        }
        return 3; // justifiy
      }

      let presentBackground = false;
      let textColorHex = '';
      let NSColor = '';

      if(item.backgroundColor){
        presentBackground = true;
        textColorHex = item.backgroundColor;
        NSColor = item.backgroundColor ?? item.clipPath.fill as string;
      }
      else{
        presentBackground = false;
        textColorHex = item.fill as string;
        NSColor = item.fill as string ?? item.clipPath.fill as string;
      }
      let {transformation, metrics} = this.extractImageToTextTransformation(item);
      let charSpacing = this.extractCharSpacing(item);
      let lineHeight = this.extractLineHeight(item, metrics.lineHeightRatio);
      

      let textProperties: TextProperties = {
        presentBackground: presentBackground,
        textColorHex: textColorHex,
        imageToTextTransformation: transformation,
        arcAngle: item.arcAngle ?? 0,
        alignment: alignFromOption(),
        attributedText: {
          string: item.text,
          runs: [{
            range: `{0, ${item.text.length}}`,
            attributes: {
              NSKern: charSpacing,
              NSLigature: 1,
              NSColor: NSColor,
              NSParagraphStyle: {
                NSWritingDirection: 1,
                NSAlignment: NSalignFromOption(),
                NSMinLineHeight: lineHeight,
                NSMaxLineHeight: lineHeight,
              },
              NSFont: {
                size: item.fontSize,
                systemName: item.fontFamily,
              },
              NSOriginalFont: {
                size: item.fontSize,
                systemName: item.fontFamily
              }
            }
          }]
        }
      }
      return textProperties;
    }

  async [ObjectType.STATIC_TEXT](item, options, isExport) {
    const baseOptions = await this.getBaseOptions(item, options)
    let object
    if (isExport) {
      const { features, textProperties } = item
      object = {
        ...baseOptions,
        features: features,
        textProperties: textProperties,
      }
      object.effects = {
        hasFillImage: false
      };

      let outline = this.convertTextOutlineProperties(item);
      if (outline){
        object.effects.outline = outline
      }
      let shadow = this.convertTextShadowProperties(item);
      if (shadow){
        object.effects.shadow = shadow
      }
      object.textProperties = this.convertAttributedTextProperty(item)
    } else {
      const { fontFamily, textAlign, fontSize, fontWeight, charSpacing, lineHeight, fill, text, angle } = item
      const scaledFontSize = fontSize
      const metadata = {
        ...item.metadata,
        angle,
        fill,
        fontWeight,
        charspacing: charSpacing,
        fontSize: scaledFontSize,
        template: text,
        fontFamily,
        textAlign,
        lineheight: lineHeight,
        text: item.text,
      }

      object = {
        ...baseOptions,
        metadata,
      }
    }

    this.removeUndefinedFields(object)
    object = this.removeUnwantedFields(object)
    return object
  }

  async [ObjectType.STATIC_IMAGE](item, options, isExport) {
    const baseOptions = await this.getBaseOptions(item, options)
    let object
    if (isExport) {
      object = { ...baseOptions, isTemplateLayer: false }
    } else {
      const { filters, src, cropX, cropY, type } = item
      object = {
        ...baseOptions,
        filters: filters,
        metadata: {
          src: src,
          cropX: cropX,
          cropY: cropY,
        },
      }
      object.type = type
    }

    this.removeUndefinedFields(object)
    object = this.removeUnwantedFields(object)
    return object
  }

  async [ObjectType.STATIC_VECTOR](item, options, isExport) {
    const baseOptions = await this.getBaseOptions(item, options)
    let object
    if (isExport) {
      object = {
        ...baseOptions,
      }
    } else {
      object = {
        ...baseOptions,
        metadata: {
          src: item.src,
        },
      }
    }
    this.removeUndefinedFields(object)
    return object
  }

  async [ObjectType.STATIC_PATH](item, options, isExport) {
    const baseOptions = await this.getBaseOptions(item, options)
    let object
    if (isExport) {
      object = {
        ...baseOptions,
      }
    } else {
      object = {
        ...baseOptions,
        metadata: {
          value: item.path,
          fill: item.fill,
        },
      }
    }
    this.removeUndefinedFields(object)
    return object
  }

  async [ObjectType.BAZAART_OVERLAY](item, options, isExport) {
    const baseOptions = await this.getBaseOptions(item, options)
    let object
    if (isExport) {
      const { blending, isAnimated, overlayType, startTime, _endTime } = item
      object = {
        ...baseOptions,
        blending: blending,
        isAnimated: isAnimated,
        overlayType: overlayType,
        startTime: startTime,
        _endTime: _endTime,
      }
    } else {
      object = {
        ...baseOptions,
      }
      object.type = item.type
    }
    this.removeUndefinedFields(object)
    return object
  }

  async [ObjectType.GROUP](item, options, isExport) {
    const baseOptions = await this.getBaseOptions(item, options)
    // baseOptions.originX = 'center'
    // baseOptions.originY = 'center'
    const groupObjects = item.objects.map(object => {
      let flated_obj = this.run(object, options, isExport)
      // flated_obj.left = flated_obj.left + (item.left + item.width/2)
      // flated_obj.top = flated_obj.top + (item.top + item.height/2)
      // flated_obj.opacity = item.opacity
      return flated_obj
    })
    if (isExport) {
      return groupObjects
    }
    return {
      ...baseOptions,
      type: ObjectType.GROUP,
      objects: groupObjects,
      metadata: item.metadata,
    }
  }

  removeUndefinedFields(obj) {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key]
      }
    }
    return obj
  }

  removeUnwantedFields(obj) {
    const filteredObj = {}
    for (const key of PROPERTIES_TO_EXPORT) {
      if (obj.hasOwnProperty(key)) {
        filteredObj[key] = obj[key]
      }
    }
    filteredObj['center'] = obj['center']
    return filteredObj
  }

  adjustNonSymtericOffset(item, options): Point {
    if (!item.isLatest) {
      return new Point(0, 0)
    }
    let shadowEffects = new CanvasLayerShadowEffect();
    let originalCanvasSize = new Size(options.width, options.height);
    let sourceBounds = new Rectangle(0, 0, item.width * item.scaleX, item.height * item.scaleY);
    let shadowRect = shadowEffects.revertEffectBounds(item, originalCanvasSize, false, sourceBounds);
    let nonSymmetricOffset = new Point(
      shadowRect.x,
      shadowRect.y
    )
    if (item.flipX) {
      nonSymmetricOffset.x = - nonSymmetricOffset.x;
    }
    if (item.flipY) {
      nonSymmetricOffset.y =  - nonSymmetricOffset.y;
    }
    return nonSymmetricOffset;
  }

  async getBaseOptions(item, options) {
    const absoluteRotation = (item.angle / 180) * Math.PI
    let sizeOnCanvas = {
      width: item.sizeOnCanvas?.width ?? item.width / options.width,
      height: item.sizeOnCanvas?.height ?? item.height / options.height,
    }
    let nonSymmetricOffset = this.adjustNonSymtericOffset(item, options);
    const center = {
      x: (item.left - options.left - nonSymmetricOffset.x) / options.width,
      y: (item.top - options.top - nonSymmetricOffset.y) / options.height,
    }

    let boundingBox = item.boundingBox ?? {x: 0, y: 0, width: 1, height : 1};
    const baseOptions = {
      ...item,
      center,
      absoluteRotation,
      version: 511,
      sizeOnCanvas: sizeOnCanvas,
      bazaartGuid: item?.bazaartGuid,
      imageHeight: item?.imageHeight,
      imageWidth: item?.imageWidth,
      itemType: item?.itemType ? item?.itemType : item?.type,
      boundingBox: boundingBox,
      isInitializing: false,
      transformation: {
        horizontalFlip: item.flipX == true,
        verticalFlip: item.flipY == true,
      }
    }

    this.setAdjustments(baseOptions)
    await this.setItemMetadataMedia(baseOptions)

    return baseOptions
  }
}

export default new ExportObject()
