enum ProcessType {
    Upload = "upload",
    Download = "download"
}

namespace ProcessType {
    export function toString(type: ProcessType): string {
        return type === ProcessType.Upload ? "upload" : "download";
    }
}

export { ProcessType };
