import useAppContext from '@/hooks/useAppContext'
import { useEditorContext } from '@/scenes/engine'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { setUploads } from '@/store/slices/uploads/actions'
import { useAppDispatch } from '@/store/store'
import { customAmplitude } from '@/utils/customAmplitude'
import LogoIcon from '@assets/images/logo_only.svg'
import LogoCreatePage from '@assets/images/logo-create-page.svg'
import { useHistory, useLocation } from 'react-router-dom'
function Logo({ size }: { size: number }) {
  const { setShowModalType } = useAppContext()
  const editor = useEditorContext().editor
  const history = useHistory()
  const dispatch = useAppDispatch()
  const location = useLocation()

  return (
    <img
      src={location.pathname.includes('create') ? LogoCreatePage : LogoIcon}
      alt="logo"
      style={{
        cursor: 'pointer',
        width: 'fite-content',
        height: size,
      }}
      onClick={() => {
        if(location.pathname.includes('create')) {
          // nothing
        } else {
          if (!editor.handlers.transactionHandler.isFlushed) {
            setShowModalType(ShowModalType.DISCARD_PROJECT)
          } else {
            dispatch(setUploads([]))
            history.push('/create')
            customAmplitude('Open', { Source: 'Editor' })
          }
        }
       
      }}
    />
  )
}

export default Logo
