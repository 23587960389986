import RemoveBaseHandler from './RemoveBaseHandler'
import { RemoveHandlerOptions } from '../../common/interfaces'
import shourcutsManager from '../../utils/shourcutsManager'
import { ObjectType } from '../../common/constants'
import { MediaImageRepositoryProcessing } from '@scenes/engine/objects/media-repository/media_image_repository_processing'
import store from '@/store/store'
import { setBrushSize } from '@/store/slices/removeTool/action'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '../../objects/media-repository/media_image_type'
import { nanoid } from 'nanoid'
import throttle from 'lodash/throttle'
import RemoveObjectHandler from './RemoveObjectHandler'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import EnhanceObjectHandler from './EnhanceObjectHandler'
import EraserObjectHandler from './EraserObjectHandler'
import { getTourState } from '@/utils/tutorial'

class RemoveEventsHandler extends RemoveBaseHandler {
  stateBeforeChange: { version: string; objects: Object[] }
  public isAnimating = false
  isSpaceKeyPressed = false
  lastPointerX
  lastPointerY
  isPanning = false
  boundOnKeyDown
  boundOnKeyUp
  programScrolling = false

  private mediaImageRepositoryProcessing: MediaImageRepositoryProcessing
  private container: HTMLElement
  private zoomContainer: HTMLElement

  constructor(props: RemoveHandlerOptions) {
    super(props)
    this.mediaImageRepositoryProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing
    this.container = document.getElementById('wrap-canvas-remove-tool')
    this.zoomContainer = document.getElementById('wrap-canvas-remove-zoom-tool')
    this.boundOnKeyDown = this.onKeyDown.bind(this);
    this.boundOnKeyUp = this.onKeyUp.bind(this);

  }

  initialize() {
    this.canvas.wrapperEl.tabIndex = 1
    this.canvas.wrapperEl.style.outline = 'none'
    // @ts-ignore
    this.canvas.on({
      'selection:created': this.handleSelection,
      'selection:cleared': this.handleSelection,
      'selection:updated': this.handleSelection,
      'mouse:wheel': this.onMouseWheel,
      'mouse:out': this.onMouseOut,
      'mouse:over': this.onMouseOver,
      'object:modified': this.objectModified,
      'before:transform': this.objectBeforeTransform,
      'mouse:up': this.onMouseUp,
      'mouse:down': this.onMouseDown,
      'before:path:created': this.onBeforePathCreated,
    })
    window.addEventListener('keydown', this.boundOnKeyDown)
    window.addEventListener('keyup', this.boundOnKeyUp)
    /**
     * TODO: Need to find better way to fix this. This is likely browser handles background tabs
     * https://app.asana.com/0/1205829032146905/1207360455814507
     */
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && this.root.canvasRemoveHandler.toolType === 'MagicBg') {
        this.canvas.requestRenderAll()
      }
    })
    this.container.addEventListener('wheel', this.onWindowWheel, {passive: false});  // The 'passive: false' option allows you to prevent the default behavior
    this.container.addEventListener('scroll', this.onScrollEnd )
    this.container.addEventListener('mousedown', this.onContainerMouseDown)
    this.container.addEventListener('mouseup', this.onContainerMouseUp)
  }

  destroy() {
    this.canvas.off({
      'selection:created': this.handleSelection,
      'selection:cleared': this.handleSelection,
      'selection:updated': this.handleSelection,
      'mouse:wheel': this.onMouseWheel,
      'mouse:out': this.onMouseOut,
      'object:modified': this.objectModified,
      'before:transform': this.objectBeforeTransform,
      'mouse:up': this.onMouseUp,
      'mouse:down': this.onMouseDown,
      'before:path:created': this.onBeforePathCreated,
    })
    window.removeEventListener('keydown', this.boundOnKeyDown)
    window.removeEventListener('keyup', this.boundOnKeyUp)
    this.container.removeEventListener('wheel', this.onWindowWheel);  // The 'passive: false' option allows you to prevent the default behavior
    this.container.removeEventListener('scrollend', this.onScrollEnd )

    this.container.removeEventListener('mousemove', this.onMouseMove)
    this.container.removeEventListener('mouseleave', this.onMouseLeave)
    this.container.removeEventListener('mousedown', this.onContainerMouseDown)
    this.container.removeEventListener('mouseup', this.onContainerMouseUp)
  }

  objectBeforeTransform = () => {
    this.stateBeforeChange = this.root.canvasRemoveHandler.exportToCanvasJSON()
  }

  objectModified = e => {
    this.root.transactionRemoveHandler.save()
  }

  onMouseOut = () => {
    const toolType = this.root.canvasRemoveHandler.toolType
    if (toolType != 'Eraser') {
      this.canvas.renderAll()
    }
  }

  onMouseWheel = event => {
 
  }

  onScrollEnd = () => {
    if(this.programScrolling) {
      this.programScrolling = false
      return
    }
    const scrollElement = document.getElementById('wrap-canvas-remove-tool')
    if(scrollElement.style.overflow !== 'auto') { return }
    this.root.zoomRemoveHandler.prevScrollLeft = scrollElement.scrollLeft
    this.root.zoomRemoveHandler.prevScrollTop = scrollElement.scrollTop
  }

  onMouseOver = (e) => {
  }

  onMouseDown = (e) => {
    if(this.isSpaceKeyPressed) {
      e.e.preventDefault()
    }
  }
  
  onMouseUp = async () => {
    if(!this.canvas.isDrawingMode) {
      return
    }
    const toolType = this.root.canvasRemoveHandler.toolType
    switch (toolType) {
      case 'Remove':
        await this.processRemoveTool()
        break
      case 'Eraser':
        // @ts-ignore
        //let base64 = this.canvas.lowerCanvasEl.toDataURL();
        // @ts-ignore
        let blob = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.canvasToBlobUrl(this.canvas.lowerCanvasEl)
        await this.processEraserTool(blob, false)
        break
        case 'Enhance':
          await this.processEnhanceTool()
          break
        case 'MagicBg':
          await this.processMagicBgTool()
          break
      default:
        break
    }
  }

  onWindowWheel = event => { 
    if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
      event.preventDefault(); 
      return
    }
    if (event.ctrlKey === true || event.metaKey) {  // Checks if Ctrl or Meta (Cmd on Mac) is pressed
      event.preventDefault();  // Prevents the default zoom behavior
      this.handleZoom(event)
    } else {
      if(this.root.canvasRemoveHandler.toolType === 'Eraser') {
        // use setTimeout to delay the handling slightly for getting the correct scroll position
        setTimeout(() => {
          this.updateMainCanvasScrollPosition()
        }, 0);
      }
    }
  }
  
  updateMainCanvasScrollPosition = () => {
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
    const maxScrollTop = this.mainContext.editor.handlers.scrollbarHandler.getMaxScrollTop()
    const maxScrollLeft = this.mainContext.editor.handlers.scrollbarHandler.getMaxScrollLeft()
    let viewportY = this.mainContext.canvas.viewportTransform[5]
    let viewportX = this.mainContext.canvas.viewportTransform[4]
    const eraserObjectHandler = this.root.pixelManipulationObjectHandler as EraserObjectHandler 
    if (eraserObjectHandler.maxScrollY > 0) {
      const scrollYRatio = wrapCanvasRemoveTool.scrollTop / eraserObjectHandler.maxScrollY
      viewportY = maxScrollTop - scrollYRatio * (maxScrollTop - eraserObjectHandler.maxScrollDown)
    }
    if (eraserObjectHandler.maxScrollX > 0) {
      const scrollXRatio = (wrapCanvasRemoveTool.scrollLeft) / eraserObjectHandler.maxScrollX
      viewportX = maxScrollLeft - scrollXRatio * (maxScrollLeft - eraserObjectHandler.maxScrollRight)
    }
    const viewportTransform = this.mainContext.canvas.viewportTransform
    viewportTransform[4] = viewportX
    viewportTransform[5] = viewportY
    this.mainContext.canvas.setViewportTransform(viewportTransform)
    this.mainContext.editor.handlers.scrollbarHandler.handleAfterScroll(true)
  }

  adjustCanvasForHighDPI(canvas, dpr) {
    // Before resizing, copy the existing content to an in-memory canvas
    let tempCanvas = document.createElement('canvas');
    let tempCtx = tempCanvas.getContext('2d');
    tempCtx.imageSmoothingEnabled = true;
    tempCtx.imageSmoothingQuality = 'high';

    tempCanvas.width = canvas.width * dpr;
    tempCanvas.height = canvas.height * dpr;
    tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);

    return tempCanvas
}

  processEraserTool = async (canvasInBlob: string, isHighRes: Boolean) => {
    this.root.transactionRemoveHandler.save()
    let blob = canvasInBlob
    const eraseLayer = this.root.canvasRemoveHandler.canvas.getObjects()[0];

    if (!isHighRes) {
      // @ts-ignore
      await MediaImageRepository.getInstance().storeImageBlobString(eraseLayer.id, eraseLayer.assetStateId, MediaImageType.mask, blob)
      // @ts-ignore
      await eraseLayer.replaceImage(blob, false)
      return;
    }

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(blob, 0)
    let boundingBox = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractBoundingBox(blob, false)
    let relativeSize = new Size(1.0 / maskInfo.size.width, 1.0 / maskInfo.size.height);
    let relativeBoundingBox = boundingBox.multiply(relativeSize);


    // @ts-ignore
    eraseLayer._originalScaleX = eraseLayer._originalScaleX ? eraseLayer._originalScaleX : eraseLayer.scaleX
    // @ts-ignore
    eraseLayer._originalScaleY = eraseLayer._originalScaleY ? eraseLayer._originalScaleY : eraseLayer.scaleY
    // @ts-ignore
    eraseLayer.boundingBox = relativeBoundingBox

    // @ts-ignore
    await MediaImageRepository.getInstance().storeImageBlobString(eraseLayer.id, eraseLayer.assetStateId, MediaImageType.mask, maskInfo.blob)

    let activeObject = this.root.pixelManipulationObjectHandler.activeObject as any
    let originalInBase64 = await MediaImageRepository.getInstance().getImage(activeObject.id, activeObject.layerAssetStateId, MediaImageType.original)
    let mask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(maskInfo.blob);
    let original = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(originalInBase64);
    let masked = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.applyMaskToImage(original, mask)

    // @ts-ignore
    await eraseLayer.replaceImage(masked, false)
  }

  processMagicBgTool = async () => {
  }

  processEnhanceTool = async () => {
    let enhanceObjectHandler : EnhanceObjectHandler = this.root.pixelManipulationObjectHandler as EnhanceObjectHandler
  }

  processRemoveTool = async () => {
    let removeObjectHandler : RemoveObjectHandler = this.root.pixelManipulationObjectHandler as RemoveObjectHandler

    if (this.isAnimating) {
      return
    }
    this.isAnimating = true
    this.editorEventManager.emit('remove-tool-animating', {
      isAnimating: true
    })
    this.canvas.isDrawingMode = false
    const customCursor = document.querySelector('.custom-cursor') as HTMLElement
    const removeOverlay = document.querySelector('#remove-overlay') as HTMLElement
    removeOverlay.style.display = 'block'
    removeOverlay.onmousemove = this.onMouseMove
    customCursor.style.display = 'none'

    // @ts-ignore
    let drawingCanvas = this.canvas.upperCanvasEl;
    let maskedAreaDrawnImage = await this.mediaImageRepositoryProcessing.loadImage(drawingCanvas.toDataURL());
    removeObjectHandler.blinkAnimation(this.canvas)

    let allObjects = this.canvas.getObjects();
    const layerIndexes = allObjects.reduce((acc, obj, index) => {
      if (obj.type === ObjectType.STATIC_IMAGE) {
        acc.push(index);
      }
      return acc;
    }, []);

    const lowerLayer = allObjects[layerIndexes[0]]
    const upperLayer = allObjects[layerIndexes[1]]
    // @ts-ignore
    let image = lowerLayer?.getElement()
    // @ts-ignore
    let inpaintingResult = await this.root.removeLogicHandler.inpaint(image, maskedAreaDrawnImage, getTourState().isOpen);
    // @ts-ignore
    lowerLayer.getElement().src = inpaintingResult ? inpaintingResult : lowerLayer.getElement().src;

    setTimeout(async () => {
      removeOverlay.removeEventListener('mousemove', this.onMouseMove)
      this.container.style.cursor = 'none'
      removeOverlay.style.display = 'none'
      customCursor.style.display = 'block'
      removeObjectHandler.clearBlinkAnimation(this.canvas)
      // After api is called
      this.canvas.isDrawingMode = true
      this.isAnimating = false
      this.editorEventManager.emit('remove-tool-animating', {
        isAnimating: false
      })
      if (inpaintingResult) {
        removeObjectHandler.animateFade(upperLayer, layerIndexes[0], inpaintingResult)
      }
    }, 10);
  }

  onBeforePathCreated = e => {
    if (!e) {
      return
    }
    e.path.selectable = false
    e.path.evented = false
  }

  handleZoom = throttle(event => {

    const activeObject = this.root.pixelManipulationObjectHandler.activeObject
    const mainCanvas = activeObject.canvas
    const rect = mainCanvas.getElement().getBoundingClientRect()
    const pointer = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    }
    const delta = event.deltaY
    let cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
    if(this.root.canvasRemoveHandler.toolType === 'Eraser') {
      delta > 0 ? this.mainContext.editor.handlers.zoomHandler.zoomOut(pointer) : this.mainContext.editor.handlers.zoomHandler.zoomIn(pointer)
      this.root.pixelManipulationObjectHandler.isEraserToolResize = true
      this.root.pixelManipulationObjectHandler.updatePositionFollowMainCanvas()
    } else {
      let zoomRatio = this.root.zoomRemoveHandler.currentZoom
      zoomRatio = delta > 0 ? Math.max(zoomRatio - this.root.zoomRemoveHandler.baseStep, 1) : Math.min(zoomRatio + this.root.zoomRemoveHandler.baseStep, 5)
      this.root.zoomRemoveHandler.zoomToRatio(zoomRatio, {pageX: event.pageX, pageY: event.pageY, clientX: event.clientX, clientY: event.clientY})
    }
    cursorPreview.style.left = `${event.clientX + this.container.scrollLeft}px`
    cursorPreview.style.top = `${event.clientY + this.container.scrollTop - 64}px`
    event.preventDefault()
    event.stopPropagation()
  }, 50)

  onKeyDown(event) {
    if (shourcutsManager.isCtrlZero(event)) {
      event.preventDefault()
      this.root.zoomRemoveHandler.zoomToFit()
      if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
        return
      }
    } else if (shourcutsManager.isCtrlOne(event)) {
      event.preventDefault()
      if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
        return
      }
      this.root.zoomRemoveHandler.zoomToOne()
    } else if (shourcutsManager.isCtrlZ(event)) {
      this.root.transactionRemoveHandler.undo()
    } else if (shourcutsManager.isCtrlShiftZ(event)) {
      this.root.transactionRemoveHandler.redo()
    } else if (shourcutsManager.isCtrlY(event)) {
      this.root.transactionRemoveHandler.redo()
    } else if (shourcutsManager.isOpeningBrace(event)) {
      const logicValue = this.root.canvasRemoveHandler.originalBrushSize <= 12 ? 12 : --this.root.canvasRemoveHandler.originalBrushSize
      // this.root.pixelManipulationObjectHandler.addCursorPreview(logicValue) // Add cursor preview when adjust by hotkeys
      store.dispatch(setBrushSize(logicValue))
    } else if (shourcutsManager.isClosingBrace(event)) {
      const logicValue = this.root.canvasRemoveHandler.originalBrushSize >= 200 ? 200 : ++this.root.canvasRemoveHandler.originalBrushSize
      // this.root.pixelManipulationObjectHandler.addCursorPreview(logicValue) // Add cursor preview when adjust by hotkeys
      store.dispatch(setBrushSize(logicValue))
    } else if (shourcutsManager.isCtrlMinus(event)) {
      event.preventDefault()
      if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
        return
      }
      this.root.zoomRemoveHandler.zoomOut()
    } else if (shourcutsManager.isCtrlEqual(event)) {
      event.preventDefault()
      if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
        return
      }
      this.root.zoomRemoveHandler.zoomIn()
    }  else if (shourcutsManager.isCtrlOne(event)) {
      event.preventDefault()
      if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
        return
      }
      this.root.zoomRemoveHandler.zoomToOne()
    } else if (shourcutsManager.isSpace(event)) {
      event.preventDefault()
      if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
        return
      }
      if(!this.isSpaceKeyPressed) {
        this.isSpaceKeyPressed = true
        this.canvas.isDrawingMode = false
        const customCursor = document.querySelector('.custom-cursor') as HTMLElement
        customCursor.style.display = 'none'
        this.container.style.cursor = 'grab'
        this.canvas.wrapperEl.style.pointerEvents = 'none'
        this.canvas.renderAll()
      }
    }
  }

  handleSelection = target => {
    if (target) {
      // this.context.setActiveObject(null)
      const selection = this.canvas.getActiveObject()
      // if(target.e || target.selected){
      if (selection != null || !this.root.transactionRemoveHandler.active) {
        this.context.setActiveObject(selection)
      }
      // }
    } else {
      this.context.setActiveObject(null)
    }
  }

  handleCursor = () => {
    this.container.addEventListener('mousemove', this.onMouseMove);
    this.container.addEventListener('mouseleave', this.onMouseLeave);
  }

  onMouseMove = (e) => {
    this.handlePanning(e)
    const customCursor = document.querySelector('.custom-cursor') as HTMLElement
    const scrollLeft = this.container.scrollLeft;
    const scrollTop = this.container.scrollTop;
    const rect = this.container.getBoundingClientRect()
    const scaledX = (e.clientX - rect.left)+ scrollLeft;;
    const scaledY = (e.clientY - rect.top) + scrollTop;

    if(!customCursor) { return }
    customCursor.style.left = `${scaledX}px`;
    customCursor.style.top = `${scaledY}px`;
    customCursor.style.transform = 'translate(-50%, -50%)'
    if(e.clientX > this.container.clientWidth || e.clientY > this.container.clientHeight + 64 || this.isSpaceKeyPressed) {
      customCursor.style.display = 'none'
      return
    }
    customCursor.style.display = this.isAnimating ? 'none' : 'block'
  }

  onKeyUp = (e) => {
    if(!this.root.pixelManipulationObjectHandler.isZoomAvaliable()) {
      return
    }
    if(e.code === 'Space') {
      this.isSpaceKeyPressed = false
      this.isPanning = false
      const customCursor = document.querySelector('.custom-cursor') as HTMLElement
      customCursor.style.display = 'block';
      this.container.style.cursor = 'none'
      this.canvas.wrapperEl.style.pointerEvents = 'auto'
      this.canvas.isDrawingMode = true
      this.canvas.renderAll()
    }
  }

  onMouseLeave = () => {
    const customCursor = document.querySelector('.custom-cursor') as HTMLElement
    customCursor.style.display = 'none';
  }

  onContainerMouseDown = (e) => {
    if(this.isSpaceKeyPressed && e.button === 0) {
      this.isPanning = true
      this.lastPointerX = e.clientX
      this.lastPointerY = e.clientY
    } else {
      e.preventDefault()
    }
  }

  onContainerMouseUp = (e) => {
    this.isPanning = false
    if(this.isSpaceKeyPressed) {
      this.container.style.cursor = 'grab'
    }
  }

  handlePanning = (event) => {
    if(!this.isPanning) { return }
    this.container.style.cursor = 'grabbing'
    
    let deltaX = event.clientX - this.lastPointerX
    let deltaY =  event.clientY - this.lastPointerY 

    this.container.scrollLeft -= deltaX;
    this.container.scrollTop -= deltaY;

    this.lastPointerX = event.clientX;
    this.lastPointerY = event.clientY;
  }
}

export default RemoveEventsHandler
