import { useStyletron } from 'baseui'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getResizeUrl } from '@/utils/getResizeUrl'

interface WrapImageProps {
  srcImage: URL;
  resize?: [number, number] | null;
  ratioFrame?: number;
  hasHoverState?: boolean;
}

function WrapImage({srcImage, resize = null, ratioFrame = 1, hasHoverState = true}: WrapImageProps) {
  const [css] = useStyletron()
  return (
    <div
      className={css({
        position: 'relative',
        width: '100%',
        height: '100%',
        ':hover div': {
          display: hasHoverState ? 'block !important' : 'none',
        },
        borderRadius: '6px',
        overflow: 'hidden',
      })}
    >
      {srcImage ? (
        <LazyLoadImage
          width={'100%'}
          height={'100%'}
          src={resize ? getResizeUrl({ size: `${resize[0]}x${resize[1]}`, url: srcImage }).toString() : srcImage.toString()}
          alt=""
          style={{ objectFit: 'fill' }}
        />
      ) : null}
      <div
        className="overlay"
        style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(0,0,0,0.2)',
          display: 'none',
        }}
      ></div>
    </div>
  )
}

export default WrapImage