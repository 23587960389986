import { styled } from 'baseui'
import DropZone from './DropZone'
import Resize from './Resize'
import MagicTool from './MagicTool'
import Templates from './Templates'
import { uniqueFilename } from '@/utils/unique'
import { useAppDispatch } from '@/store/store'
import { uploadFile } from '@/store/slices/uploads/actions'
import { customAmplitude } from '@/utils/customAmplitude'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '../engine/common/constants'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { ActionType } from '@/store/slices/editor/reducer'
import { useSelector } from 'react-redux'
import { selectUploads } from '@/store/slices/uploads/selectors'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { selectCallbackFromCreationPage } from '@/store/slices/editor/selector'
import { useEditorContext } from '../engine'
import { SignInManager } from '../Editor/components/Navbar/components/SignInManager'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { selectOpenModalTryPremium, selectUser } from '@/store/slices/user/selectors'
import Loading from '@/components/Loading'
import { Helmet } from 'react-helmet'
import { useStyletron } from 'styletron-react'
import Icons from '../Editor/components/Icons'
import { lightTheme } from '@/customTheme'
import { t } from 'i18next'
import Sidebar from './Sidebar'
import Projects from './Projects'
import { useTour } from '@reactour/tour'

const WrapScroll = styled('div', props => ({
  width: '100%',
  overflow: 'hidden scroll',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
}))

function Dashboard() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const {
    setShowModalType,
    cancelPixelManipulation
  } = useAppContext()
  const editor = useEditorContext().editor
  const uploads = useSelector(selectUploads)
  const callbackFromCreationPage = useSelector(selectCallbackFromCreationPage)
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const user = useSelector(selectUser)
  
  useEffect(() => {
    if (callbackFromCreationPage) {
      dispatch(setCallbackFromCreationPage(null))
      cancelPixelManipulation()
      if (editor) {
        editor.handlers.canvasHandler.canvas.discardActiveObject()
        editor.handlers.objectsHandler.clear()
      }
      if(isOpen) {
        setIsOpen(false)
      }
    }
  }, [])

  const { isOpen, setIsOpen} = useTour()

  useEffect(() => {
    if (uploads.length && callbackFromCreationPage) {
      history.push('/')
    }
  }, [uploads, callbackFromCreationPage])

  const handleUploadFile = async (file: File) => {
    try {
      const updatedFileName = uniqueFilename(file.name)
      const updatedFile = new File([file], updatedFileName)
      await dispatch(uploadFile({ file: updatedFile, fromCreationPage: true }))
      customAmplitude('bazaart.add.photo.upload')
    } catch (err) {
      console.log({ err })
    }
  }
  const handleDropFiles = (files: FileList, action: 'add' | 'remove-bg' | 'remove-obj' | 'magic-background') => {
    setIsLoadingImage(true)
    let isUnsupport = false

    for (let file of files) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/ifif' ||
        file.type === 'image/pjpeg' ||
        file.type === 'image/pjp'
      ) {
        handleUploadFile(file)
        const reader = new FileReader()
        reader.addEventListener('load', () => {}, false)

        if (action === 'add') {
          dispatch(setCallbackFromCreationPage({ action: ActionType.ADD_IMAGE }))
        } else if (action === 'remove-bg') {
          dispatch(setCallbackFromCreationPage({ action: ActionType.REMOVE_BG }))
        } else if (action === 'remove-obj') {
          dispatch(setCallbackFromCreationPage({ action: ActionType.REMOVE_OBJ }))
        } else if (action === 'magic-background') {
          dispatch(setCallbackFromCreationPage({ action: ActionType.MAGIC_BACKGROUND }))
        }

        if (file) {
          reader.readAsDataURL(file)
        }
      } else {
        isUnsupport = true
      }
    }
    // setIsLoadingImage(false)

    if (isUnsupport) {
      setShowModalType(ShowModalType.UNSUPPORTED_FILE_TYPE)
      setIsLoadingImage(false)
    }
  }
  const [css] = useStyletron()
  const [isDragging, setIsDragging] = useState(false)
  const dropRef = useRef<HTMLDivElement>(null)
  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleDragDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDropFiles(e.dataTransfer.files, 'add')
      setIsDragging(false)
    }
  }

  useEffect(() => {
    let div = dropRef.current
    if (div) {
      div.addEventListener('dragenter', handleDragEnter)
    }
    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragEnter)
      }
    }
  }, [])
  
  const recalculateMargin = () => {
    return window.innerWidth / 2 - 1517 / 2 + 260 / 2 - 8 / 2
  }
  const [marginLeft, setMarginLeft] = useState(recalculateMargin())
  useEffect(() => {
    window.addEventListener('resize', () => {
      setMarginLeft(recalculateMargin())
    })

    return window.removeEventListener('resize', () => {
      setMarginLeft(recalculateMargin())
    })
  }, [window])

  return (
    <>
      <WrapScroll>
        <Helmet>
          <title>Free and easy-to-use design and photo editor | Bazaart</title>
          <meta
            name="description"
            content="Easy-to-use design studio and photo editor. From online selling to digital marketing to social media posting, Bazaart is here to help!."
          ></meta>
        </Helmet>
        <Sidebar /> 
        <div
          id="container-dashboard"
          ref={dropRef}
          className={css({
            // width: '100%',
            padding: '32px 24px 32px 24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            marginLeft: '260px',
            '@media screen and (min-width: 1777px)': {
              width: '1517px',
              margin: '0 auto',
              marginLeft: `${marginLeft}px`,
              padding: '32px 32px',
            },
          })}
        >
          <DropZone handleDropFiles={handleDropFiles} />
          <MagicTool handleDropFiles={handleDropFiles} />
          {/* {
            user ? <RecentDesign /> : null
          } */}
          <Resize />
          <Templates />
          <Projects />
        </div>
        {isLoadingImage ? (
          <div
            style={{
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              inset: 0,
              background: 'rgba(255, 255, 255, 0.7)',
              zIndex: 10000,
              cursor: 'auto',
            }}
            id="overlay-loading"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 'calc(50% + 32px)',
                transform: 'translateY(-50%)',
                left: 0,
                right: 0,
                zIndex: 1000,
                transition: 'all .4s ease-in',
              }}
            >
              <Loading />
            </div>
          </div>
        ) : null}
        {isDragging ? (
          <div
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              position: 'absolute',
              height: '100vh',
              inset: 0,
            }}
            onDrop={handleDragDrop}
            onDragEnter={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onDragOver={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onDragLeave={handleDragLeave}
          >
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                marginTop: 0,
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FF0560',
                pointerEvents: 'none',
                height: '100%',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '106px',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    animation: 'moveToTop .5s linear',
                  }}
                >
                  <Icons.DropImage />
                  <style>
                    {`
                  @keyframes moveToTop {
                    from {
                      margin-top: 50px;
                    }
                    to {
                      margin-top: 0px;
                    }
                  }
                  @keyframes fade {
                    from {
                      opacity: 0;
                    }
                    to {
                      opacity: 1;
                    }
                  }
                  `}
                  </style>
                </div>
              </div>
              <div
                style={{
                  ...lightTheme.typography.TitleBold,
                  margin: '24px 0 16px 0',
                  color: '#FF0560',
                  animation: 'fade .5s linear',
                }}
              >
                {t('Drop to upload your file')}
              </div>
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '30px',
                  margin: 0,
                  width: '330px',
                  textAlign: 'center',
                  animation: 'fade .5s linear',
                }}
              >
                {t('Files for upload should be .jpg or .png and 10 MB or less.')}
              </p>
            </div>
          </div>
        ) : null}
      </WrapScroll>
    </>
  )
}

export default Dashboard