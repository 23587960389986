import { getDatabase, ref, DatabaseReference, onValue, update } from 'firebase/database';
import { AtomicDictionary } from './AtomicDictionary';


export interface RealTimeDelegate {
    realTimeDidReceiveUpdate(): void;
}

export class RealTimeDictionary<Value> {
    private dictionary: AtomicDictionary<string, Value>;
    private baseChangesReference: DatabaseReference;
    private delegate?: RealTimeDelegate;

    constructor(reference: DatabaseReference, delegate?: RealTimeDelegate) {
        this.dictionary = new AtomicDictionary<string, Value>();
        this.baseChangesReference = reference;
        this.delegate = delegate;
        this.observe();
    }

    get values(): { [key: string]: Value } {
        return Object.fromEntries(this.dictionary.entries());
    }

    get(key: string): Value | undefined {
        return this.dictionary.get(key);
    }

    removeValues(keys: string[]): void {
        console.log("removing values from cloud recycleBin");
        const data: { [key: string]: Value | null } = {};
        keys.forEach(key => data[key] = null);
        this.setValues(data);
    }

    setValues(data: { [key: string]: Value | null }): void {
        const updateData: { [key: string]: any } = {};

        this.dictionary.write(inoutDictionary => {
            for (const key in data) {
                const value = data[key];
                if (value !== null) {
                    inoutDictionary.set(key, value);
                    updateData[key] = value;
                } else {
                    inoutDictionary.delete(key);
                    updateData[key] = null;
                }
            }
        });

        update(this.baseChangesReference, updateData).catch(error => {
            console.log(`sync: failed to update realtime recycle bin: ${error}`);
        });
    }

    private observe(): void {
        onValue(this.baseChangesReference, snapshot => {
            this.dictionary.write(inoutDictionary => {
                inoutDictionary.clear();
                const newValue = snapshot.val() as { [key: string]: Value };
                if (newValue) {
                    for (const key in newValue) {
                        inoutDictionary.set(key, newValue[key]);
                    }
                }
            });
            if (this.delegate) {
                setTimeout(() => {
                    this.delegate?.realTimeDidReceiveUpdate();
                }, 0);
            }
        });
    }
}
