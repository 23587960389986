import { StorageReference } from "firebase/storage";
import { CloudFileTransfer } from "./CloudFileTransfer";
import {CloudUrl} from "./CloudUrl";

export class CloudFontFileTransfer extends CloudFileTransfer {
    get localUploadUrl(): URL {
        return this.localDownloadUrl;
    }

    get localDownloadUrl(): URL {
        // let url = FontsManager.shared.customFontsDirectory;
        // const relativeComponents = this.relativePath.split('/');
        // relativeComponents.forEach(component => url = new URL(component, url));
        // return url;
        // TODO Dror: implement this
        return new URL("https://www.google.com");
    }

    get cloudUrl(): CloudUrl.ExtendedStorageReference {
        return CloudUrl.child(CloudUrl.fontsBaseUrl(), this.relativePath);
    }
}
