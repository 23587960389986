export enum SyncStatus {
    None = "none",
    WaitingForUpload = "waitingForUpload",
    Uploading = "uploading",
    Updated = "updated",
    Deleting = "deleting",
    Deleted = "deleted",
}

export namespace SyncStatus {
    export function toString(status: SyncStatus): string {
        return status;
    }

    export function isDeleted(status: SyncStatus): boolean {
        return status === SyncStatus.Deleted || status === SyncStatus.Deleting;
    }

    export function fromString(str: string): SyncStatus {
        switch (str) {
            case SyncStatus.WaitingForUpload:
                return SyncStatus.WaitingForUpload;
            case SyncStatus.Uploading:
                return SyncStatus.Uploading;
            case SyncStatus.Updated:
                return SyncStatus.Updated;
            case SyncStatus.Deleted:
                return SyncStatus.Deleted;
            case SyncStatus.Deleting:
                return SyncStatus.Deleting;
            case SyncStatus.None:
                return SyncStatus.None;
            default:
                throw new Error("Invalid SyncStatus string");
        }
    }
}