import { lightTheme } from '@/customTheme'
import { ThemeProvider, styled } from 'baseui'
import Icons from '../Editor/components/Icons'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectOpenModalTryPremium, selectUser } from '@/store/slices/user/selectors'
import { useHistory } from 'react-router'
import { useStyletron } from 'styletron-react'
import { useTranslation } from 'react-i18next'
import { selectFBUser } from '@/store/slices/firebaseAuth/selectors'
import { SyncManager } from '@/firebase/services/sync/SyncManager'
import useAppContext from '@/hooks/useAppContext'
import { StatefulPopover } from 'baseui/popover'
import ButtonCustom from '@/components/ButtonCustom'
import { KIND, SHAPE } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import { ShowModalType } from '../engine/common/constants'
import Sidebar from './Sidebar'
// @ts-ignore
import EmptyProjectLottie from '@/assets/lottie/empty_project.lottie'
import { DotLottiePlayer } from '@dotlottie/react-player'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '@/store/store'
import { DraftSyncItem } from '@/firebase/services/sync/DraftSyncItem'
import { TemplateConfig } from '@/interfaces/editor'
import { BtDraftsManager } from '@/firebase/services/sync/BtDraftsManager'
import { DraftSyncVersion } from '@/firebase/services/sync/DraftSyncVersion'
import { CloudTempProject } from '@/firebase/services/sync/CloudTempProject'
import { BtDiskFileUtils } from '@/firebase/services/sync/BtDiskFileUtils'
import Loading from '@/components/Loading'
import emptyFramBG from '@assets/images/empty_frame_bg.svg'
import defaultImage from '@assets/images/default-image.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ScrollButton from './components/ScrollButton'
import { useTour } from '@reactour/tour'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { selectCallbackFromCreationPage } from '@/store/slices/editor/selector'
import { useEditorContext } from '../engine'
import { customAmplitude } from '@/utils/customAmplitude'

const WrapScroll = styled('div', (props: any) => ({
  overflow: 'hidden scroll',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: '#e5e5e5',
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: '#D9D9D9',
  },
  ...props.style
}))

const WrapProjectItem = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '24px',
  borderRadius: '16px',
  backgroundColor: lightTheme.colors.grayScale50,
  overflow: 'hidden',
  ...props.style
}))

const DURATION_TOAST_MESSAGE = 5000

let didSetup = false

function Projects() {
  const [projects, setProjects] = useState<any>([])
  const user = useSelector(selectUser)
  const [showToastMessage, setShowToastMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const firebaseUser = useSelector(selectFBUser)

  const history = useHistory()
  const location = useLocation()
  const [css] = useStyletron()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const {setShowModalType, cancelPixelManipulation } = useAppContext()
  const [showBanner, setShowBanner] = useState(true)
  const [isFlushed, setIsFlushed] = useState(false);
  const { isOpen, setIsOpen} = useTour()
  const callbackFromCreationPage = useSelector(selectCallbackFromCreationPage)
  const editor = useEditorContext().editor
  
  useEffect(() => {
    if (callbackFromCreationPage) {
      dispatch(setCallbackFromCreationPage(null))
      cancelPixelManipulation()
      if (editor) {
        editor.handlers.canvasHandler.canvas.discardActiveObject()
        editor.handlers.objectsHandler.clear()
      }
      if(isOpen) {
        setIsOpen(false)
      }
    }
  }, [])

  const handleOpenDesign = id => {
    let syncItem = SyncManager.shared.getSyncItem(id);
    // console.log('syncItem', syncItem);
    if(!syncItem.mostUpdatedVersion.isSupportedOnWeb()) {
      setShowModalType(ShowModalType.PROJECT_NOT_SUPPORT)
    } else if (!syncItem.hasLocal() || syncItem.isOutDated()) {
      if (syncItem.isUploading()) {
        const tenMinutesInMs = 10 * 60 * 1000;
        const isOlderThanTenMinutes = Date.now() - syncItem.mostUpdatedVersion.modifiedDate.getTime() > tenMinutesInMs;
        
        if (isOlderThanTenMinutes) {
          setShowModalType(ShowModalType.PROJECT_OPEN_FAILED)
        }
        else{
          setShowModalType(ShowModalType.PROJECT_STILL_SYNC)
        }
        return
      } else if (!SyncManager.shared.hasConnection) {
        console.log('no internet');
        setShowModalType(ShowModalType.NO_INTERNET)
        return
      } 
      history.push(`/design/${id}/edit`)
    } else if(syncItem.hasConflict()){
      setShowModalType(ShowModalType.PROJECT_CONFLICT)
    } else if (syncItem.hasLocal()){
      history.push(`/design/${id}/edit`)
    }
  }

  const handleDuplicateProject = async (version: DraftSyncVersion) => {
    setIsFlushed(false)
    const emptyProject = {};
    setProjects(project => {
      const index = projects.indexOf(version)
      if(index !== -1) {
        const updatedProjects = [...project]; // Copy the existing projects
        updatedProjects.splice(index + 1, 0, emptyProject); // Insert mask after the found index
        return updatedProjects; // Return updated projects
      }
      return project;
    })
    await SyncManager.shared.downloadProject(version, false, async (originalDraft, layers) => {
      if (!originalDraft){
        return;
      }
      
      let duplicatedDraft = BtDraftsManager.duplicateDraft(originalDraft)
      let originalTempProject = new CloudTempProject(originalDraft.draftGuid)
      
      let duplicatedTempProject = new CloudTempProject(duplicatedDraft.draftGuid)
      await BtDiskFileUtils.duplicate([originalTempProject.finalProjectDest], [duplicatedTempProject.finalProjectDest])
      
      let project = BtDraftsManager.createDraft(duplicatedDraft.draftGuid)
      let draftSyncVersion = DraftSyncVersion.fromDraft(project)
      let syncItem = new DraftSyncItem(draftSyncVersion)
      syncItem.merge()
      
      let idx = SyncManager.shared.draftSyncItems.map((i) => i.draftId).indexOf(version.draftId)
      SyncManager.shared.draftSyncItems.splice(idx, 0, syncItem)
      await SyncManager.shared.didDuplicateLocalProject(duplicatedDraft)
      renderProjets();
      setIsFlushed(true)
    });
  }

  const handleDeleteProject = async (project: TemplateConfig) => {
    SyncManager.shared.deleteProjects([project.draftGuid])
  }

  const renderProjets = () => {
    const syncItems = SyncManager.shared.draftSyncItems
    const nonDeletedSyncItems = syncItems.filter (item => !(item.isDeleted() || item.shouldDeleteFromCloud()))
                                         .map(item => item.mostUpdatedVersion)
    setProjects(nonDeletedSyncItems)
    setIsLoading(false)
  }

  const loadProjects = async () => {
    setIsLoading(true)

    if (SyncManager.shared.didCompleteSetup) {
      renderProjets()
    }
  }

  useEffect(() => {
    const notifier = {
      syncManagerHasUpdates: () => {
        renderProjets()
      },
      syncManagerHasStateChange: () => {
        renderProjets()
      },
      syncManagerHasProjectStateChange: () => {},
    }

    SyncManager.shared.addDelegate(notifier)
    
    return () => {
      SyncManager.shared.removeDelegate(notifier)
    };
  }, []); 

  useEffect(() => {
    if(SyncManager.shared.currentUserId)
    {
      loadProjects()
    }
  }, [location, firebaseUser, SyncManager.shared.currentUserId])

  useEffect(() => {
    if (openModalTryPremium && openModalTryPremium.source === 'save-project') {
      setShowToastMessage(true)
      setTimeout(() => {
        setShowToastMessage(false)
      }, DURATION_TOAST_MESSAGE)
    }
  }, [SyncManager.shared.currentUserId])

  useEffect(() => {
    const handleLeaveProject = function (e) {
      if (!isFlushed) {
        e.preventDefault()
        e.stopPropagation()
        this.alert('You have unsaved changes. Are you sure you want to leave?')
      }
    }
    window.addEventListener('beforeunload', handleLeaveProject)
    return () => {
      window.removeEventListener('beforeunload', handleLeaveProject)
    }
  }, [isFlushed])

  return (
    <ThemeProvider theme={lightTheme}>
      <>
        <Sidebar />
        {/* create page */}
        {location.pathname.includes('projects') ? (
          <WrapScroll
            id="container-projects"
            className={css({
              position: 'relative',
              width: 'calc(100% - 260px)',
              minWidth: '586px',
              height: 'calc(100vh - 64px)',
              padding: '0 32px',
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '260px',
              '@media screen and (min-width: 1517px)': {
                width: 'calc(100% - 260px)',
                margin: '0 auto',
                marginLeft: '260px',
                padding: '0 32px',
              },
            })}
          >
            <div style={{position: 'sticky', top: '0px', paddingTop: '32px', background: '#fff', zIndex: 2, display: 'flex', flexDirection: 'column', gap: '32px'}}>
            {/* banner - hide for now */}
            {/* <div
              style={{
                position: 'relative',
                height: '64px',
                width: '100%',
                background: SyncManager.shared.currentUserId && user?.userIsPremium ? '#E6F7F3' : lightTheme.colors.grayScale50,
                borderRadius: '12px',
                zIndex: 2,
                display: showBanner ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '12px 20px',
              }}
            >
              <p
                style={{
                  ...lightTheme.typography.Small16Bold,
                  fontWeight: 600,
                }}
              >
                {t('Access your projects anywhere.')} <span style={{
                  ...lightTheme.typography.Small16Bold,
                  fontWeight: 400,
                }}>{SyncManager.shared.currentUserId && user?.userIsPremium
                  ? t('Projects are automatically synced across all your devices.')
                  : t('Upgrade to Premium to sync across all your devices.')}</span>
              </p>
              <button
                className={css({
                  padding: '8px 16px',
                  height: '40px',
                  minWidth: SyncManager.shared.currentUserId && user?.userIsPremium ? '111px' : '176px',
                  width: 'fit-content',
                  ...lightTheme.typography.Small14Semibold,
                  background: SyncManager.shared.currentUserId && user?.userIsPremium ? lightTheme.colors.white : '#FCD34D',
                  border: 'none',
                  borderRadius: '20px',
                  margin: '0 40px 0 16px',
                  ':hover': {
                    background: SyncManager.shared.currentUserId && user?.userIsPremium ? lightTheme.colors.grayScale20 : '#F7CF4B',
                  },
                  ':active': {
                    background: SyncManager.shared.currentUserId && user?.userIsPremium ? lightTheme.colors.grayScale50 : '#F2CB4A',
                  },
                  cursor: 'pointer',
                })}
                onClick={e => {}}
              >
                {SyncManager.shared.currentUserId && user?.userIsPremium ? t('Learn more') : t('Upgrade to Premium')}
              </button>
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  top: '20px',
                  right: '20px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  position: 'absolute',
                }}
                onClick={() => setShowBanner(false)}
              >
                <Icons.Close size={24} fill={lightTheme.colors.grayScale600} />
              </div>
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '32px' }} className="heading">
                <h2 style={{ ...lightTheme.typography.TitleBold }}>{t('Projects')}</h2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    marginLeft: '16px',
                    height: '32px',
                    marginTop: '8px'
                  }}
                >
                  <span
                    style={{
                      background: lightTheme.colors.grayScale50,
                      ...lightTheme.typography.Small12medium,
                      fontWeight: 700,
                      width: 'fit-content',
                      minWidth: '48px',
                      height: '24px',
                      borderRadius: '4px',
                      padding: '0px 8px',
                    }}
                  >
                    {t('BETA')}
                  </span>
                  <ButtonCustom
                    onClick={() => {}}
                    type={SizeButton.SMALL}
                    kind={KIND.minimal}
                    shape={SHAPE.square}
                    tooltip={{
                      content: <div style={{...lightTheme.typography.Small12regular, color: lightTheme.colors.white, lineHeight: '16px', textAlign: 'center'}}><p>{t('Your web projects live here.')}</p><p style={{color: 'rgba(82, 181, 224, 1)', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                        window.open(
                          'https://support.bazaart.com/en/content/where-are-all-my-bazaart-projects?share=2kZQBmYC9VCIiTRzwqIB5vV70eOMjLSW',
                          '_blank'
                        )
                      }}>{t('Learn more')}</p></div>,
                      placement: 'bottom',
                    }}
                    style={{
                      borderRadius: '50%',
                    }}
                  >
                    <Icons.HelpIcon />
                  </ButtonCustom>
                </div>
                {projects.length ? (
                  <ButtonCustom
                    type={SizeButton.STANDARD}
                    kind="primary"
                    onClick={() => history.push('/create')}
                    style={{
                      minWidth: '109px',
                      width: 'max-content',
                      padding: '0 12px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '6px',
                      justifyContent: 'center',
                      marginLeft: 'auto'
                    }}
                  >
                    <Icons.PlusIcon size={24} fill={'#fff'} />
                    <p
                      style={{
                        ...lightTheme.typography.Small14Semibold,
                        color: '#fff',
                        margin: 0,
                      }}
                    >
                      {t('Create')}
                    </p>
                  </ButtonCustom>
                ) : null}
              </div>
            </div>
            {/*  */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                position: 'relative',
                height: '100%',
                zIndex: 1,
              }}
            >
              {projects.length ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    paddingBottom: '32px',
                    minWidth: '586px',
                  }}
                  className="wrap-projects"
                >
                  {projects.map((project, index) => {
                    return (
                      <ProjectItem
                        key={index}
                        handleOpenDesign={handleOpenDesign}
                        user={SyncManager.shared.currentUserId}
                        handleDuplicateProject={() => handleDuplicateProject(project) }
                        project={project}
                      />
                    )
                  })}
                </div>
              ) : (
                !isLoading && (!SyncManager.shared.currentUserId || !projects.length) ? <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '24px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <DotLottiePlayer
                    src={EmptyProjectLottie}
                    autoplay
                    loop
                    style={{
                      height: '106px',
                      width: '150px',
                    }}
                  ></DotLottiePlayer>
                  <h2 style={{ ...lightTheme.typography.Header20bold }}>{t('No projects yet')}</h2>
                  <ButtonCustom
                    type={SizeButton.STANDARD}
                    kind="primary"
                    onClick={() => history.push('/create')}
                    style={{
                      minWidth: '109px',
                      width: 'max-content',
                      padding: '0 12px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '6px',
                      justifyContent: 'center',
                    }}
                  >
                    <Icons.PlusIcon size={24} fill={'#fff'} />
                    <p
                      style={{
                        ...lightTheme.typography.Small14Semibold,
                        color: '#fff',
                        margin: 0,
                      }}
                    >
                      {t('Create')}
                    </p>
                  </ButtonCustom>
                </div> : null
              )}
            </div>
            {/* loader */}
            {
              isLoading && SyncManager.shared.currentUserId ? <div
              style={{
                position: 'absolute',
                top: 'calc(50% + 32px)',
                transform: 'translateY(-50%)',
                left: 0,
                right: 0,
                zIndex: 1000,
                transition: 'all .4s ease-in',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Loading />
            </div> : null
            }
          </WrapScroll>
        ) : projects.length ? (
          <RecentProjects projects={projects} handleOpenDesign={handleOpenDesign} handleDuplicateProject={handleDuplicateProject} />
        ) : null}
        {/* projects page */}
              
      </>
      <div
        style={{
          position: 'fixed',
          height: '46px',
          width: 'fit-content',
          minWidth: '541px',
          background: lightTheme.colors.blackGray,
          borderRadius: '24px',
          zIndex: 1,
          top: '80px',
          left: '50%',
          transform: 'translateX(-50%)',
          display: showToastMessage ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '8px 8px 8px 16px',
        }}
      >
        <Icons.SaveProjectIcon />
        <p
          style={{
            ...lightTheme.typography.Small14regular,
            color: lightTheme.colors.white,
            margin: '0 16px 0 12px',
          }}
        >
          {t('Projects are auto save, You can continue editing them at anytime')}
        </p>
        <div
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            background: lightTheme.colors.grayScale800,
            cursor: 'pointer',
          }}
          onClick={() => setShowToastMessage(false)}
        >
          <Icons.Close size={24} fill={'#fff'} />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Projects

function ProjectItem(props) {
  const [css] = useStyletron()
  const { setShowModalType } = useAppContext()
  const [displayDotsIcon, setDisplayDotsIcon] = useState(false)
  // const [hoverDeleteButton, setHoverDeleteButton] = useState(false)
  const { t } = useTranslation()
  const location = useLocation()
  
  const projectIsLoaded = props.project && props.project.draftId

  return (
    <WrapProjectItem
      onMouseEnter={() => projectIsLoaded && setDisplayDotsIcon(true)}
      onMouseLeave={() => setDisplayDotsIcon(false)}
      className={location.pathname.includes('projects') ? 'project-item' : ''}
      onClick={() => props.handleOpenDesign(props.project.draftId)}
      style={{
        ...props.style,
        ':hover': {
          backgroundColor: projectIsLoaded ? lightTheme.colors.grayScale100 : 'auto',
        },
        cursor: projectIsLoaded ? 'pointer' : 'auto',
      }}
    >
      {projectIsLoaded ? (
        props.project.screenshotUrl ?<LazyLoadImage
          effect="blur"
          src={props.project.screenshotUrl}
          alt="project thumb"
          style={{
            borderRadius: '6px',
            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
            backgroundImage: `url(${emptyFramBG})`,
            objectFit: 'contain',
            maxWidth: 'fit-content',
            maxHeight: 'fit-content',
            width: '100%',
            height: '100%',
          }}
          wrapperProps={{
            style: {
              maxWidth: 'fit-content',
              maxHeight: 'fit-content',
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            },
          }}
        /> : <div style={{width: "40px", height: "40px"}}><img src={defaultImage} style={{width: '100%', height: '100%'}} alt="default thumb" /></div>
      ) : (
        <Loading />
      )}

      <div style={{ position: 'absolute', top: '8px', right: '8px' }}></div>
      <div
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          display: displayDotsIcon ? 'block' : 'none',
        }}
        onClick={e => e.stopPropagation()}
      >
        {displayDotsIcon ? (
          <StatefulPopover
            popoverMargin={4}
            placement="bottom"
            returnFocus={false}
            focusLock={false}
            content={({ close }) => (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '16px',
                  backgroundColor: '#fff',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
                  padding: '16px',
                }}
              >
                {/* <ButtonCustom
                  shape={SHAPE.square}
                  kind={KIND.minimal}
                  type={SizeButton.STANDARD}
                  onClick={() => {
                    props.handleDuplicateProject()
                    close()
                  }}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '8px!important',
                  }}
                  startEnhancer={<Icons.Duplicate />}
                >
                  <p style={{ ...lightTheme.typography.Small14regular }}>{t('Duplicate')}</p>
                </ButtonCustom> */}
                <ButtonCustom
                  shape={SHAPE.square}
                  kind={KIND.minimal}
                  type={SizeButton.STANDARD}
                  onClick={e => {
                    SyncManager.shared.idDownloadProject = props.project.draftId
                    setShowModalType(ShowModalType.DOWNLOAD_PROJECT)
                    close()
                  }}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '8px!important',
                  }}
                  startEnhancer={<Icons.DownloadIcon />}
                >
                  <p style={{ ...lightTheme.typography.Small14regular }}>{t('Download')}</p>
                </ButtonCustom>
                <div style={{height: '1px',width: '100%', margin: '8px 0', background: lightTheme.colors.grayScale100}}></div>
                <div
                  // onMouseEnter={() => setHoverDeleteButton(true)}
                  // onMouseLeave={() => setHoverDeleteButton(false)}
                >
                  <ButtonCustom
                    shape={SHAPE.square}
                    kind={KIND.minimal}
                    type={SizeButton.STANDARD}
                    onClick={e => {
                      SyncManager.shared.idDeleteProject = [props.project.draftId]
                      setShowModalType(ShowModalType.DELETE_PROJECT)
                      e.stopPropagation()
                    }}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      padding: '8px!important',
                      ':hover': {
                        color: '#FF0505',
                      },
                    }}
                    startEnhancer={<Icons.Delete fill={'#FF0505'} />}
                  >
                    <p
                      className={css({
                        ...lightTheme.typography.Small14regular,
                        color: '#FF0505',
                      })}
                    >
                      {t('Delete')}
                    </p>
                  </ButtonCustom>
                </div>
              </div>
            )} 
            overrides={{
              Body: {
                style: ({ $theme }) => ({
                  zIndex: 1000,
                  transitionDuration: 'none',
                  width: '224px',
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  transitionDuration: 'none',
                }),
              },
            }}
            onClose={() => {}}
            stateReducer={(a, b, c) => {
              return b
            }}
            triggerType="click"
            accessibilityType={'tooltip'}
          >
            <div>
              <ButtonCustom
                type={SizeButton.STANDARD}
                kind={KIND.secondary}
                style={{
                  width: '40px',
                  padding: '0 8px',
                  background: '#fff',
                  ':hover': {
                    background: '#fff!important',
                  },
                }}
                onClick={() => {}}
              >
                <Icons.Dots fill={'#000'} />
              </ButtonCustom>
            </div>
          </StatefulPopover>
        ) : null}
      </div>
    </WrapProjectItem>
  )
}


function RecentProjects({projects, handleOpenDesign, handleDuplicateProject}) {
  const history = useHistory()
  const [css] = useStyletron()
  const containerRef = useRef<HTMLDivElement>(null)
  const [visibleScrollBtn, setVisibleScrollBtn] = useState({ left: false, right: false })
  useEffect(() => {
    const handleResize = () => {
      // 1452 is the width of max items in the container
      if(containerRef.current.clientWidth < 1452) {
        setVisibleScrollBtn({ left: false, right: true })
      } else {
        setVisibleScrollBtn({ left: false, right: false })
      }
      if(containerRef.current.scrollLeft === 0) {
        setVisibleScrollBtn(pre => ({ ...pre, left: false }))
      } else {
        setVisibleScrollBtn(pre => ({ ...pre, left: true }))
      }
    };
    handleResize()
    // Add the event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleScroll = (direction: 'left' | 'right') => {
    const widthTemplateItem = 222
    const gap = 24
    let itemsVissible = Math.floor(containerRef.current.clientWidth / (widthTemplateItem + gap))
    if(direction === 'left') {
      if(containerRef.current.scrollLeft + containerRef.current.clientWidth === containerRef.current.scrollWidth) {
        containerRef.current.scrollLeft -= containerRef.current.clientWidth
      } else {
      containerRef.current.scrollLeft -= (widthTemplateItem + gap) * itemsVissible
      }
      const isReachEnd = containerRef.current.scrollLeft >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: containerRef.current.scrollLeft < containerRef.current.clientWidth ? false : true, right: isReachEnd ? false : true }))
    } else {
      let newValue = containerRef.current.scrollLeft + (widthTemplateItem + gap) * itemsVissible
      containerRef.current.scrollLeft = newValue
      const isReachEnd = newValue + containerRef.current.clientWidth >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: true, right: isReachEnd ? false : true }))
    }
  }
  const [t] = useTranslation()

  return <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingBottom: '32px',
    marginTop: '22px',
    position: 'relative',
  }}
>
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    <h2 style={{ ...lightTheme.typography.Header20bold }}>{t('Recent projects')}</h2>
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '12px',
        padding: '0 4px 0 8px',
        height: '24px',
        ':hover': {
          background: lightTheme.colors.grayScale50,
        },
        ':active': {
          background: lightTheme.colors.grayScale100,
        },
      })}
      onClick={() => history.push('/create/projects')}
    >
      <p
        style={{
          ...lightTheme.typography.Small12medium,
        }}
      >
        {t('See all')}
      </p>
      <Icons.RedArrowRight fill={lightTheme.colors.blackGray} />
    </div>
  </div>
  <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', overflow: 'hidden', width: '100%', scrollBehavior: 'smooth' }} ref={containerRef}>
    {projects?.map((project, index) => {
      return index < 6 ? (
        <ProjectItem
          key={project.draftId}
          style={{ minWidth: `222px`, height: `222px`, maxWidth: 'calc(100% / 6)' }}
          src={project.screenshotUrl}
          project={project}
          handleOpenDesign={() => {
            handleOpenDesign()
            customAmplitude('Create', {
              Source: 'Recent projects'
            })
          }}
          handleDuplicateProject={() => handleDuplicateProject(project) }
        ></ProjectItem>
      ) : null
    })}
  </div>
  {visibleScrollBtn.left ? <ScrollButton direction="left" handle={() => handleScroll('left')} styles={{ left: '0px', top: '139px', transform: 'translateX(-50%)' }} /> : null}
  {visibleScrollBtn.right ? <ScrollButton direction="right" handle={() => handleScroll('right')} styles={{ right: '0px', top: '139px', transform: 'translateX(50%)' }} /> : null}
</div>
}