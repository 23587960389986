import { CloudProjectFileTransfer } from "./CloudProjectFileTransfer";
import { CloudProgress } from "./CloudProgress";
import { ProcessType } from "./ProcessType"; // Ensure you import this from your project
import { EmptyResponse } from "./CloudResponse";
import { ScreenshotProvider } from "./ScreenshotProvider";


class CloudProcess {
    private id: string;
    type: ProcessType;
    error?: EmptyResponse;
    
    private _shouldCancel: boolean = false;

    private completed: boolean = false;
    private filesTransfer: CloudProjectFileTransfer[] = [];

    constructor(id: string, type: ProcessType) {
        this.id = id;
        this.type = type;
    }

    get failed(): boolean {
        return !!this.error;
    }

    get canProceed(): boolean {
        return !this._shouldCancel && !this.failed;
    }

    shouldCancel(): boolean {
        return this._shouldCancel;
    }

    private cancelTasks(): void {
        this._shouldCancel = true;
        this.filesTransfer.forEach((fileTransfer) => {
            const task = fileTransfer.task;
            if (task && task.cancel) {
                task.cancel();
            }
        });
    }

    async cancel(): Promise<void> {
        if (this.completed) {
            console.log(`sync: canceling a completed process of ${this.type.toString()} process: ${this.id}`);
            return;
        }

        this._shouldCancel = true;
        const typeString = this.type.toString();
        const currentId = this.id;
        console.log(`sync: canceling ${typeString} process: ${currentId}`);

        if (this.filesTransfer.length === 0) return;

        return new Promise<void>((resolve) => {
            this.notifyOnCompletion(() => {
                console.log(`sync: did cancel ${typeString} process: ${currentId}`);
                resolve();
            });

            this.cancelTasks();
        });
    }

    didFailed(error: EmptyResponse): void {
        this.error = error;
    }

    private completionBlocks: ((success: boolean) => void)[] = [];

    notifyOnCompletion(comp: (success: boolean) => void): void {
        this.completionBlocks.push(comp);
    }

    didComplete(success: boolean): void {
        this.completed = true;
        console.log(`sync: did complete ${this.type.toString()} process: ${this.id}, success: ${success}`);

        this.completionBlocks.forEach((callback) => {
            callback(success);
        });

        this.completionBlocks = [];
    }

    async runFileTransfers(files: CloudProjectFileTransfer[], progress?: CloudProgress): Promise<EmptyResponse> {
        console.log(`sync: run process: ${this.id}`);
        this.filesTransfer = files;
        const screenshot = files.find((file) => file.relativePath.endsWith(ScreenshotProvider.screenshotFilename()));
        const links = files.filter((file) => file.isHardLink);
        const other = files.filter((file) => file !== screenshot && !file.isHardLink);

        let filesToTransfer = [screenshot].filter(Boolean).concat(other)

        let isUploadingOnlyTask = files.every (f=>f.type == ProcessType.Upload)
        let retryAccount = isUploadingOnlyTask ? 0 : 2; // no need to retry upload the firebase sdk does it for us 
        const maxConcurrent = 9
        const transfers: EmptyResponse[] = await this.asyncResponseActions(
            filesToTransfer,
            maxConcurrent,
            (transfer) => transfer.run(retryAccount, this, progress)
        );

        let failedTransfer = transfers.find((t) => !t.succeed)
        if (failedTransfer) {
            this.didComplete(false);
            return failedTransfer
        }

        const res: EmptyResponse[] = await this.asyncResponseActions(
            links,
            maxConcurrent,
            (transfer) => transfer.run(2, this, progress)
        );

        let failedLinkTransfer = res.find((t) => !t.succeed)
        if (failedLinkTransfer) {
            this.didComplete(false);
            return failedLinkTransfer
        }

        this.didComplete(true);
        return EmptyResponse.success();
    }

    private async asyncResponseActions<T, R>(
        items: T[],
        maxConcurrent: number,
        action: (item: T) => Promise<R>
    ): Promise<R[]> {
        const results: R[] = new Array(items.length); // To store results
        const executing = new Set<Promise<void>>(); // To store current running promises
    
        // Function to process individual items
        const processItem = async (index: number): Promise<void> => {
            try {
                results[index] = await action(items[index]);
            } catch (error) {
                console.error(`Error processing item at index ${index}:`, error);
            }
        };
    
        // Iterate over all the items
        for (let i = 0; i < items.length; i++) {
            // Start processing the item and add a then handler to remove it from the set when done
            const promise = processItem(i).then(() => {
                executing.delete(promise);
            });
    
            // Add it to the executing set
            executing.add(promise);
    
            // If we have reached the max concurrency, wait for one to finish
            if (executing.size >= maxConcurrent) {
                await Promise.race(executing);
            }
        }
    
        // Once all items have been processed, wait for the remaining tasks to complete
        await Promise.all(executing);
    
        return results; // No need to use flatMap here since results is already an array of R
    }
}

export { CloudProcess };
