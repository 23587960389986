class ArrayExtensions<T> {
    private arr: T[];

    constructor(arr: T[]) {
        this.arr = arr;
    }

    argmax(): number | null {
        if (this.arr.length === 0) return null;
        return this.arr.reduce((maxIndex, currentValue, currentIndex, array) => 
            array[currentIndex] > array[maxIndex] ? currentIndex : maxIndex, 0
        );
    }

    argmin(): number | null {
        if (this.arr.length === 0) return null;
        return this.arr.reduce((minIndex, currentValue, currentIndex, array) => 
            array[currentIndex] < array[minIndex] ? currentIndex : minIndex, 0
        );
    }
}

class GraphNode<T> {
    item: T;
    id: string;
    outNeighbors: GraphNode<T>[];
    inNeighborIds: Record<string, boolean>;

    constructor(item: T, id: string) {
        this.item = item;
        this.id = id;
        this.outNeighbors = [];
        this.inNeighborIds = {};
    }
}

export class Graph<T> {
    static sortByKahnsAlgorithm<T>(itemsArray: T[][], itemId: (item: T) => string): T[] {
        if (itemsArray.length === 0) return [];
        if (itemsArray.length === 1) return itemsArray[0];

        const flatItems = itemsArray.flat();
        const nodes: Record<string, GraphNode<T>> = {};

        flatItems.forEach(item => {
            const id = itemId(item);
            nodes[id] = new GraphNode<T>(item, id);
        });

        itemsArray.forEach(items => {
            if (items.length <= 1) return;
            for (let i = 1; i < items.length; i++) {
                const nodeId = itemId(items[i - 1]);
                const neighborId = itemId(items[i]);

                const node = nodes[nodeId];
                const neighbor = nodes[neighborId];

                if (neighbor.inNeighborIds[nodeId]) {
                    continue;
                }

                node.outNeighbors.push(neighbor);
                neighbor.inNeighborIds[nodeId] = true;
            }
        });

        // Initialize the queue with nodes that have zero in-degree
        let queue = Object.values(nodes).filter(node => Object.keys(node.inNeighborIds).length === 0);
        const topologicalOrder: T[] = [];

        // Process the queue
        while (queue.length > 0) {
            const node = queue.shift();
            topologicalOrder.push(node.item);

            node.outNeighbors.forEach(neighbor => {
                delete neighbor.inNeighborIds[itemId(node.item)];
                if (Object.keys(neighbor.inNeighborIds).length === 0) {
                    queue.push(neighbor);
                }
            });
        }

        // Check if the topological sort includes all nodes
        if (topologicalOrder.length === Object.keys(nodes).length) {
            return topologicalOrder;
        } else {
            // Graph contains a cycle
            const leftOvers = Object.values(nodes).filter(node => Object.keys(node.inNeighborIds).length > 0).map(node => node.item);
            return topologicalOrder.concat(leftOvers);
        }
    }
}
