import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import Editor from '@scenes/Editor'
import Magic from './scenes/Editor/Magic'
import { Suspense, lazy, useEffect } from 'react'
import Navbar from './scenes/Editor/components/Navbar'
import Dashboard from './scenes/Dashboard'
import ShowModal from './components/ShowModal'
import Projects from './scenes/Dashboard/Projects'
import { URLLockManager } from './scenes/Editor/components/Navbar/URLLockManager'

const TemplateManagerView = lazy(() => import('./views/TemplateManagerView'))
const TemplateGeneratorView = lazy(() => import('./views/TemplateGeneratorView'))
const CategoryManagerView = lazy(() => import('./views/CategoryManagerView'))

function useURLLockManager() {
  const location = useLocation();
  const lockManager = URLLockManager.getInstance();

  useEffect(() => {
    const currentURL = location.pathname + location.search;
    lockManager.handleURLChange(currentURL);
    
    // Cleanup function on unmount
    return () => {
      lockManager.clear();
    };
  }, [location, lockManager]);

  return null;
}

// Separate component for URL lock management inside Router context
const URLLockManagerWrapper = () => {
  useURLLockManager();  // Calling the hook within the Router context
  return null;
};

const Routes = () => {

  return (
    <Router>
      <URLLockManagerWrapper />
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <Switch>
          <Route path="/generate-templates" component={TemplateGeneratorView} />
          <Route path="/manage-templates" component={TemplateManagerView} />
          <Route path="/manage-categories" component={CategoryManagerView} />
          <Route path="/magic" component={Magic} />
          <Route path="/design/:id/edit" component={Editor} />
          <Route path="/create/projects" component={Projects} />
          <Route path="/create" component={Dashboard} />
          <Route path="/" component={Editor} />
        </Switch>
        <ShowModal />
      </Suspense>
    </Router>
  )
}

export default Routes
