import Group from './components/Group'
import Opacity from './components/Opacity'
import Transform from './components/Transform'
import { useEffect } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { customAmplitude } from '@/utils/customAmplitude'

function MultiElement() {
  const activeObject = useEditorContext().activeObject

  useEffect(() => {
    const center = activeObject.getCenterPoint()
    activeObject.set({
      originX: 'center',
      originY: 'center',
    })
    activeObject.set({
      left: center.x,
      top: center.y,
    })
    activeObject.setCoords()
    const eventProperties = {
      Tool: 'bazaart.grouping',
    }
    customAmplitude('Selected tool', eventProperties)
  }, [])

  return (
      <div>
        <Group />
        <Opacity />
        <Transform />
      </div>
  )
}

export default MultiElement
