import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import ButtonCustom from '@/components/ButtonCustom'
import { KIND } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'
import Icons from '../../Icons'
import AutoScroll from '@/components/AutoScroll'
import { useStyletron } from 'styletron-react'
import { ObjectType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import { useSelector } from 'react-redux'
import { selectFonts } from '@/store/slices/fonts/selectors'
import groupBy from 'lodash/groupBy'
import { customAmplitude } from '@/utils/customAmplitude'
import { StaticTextOptions } from '@/scenes/engine/objects/StaticText'
import { useTranslation } from 'react-i18next'
import HeadingInspector from '@/components/HeadingInspector'
import { nanoid } from 'nanoid'
import { fabric } from 'fabric'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'

const normalizeLineHeight = (value) => {
  return value / 10
}

const normalizeCharSpacing = (fontSize, value) => {
  return value / fontSize * 1000;
}

const canvasImageRenderer = CanvasImageRenderer.getInstance()
const outlineFilter = canvasImageRenderer.outlineFilter
const normalizeOutlineUIValue = (value) => {
  return value / 100
}

const TEXT_PRESENT = [
  {
    url: 'https://assets.bazaart.me/tp/new_post.png',
    fontPack: 'Featured',
    fontFamily: 'Marcellus-Regular',
    string: 'NEW POST',
    color: '#3e3e3e',
    fontSize: 208,
    alignment: 'center',
    charSpacing: 10,
    lineHeight: normalizeLineHeight(8),
  },
  {
    url: 'https://assets.bazaart.me/tp/Morning_routi.png',
    fontPack: 'Signature',
    fontFamily: 'Signature',
    string: 'Morning\nRoutine',
    color: '#b349ff',
    fontSize: 164,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(9),
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-14.png',
    fontPack: 'Featured',
    fontFamily: 'Quicksand-Bold',
    string: 'COFFEE\nBREAK',
    color: '#2affc4',
    fontSize: 192,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(8),
    outline: {
      color: '#a5ffe6',
      strokeWidth:  normalizeOutlineUIValue(5) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    },
    shadow: {
      angle: 0,
      blur: 18,
      color: 'rgba(43,255,195,1.0)', // Converted from '#2bffc3' with opacity 100%
      distance: 0
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-11.png',
    fontPack: 'Artsy',
    fontFamily: 'PinkLemonadeRegular',
    string: 'Happy\nBirthday!',
    color: '#ec9aff',
    fontSize: 227,
    alignment: 'center',
    charSpacing: normalizeCharSpacing(227, 20),
    lineHeight: normalizeLineHeight(9),
    outline: {
      color: '#e0f004',
      strokeWidth: normalizeOutlineUIValue(12) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-13.png',
    fontPack: 'Featured',
    fontFamily: 'ZillaSlab-Bold',
    string: 'subscribe\nand become\na member',
    color: '#6a3d29',
    fontSize: 215,
    alignment: 'left',
    charSpacing: normalizeCharSpacing(215, 4),
    lineHeight: normalizeLineHeight(8),
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart%20(61).png',
    fontPack: 'Dreamy',
    fontFamily: 'GreatVibes-Regular',
    string: 'Thank you!',
    color: '#eb10a9',
    fontSize: 164,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(8),
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart%20(63).png',
    fontPack: 'Love',
    fontFamily: 'FoolForLove',
    string: 'YOU ARE\nTHE ONE\nI LOVE !',
    color: '#fd0909',
    fontSize: 90,
    alignment: 'center',
    charSpacing: normalizeCharSpacing(90, 15),
    lineHeight: normalizeLineHeight(10),
    outline: {
      color: '#a8eb6e',
      strokeWidth: normalizeOutlineUIValue(9) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-8.png',
    fontPack: 'Strong',
    fontFamily: 'BungeeInline-Regular',
    string: 'RETRO VIBES',
    color: '#e7cd49',
    fontSize: 215,
    alignment: 'center',
    charSpacing: normalizeCharSpacing(215, 4),
    lineHeight: normalizeLineHeight(8),
    outline: {
      color: lightTheme.colors.blackGray,
      strokeWidth: normalizeOutlineUIValue(6) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-12.png',
    fontPack: 'Featured',
    fontFamily: 'BebasNeue-Regular',
    string: 'JOIN OUR\nCOMMUNITY',
    color: lightTheme.colors.blackGray,
    fontSize: 220,
    alignment: 'left',
    charSpacing: normalizeCharSpacing(220, 4),
    lineHeight: normalizeLineHeight(7),
  },
  {
    url: 'https://assets.bazaart.me/tp/fun.png',
    fontPack: 'Strong',
    fontFamily: 'Shrikhand-Regular',
    string: 'FUN',
    color: '#f1ff3a',
    fontSize: 217,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(8),
    outline: {
      color: '#ff2bbf',
      strokeWidth: normalizeOutlineUIValue(24) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    },
    shadow: {
      angle: 68,
      blur: 32,
      color: 'rgba(255,43,191,0.36)', // Converted from '#ff2bbf' with opacity 36%
      distance: 4
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-10.png',
    fontPack: 'Strong',
    fontFamily: 'YesevaOne',
    string: 'FOLLOW',
    color: '#e1b945',
    fontSize: 176,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(8),
  },
  {
    url: 'https://assets.bazaart.me/tp/PM.png',
    fontPack: 'Yummy',
    fontFamily: 'VT323-Regular',
    string: 'PM 2:55\nSep.12 2025',
    color: lightTheme.colors.blackGray,
    fontSize: 173,
    alignment: 'left',
    charSpacing: normalizeCharSpacing(173, 13),
    lineHeight: normalizeLineHeight(11),
  },
  {
    url: 'https://assets.bazaart.me/tp/fire.png',
    fontPack: 'Halloween',
    fontFamily: 'MeltedMonsterRegular',
    string: 'FIRE',
    color: '#ff0404',
    fontSize: 218,
    alignment: 'center',
    charSpacing: normalizeCharSpacing(218, 45),
    lineHeight: normalizeLineHeight(7),
    outline: {
      color: '#fff24a',
      strokeWidth: normalizeOutlineUIValue(5) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    },
    shadow: {
      angle: 12,
      blur: 40,
      color: 'rgba(255,242,74,1.0)', // Converted from '#fff24a' with opacity 100%
      distance: 8
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/Bazaart-15.png',
    fontPack: 'Signature',
    fontFamily: 'FancySignature',
    string: 'coming soon...',
    color: '#454545',
    fontSize: 176,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(8),
  },
  {
    url: 'https://assets.bazaart.me/tp/pixel_perfect.png',
    fontPack: 'Gaming',
    fontFamily: 'PressStart2P-Regular',
    string: 'pixel\nperfect',
    color: '#c141ff',
    fontSize: 164,
    alignment: 'center',
    charSpacing: normalizeCharSpacing(164, 12),
    lineHeight: normalizeLineHeight(9),
    outline: {
      color: '#4d0062',
      strokeWidth: normalizeOutlineUIValue(22) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    },
    shadow: {
      angle: 0,
      blur: 22,
      color: 'rgba(103,0,184,1.0)', // Converted from '#6700b8' with opacity 100%
      distance: 0
    }
  },
  {
    url: 'https://assets.bazaart.me/tp/happy_hour.png',
    fontPack: 'Strong',
    fontFamily: 'ClimateCrisis-Regular',
    string: 'HAPPY\nHOUR',
    color: '#0101ff',
    fontSize: 217,
    alignment: 'center',
    charSpacing: 0,
    lineHeight: normalizeLineHeight(8),
    outline: {
      color: '#bdcfff',
      strokeWidth: normalizeOutlineUIValue(24) * (outlineFilter.maximumForKey('thickness') - outlineFilter.minimumForKey('thickness')) + outlineFilter.minimumForKey('thickness')
    }
  },
];


function Text({ isOpen, setIsOpen }) {
  const [isBoxShadow, setIsBoxShadow] = useState(false)

  const fonts = useSelector(selectFonts)

  const fontGroups = groupBy(fonts, 'category')


  const editor = useEditorContext().editor
  const { setPopoverActive, objDragging, setObjDragging } = useAppContext()

  const addStaticText = (font?) => {
    const canvasSize = editor.handlers.frameHandler.getSize();
    const canvasCenter = editor.handlers.frameHandler.getCenter();

    let textOptions: StaticTextOptions = {
      text: font?.text ?? 'Add your text',
      isWrapping: true,
      left: canvasCenter.x,
      top: canvasCenter.y,
      originX: 'center',
      originY: 'center',
      scaleX: 1.0,
      scaleY: 1.0,
      arcAngle: 0,
      fill: font?.color ?? lightTheme.colors.blackGray,
      charSpacing: font ? font.charSpacing : 1,
      lineHeight: font ? font.lineHeight: fabric.Text.prototype._fontSizeMult,
      fontSize: font ? font.fontSize : 200,
      fontWeight: 900,
      fontFamily: font ? font.family : 'Poppins-SemiBold',
      name: font ? font.name : 'Aniston',
      isPresetText: font ? font.isPresetText : false,
      opacity: 1,
      type: ObjectType.BAZAART_TEXT,
      id: nanoid(),
      bazaartGuid: nanoid(),
      selected: false,
      textAlign: font?.alignment ?? 'center',
      paintFirst: 'stroke',
      strokeLineJoin: 'round',
      strokeLineCap: 'round',
    }

    // By default set very wide text so it doesn't wrap. We recalculate it later
    textOptions.width = canvasSize.width * 0.8;

    if (font?.shadow) {
      textOptions.shadow = font?.shadow;
    }
    
    let addedTextObject = new fabric.StaticText(textOptions)
    // @ts-ignore
    addedTextObject.width = Math.ceil(addedTextObject.calcTextWidth());
    if (font?.outline?.color) {
      addedTextObject.stroke = font?.outline?.color;
    }
    if(font?.outline?.strokeWidth) {
      addedTextObject.strokeWidth = font?.outline?.strokeWidth * Math.max(addedTextObject.width, addedTextObject.height)
    }
    editor.handlers.objectsHandler.addElement(addedTextObject)
    setPopoverActive(null)
    font
      ? customAmplitude('Selected tool', {
        Tool: 'bazaart.add.text_style',
      })
      : customAmplitude('Selected tool', {
        Tool: 'bazaart.add.text',
      })
  }

  const handleStyleSelect = (textItem) => {
    const { fontFamily, fontPack, string, color, fontSize, alignment, charSpacing, lineHeight, outline, shadow } = textItem
    if (fontGroups[fontPack]) {
      const font = fontGroups[fontPack].find(f => f.family === fontFamily)
      if (font) {
        const fontFile = font.files['regular' as any]
        const fontOpt = {
          name: font.name,
          family: font.family,
          url: fontFile,
          options: { style: 'normal', weight: 400 },
        }
        // @ts-ignore
        const fontFace = new FontFace(fontOpt.family, `url(${fontOpt.url})`, fontOpt.options)
        fontFace
          .load()
          .then(loadedFont => {
            document.fonts.add(loadedFont)
            fontFace.loaded.then(() => {
              addStaticText({
                ...font,
                color,
                text: string,
                fontSize,
                alignment,
                charSpacing,
                lineHeight,
                outline,
                shadow,
                isPresetText: true
              })
            })
          })
          .catch(err => console.log(err))
      }
    }
  }

  const { t } = useTranslation()
  useEffect(() => {
    if (objDragging) {
      if (objDragging.type === ObjectType.BAZAART_TEXT && objDragging.dropped) {
        handleStyleSelect(objDragging.item)
        setObjDragging({
          item: null,
          type: null,
          dropped: false
        })
      }
    }
  }, [objDragging])

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-text"
        style={{
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 180px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
        }}
      >
        <div style={{
          boxShadow: isBoxShadow ? '0px 1px 6px 0px rgba(0, 0, 0, 0.10)' : 'none',
        }}>
          <HeadingInspector
            hasBoxShadow={false}
            hasNavigation={false}
            title={t('Text')}
            hasClose={true}
            handleClose={() => setIsOpen()}
          ></HeadingInspector>
          <div
            style={{
              textAlign: 'center',
              zIndex: 100,
              paddingBottom: '12px',
            }}
          >
            <ButtonCustom
              kind={KIND.primary}
              type={SizeButton.LARGE}
              onClick={() => addStaticText()}
              style={{ color: 'white', margin: '0 auto', width: '292px' }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}>
                <Icons.PlusIcon fill="#fff" />
                <p style={{ ...lightTheme.typography.Small14Semibold, color: '#fff' }}>{t('Add text')}</p>
              </div>
            </ButtonCustom>
          </div>
        </div>
        <AutoScroll
          style={{ position: 'relative' }}
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          <div style={{ boxSizing: 'border-box', width: '100%', height: 'fit-content' }}>
            <div
              style={{
                height: '26px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px',
              }}
            >
              <p style={{ ...lightTheme.typography.Small14Semibold, color: lightTheme.colors.text.text_black_title }}>{t('Styles')}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
              {TEXT_PRESENT.map((textItem, index) => (
                <TextItem
                  key={textItem.url}
                  textItem={textItem}
                  handleOnClick={() => {
                    handleStyleSelect(textItem)
                  }}
                />
              ))}
            </div>
          </div>
        </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default Text

function TextItem({ textItem, handleOnClick }) {
  const { objDragging, setObjDragging } = useAppContext()

  const [css] = useStyletron()
  return (
    <div
      className={css({
        width: '142px',
        height: '142px',
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid rgba(0,0,0,0.1)',
        cursor: 'pointer',
        ':hover': {
          background: lightTheme.colors.grayScale50,
        },
        ':active': {
          background: lightTheme.colors.grayScale100,
        },
      })}
      onClick={() => handleOnClick()}
      onDrag={() => {
        if (!objDragging.item || objDragging.item?.string !== textItem.string) {
          setObjDragging({
            item: textItem,
            type: ObjectType.BAZAART_TEXT,
          })
        }
      }}
    >
      <img src={textItem.url} alt="" width="100%" height="100%" />
    </div>
  )
}
