import { TemplateConfig } from "@/interfaces/editor";
import { DraftSyncItem } from "./DraftSyncItem";
import { DateProvider } from "../DateProvider";
import { nanoid } from "nanoid";
import { BtDiskFileUtils } from "./BtDiskFileUtils";

export class BtDraftsManager {
    static getDrafts(): TemplateConfig[] {
        return [];
    }

    static duplicateDraft(draft: TemplateConfig): TemplateConfig {
        let id =  nanoid()
        let newDraft = Object.assign({}, draft, {draftGuid: id})  
        return newDraft
    }
    
    static createDraft(projectId: string): TemplateConfig {
        let placeholderTemplateConfig: TemplateConfig = 
            {
            canvasIsCircular: false,
            draftCreationDate: DateProvider.getUTCDate(),
            draftGuid: projectId,
            draftTemplateCategoryId: 0,
            draftTitle: "",
            hasOpacity: false,
            isAnimatedDraft: false,
            isExtracted: false,
            minDraftVersion: 1,
            originId: 0,
            originalCanvasSize: {
                width: 1024,
                height: 1024
            },
            parentId: 0,
            screenshotDate: DateProvider.getUTCDate(),
            version: 255, 
            isMagicTemplate : false,
            isTemplateReels: false
        }
        return placeholderTemplateConfig;
    }
}