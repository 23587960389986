import throttle from 'lodash/throttle'
import BaseHandler from './BaseHandler'
import { ObjectType } from '@/scenes/engine/common/constants'
import { MediaImageRepository } from '../objects/media-repository/media_image_repository'
import { allMediaImageTypeValues, MediaImageType } from '../objects/media-repository/media_image_type'

class TransactionHandler extends BaseHandler {
  redos = []
  undos = []
  state = []
  active = false
  isFlushed = true
  
  originalCanvas: { version: string; objects: Object[] }
  editor_layout
  undone = true

  save = (canvasJSON?) => {
    if (!canvasJSON) {
      canvasJSON = this.root.canvasHandler.exportToCanvasJSON()
    }
    const activeObj = this.canvas.getActiveObject()

    //@ts-ignore
    this.undos.push({ canvasJSON, activeObjId: activeObj?.id ? activeObj.id : null })
    this.undone = true
    if (this.undone) {
      this.redos = []
    }
    this.isFlushed = false
    this.emitHistoryChanged()
  }

  flushed(){
    this.isFlushed = true
  }

  clear = () => {
    this.redos = []
    this.undos = []
    this.emitHistoryChanged()
  }

  undo = throttle(() => {
    this.undone = false
    const canvasJSON = this.root.canvasHandler.exportToCanvasJSON()
    const undo = this.undos.pop()
    if (!undo) {
      return
    }
    const activeObj = this.canvas.getActiveObject()
    //@ts-ignore
    this.redos.push({ canvasJSON, activeObjId: activeObj?.id ? activeObj?.id : null })
    this.replay(undo)
  }, 100)

  redo = throttle(() => {
    const canvasJSON = this.root.canvasHandler.exportToCanvasJSON()
    const redo = this.redos.pop()
    if (!redo) {
      return
    }

    const activeObj = this.canvas.getActiveObject()
    //@ts-ignore
    this.undos.push({ canvasJSON, activeObjId: activeObj?.id ? activeObj.id : null })
 
    this.replay(redo)
  }, 100)

  replaceType = function(obj){
    obj.previousType = obj.type
    switch (obj.type) {
      case ObjectType.BAZAART_BG:
        obj.type = ObjectType.BACKGROUND_IMAGE
        break
      case ObjectType.BAZAART_TEXT:
        obj.type = ObjectType.STATIC_TEXT
        break
      case ObjectType.BAZAART_STICKER:
      case ObjectType.BAZAART_IMAGE:
      case ObjectType.BAZAART_SHAP:
      case ObjectType.BAZAART_DRAWING:
      case ObjectType.BAZAART_OVERLAY:
        obj.type = ObjectType.STATIC_IMAGE
        break
      case ObjectType.GROUP:
        obj.objects = obj.objects.map((obj: any) => {
          return this.replaceType(obj)
        })
        break
    }
    return obj
  }

  replay = async transaction => {
    this.active = true
    transaction.objects = transaction.canvasJSON.objects.map((obj: any) => {
      return this.replaceType(obj)
    })
    const currentFrame = this.root.frameHandler.get()

    this.canvas.loadFromJSON(transaction,
      ()=>{

      this.emitHistoryChanged(true)
      const getObjects = this.canvas.getObjects()
      getObjects.forEach((o: any, index) => {
        if(o.type === ObjectType.BAZAART_IMAGE && o.isTemplateLayer) {
          this.root.objectsHandler.handleDarkLayer(o)
        }
        if(o.id?.includes('replace')) {
          this.root.objectsHandler.addReplaceIcon(getObjects[index - 1], false)
        }
      })
      
      setTimeout(() => {
        const getObjs = this.canvas.getObjects()
        let activeObjectExsit = false
        getObjs.forEach((o: any) => {
          o.set('dirty', true);

          //@ts-ignore
          if (o.id === transaction.activeObjId) {
              activeObjectExsit = true
              this.canvas.setActiveObject(o)
          }
          // update undo date
          for (let mediaType of allMediaImageTypeValues){
            MediaImageRepository.getInstance().restoreUndo(o.bazaartGuid, o.layerAssetStateId, mediaType);
          }
        });

        if(!activeObjectExsit){
          this.context.setActiveObject(null)
        }

        if ((this.root.frameHandler.get().width !== currentFrame.width || this.root.frameHandler.get().height !== currentFrame.height)) {
          this.root.frameHandler.adjustLayers(this.root.frameHandler.get().width, this.root.frameHandler.get().height)
          this.root.frameHandler.scaleImageBgFill()
          this.root.zoomHandler.zoomToFit()
        }

        this.canvas.renderAll()
        this.active = false
        this.isFlushed = false
      }, 0)
    }
    ,(o, object) => {
      if (object.type === ObjectType.BAZAART_BG) {
        this.root.frameHandler.setImageLatest(object, this.canvas)
      }
      if(object.type === ObjectType.GROUP) {
        object.getObjects().map(obj => obj.set('type', obj.previousType))
      } else {
        object.set('type', o.previousType)
      }
  
    })
  }

  getAll = () => {
    return {
      undos: this.undos,
      redos: this.redos,
      state: this.state,
    }
  }

  fireWithoutSave = type => {
    this.editorEventManager.emit(type, {})
  }

  deleteReplaceableLayerHistory(layer) {
    this.undos = this.undos.map(undo => {
      const updatedCanvasJSON = {
        ...undo.canvasJSON,
        objects: undo.canvasJSON.objects.map((obj, index, array) => {
          if (layer.id === obj.id && obj.type === ObjectType.BAZAART_IMAGE && obj.isTemplateLayer && obj.hasReplaceableLayer) {
            return { ...obj, effects: {
              hasFillImage: false
            }, transformation: {
              horizontalFlip: false,
              verticalFlip: false,
            },};
          }
          if (obj.id === `${layer.id}replace`) {
            return null;
          }
          return obj;
        }).filter(Boolean), // Filter out null values (removed 'icon' types)
      };
      return { ...undo, canvasJSON: updatedCanvasJSON };
    })

    this.redos.map(redo => {
      const updatedCanvasJSON = {
        ...redo.canvasJSON,
        objects: redo.canvasJSON.objects.map((obj, index, array) => {
          if (layer.id === obj.id && obj.type === ObjectType.BAZAART_IMAGE && obj.isTemplateLayer && obj.hasReplaceableLayer) {
            return { ...obj, effects: {
              hasFillImage: false
            }, transformation: {
              horizontalFlip: false,
              verticalFlip: false,
            }};
          }
          if (obj.id === `${layer.id}replace`) {
            return null;
          }
          return obj;
        }).filter(Boolean), // Filter out null values (removed 'icon' types)
      };
      return { ...redo, canvasJSON: updatedCanvasJSON };
    })
  }

  emitHistoryChanged = (afterUndo?: boolean) => {
    let value = {
      hasUndo: this.undos.length > 0,
      hasRedo: this.redos.length > 0,
    }
    if (afterUndo) {
      value['afterUndo'] = true
    }
    this.editorEventManager.emit('history:changed', value)
  }
  destroy() {
    this.clear()
  }
}

export default TransactionHandler
