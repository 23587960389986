import { CloudFileTransfer } from './CloudFileTransfer'; // Assuming you have this module
import { CloudUrl } from './CloudUrl';
import { ProjectFileMetaData } from './ProjectFileMetaData';
import { BtDiskFileUtils } from './BtDiskFileUtils';
import { ProcessType } from './ProcessType';
import { CloudTempProject } from './CloudTempProject';

export class CloudProjectFileTransfer extends CloudFileTransfer {
    get localUploadUrl(): URL {
        return this.localDownloadUrl;
    }

    get localDownloadUrl(): URL {
        let url = new URL(CloudTempProject.baseUrl);
        const relativeComponents = this.relativePath.split('/');
        relativeComponents.forEach(component => url.pathname += `/${component}`);
        return url;
    }

    get localLinkUrl(): URL | null {
        if (!this.linkToRelativeFilePath) return null;
        let url = new URL(CloudTempProject.baseUrl);
        const relativeComponents = this.linkToRelativeFilePath.split('/');
        relativeComponents.forEach(component => url.pathname += `/${component}`);
        return url;
    }

    get cloudUrl(): CloudUrl.ExtendedStorageReference {
        const path = this.linkToRelativeFilePath ?? this.relativePath;
        return CloudUrl.create(path, this.deviceId);
    }

    static clearAllTempProjects() {
        try {
            BtDiskFileUtils.deleteFiles([CloudTempProject.baseUrl.pathname]);
        } catch (error) {
            console.error(`Failed to delete temp projects folder: ${error.message}`);
        }
    }

    private fileMetaData: ProjectFileMetaData | null = null;

    constructor(file: ProjectFileMetaData, type: ProcessType, modifiedDate: Date | null, deviceId?: string | null, shouldDelete: boolean = false) {
        super(file.relativePath, type, modifiedDate, deviceId, shouldDelete, file._link);
        this.fileMetaData = file;
    }

    get size(): number | null {
        if (this.linkToRelativeFilePath && BtDiskFileUtils.exist(this.linkToRelativeFilePath)) {
            return 0;
        }
        if (this.shouldDelete) {
            return 0;
        }
        return this.fileMetaData?.size ?? null;
    }
}
