import { FileSystemRecord } from "@/scenes/engine/objects/media-repository/VirtualFileSystem";
import { BtDiskFileUtils } from "./BtDiskFileUtils";
import { CloudTempProject } from "./CloudTempProject";

// Type alias for FileLinks
export type FileLinks = ProjectFileMetaData[];

export class ProjectFileMetaData {
    relativePath: string;
    modifiedDate: Date;
    private _size?: number;
    
    _link?: string;

    fileNumber: number = 0;

    get timeStamp(): number {
        return Math.round(this.modifiedDate.getTime() / 1000);
    }

    constructor(relativePath: string, modifiedDate: Date, size?: number, link?: string) {
        this.relativePath = relativePath;
        this.modifiedDate = modifiedDate;
        this._size = size;
        this._link = link;
    }

    static fileLinksMetaDataOutOfLocalUrls(files: [path: string, metadata: FileSystemRecord][]): FileLinks[] {
        const filesBySystemFileNumber: { [key: number]: ProjectFileMetaData[] } = {};
        files.forEach(async (file) => {
            let path = file[0]
            let metadata = file[1]

            try {
                const systemFileNumber = metadata.systemFileNumber;
                const relativePath = CloudTempProject.relativeToProjectsPath(path);
                const modifiedDate = metadata.modificationDate;
                const size = metadata.size;

                if (size === undefined || modifiedDate === undefined) {
                    console.warn(`sync: failed to get file metadata: ${relativePath}`);
                    return;
                }

                const res = new ProjectFileMetaData(relativePath, modifiedDate, size, undefined);
                res.fileNumber = systemFileNumber || 0;

                if (systemFileNumber !== undefined) {
                    if (!filesBySystemFileNumber[systemFileNumber]) {
                        filesBySystemFileNumber[systemFileNumber] = [];
                    }
                    filesBySystemFileNumber[systemFileNumber].push(res);
                } else {
                    console.warn(`sync: failed to get file system number of file: ${relativePath}`);
                    return;
                }
            } catch (error) {
                console.warn(`sync: failed to get file metadata: ${path}, error: ${error.message}`);
                return;
            }
        });

        let results = Object.values(filesBySystemFileNumber);
        return results
    }

    link(file: ProjectFileMetaData): void {
        this._link = file.relativePath;
        this._size = 0;
    }

    get isHardLink(): boolean {
        return this._link !== undefined;
    }

    // Encoding and decoding logic
    static fromJSON(data: any): ProjectFileMetaData {
        const json =  (typeof data === 'string') ? JSON.parse(data as string) : data;
        return new ProjectFileMetaData(
            json.relativePath,
            new Date(json.modifiedDate),
            json.size,
            json.link
        );
    }

    toJSON(): any {
        return {
            "bazaart.ProjectFileMetaData": {
                relativePath: this.relativePath,
                size: this._size,
                modifiedDate: this.modifiedDate.toISOString(),
                link: this._link,
                fileNumber: this.fileNumber
            }
        };
    }

    toString(): string {
        // return `File: ${this.relativePath}, size: ${this._size ? URL.formattedSize(this._size) : 'missing'}, modified date: ${this.modifiedDate}, is link: ${this.isHardLink}, fileId: ${this.fileNumber}`;
        // TODO DROR : handle URL.formattedSize
        return 
    }

    get size() : number {
        return this._size ?? 0;
    }

    set size(value: number) {
        this._size = value;
    }

    linkTo(file: ProjectFileMetaData) {
        this._link = file.relativePath;
        this.size = 0
    }
}
