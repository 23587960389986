import firebase from "firebase/app";

import "firebase/auth";
import { CloudManager } from "./CloudManager";
import { UserDevice } from "./UserDevice";
import { ref as storageRef, StorageReference, listAll, getMetadata, deleteObject, UploadMetadata, StorageError, uploadBytesResumable, UploadTaskSnapshot, getBlob, getDownloadURL, UploadTask, StorageObserver, TaskEvent, getBytes } from 'firebase/storage';
import { Unsubscribe } from "@reduxjs/toolkit";
import { DownloadTaskSnapshot, DownloadTask } from "./DownloadTask";
import { CloudResponse, EmptyResponse, SuccesfulResponse } from "./CloudResponse";
import { ProjectFileMetaData } from "./ProjectFileMetaData";
import { ScreenshotProvider } from "./ScreenshotProvider";
import { DownloadTaskWithDisconnectionHandling } from "./DownloadTaskWithDisconnectionHandling";
import { FirebaseWrapper } from "@/firebase/FirebaseBaseWrapper";

const projectFilesListSuffix = "_files.json";

export interface StorageTask {
    cancel(): boolean;
    on(event: TaskEvent, nextOrObserver?: StorageObserver<DownloadTaskSnapshot> | null | ((snapshot: DownloadTaskSnapshot) => unknown), error?: ((a: StorageError) => unknown) | null, complete?: Unsubscribe | null): Unsubscribe;
}
  
export namespace CloudUrl {
    export interface ExtendedStorageReference extends StorageReference {
        
        logsUrl(tag: string): ExtendedStorageReference | null
        baseUrl(): string;
        userBaseUrl(): ExtendedStorageReference;
        deleteAllFiles(ref: ExtendedStorageReference): Promise<void>
        deleteFile(stroageReference: ExtendedStorageReference, filePath?: string): Promise<void>;
        allChilds(ref: ExtendedStorageReference): Promise<ExtendedStorageReference[]>;
        relativeToProjectsPath(ref: ExtendedStorageReference, deviceId?: string): string;
        exist(ref: ExtendedStorageReference): Promise<boolean>;
        create(projectsRelativePath: string, deviceId?: string): ExtendedStorageReference;
        projectIdOfProjectFilesList(ref: ExtendedStorageReference): Promise<string | null>;
        projectFilesList(projectId: string, device?: string): ExtendedStorageReference;
        deviceMetaData(device?: string): ExtendedStorageReference;
        devicesBaseUrl(): ExtendedStorageReference ;
        fontsBaseUrl(): ExtendedStorageReference ;
        deviceBaseUrl(deviceId?: string): ExtendedStorageReference ;
        projectBaseUrl(deviceId?: string): ExtendedStorageReference ;
        projectUrl(projectId: string, deviceId?: string): ExtendedStorageReference ;
        projectScreenshotUrl(projectId: string, deviceId: string): ExtendedStorageReference ;
        foldersMetaData(): ExtendedStorageReference ;
        unsafeFeedMetaData(): ExtendedStorageReference ;
        lastComponent(ref: ExtendedStorageReference): string | null;
        getDownloadUrl(ref: ExtendedStorageReference): Promise<string>;

        child(relativePath: string): ExtendedStorageReference;
        
        putJson(
            storageRef: StorageReference,
            jsonObject: object,
            metadata?: UploadMetadata,
            completion?: (snapshot: UploadTaskSnapshot | null, error: Error | null) => void
        ): StorageTask;

        putFile(
            storageRef: StorageReference,
            fileURL: string,
            metadata?: UploadMetadata,
            completion?: (snapshot: UploadTaskSnapshot | null, error: Error | null) => void
          ): Promise<StorageTask>;
        
        putFileBlob(
            storageRef: StorageReference,
            fileBlob: Blob,
            metadata?: UploadMetadata,
            completion?: (snapshot: UploadTaskSnapshot | null, error: Error | null) => void
          ): StorageTask;
          
        downloadFile(storageRef: StorageReference,
                fileURL: string,
                completion?: (fileURL: string | null, error: Error | null) => void): StorageTask;

        get(baseURl: ExtendedStorageReference, maxSize?: number): Promise<ArrayBuffer>;

        projectFileMetaData(
            cloudUrl: StorageReference,
            deviceId?: string
        ): Promise<CloudResponse<ProjectFileMetaData> | EmptyResponse>;
    }

    export async function getDownloadUrl(ref: ExtendedStorageReference): Promise<string> {
        let downloadUrl = await getDownloadURL(ref);
        return downloadUrl;
    }

    export function baseUrl(): string {
        const schemeName = "bazaart"; // Replace with actual app name logic
        return schemeName.replace(/\s+/g, "_");
    }

    export function userBaseUrl(): ExtendedStorageReference {
        const userId = CloudManager.shared.userId;
        return storageRef(FirebaseWrapper.FirebaseBaseWrapper.shared.storage , `/${this.baseUrl()}/cloud_storage/users/${userId}`) as ExtendedStorageReference;
    }

    export function devicesBaseUrl(): ExtendedStorageReference {
        return storageRef(this.userBaseUrl(), "devices") as ExtendedStorageReference;
    }

    export function fontsBaseUrl(): ExtendedStorageReference {
        return storageRef(this.userBaseUrl(), "fonts") as ExtendedStorageReference;
    }

    export function deviceBaseUrl(optionalDeviceId?: string): ExtendedStorageReference {
        let deviceId = optionalDeviceId || UserDevice.current.id
        return storageRef(this.devicesBaseUrl(), deviceId) as ExtendedStorageReference;
    }

    export function projectBaseUrl(deviceId?: string): ExtendedStorageReference {
        return storageRef(this.deviceBaseUrl(deviceId), "projects") as ExtendedStorageReference;
    }

    export function projectUrl(projectId: string, deviceId?: string): ExtendedStorageReference {
        return storageRef(this.projectBaseUrl(deviceId), projectId) as ExtendedStorageReference;
    }

    export function projectScreenshotUrl(projectId: string, deviceId: string): ExtendedStorageReference {
        const relativeUrl = ScreenshotProvider.screenshotRelativeToProject(projectId); // Adjust according to your URL module
        return storageRef(this.projectBaseUrl(deviceId), relativeUrl) as ExtendedStorageReference;
    }

    export function foldersMetaData(): ExtendedStorageReference {
        return storageRef(this.userBaseUrl(), "foldersMetaData.json") as ExtendedStorageReference;
    }

    export function unsafeFeedMetaData(): ExtendedStorageReference {
        return storageRef(this.userBaseUrl(), "unsafeFeed.json") as ExtendedStorageReference;
    }

    export function deviceMetaData(device?: string): ExtendedStorageReference {
        return storageRef(this.deviceBaseUrl(device), "syncMetaData.json") as ExtendedStorageReference;
    }

    export function projectFilesList(projectId: string, device?: string): ExtendedStorageReference {
        return storageRef(this.projectBaseUrl(device), `${projectId}${projectFilesListSuffix}`) as ExtendedStorageReference;
    }

    export async function projectIdOfProjectFilesList(ref: ExtendedStorageReference): Promise<string | null> {
        const lastComponent = ref.fullPath.split("/").pop();
        return lastComponent ? lastComponent.replace(projectFilesListSuffix, "") : null;
    }

    export function create(projectsRelativePath: string, deviceId?: string): ExtendedStorageReference {
        return storageRef(this.projectBaseUrl(deviceId), projectsRelativePath) as ExtendedStorageReference;
    }

    export function lastComponent(ref: ExtendedStorageReference): string | null {
        return ref.fullPath.split("/").pop() || null;
    }

    export function relativeToProjectsPath(ref: ExtendedStorageReference, deviceId?: string): string {
        const base = this.projectBaseUrl(deviceId).fullPath + "/";
        return ref.fullPath.replace(base, "");
    }

    export async function exist(ref: ExtendedStorageReference): Promise<boolean> {
        try {
            await getMetadata(ref);
            return true;
        } catch (error) {
            if (error.code === "storage/object-not-found") {
                return false;
            }
            throw error;
        }
    }

    export async function allChilds(ref: ExtendedStorageReference): Promise<ExtendedStorageReference[]> {
        const result: ExtendedStorageReference[] = [];
        try {
            const firstChilds = await listAll(ref);
            result.push(...firstChilds.items as ExtendedStorageReference[]);

            for (const folder of firstChilds.prefixes) {
                const items = await this.allChilds(folder);
                result.push(...items);
            }
        } catch (error) {
            throw error;
        }

        return result;
    }

    export async function deleteFile(stroageReference: ExtendedStorageReference, filePath?: string): Promise<void> {
        return new Promise((resolve, reject) => {
          const fileRef = storageRef(stroageReference, filePath);
      
          deleteObject(fileRef)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      
    export async function deleteAllFiles(ref: ExtendedStorageReference): Promise<void> {
        const urls = await this.allChilds(ref);
        console.log(`sync: deleting: ${urls.length} files under directory: ${ref.fullPath}`);
        const results = await Promise.all(
            urls.map(async (url) => {
                try {
                    await deleteObject(url);
                    return { success: true };
                } catch (error) {
                    return { success: false, error: error.message };
                }
            })
        );

        const errors = results.filter((result) => !result.success);
        if (errors.length > 0) {
            throw new Error(errors.map((e) => e.error).join(", "));
        }
    }
    
    export function child(url: ExtendedStorageReference, relativePath: string): ExtendedStorageReference{
        return storageRef(url, relativePath) as ExtendedStorageReference
    }

    export function putJson(
        storageRef: StorageReference,
        jsonObject: object,
        metadata?: UploadMetadata,
        completion?: (snapshot: UploadTaskSnapshot | null, error: Error | null) => void
    ): StorageTask {
        // Convert the JSON object to a Blob
        const jsonBlob = new Blob([JSON.stringify(jsonObject)], { type: 'application/json' });
    
        // Use the putFile function to upload the JSON Blob
        return putFileBlob(storageRef, jsonBlob, metadata, completion);
    }
    
    export async function putFile(
        storageRef: StorageReference,
        fileUrl: string,
        metadata?: UploadMetadata,
        completion?: (snapshot: UploadTaskSnapshot | null, error: Error | null) => void
      ): Promise<StorageTask> {
        try {
            const blob = await urlToBlob(fileUrl);
            // Assign the task after the blob is created
            let task = putFileBlob(storageRef, blob, metadata, completion);
            return task
        } catch (error) {
            console.error(`Failed to read url ${fileUrl} with herror Error: ${error}`);
            if (completion) {
                completion(null, error);
            }
            return Promise.resolve(null)
        }
      }

    export function putFileBlob(
        storageRef: StorageReference,
        fileBlob: Blob,
        metadata?: UploadMetadata,
        completion?: (snapshot: UploadTaskSnapshot | null, error: Error | null) => void
      ): StorageTask {
          // Create the upload task
          const uploadTask = uploadBytesResumable(storageRef, fileBlob, metadata);
      
          // Listen for state changes, errors, and completion of the upload
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Handle state changes here if needed (progress updates, etc.)
            },
            (error: StorageError) => {
              // Handle unsuccessful uploads
              completion?.(null, error);
            },
            () => {
              // Handle successful uploads
              getMetadata(uploadTask.snapshot.ref).then((metadata) => {
                completion?.(uploadTask.snapshot, null);
              }).catch((error: Error) => {
                completion?.(null, error);
              });
            }
          );
          return uploadTask;
      }
      
      export function downloadFile(
        storageRef: StorageReference,
        fileURL: string,
        completion?: (fileURL: string | null, error: Error | null) => void
      ): StorageTask {
        let downloadTask = new DownloadTaskWithDisconnectionHandling(storageRef);
        let errorHandler: StorageObserver<DownloadTaskSnapshot> = {
          error: (error: StorageError) => {
            completion(null, error);
          }
        }
        downloadTask.on('state_changed' as TaskEvent, errorHandler);
        downloadTask.startDownload(fileURL, completion)
        return downloadTask;
      }

      export async function get(baseURL: ExtendedStorageReference, maxSize?: number): Promise<ArrayBuffer> {
        try {
          const data = await getBytes(storageRef(baseURL), maxSize);
          return data;
        } catch (error) {
          if (error instanceof Error) {
            // Check if the error is due to exceeding maxSize
            if (error.message.includes("max allowed size")) {
              throw new Error(`Download failed: file size exceeds max size of ${maxSize} bytes`);
            }
          }
          // Re-throw the original error if it's not a maxSize issue
          throw error;
        }
      }

      export async function projectFileMetaData(
        cloudUrl: StorageReference,
        deviceId?: string
      ): Promise<CloudResponse<ProjectFileMetaData> | EmptyResponse> {
        try {
          let relativeUrl = this.relativeToProjectsPath(deviceId)
          const cloudMetadata = await getMetadata(relativeUrl); // Replace with actual call to get metadata
          const cloudDate = cloudMetadata.timeCreated;
    
          if (!cloudDate) {
            return EmptyResponse.sendError(`sync: missing creation date of cloud file`);
          }
    
          const size = cloudMetadata.size > 0 ? cloudMetadata.size : null;
    
          const metaData = new ProjectFileMetaData(
            relativeUrl.fullPath,
            new Date(cloudDate),
            size,
            null,
          );
    
          return new SuccesfulResponse(metaData);
        } catch (error) {
            return EmptyResponse.sendError(`sync: couldn't receive cloud file metadata: ${error}`);
        }
    }
}

async function urlToBlob(url: string): Promise<Blob> {
    try {
        // Fetch the file from the URL
        const response = await fetch(url);

        // Check if the response is OK (status 200-299)
        if (!response.ok) {
            throw new Error(`Failed to fetch the file. Status: ${response.status}`);
        }

        // Convert the response to a Blob
        const blob = await response.blob();

        return blob;
    } catch (error) {
        console.error('Error converting URL to Blob:', error);
        throw error;
    }
}
