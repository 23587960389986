export interface BtDraftMigratorResult  {
    success: boolean;
}

export class BtDraftMigrator {
    async asyncMigrate(project: any): Promise<BtDraftMigratorResult> {
        let result = {
            success: true
        }
        return Promise.resolve(result);
    }
}