import { fabric } from 'fabric'
import { CanvasOptions, CanvasTextLayer, HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'
import * as _ from 'lodash'
import { ObjectType } from '../common/constants'
import { DateProvider } from '@/firebase/services/DateProvider'
import { CloudTempProject } from '@/firebase/services/sync/CloudTempProject'
import { DraftSyncItem } from '@/firebase/services/sync/DraftSyncItem'
import { DraftSyncVersion } from '@/firebase/services/sync/DraftSyncVersion'
import { ScreenshotProvider } from '@/firebase/services/sync/ScreenshotProvider'
import { SyncManager } from '@/firebase/services/sync/SyncManager'
import { FontModel, IFontFamily, TemplateConfig } from '@/interfaces/editor'
import { MediaImageRepository } from '../objects/media-repository/media_image_repository'
import { MediaImageType } from '../objects/media-repository/media_image_type'
import { VirtualFileSystem } from '../objects/media-repository/VirtualFileSystem'
import { BtDiskFileUtils } from '@/firebase/services/sync/BtDiskFileUtils'
import { BtDraftsManager } from '@/firebase/services/sync/BtDraftsManager'
import { setTemplateConfig } from '@/store/slices/templates/actions'
import { nanoid } from 'nanoid'
import { RepositoryToUrlMap } from '../objects/media-repository/repositoryToUrlMap'
import store from '@/store/store'
import CanvasImageRenderer from '../utils/canvasImageRenderer'
import { Rectangle } from '../objects/media-repository/rectangle'
import { Size } from '../objects/media-repository/size'
import { Inset } from '../objects/media-repository/inset'
import { CanvasLayerShadowEffect } from '@/interfaces/CanvasLayerShadowEffect'
import { CanvasLayerOutlineEffect } from '@/interfaces/CanvasLayerOutlineEffect'
import { ResizeMode, ResizeOptions } from '../objects/media-repository/media_image_repository_processing'


class ProjectHandler extends BaseHandler {

  constructor(props: HandlerOptions) {
    super(props)
  }

  async saveProject(): Promise<string | null> {

    // get json layers
    let layers = await this.root.templateHandler.exportToJSON()
    
    let canvasSize = this.root.frameHandler.getSize()
    let objects = this.root.canvasHandler.canvas.getObjects()
    let allFonts = store.getState().editor.fonts.fonts;
    let selectedFonts: FontModel[] = [];

    const renderLatestCanvas = document.createElement('canvas') as HTMLCanvasElement;
    let renderLatestCanvasCtx = renderLatestCanvas.getContext('2d');
    for (let layerWrapper of layers) {
      let layer = Object.values(layerWrapper)[0]

      // @ts-ignore
      let object = objects.find(o => o.id === layer.bazaartGuid || o.bazaartGuid === layer.bazaartGuid)

      // 2. reset uneeded properties
      // @ts-ignore
      delete layer.src

      // @ts-ignore
      if (object.type == ObjectType.BAZAART_TEXT) {
        await this.exportTextLayer(layer, object)
        let font = this.extractFontFromTextLayer(layer, allFonts)
        selectedFonts.push(font);
      } else {
        await this.exportImageLayerImageAssets(layer, object, canvasSize, renderLatestCanvas, renderLatestCanvasCtx)
      }
    }

    let project = this.getOrCreateProject(selectedFonts)
    let projectId = project.draftGuid

    let foundSyncItem = SyncManager.shared.draftSyncItems.find(i => i.mostUpdatedVersion.draftId == projectId)
    if (!foundSyncItem) {
      let draftSyncVersion = DraftSyncVersion.fromDraft(project)
      let syncItem = new DraftSyncItem(draftSyncVersion)
      syncItem.merge()

      SyncManager.shared.draftSyncItems.splice(0, 0, syncItem)
    }


    // 1. save screenshot
    // @ts-ignore
    const image_png = (await this.root.designHandler.toDataURL(null, true)) as unknown as string
    await ScreenshotProvider.saveScreenshot(projectId, image_png)

    let projectForUpload = await this.saveCloudTempProject(project, layers);

    // 4. save project locally
    await SyncManager.shared.didSaveProject(projectForUpload)

    // 5. upload project to cloud
    let success = await SyncManager.shared.uploadProject(projectForUpload)

    if (success) {
      // End sync project
      this.root.transactionHandler.flushed()
      return projectId
    } 
    
    return null;
  }
 
  private extractFontFromTextLayer(layer: any, allFonts: IFontFamily[]): FontModel | null {
    let textLayer = layer as CanvasTextLayer;
    let layerFont = textLayer.textProperties.attributedText.runs[0].attributes.NSFont.systemName
    let foundFontFamily = allFonts.find(font => font.family == layerFont);
    if (!foundFontFamily) {
      return null;
    }
    
    const fontFile = foundFontFamily.files['regular']
    let foundFondModel: FontModel = {
      kId: foundFontFamily.id,
      kPackId: parseInt(foundFontFamily.id),
      keyFileURL: fontFile,
      keyName: foundFontFamily.family,
      keyNickName: foundFontFamily.name,
      keySystemName: foundFontFamily.family,
      isCustomFont: false,
      fileURL: fontFile
    }; 
    
    return foundFondModel;
  }

  private saveCloudTempProject = async (project: TemplateConfig, layers: any[]): Promise<TemplateConfig> => {
    // 3. create temp project and link to it
    let tempProject = new CloudTempProject(project.draftGuid)

    const layersString = JSON.stringify(layers);
    // Step 2: Create a Blob from the JSON string
    const layersBlob = new Blob([layersString], { type: 'application/json' });
    await VirtualFileSystem.getInstance().writeBlob(tempProject.configLayersPath, layersBlob)
    let layersBlobUrl = VirtualFileSystem.getInstance().get(tempProject.configLayersPath)
    console.log(layersBlobUrl);

    let projectForUpload = { ...project };
    if (project.draftCreationDate && !(project.draftCreationDate instanceof Date)) {
      projectForUpload.draftCreationDate = new Date(project.draftCreationDate)
    }
    if (project.draftDate && !(project.draftDate instanceof Date)) {
      projectForUpload.draftDate = new Date(project.draftDate)
    }
    if (project.screenshotDate && !(project.screenshotDate instanceof Date)) {
      projectForUpload.screenshotDate = new Date(project.screenshotDate)
    }
    projectForUpload.draftModificationDate = DateProvider.getUTCDate();

    const configString = JSON.stringify({ 'BtDraftObject': projectForUpload });
    // Step 2: Create a Blob from the JSON string
    const configBlob = new Blob([configString], { type: 'application/json' });
    await VirtualFileSystem.getInstance().writeBlob(tempProject.configProjectPath, configBlob)

    return projectForUpload
  }


  private exportImageLayerImageAssets = async (layer: any, object: any, frameSize: Size, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    await this.exportImageLayerLatest(layer, object, frameSize, canvas, ctx);

    const fallbackNoMask = async (width: number, height: number): Promise<HTMLImageElement> => {
      // default black image
      maskSrc = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.createColorImage('#000000', width, height)
      await MediaImageRepository.getInstance().storeImageBlobString(layer.bazaartGuid, assetStateId, MediaImageType.mask, maskSrc);
      
      let maskPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, assetStateId, MediaImageType.mask)
      let fittedMaskPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, assetStateId, MediaImageType.fittedMask)

      await VirtualFileSystem.getInstance().link(maskPath, fittedMaskPath);
      
      layer.boundingBox = {
        x: 0,
        y: 0,
        width: 1,
        height: 1
      };

      return await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(maskSrc);
    }
    
    let assetStateId = object.layerAssetStateId;

    // hard link fullRes
    let originalPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, assetStateId, MediaImageType.original)
    let fullResPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, assetStateId, MediaImageType.fullRes);
    
    let originalSrc = await MediaImageRepository.getInstance().getImage(layer.bazaartGuid, assetStateId, MediaImageType.original);
    let maskSrc = await MediaImageRepository.getInstance().getImage(layer.bazaartGuid, assetStateId, MediaImageType.mask);

    // if there's no original image asset just make the latest the only image
    if (!originalSrc) {
      let latestPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, assetStateId, MediaImageType.latest)
      let fittedPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, assetStateId, MediaImageType.fitted)
      
      await VirtualFileSystem.getInstance().link(latestPath, fittedPath);
      await VirtualFileSystem.getInstance().link(latestPath, fullResPath);
      await VirtualFileSystem.getInstance().link(latestPath, originalPath);

      let latestImageSrc = await MediaImageRepository.getInstance().getImage(layer.bazaartGuid, assetStateId, MediaImageType.latest);
      let latestImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(latestImageSrc);

      await fallbackNoMask(latestImage.width, latestImage.height)

      return
    }
    
    await VirtualFileSystem.getInstance().link(originalPath, fullResPath);

    let original = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(originalSrc);
    let mask = maskSrc 
      ? await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(maskSrc)
      : await fallbackNoMask(original.width, original.height);
    
    let hasFitted = !!(await MediaImageRepository.getInstance().getImage(layer.bazaartGuid, assetStateId, MediaImageType.fitted));
    if (!hasFitted) {
      let roi = new Rectangle(
        layer.boundingBox.x * original.width,
        layer.boundingBox.y * original.height,
        layer.boundingBox.width * original.width,
        layer.boundingBox.height * original.height
      );  
      let fitted = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(original, roi);
      await MediaImageRepository.getInstance().storeImageBlobString(layer.bazaartGuid, assetStateId, MediaImageType.fitted, fitted.src);
    }

    let hasFittedMask = !!(await MediaImageRepository.getInstance().getImage(layer.bazaartGuid, assetStateId, MediaImageType.fittedMask));
    if (!hasFittedMask) {
      let roi = new Rectangle(
        layer.boundingBox.x * mask.width,
        layer.boundingBox.y * mask.height,
        layer.boundingBox.width * mask.width,
        layer.boundingBox.height * mask.height
      );
      let fittedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(mask, roi);
      await MediaImageRepository.getInstance().storeImageBlobString(layer.bazaartGuid, assetStateId, MediaImageType.fittedMask, fittedMask.src);
    }
  }

  private exportImageLayerLatest = async (layer: any, object: any, frameSize: Size, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
    const hasAdjustments = !CanvasImageRenderer.getInstance().isAdjustDefault(layer.adjustments);
    const hasShadow = layer.effects && layer.effects.shadow && ((layer.effects.shadow.opacity !== 0))
    const hasOutline = layer.effects && layer.effects.outline && layer.effects.outline.thickness !== 0
    
    let needsLatest = hasAdjustments || hasShadow || hasOutline
    if (needsLatest) {
      let imageWithEffects = object;

      let imageWithEffectInBase64 = imageWithEffects._element.src;
      if (!imageWithEffectInBase64) {
        imageWithEffectInBase64 = await this.fixOffsetingBlur(layer, imageWithEffects, canvas, ctx);
      }
      
      if (object.type == ObjectType.BAZAART_BG || object.type == ObjectType.BAZAART_OVERLAY) {
        imageWithEffectInBase64 = await this.aspectFitImage(imageWithEffectInBase64, frameSize);
      }

      let storeLatestP = MediaImageRepository.getInstance().storeImageBlobString(
        layer.bazaartGuid,
        layer.assetStateId,
        MediaImageType.latest,
        imageWithEffectInBase64
      )
      await Promise.all([storeLatestP]);
    }
  }

  private async aspectFitImage(image: string, frameSize: Size): Promise<string>{
    let resizeOptions: ResizeOptions = {
      allowUpsampling: true,
      exportType: 'image/png',
      pad: false,
      resizeMode: ResizeMode.aspectFill
    }
    let aspectFitImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBlob(image, frameSize, null, resizeOptions)
    return aspectFitImage;
  }

  private async padImageIfNeeded(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    shadowDirection: any,
    rect: Rectangle,
    inset: Inset,
    w: number,
    h: number,
  ): Promise<HTMLImageElement> {

    let offsetX = shadowDirection.x > 0 ? 0 : inset.left + rect.x;
    let offsetY = shadowDirection.y > 0 ? 0 : inset.top + rect.y;

    let targetW = w + Math.abs(offsetX);
    let targetH = h + Math.abs(offsetY);

    canvas.width = targetW;
    canvas.height = targetH;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      image,
      0, 0,
      w,
      h,
      Math.abs(offsetX),
      Math.abs(offsetY),
      w,
      h
    )

    let base64 = canvas.toDataURL()
    let nextImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(base64);
    return nextImage;
  }

  private suggestNewSize(inputSize: Size): Size {
    let maxEdge = 1280;
    let aspectRatio = inputSize.width / inputSize.height
    let targetSize = inputSize;

    if (aspectRatio > 1) {
      targetSize.width = maxEdge
      targetSize.height = maxEdge / aspectRatio
    } else {
      targetSize.width = maxEdge * aspectRatio
      targetSize.height = maxEdge
    }
    return targetSize;
  }

  private async fixOffsetingBlur(
    layer: any,
    imageWithEffects: any,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ): Promise<string> {
    // notice that source image is distorted, we solve it when displaying the image by rendering it with correct aspect ratio
    

    let nextImage = imageWithEffects._element;
    
      
    let w = imageWithEffects._element.width;
    let h = imageWithEffects._element.height;
    
    let size = this.suggestNewSize(new Size(w / imageWithEffects._filterScalingX, h / imageWithEffects._filterScalingY))
    
    canvas.width = size.width;
    canvas.height = size.height;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      nextImage,

      0,
      0,
      w,
      h,
      0,
      0,
      canvas.width,
      canvas.height
    )

    nextImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(canvas.toDataURL());
    let sourceInset = imageWithEffects.filters.reduce((accum, f) => Inset.combine(accum, f._inset), new Inset(0, 0, 0, 0))
    let inset = sourceInset.mulitply(size.width / w * imageWithEffects._filterScalingX, size.height / h * imageWithEffects._filterScalingY)
    
    let targetInputBounds = new Rectangle(
      0, 0,
      size.width - (inset.left + inset.right),
      size.height - (inset.top + inset.bottom),
    );

    let outlineEffects = new CanvasLayerOutlineEffect();
    let targetSizeWithOutline = outlineEffects.applyEffectBounds(layer, targetInputBounds);
    let shadowEffects = new CanvasLayerShadowEffect();
    let targetBoundsWithShadow = shadowEffects.applyEffectBounds(layer, targetSizeWithOutline);

    let targetBounds = targetBoundsWithShadow

    let originalImageRect = targetBoundsWithShadow;
    let targetImageRect = new Rectangle(0, 0, targetBounds.width, targetBounds.height);

    let shadowAngle = layer.effects.shadow?.angle ?? 0
    let shadowDirection = {
      x: Math.cos(shadowAngle) < 0 ? - 1 : 1,
      y: Math.sin(shadowAngle) < 0 ? - 1 : 1
    };

    nextImage = await this.padImageIfNeeded(nextImage, canvas, ctx, shadowDirection, targetBoundsWithShadow, inset, size.width, size.height);

    if (shadowDirection.x > 0) {
      let suggestedX = inset.left + targetBoundsWithShadow.x;
      originalImageRect.x = suggestedX;

      let exceedingWidth = Math.min(0, size.width - suggestedX - originalImageRect.width);
      originalImageRect.width = originalImageRect.width + exceedingWidth;
    } else {
      originalImageRect.x = 0;
    }

    if (shadowDirection.y > 0) {
      let suggestedY = inset.top + targetBoundsWithShadow.y;
      originalImageRect.y = suggestedY;

      let exceedingHeight = Math.min(0, size.height - suggestedY - originalImageRect.height);
      originalImageRect.height = originalImageRect.height + exceedingHeight;
    } else {
      originalImageRect.y = 0;
    }

    canvas.width = targetImageRect.width + Math.abs(targetImageRect.x);
    canvas.height = targetImageRect.height + Math.abs(targetImageRect.y);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      nextImage,

      Math.max(0, originalImageRect.x),
      Math.max(0, originalImageRect.y),
      originalImageRect.width,
      originalImageRect.height,

      targetImageRect.x,
      targetImageRect.y,
      targetImageRect.width,
      targetImageRect.height
    )

    let base64 = canvas.toDataURL()

    function openBase64InNewTab(data: string, mimeType: string) {
      const newTab = window.open();
      newTab.document.write(`<img src="${data}" alt="Image"/>`);
      newTab.document.close();
    }

    // openBase64InNewTab(base64, 'image/png')

    return base64;
  }

  private exportTextLayer = async (layer, object) => {
    let latestTextImageInBase64 = await this.getImageText(object)
    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(latestTextImageInBase64)
    let maskTextImageInBase64 = maskInfo.base64;

    let color = layer.textProperties.textColorHex
    let fullResImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.createColorImage(color, maskInfo.size.width, maskInfo.size.height)

    let storeLatestP = MediaImageRepository.getInstance().storeImageBlobString(
      layer.bazaartGuid,
      layer.assetStateId,
      MediaImageType.latest,
      latestTextImageInBase64
    )

    let storeOriginalP = MediaImageRepository.getInstance().storeImageBlobString(
      layer.bazaartGuid,
      layer.assetStateId,
      MediaImageType.original,
      fullResImage
    )

    let storeMaskP = MediaImageRepository.getInstance().storeImageBlobString(
      layer.bazaartGuid,
      layer.assetStateId,
      MediaImageType.mask,
      maskTextImageInBase64
    )

    await Promise.all([storeLatestP, storeOriginalP, storeMaskP]);

    let maskPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, layer.assetStateId, MediaImageType.mask)
    let fittedMaskPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, layer.assetStateId, MediaImageType.fittedMask)
    await VirtualFileSystem.getInstance().link(maskPath, fittedMaskPath)


    let fullResPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, layer.assetStateId, MediaImageType.fullRes)
    let originalPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, layer.assetStateId, MediaImageType.original)
    let fittedPath = await MediaImageRepository.getInstance().getImagePath(layer.bazaartGuid, layer.assetStateId, MediaImageType.fitted)

    await VirtualFileSystem.getInstance().link(originalPath, fullResPath)
    await VirtualFileSystem.getInstance().link(originalPath, fittedPath)
  }

  private getImageText = async (text): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      text.clone(function (clonedText) {
        clonedText.set({
          angle: 0,
          arcAngle: 0,
          path: null,
          clipPath: null,
        });

        clonedText.cloneAsImage(async (img) => {
          try {
            const dataURL = img.toDataURL({ format: 'image/png' })
            let bb = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractBoundingBox(dataURL, false)
            let resizedHtmlImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(dataURL)
            let croppedHtmlImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(resizedHtmlImage, bb);
            resolve(croppedHtmlImage.src)
          } catch (error) {
            console.error('failed to clone text with error ' + error);
          }
        });
      });
    })
  }

  private getOrCreateProject = (fonts: FontModel[]): TemplateConfig | null => {
    const setFrameSize = (project: TemplateConfig) => {
      let frameHandler = this.root.frameHandler.get()
      if (!frameHandler) {
        return;
      }
      project.originalCanvasSize = {
        width: frameHandler.width,
        height: frameHandler.height
      }
    }

    let templateConfig = store.getState().editor.templates.templateConfig
    const frame = this.root.frameHandler.get()
    // @ts-ignore
    const hasOpacity = !!(frame && frame.fill && frame.fill.id)
    if (templateConfig) {
      let syncItem = SyncManager.shared.getSyncItem(templateConfig.draftGuid)
      if (syncItem) {
        let updatedTemplateConfig = { ...templateConfig }
        updatedTemplateConfig.fonts = fonts;
        updatedTemplateConfig.hasOpacity = hasOpacity
        setFrameSize(updatedTemplateConfig)
        store.dispatch(setTemplateConfig(updatedTemplateConfig))
        return updatedTemplateConfig;
      }
    }

    let newProjectId = nanoid();

    let project = { ...BtDraftsManager.createDraft(newProjectId) }
    project.fonts = fonts;
    project.hasOpacity = hasOpacity
    setFrameSize(project)
    store.dispatch(setTemplateConfig(project))

    const oldProjectId = MediaImageRepository.getInstance()._filesystem.projectId
    if (oldProjectId) {
      BtDiskFileUtils.linkFiles([RepositoryToUrlMap.getLayersFolder(oldProjectId)], [RepositoryToUrlMap.getLayersFolder(newProjectId)])
    }
    MediaImageRepository.getInstance()._filesystem.projectId = newProjectId;

    return project
  }
}

export default ProjectHandler