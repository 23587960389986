import { lightTheme } from '@/customTheme'
import useAppContext from '@/hooks/useAppContext'
import { styled, ThemeProvider } from 'baseui'
import ModalUnsuportType from './ModalUnsuportType'
import IntroVideo from '../IntroVideo'
import ModalDiscardProject from './ModalDiscardProject'
import ModalDeleteProject from './ModalDeleteProject'
import ModalDownloadProject from './ModalDownloadProject'
import ModalDownloadApp from './ModalDownloadApp'
import ModalTutorial from './ModalTutorial'
import { ShowModalType } from '@/scenes/engine/common/constants'
import ModalGoodWork from './ModalGoodWork'
import ModalProjectNotSupport from './ModalProjectNotSupport'
import ModalProjectSyncing from './ModalProjectSyncing'
import ModalProjectOpenFailed from './ModalProjectOpenFailed'
import ModalProjectConflict from './ModalProjectConflict'
import ModalProjectAlreadyOpen from './ModalProjectAlreadyOpen'
import ModalProjectDoesntExist from './ModalProjectDoesntExist'
import ModalSaveProject from './ModalSaveProject'
import Onboarding from './Onboarding'
import ModalConfirmSignOut from './ModalConfirmSignOut'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectOpenModalTryPremium, selectUser, selectUserIsPremium } from '@/store/slices/user/selectors'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import modalStyles from '@/scenes/Editor/components/Modal/Modal.module.css'
import ScaledAppearanceModal from '@/scenes/Editor/components/Modal/ScaledAppearanceModal'
import ModalTryPremium from '@/scenes/Editor/components/Navbar/components/SignIn/ModalTryPremium'
import { SignInManager } from '@/scenes/Editor/components/Navbar/components/SignInManager'
import Cookies from 'universal-cookie'
import SignIn from '@/scenes/Editor/components/Navbar/components/SignIn/SignIn'
import ModalNoInternet from './ModalNoInternet'
import ModalWhatNew from './ModalWhatNew'
import { customAmplitude } from '@/utils/customAmplitude'
import { useAppDispatch } from '@/store/store'
import { useLocation } from 'react-router-dom'

const USE_ANIMATIONS = false

function ShowModal() {  
  const { isOpenPixelManipulationObject, setIsLoadedJsonSuccess, isLoadedJsonSuccess, showModalType, setShowModalType } = useAppContext()
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const hasPremium = useSelector(selectUserIsPremium)
  const [isLoggined, setIsLoggined] = useState(false)
  const user = useSelector(selectUser)
  const dispatch = useAppDispatch()
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      setIsLoggined(false)
    }
  }, [user])

  useEffect(() => {
    function getUser() {
      let cookies = new Cookies()
      let userInfo = cookies.get('userInfo')
      if (userInfo) {
        setIsLoggined(true)
    }
    getUser()
  }}, [])

  const handleOpenTutorial = () => {
    // open good work modal again
    if(isOpenPixelManipulationObject && (!SignInManager.getInstance().userId || !hasPremium)) {
      setShowModalType(ShowModalType.GOOD_WORK_MODAL)
      return
    }
    let showedRemoveObjectTutorial = localStorage.getItem('showedRemoveObjectTutorial') ? JSON.parse(localStorage.getItem('showedRemoveObjectTutorial')) : false
    if(!showedRemoveObjectTutorial || !showedRemoveObjectTutorial.isOpened) {
      setShowModalType(ShowModalType.QUICK_TUTORIAL)
    } 
  }

  const showWrapModal = (showModalType == ShowModalType.SIGN_IN && isLoadedJsonSuccess) || openModalTryPremium.isOpen || showModalType == ShowModalType.QUICK_TUTORIAL || showModalType == ShowModalType.ONBOARDING || showModalType === ShowModalType.WHAT_NEW
  const onCloseOnboarding = () => {
    if (!SignInManager.getInstance().userIsPremium && !openModalTryPremium.opened) {
      
      let source = location.pathname.includes('create') ? 'Create' : 'Editor Open'

      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: source,
          opened: true,
        })
      )

      const eventProperties = {
        Source: source,
        Type: 'Standard',
      }
      customAmplitude('Premium Prompt', eventProperties)
      // @ts-ignore
      window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
    }
  }

  const WrapModal = styled('div', (props: any) => ({
    position: 'absolute',
    pointerEvents: showWrapModal ? 'auto' : 'none',
    inset: 0,
    background: 'rgba(0,0,0,0.5)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    alignItems: 'center',
    '::-webkit-scrollbar': {
      width: '8px',
      margin: '20px 0',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: lightTheme.colors.grayScale100,
      minHeight: '100px ',
    },
    '::-webkit-scrollbar-thumb:hover': {
      cursor: 'pointer',
      backgroundColor: lightTheme.colors.grayScale150,
    },
    '@media screen and (max-height: 652px)': {
      alignItems: 'start',
    },
    ...props.style,
  }))

  return (
    <ThemeProvider theme={lightTheme}>
      {/* Modals handled by baseui */}
      <ModalUnsuportType />
      <ModalDownloadApp />
      <ModalGoodWork />
      <ModalProjectNotSupport />
      <ModalProjectSyncing />
      <ModalProjectOpenFailed />
      <ModalProjectConflict />
      <ModalProjectAlreadyOpen />
      <ModalProjectDoesntExist />
      <ModalConfirmSignOut />
      <ModalDiscardProject />
      <ModalSaveProject />
      <ModalDeleteProject />
      <ModalDownloadProject />
      <ModalNoInternet />
      <IntroVideo />

      {/* Modals handled by ScaledAppearanceModal */}
      <WrapModal
        id="wrap-modal"
        className={`custom-scroll ${showWrapModal ? (USE_ANIMATIONS ? modalStyles.showWrapModal : modalStyles.showWrapModalNoAnimation) : (USE_ANIMATIONS ? modalStyles.hideWrapModal : modalStyles.hideWrapModalNoAnimation) }`}
        style={{
          zIndex: 1000
        }}
      >
        <div style={{zIndex: 1000, width: '100%', height: '100%'}}>
          <ScaledAppearanceModal isOpen={showModalType != ShowModalType.ONBOARDING && openModalTryPremium.isOpen} name="premium" useAnimations={USE_ANIMATIONS}>
            <ModalTryPremium handleOpenTutorial={handleOpenTutorial} />
          </ScaledAppearanceModal>
          <ScaledAppearanceModal isOpen={!isLoggined && showModalType == ShowModalType.SIGN_IN} name="login" useAnimations={USE_ANIMATIONS}>
            <SignIn
              close={() => {setShowModalType(null)}}
              setLoggin={setIsLoggined}
              isOpen={showModalType == ShowModalType.SIGN_IN}
              handleOpenTutorial={handleOpenTutorial}
            />
          </ScaledAppearanceModal>
          <ScaledAppearanceModal isOpen={showModalType == ShowModalType.QUICK_TUTORIAL} name="tutorial" useAnimations={USE_ANIMATIONS}>
            <ModalTutorial />
          </ScaledAppearanceModal>
          <ScaledAppearanceModal isOpen={showModalType == ShowModalType.ONBOARDING} name="onboarding" useAnimations={USE_ANIMATIONS}>
            <Onboarding onClosed={onCloseOnboarding}/>
          </ScaledAppearanceModal>
          {/* WHAT NEW */}
          <ScaledAppearanceModal isOpen={showModalType === ShowModalType.WHAT_NEW} name="what-new" useAnimations={USE_ANIMATIONS}>
            <ModalWhatNew/>
          </ScaledAppearanceModal>
        </div>
      </WrapModal>
    </ThemeProvider>
  )
}

export default ShowModal