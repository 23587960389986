import { lightTheme } from '@/customTheme'
import useAppContext from '@/hooks/useAppContext'
import Icons from '@/scenes/Editor/components/Icons'
import { ObjectType, ShowModalType } from '@/scenes/engine/common/constants'
import { Modal, ModalHeader, ROLE, SIZE } from 'baseui/modal'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND, SHAPE } from 'baseui/button'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { SyncManager } from '@/firebase/services/sync/SyncManager'
import { useSelector } from 'react-redux'
import { selectUser } from '@/store/slices/user/selectors'
import { fabric } from 'fabric'
import { loadImageFromURL } from '@/scenes/engine/utils/image-loader'
import { nanoid } from 'nanoid'
import Watermark from '@assets/images/watermark_2024_black.png'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import objectToFabric from '@/scenes/engine/utils/objectToFabric'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'


function ModalDownloadProject() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [previewDownload, setPreviewDownload] = useState(null)
  const [previewWithWatermark, setPreviewWithWatermark] = useState(null)
  const user = useSelector(selectUser)

  useEffect(() => {
    const syncItems = SyncManager.shared.draftSyncItems
    const projects = syncItems
      .filter(item => !(item.isDeleted() || item.shouldDeleteFromCloud()))
      .filter(item => {
        if (item.draftId === SyncManager.shared.idDownloadProject) {
          return item.mostUpdatedVersion
        }
      })
    if (projects.length) {
      setPreviewDownload(projects[0].mostUpdatedVersion)
    }
    if (!showModalType) {
      setPreviewDownload(null)
      setPreviewWithWatermark(null)
    }
  }, [showModalType])

  useEffect(() => {
    if (user?.userIsPremium && previewDownload) {
      handleDownloadImage()
    } else if(!user?.userIsPremium && previewDownload) {
      setTimeout(async() => {
        const data = await handleBuildImage()
        setPreviewWithWatermark(data)
      })
    }
  }, [previewDownload, user])

  const handleBuildImage = async () => {
    const staticCanvas = new fabric.Canvas(null)
    const img: any = await loadImageFromURL(previewDownload.screenshotUrl)
    let scaleFactor = Math.max(previewDownload.size.width / img.width, previewDownload.size.height / img.height)
    const fabricImage: any = new fabric.StaticImage(img, {
      scaleX: scaleFactor,
      scaleY: scaleFactor,
    })
    staticCanvas.add(fabricImage)
    if(!user?.userIsPremium) {
      await addWatermark(staticCanvas, previewDownload)
    }
    const data = staticCanvas.toDataURL({
      multiplier: 1,
      top: 0,
      left: 0,
      height: previewDownload.size.height,
      width: previewDownload.size.width
    })
    return data
  }
  
  const handleDownloadImage = async () => {
    const data = await handleBuildImage()
    const link = document.createElement('a')
    link.href = data
    link.download = 'Bazaart.png'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    //
    setShowModalType(null)
    SyncManager.shared.idDownloadProject = null
  }

  const addWatermark = async (canvas, previewDownload) => {
    let guid = nanoid()
    let assetStateId = nanoid()
    let watermarkUrl = new URL(Watermark)

    const WATERMARK_WIDTH_FACTOR_PORTRAIT = 0.22
    const WATERMARK_WIDTH_FACTOR_LANDSCAPE = 0.16
    const WATERMARK_MARGIN = 0.02

    let imageWatermark = await loadImageFromURL(watermarkUrl)

    const padding = Math.min(previewDownload.size.width * WATERMARK_MARGIN, previewDownload.size.height * WATERMARK_MARGIN);
    const bottomRightPoint = { x: previewDownload.size.width - padding, y: previewDownload.size.height - padding };

    // @ts-ignore
    const watermarkAspectRatio = imageWatermark.width / imageWatermark.height;
    const isPortrait = previewDownload.size.width <= previewDownload.size.height;
    const factor = isPortrait ? WATERMARK_WIDTH_FACTOR_PORTRAIT : WATERMARK_WIDTH_FACTOR_LANDSCAPE;
    const watermarkSize = { width: factor * previewDownload.size.width, height: factor * previewDownload.size.width / watermarkAspectRatio };

    const ROIscaled = {
      x: bottomRightPoint.x - watermarkSize.width / 2,
      y: bottomRightPoint.y - watermarkSize.height / 2,
    };

    await MediaImageRepository.getInstance().storeImageUrl(guid, assetStateId, MediaImageType.latest, watermarkUrl)
    const watermarkOption = {
      id: guid,
      type: ObjectType.BAZAART_STICKER,
      centerPoint: {
        x: ROIscaled.x / previewDownload.size.width,
        y: ROIscaled.y / previewDownload.size.height,
      },
      sizeOnCanvas: {
        width: factor,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
    }

    const object: fabric.Object = await objectToFabric.run(watermarkOption, {width: previewDownload.size.width, height: previewDownload.size.height, left: 0, top: 0})
    object.lockScalingFlip = true
    canvas.add(object)
  }
  return (
    <>
      {user?.userIsPremium ? null : previewWithWatermark && (
        <Modal
          isOpen={showModalType === ShowModalType.DOWNLOAD_PROJECT}
          onClose={() => setShowModalType(null)}
          animate
          closeable={true}
          focusLock={false}
          returnFocus={false}
          size={SIZE.default}
          role={ROLE.dialog}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                display: 'flex',
                flexDirection: 'column',
                zIndex: '10000',
              }),
            },
            Dialog: {
              style: ({ $theme }) => ({
                width: '450px',
                height: '565px',
                padding: '24px 0',
                transition: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }),
            },
            Backdrop: {
              style: ({ $theme }) => ({
                transition: 'none',
                background: 'rgba(0, 0, 0, 0.65)',
                backdropFilter: 'blur(5px)',
              }),
            },
            Close: {
              style: ({ $theme }) => ({
                display: 'none',
              }),
            },
          }}
        >
          <ModalHeader
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0',
              padding: '0 24px',
              width: '100%',
              height: '32px',
              marginBottom: '16px',
            }}
          >
            <p style={{ ...lightTheme.typography.Header20bold }}>{t('Download')}</p>

            <ButtonCustom
              shape={SHAPE.square}
              kind={KIND.minimal}
              type={SizeButton.SMALL}
              onClick={() => setShowModalType(null)}
              style={{ marginLeft: 'auto' }}
            >
              <Icons.Close size={24} />
            </ButtonCustom>
          </ModalHeader>

          <div
            style={{
              width: '100%',
              height: '305px',
              background: '#F9F9F9',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={previewWithWatermark}
              alt="img-thumb"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'fit-content',
                height: 'fit-content',
                borderRadius: '6px',
              }}
            />
          </div>
          <p
            style={{
              ...lightTheme.typography.Small11medium,
              color: '#666',
              padding: '0 24px',
              marginTop: '12px',
            }}
          >
            {previewDownload?.size.width} X {previewDownload?.size.height} {t('px')}
          </p>
          <div style={{ padding: '0 24px', width: '100%' }}>
            <ButtonCustom
              type={SizeButton.LARGE}
              kind={KIND.primary}
              style={{ width: '100%', padding: '0 12px', gap: '6px', marginTop: '24px' }}
              onClick={() => {
                setShowModalType(null)
                dispatch(
                  setOpenModalTryPremium({
                    isOpen: true,
                    source: 'download_project_modal',
                    opened: true,
                  })
                )
                // @ts-ignore
                window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
              }}
            >
              <Icons.PremiumIcon />
              <p style={{ ...lightTheme.typography.Small14Semibold, color: '#fff' }}>
                {t('Remove watermark')}
              </p>
            </ButtonCustom>
            <ButtonCustom
              type={SizeButton.LARGE}
              kind={KIND.secondary}
              style={{ width: '100%', padding: '0 12px', gap: '6px', marginTop: '12px' }}
              onClick={() => {
                handleDownloadImage()
                setShowModalType(null)
              }}
            >
              <Icons.DownloadIcon />
              <p style={{ ...lightTheme.typography.Small14Semibold }}>{t('Download')}</p>
            </ButtonCustom>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ModalDownloadProject
