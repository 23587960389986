import { ImageFileSystemRecord } from "@/scenes/engine/objects/media-repository/image_filesystem";
import { FileSystemRecord, VirtualFileSystem } from "@/scenes/engine/objects/media-repository/VirtualFileSystem";

export class BtDiskFileUtils {
  static async deleteFiles(filePaths: string[]): Promise<void> {
    const deletePromises = filePaths.map(async (filePath) => {
      VirtualFileSystem.getInstance().delete(filePath, false)
      return true;
    });
    await Promise.all(deletePromises);
  }

  static resetDate(filePath: string, newDate: Date): boolean {
    VirtualFileSystem.getInstance().resetDate(filePath, newDate)
    return true;
  }

  static async writeFile(filePath: string, fileBlob: string): Promise<boolean> {
    await VirtualFileSystem.getInstance().writeBlobUrl(filePath, fileBlob)
    return true;
  }

  static readFile(filePath: string): string | null {
    let record = VirtualFileSystem.getInstance().get(filePath)
    return record?.isFolder ? null : record?.blobUrl;
  }

  static async attributesOfItem(filePath: string): Promise<FileSystemRecord | null> {
    let record = await VirtualFileSystem.getInstance().get(filePath)
    return record
  }

  static async moveFiles(sourcePaths: string[], targetPaths: string[]): Promise<void> {
    if (sourcePaths.length !== targetPaths.length){
      throw new Error('Source and target paths arrays must have the same length.');
    }
    for (let i = 0; i < sourcePaths.length; i++) {
      VirtualFileSystem.getInstance().move(sourcePaths[i], targetPaths[i])
    } 
    return Promise.resolve();
  }

  static allEmptyDirectories(path: string): string[]{
    return []
  }

  static async duplicate(sourcePaths: string[], targetPaths: string[]): Promise<void> {
    if (sourcePaths.length !== targetPaths.length){
      throw new Error('Source and target paths arrays must have the same length.');
    }
    for (let i = 0; i < sourcePaths.length; i++) {
      await VirtualFileSystem.getInstance().copy(sourcePaths[i], targetPaths[i])
    } 
    return Promise.resolve();
  }

  // Create hard links for specified files
  static async linkFiles(sourcePaths: string[], targetPaths: string[]): Promise<void> {
    if (sourcePaths.length !== targetPaths.length){
      throw new Error('Source and target paths arrays must have the same length.');
    }
    for (let i = 0; i < sourcePaths.length; i++) {
      await VirtualFileSystem.getInstance().link(sourcePaths[i], targetPaths[i])
    } 
    return Promise.resolve();
  }

  static async keepOnlyLatestVersion(folder: string): Promise<void> {
    let allFiles = await VirtualFileSystem.getInstance().list(folder)
    let deduplicatedRecords = this.deduplicateRecords(allFiles);
    for (let file of allFiles){
      let url = file[0];
      if (url.indexOf('?') > 0) {
        VirtualFileSystem.getInstance().delete(url, false)
      }
    }
    for (let [url, record] of deduplicatedRecords) {
      VirtualFileSystem.getInstance().write(url, record)
    }
    return Promise.resolve();
  }

  static async filterDeletedLayers(folder: string, existingLayers: string[]): Promise<void> {
    let allFiles = await VirtualFileSystem.getInstance().list(folder)

    function isMissingLayer(url: string): boolean {
      if (url.indexOf('Layers') === -1) {
        return false;
      }
      for (let existingLayer of existingLayers) {
        if(url.indexOf(existingLayer) !== -1) {
          return false;
        }
      }
      return  true;   
    }

    for (let file of allFiles){
      let url = file[0];
      let record = file[1];
      let isMissing = isMissingLayer(url);
      if (isMissing && !record.isFolder) {
          VirtualFileSystem.getInstance().delete(url, false)
      }
    }

    let allFilesAfterDeletion = await VirtualFileSystem.getInstance().list(folder)
    console.log(allFilesAfterDeletion);

    return Promise.resolve();
  }

  static deduplicateRecords(items: [url: string, record: FileSystemRecord][]): [url: string, record: FileSystemRecord][] {
    const recordMap: Map<string, FileSystemRecord> = new Map();

    items.forEach(([url, record]) => {
        // Remove the query string from the URL
        const urlWithoutQueryString = url.split('?')[0];

        // Check if the URL (without query string) already exists in the map
        if (recordMap.has(urlWithoutQueryString)) {
            const existingRecord = recordMap.get(urlWithoutQueryString)!;

            // Compare the undodDate and update the record if the current one is newer
            if (new Date(record.undoDate) > new Date(existingRecord.undoDate)) {
                recordMap.set(urlWithoutQueryString, record);
            }
        } else {
            // If not in the map, add the current URL and record
            recordMap.set(urlWithoutQueryString, record);
        }
    });

    // Convert the map back to an array of tuples [url, record]
    return Array.from(recordMap).map(([url, record]) => [url, record]);
}



  // Create a folder if it doesn't exist
  static async createFolderIfNone(folderPath: string): Promise<Boolean> {
    VirtualFileSystem.getInstance().createFolderIfNone(folderPath)
    return Promise.resolve(true);
  }
  
  static async exist(folderPath: string): Promise<Boolean> {
    let result = VirtualFileSystem.getInstance().exist(folderPath)
    return Promise.resolve(result);
  }

  static exists(url: URL): boolean {
    let items = this.list(url);
    return items.length > 0;
  }

  static list(url: URL): [url: string, record: FileSystemRecord][] {
    let records = VirtualFileSystem.getInstance().list(url.pathname)
    return records
  }
}