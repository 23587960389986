import { fabric } from 'fabric'
import { ObjectType } from '../common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
export class StaticTextObject extends fabric.Textbox {
  static type = 'StaticText'
  arcAngle = 0
  isPresetText = false;
  id: string;
  bazaartGuid: string;

  _filterScalingX = 1;
  _filterScalingY = 1;
  

  registerEditingEvents(this: fabric.Textbox) {
    this.on('editing:entered', () => {
      this.selectAll()
      this.hasControls = true
    })

    this.on('mousedblclick', (e) => {
      this.enterEditing()
      const target = e.target
      // @ts-ignore
      if(!target.__lastIsEditing) {
        this.selectAll()
      }
      const eventProperties = {
        Tool: 'bazaart.edit.text',
        'Layer Type': ObjectType.BAZAART_TEXT,
      }
      customAmplitude('Selected tool', eventProperties)
    })

    this.on('scaling', () => {
      const { fontSize, width, scaleX } = this
      let staticText = this as fabric.StaticText
      staticText.set({
        fontSize: fontSize! * scaleX!,
        width: width! * scaleX!,
        scaleX: 1,
        scaleY: 1,
      })
    })

    this.on('editing:exited', () => {
      if(!this.text) {
        this.canvas.remove(this)
        return
      }
    })
  }

  initialize(options: StaticTextOptions) {
    const { id, bazaartGuid, arcAngle, text, isPresetText, ...textOptions } = options
    // to fix : tamplate 3958
    // effect on change width handlers break lines when dont have spaces.
    // textOptions.splitByGrapheme = true
    this.id = id;
    this.bazaartGuid = bazaartGuid;
    this.isPresetText = isPresetText == true;
    this.arcAngle = arcAngle

    if (arcAngle != 0) {
      let radianAngle = arcAngle
      // let angle = 1.2
      let R = textOptions.width / radianAngle

      let degAngle = fabric.util.radiansToDegrees(radianAngle)
      let concave = radianAngle < 0
      let path = this.describeArc(0, 0, R, -degAngle / 2, degAngle / 2, concave)

      let f = new fabric.Path(path, { inverted: true })
      f.backgroundColor = '#00ff00'
      f.fill = 'transparent'
      f.flipX = true

      //@ts-ignore
      textOptions.path = f

      // textOptions.charSpacing =  100
      // textOptions.backgroundColor = '#00ff00'
      textOptions.inverted = true
      //@ts-ignore
      textOptions.pathSide = 'right'
      //@ts-ignore
      textOptions.pathAlign = 'center'
      // textOptions.width = textOptions.width * 2
      // textOptions.height = textOptions.height * 2
    }

    this._filterScalingX = 1;
    this._filterScalingY = 1;
    

    //@ts-ignore
    super.initialize(text, textOptions)

    if (this.path) {
      //@ts-ignore
      this._splitTextIntoLines = function (text) {
        if (this.path) {
          //@ts-ignore
          var newText = fabric.Text.prototype._splitTextIntoLines.call(this, text)
          return newText
        }
      }

      this.initDimensions = function () {
        fabric.Textbox.prototype.initDimensions.call(this)
        //@ts-ignore
        this.width = this.path.width
        //@ts-ignore
        this.height = this.path.height
      }

      //@ts-ignore
      this.renderSelection = function (boundaries, ctx) {
        this.renderCursor(boundaries, ctx)
      }
    }

    this.registerEditingEvents()

    return this
  }

  polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }

  describeArc(x, y, radius, startAngle, endAngle, concave) {
    var start = this.polarToCartesian(x, y, radius, endAngle)
    var end = this.polarToCartesian(x, y, radius, startAngle)

    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
    var concaveFlag = concave ? 1 : 0

    var d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, concaveFlag, end.x, end.y].join(' ')

    return d
  }

  /**
   * Renders cursor
   * @param {Object} boundaries
   * @param {CanvasRenderingContext2D} ctx transformed context to draw on
   */
  renderCursor(boundaries, ctx) {
    var cursorLocation = this.get2DCursorLocation(),
      lineIndex = cursorLocation.lineIndex,
      charIndex = cursorLocation.charIndex > 0 ? cursorLocation.charIndex - 1 : 0,
      charHeight = this.getValueOfPropertyAt(lineIndex, charIndex, 'fontSize'),
      multiplier = this.scaleX * this.canvas.getZoom(),
      cursorWidth = this.cursorWidth / multiplier,
      topOffset = boundaries.topOffset,
      dy = this.getValueOfPropertyAt(lineIndex, charIndex, 'deltaY')
    topOffset +=
      ((1 - this._fontSizeFraction) * this.getHeightOfLine(lineIndex)) / this.lineHeight -
      charHeight * (1 - this._fontSizeFraction)

    if (this.inCompositionMode) {
      this.renderSelection(boundaries, ctx)
    }
    ctx.fillStyle = this.cursorColor || this.getValueOfPropertyAt(lineIndex, charIndex, 'fill')
    // @ts-ignore
    ctx.globalAlpha = this.__isMousedown ? 1 : this._currentCursorOpacity

    if (this.path) {
      let charBox = this.__charBounds[lineIndex][charIndex]
      ctx.save()
      // @ts-ignore
      ctx.translate(charBox.renderLeft, charBox.renderTop)
      // @ts-ignore
      ctx.rotate(charBox.angle)
      ctx.fillRect(charBox.kernedWidth / 2, -charHeight / 2, cursorWidth, charHeight)
      ctx.restore()
    } else {
      ctx.fillRect(
        boundaries.left + boundaries.leftOffset - cursorWidth / 2,
        topOffset + boundaries.top + dy,
        cursorWidth,
        charHeight
      )
    }
  }

  toObject(propertiesToInclude = []) {
    return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
      arcAngle: this.arcAngle,
    })
  }
  toJSON(propertiesToInclude = []) {
    return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
      arcAngle: this.arcAngle,
    })
  }
  static fromObject(options: StaticTextOptions, callback: Function) {
    return callback && callback(new fabric.StaticText(options))
  }
}

fabric.StaticText = fabric.util.createClass(StaticTextObject, {
  type: StaticTextObject.type,
})
fabric.StaticText.fromObject = StaticTextObject.fromObject

export type StaticTextOptions = fabric.ITextboxOptions & { 
  id: string,
  bazaartGuid: string,
  text: string; 
  arcAngle: number;
  isPresetText: boolean | undefined
 }

declare module 'fabric' {
  namespace fabric {
    class StaticText extends StaticTextObject {
      registerEditingEvents: () => void
      constructor(options: StaticTextOptions)
    }
  }
}
