import { BasePixiFilter } from '@scenes/engine/utils/PixijsFilters/BasePixiFilter'
import { Filter } from '@pixi/core';
import { Size } from '@scenes/engine/objects/media-repository/size'
import fragment from "./highlightShadow.frag";
import vertex from "../default.vert";

// @ts-ignore
export class HighlightShadowFilter implements IAdjustmentFilter {
  shadows: number;
  highlights: number;
  pixijsFilter: Filter | null

  minimumForKey(key: string): number {
    switch (key){
      case "shadows": return 0;
      case "highlights": return 0.0;
    }
  }
  maximumForKey(key: string): number {
    switch (key){
      case "shadows": return 1;
      case "highlights": return 1;
    }
  }
  defaultForKey(key: string): number {
    switch (key){
      case "shadows": return 0;
      case "highlights": return 1;
    }
  }

  constructor() {
    this.shadows = this.defaultForKey("shadows");
    this.highlights = this.defaultForKey("highlights");
  }

  buildFilter({highlights}, {shadows}) {
    if (this.isValid(shadows)) { this.shadows = shadows; }
    if (this.isValid(highlights)) { this.highlights = highlights; }

    if (!this.pixijsFilter) {
      this.pixijsFilter = HighlightShadowFilter.generateFilter(this.shadows, this.highlights);
    } else {
      this.pixijsFilter.uniformGroup.uniforms["shadow"] = this.shadows;
      this.pixijsFilter.uniformGroup.uniforms["highlights"] = this.highlights;
    }
  }

  isValid(value: number | null | undefined): boolean {
    return [undefined, null].indexOf(value) === -1;
  }

  static generateFilter(shadow: number, highlights: number): Filter {
    let filter = new Filter(
      vertex,
      fragment,
        {
          shadow: shadow,
          highlights: highlights
        }
    );
    return filter;
  }
}