import { CloudResponse, EmptyResponse } from "../CloudResponse";

export class WaitingList<T> {
    private limit: number;
    private name: string;
    private strict: boolean;
    private list: (() => Promise<CloudResponse<T>>)[];
    private activeTasks: number = 0;

    constructor(limit: number, name: string, strict: boolean) {
        this.limit = limit;
        this.name = name;
        this.strict = strict;
        this.list = [];
    }

    public add(task: () => Promise<CloudResponse<T>>): void {
        if (this.strict && this.list.length >= this.limit) {
            throw new Error(`Waiting list "${this.name}" exceeded limit of ${this.limit}`);
        }
        this.list.push(task);
        this.processNext();
    }

    public async runTask(task: () => Promise<CloudResponse<T>>): Promise<CloudResponse<T | void>> {
        await this.enter();
        try {
            const response = await task();
            this.handleResponse(response);
            return response;
        } catch (error) {
            const errorMsg = `Task failed with error: ${error}`;
            console.error(errorMsg);
            return EmptyResponse.sendError(errorMsg);
        } finally {
            await this.leave();
        }
    }

    private async enter(): Promise<void> {
        while (this.activeTasks >= this.limit) {
            await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms
        }
        this.activeTasks++;
    }

    private async leave(): Promise<void> {
        this.activeTasks--;
        this.processNext();
    }

    private handleResponse(response: CloudResponse<T>): void {
        if (response.error) {
            console.error(`Error in task: ${response.error}`);
        } else {
            console.log(`Task completed with data: ${response.value}`);
        }
    }

    public processNext(): void {
        if (this.list.length > 0 && this.activeTasks < this.limit) {
            const task = this.list.shift();
            if (task) {
                this.runTask(task);
            }
        }
    }

    public clear(): void {
        this.list = [];
    }
}
