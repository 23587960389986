import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useTranslation } from 'react-i18next'

function ModalProjectDoesntExist() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.PROJECT_DOESNT_EXIST}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Can’t open this project'),
        desc: t('This project isn\'t available at this time.'),
      }}
      action={{
        primary: {
          name: t('Ok'),
          handle: () => {
            setShowModalType(null)
          }
        },
      }}
    ></ModalCustom>
  )
}

export default ModalProjectDoesntExist