import { SyncManager } from '@/firebase/services/sync/SyncManager'
import { SyncStatus } from '@/firebase/services/sync/SyncStatus'
import { selectUser } from '@/store/slices/user/selectors'
import { DotLottiePlayer } from '@dotlottie/react-player'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// @ts-ignore
import cloudAnimation from '@/assets/lottie/cloud_animation.lottie'
import { lightTheme } from '@/customTheme'

function UnsavedProjectMessage() {
  const { id = null } = useParams<{ id: string }>()
  const [connectSucess, setConnectSuccess] = useState(true)

  const user = useSelector(selectUser)
  useEffect(() => {
    if (user && id) {
      let syncItem = SyncManager.shared.getSyncItem(id)
      if (syncItem && syncItem.mostUpdatedVersion._syncStatus !== SyncStatus.Updated) {
        setConnectSuccess(false)
      } else {
        setConnectSuccess(true)
      }
    }
  }, [user])
  const { t } = useTranslation()
  return (
    <div
      style={{
        position: 'fixed',
        top: '80px',
        left: 'calc((100vw - 356px) / 2 + 44px)',
        background: '#FCD34D',
        height: '40px',
        width: '548px',
        transform: 'translateX(-50%)',
        borderRadius: '20px',
        padding: '8px 16px',
        display: !connectSucess ? 'flex' : 'none',
        gap: '8px',
      }}
    >
      <DotLottiePlayer
        src={cloudAnimation}
        autoplay
        loop
        style={{
          width: '20px',
          height: '24px',
        }}
      ></DotLottiePlayer>
      <p style={{ ...lightTheme.typography.Small14regular, letterSpacing: '-0.084px' }}>
        {t(' ')}
      </p>
    </div>
  )
}

export default UnsavedProjectMessage
