import { Unsubscribe } from "@reduxjs/toolkit";
import { StorageError, TaskState, StorageReference, TaskEvent, StorageObserver, getDownloadURL } from "firebase/storage";

export interface DownloadTaskSnapshot {
    bytesTransferred: number;
    totalBytes: number;
    state: TaskState;
}

export class DownloadTask {
    private controller: AbortController;
    private progressCallbacks: ((snapshot: DownloadTaskSnapshot) => void)[] = [];
    
    errorCallbacks: ((error: StorageError) => void)[] = [];
    completeCallbacks: (() => void)[] = [];
    
    private bytesTransferred = 0;
    private totalBytes = 0;
    private state: TaskState = 'running';
    private storageRef: StorageReference;

    constructor(storageRef: StorageReference) {
        this.controller = new AbortController();
        this.storageRef = storageRef;
    }

    on(event: TaskEvent, nextOrObserver?: StorageObserver<DownloadTaskSnapshot> | null | ((snapshot: DownloadTaskSnapshot) => unknown), error?: ((a: StorageError) => unknown) | null, complete?: Unsubscribe | null): Unsubscribe {
        if (event === 'state_changed') {
            if (typeof nextOrObserver === 'function') {
                this.progressCallbacks.push(nextOrObserver as (snapshot: DownloadTaskSnapshot) => void);
            } else if (nextOrObserver) {
                if (nextOrObserver.next) {
                    this.progressCallbacks.push(nextOrObserver.next);
                }
                if (nextOrObserver.error) {
                    this.errorCallbacks.push(nextOrObserver.error);
                }
                if (nextOrObserver.complete) {
                    this.completeCallbacks.push(nextOrObserver.complete);
                }
            }
            if (error) {
                this.errorCallbacks.push(error);
            }
            if (complete) {
                this.completeCallbacks.push(complete);
            }
        }
        return () => {
            this.progressCallbacks = this.progressCallbacks.filter(cb => cb !== nextOrObserver);
            this.errorCallbacks = this.errorCallbacks.filter(cb => cb !== error);
            this.completeCallbacks = this.completeCallbacks.filter(cb => cb !== complete);
        };
    }

    startDownload(fileName: string, completion?: (fileURL: string | null, error: Error | null) => void): void {
        const { signal } = this.controller;

        getDownloadURL(this.storageRef)
            .then((url) => {
                return fetch(url, { signal });
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Download failed with status ${response.status}`);
                }
                this.totalBytes = parseInt(response.headers.get('content-length') || '0', 10);
                const contentType = response.headers.get('content-type') || 'application/octet-stream';
                const reader = response.body?.getReader();
                const stream = new ReadableStream({
                    start: (controller) => {
                        const read = async () => {
                            if (!reader) return;
                            const { done, value } = await reader.read();
                            if (done) {
                                this.state = 'success';
                                this.completeCallbacks.forEach(callback => callback());
                                controller.close();
                                return;
                            }
                            this.bytesTransferred += value.length;
                            const progressPercent = (this.bytesTransferred / this.totalBytes) * 100;
                            this.progressCallbacks.forEach(callback => callback({
                                bytesTransferred: this.bytesTransferred,
                                totalBytes: this.totalBytes,
                                state: this.state
                            }));
                            controller.enqueue(value);
                            read();
                        };
                        read();
                    }
                });

                const newResponse = new Response(stream);
                return new Response(stream, { headers: { 'Content-Type': contentType } });
            })
            .then((response) => response.blob())
            .then((blob) => {
                const downloadURL = window.URL.createObjectURL(blob);

                // Pass the downloadURL to the completion callback
                if (completion) {
                    completion(downloadURL, null);
                }                
                // window.URL.revokeObjectURL(downloadURL);
            })
            .catch((error) => {
                this.state = 'error';
                // @ts-ignore
                const storageError: StorageError = {
                    name: error.name,
                    message: error.message,
                    code: 'download-failed',
                    status_: undefined,
                    customData: {
                        serverResponse: null
                    },
                    status: 0,
                }; 
                this.errorCallbacks.forEach(callback => callback(storageError));
                if (completion) {
                    completion(null, error);
                }
            });
    }

    cancel(): boolean {
        this.controller.abort();
        this.state = 'canceled';
        return true
    }
}