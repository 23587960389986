// Define the generic Response type
export type CloudResponse<T> = {
    success?: (id: string) => void;
    succeed: boolean;
    value?: T;
    error?: string;
    errorCode?: number;
};

export class SuccesfulResponse<T> implements CloudResponse<T> {
    succeed: boolean;
    value?: T;
    error?: string;
    errorCode?: number;

    constructor(value: T) {
        this.value = value;
        this.succeed = true;
    }
}

// Create the alias for EmptyResponse
export class EmptyResponse implements CloudResponse<void> {
    
    success: (id: string) => void;
    succeed: boolean;

    value?: void;
    error?: string;
    errorCode?: number;

    static success(): EmptyResponse {
        return {
            success: (id: string) => { console.log(`Success with id: ${id}`); },
            succeed: true
        };
    }

    static sendError(error: string, errorCode: number = 0): EmptyResponse {
        return {
            success: (id: string) => { console.log(`Failed with id: ${id}`); },
            succeed: false,
            error,
            errorCode
        };
    }
}

