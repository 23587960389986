import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useAppDispatch } from '@/store/store'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { setUploads } from '@/store/slices/uploads/actions'
import { customAmplitude } from '@/utils/customAmplitude'
import { selectTemplateConfig } from '@/store/slices/templates/selectors'
import { useSelector } from 'react-redux'

function ModalDicardProject() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { showModalType, setShowModalType, setisOpenPixelManipulationObject, setToolType } = useAppContext()
  const templateConfig = useSelector(selectTemplateConfig)
  
  const close = () => {
    dispatch(setUploads([]))
    setShowModalType(null)
    dispatch(setCallbackFromCreationPage(null))
    setisOpenPixelManipulationObject(false)
    setToolType(null)
    customAmplitude('Open', { Source: 'Editor' })
  }
  
  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.DISCARD_PROJECT}
      onClose={() => setShowModalType(null)}
      content={{
        header: t('Discard your design?'),
        desc: t('You’re leaving this page and your design will not be saved.')
      }}
      action={{
        primary: {
          name: t('Discard'),
          handle: async () => {
            dispatch(setUploads([]))            
            setShowModalType(null)
            close()
            customAmplitude('Open', { Source: 'Editor' })
            let isProject = templateConfig?.draftGuid && !templateConfig?.templateId
            if(isProject) {
              history.push('/create/projects')
            } else {
              history.push('/create')
            }
          },
        },
        secondary: {
          name: t('Cancel'),
          handle: () => {
            setShowModalType(null)
            close()
          },
        },
      }}
    />
  )
}

export default ModalDicardProject