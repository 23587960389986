export class DateProvider {
    static getUTCDate(): Date {
        const now = new Date();
        // Get the timezone offset in minutes and convert it to milliseconds
        const timezoneOffset = -now.getTimezoneOffset() * 60 * 1000;
        // Subtract the offset from the date's time to get the UTC time
        const utcTime = now.getTime() + timezoneOffset;
        // Create a new Date object for the UTC time
        const  utcDate = new Date(utcTime);
        return utcDate
    }
}