import { AtomicDictionary } from "./AtomicDictionary";

export class SingleAccessGate {
    private atomicDictionary: AtomicDictionary<string, boolean>;

    constructor() {
        this.atomicDictionary = new AtomicDictionary<string, boolean>();
    }

    enterIfPossible(id: string): boolean {
        let canEnter = false;
        this.atomicDictionary.write(dict => {
            canEnter = !dict.has(id);
            if (canEnter) {
                dict.set(id, true);
            }
        });
        return canEnter;
    }

    leave(id: string): void {
        this.atomicDictionary.delete(id);
    }
}