import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { SAVE_PROJECT_STATE, ShowModalType } from '@/scenes/engine/common/constants'
import { useAppDispatch } from '@/store/store'
import { useTranslation } from 'react-i18next'
import { useEditorContext } from '@/scenes/engine'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { setUploads } from '@/store/slices/uploads/actions'
import { customAmplitude } from '@/utils/customAmplitude'
import { useSelector } from 'react-redux'
import { selectOpenModalTryPremium, selectUser } from '@/store/slices/user/selectors'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

function ModalSaveProject() {
  const editor = useEditorContext().editor
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    showModalType,
    setShowModalType,
    setisOpenPixelManipulationObject,
    setToolType,
    setSaveProjectState,
    saveProjectState,
  } = useAppContext()
  const user = useSelector(selectUser)
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const history = useHistory()
  const [isSaveProjectFailed, setIsSaveProjectFailed] = useState(false)
  const [error, setError] = useState('')

  
  useEffect(() => {
    if(isSaveProjectFailed){
      customAmplitude('sync - failed to save project', {"message" : error})
      Sentry.captureException(new Error('sync - failed to save project'), { level: 'error' })
    }
  }, [isSaveProjectFailed]); 

  const close = () => {
    dispatch(setUploads([]))
    setShowModalType(null)
    dispatch(setCallbackFromCreationPage(null))
    setisOpenPixelManipulationObject(false)
    setToolType(null)
    customAmplitude('Open', { Source: 'Editor' })
  }

  const handleSaveProject = async () => {
      setSaveProjectState(SAVE_PROJECT_STATE.LOADING)
      setShowModalType(null)
      customAmplitude('sync - save project')
      editor.handlers.projectHandler.saveProject().then((projectId) => {
        if(saveProjectState === SAVE_PROJECT_STATE.END) return
        if (!projectId) {
          setShowModalType(ShowModalType.SAVE_PROJECT)
          setIsSaveProjectFailed(true)
          setError("projectId not created")
          setSaveProjectState(SAVE_PROJECT_STATE.INIT)
        } else if(!window.location.href.includes('create')) {
          history.replace(`/design/${projectId}/edit`)
          setSaveProjectState(SAVE_PROJECT_STATE.SUCCESS)
          customAmplitude('sync - saved project success')
          close()
        }
        setTimeout(() => {
          setSaveProjectState(SAVE_PROJECT_STATE.INIT)
        }, 1500)
      })
      .catch(error => {
        setShowModalType(ShowModalType.SAVE_PROJECT)
        setIsSaveProjectFailed(true)
        setError(error)
        setSaveProjectState(SAVE_PROJECT_STATE.INIT)
      })
  }

  return (
    <>
      {isSaveProjectFailed ? (
        <ModalCustom
        isOpen={showModalType === ShowModalType.SAVE_PROJECT}
        onClose={() => setShowModalType(null)}
        content={{
          header: t('Can’t save this project'),
          desc:
            t('Please contact Bazaart support.')
        }}
        action={{
          primary: {
            name: t('Ok'),
            handle: () => {
              setShowModalType(null)
              setIsSaveProjectFailed(false)
              setError('')
            }
          },
        }}
      />
      ) : (
        <ModalCustom
          isOpen={showModalType === ShowModalType.SAVE_PROJECT}
          onClose={() => setShowModalType(null)}
          content={{
            header: `${t('Save project')}?`,
            desc:
            (!user ? t('Sign in to save your project.') + ' ' : '') + t('Saving projects is currently in beta. It may take a bit of time to complete.'),
          }}
          action={{
            primary: {
              name: user ? t('Save project') : t('Sign in'),
              handle: async () => {
                if (!user) {
                  setShowModalType(ShowModalType.SIGN_IN)
                  dispatch(
                    setOpenModalTryPremium({
                      ...openModalTryPremium,
                      isOpen: false,
                      callback: () => {
                        setTimeout(() => {
                          handleSaveProject()
                        }, 1000)
                      },
                    })
                  )
                } else {
                  handleSaveProject()
                }
              },
            },
            secondary: {
              name: t('Cancel'),
              handle: () => {
                setShowModalType(null)
                close()
              },
            },
          }}
        />
      )}
    </>
  )
}

export default ModalSaveProject
