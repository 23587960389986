import Icons from '../../../Icons'
import { KIND } from 'baseui/button'
import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { lightTheme } from '@/customTheme'
import SliderCustom from '@/components/SliderCustom'
import ColorsPackContainer from '@/ColorsPackContainer'
import { LabelLarge } from 'baseui/typography'
import { Point } from '@scenes/engine/objects/media-repository/point'
import { selectImageElements } from '@/store/slices/imageElement/selectors'
import { useSelector } from 'react-redux'
import { ObjectType } from '@/scenes/engine/common/constants'
import * as PIXI from 'pixi.js'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'

function Shadow({applyEffectLayerShadow, 
  updateOptions, 
  updateEffect, 
  calcMaxForKey, 
  calcMinForKey,
  defaultForKey}) {
    const [didManuallyChange, setDidManuallyChange] = useState(false)
  
  const [isFirstChange, setIsFirstChange] = useState(true)
  const [isColor, setIsColor] = useState(false)
  const { editor } = useEditorContext()

  const [distanceValue, setDistanceValue] = useState(calcMinForKey('distance'));
  const [angleValue, setAngleValue] = useState(calcMinForKey('angle'));
  const [blurValue, setBlurValue] = useState(calcMinForKey('blur'));
  const [shadowValue, setShadowValue] = useState(calcMinForKey('opacity'));
  const [colorValue, setColorValue] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const activeObject = useEditorContext().activeObject as unknown as any


  const handleObjectShadow = () => {
    let {opacity, distance, angle, blur, color, promise} = updateOptions(activeObject);
    
    if (opacity !== null && distance !== null && angle !== null &&  blur !== null && color !== null) {
      setIsFirstChange(false)
      setShadowValue(opacity)
      setDistanceValue(distance)
      setAngleValue(angle)
      setBlurValue(blur)
      setColorValue(color)
    } else {
      reset()
      setIsReset(false)
      setDidManuallyChange(false)
      setIsColor(false)
      setIsFirstChange(true)
    }
    return promise
  }
  
  useEffect(() => {
    if(activeObject){
      return handleObjectShadow()
    }
  }, [activeObject]);
  
  useEffect(() => {
    if (!editor) return
    const handleChanges = (e) => {
      if(e.afterUndo) {
        setDidManuallyChange(false)
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])
  

  useEffect(() => {
    if (!editor) return
    const handleChanges = (e) => {
      if(e.afterUndo) {
        setDidManuallyChange(false)
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
  }, [editor])
  
  const updateShadow = async (value: number) => {
    setDidManuallyChange(true);
    setShadowValue(value)
  }

  const updateColor = async (color: string) => {
    if (!didManuallyChange){
      setDidManuallyChange(true)
    }
    
    setColorValue(color)
    setIsColor(true)
  }

  const reset = () => {
    setAngleValue(calcMinForKey("angle"))
    setBlurValue(calcMinForKey("blur"))
    setDistanceValue(calcMinForKey("distance"))
    setShadowValue(calcMinForKey("opacity"))
    setColorValue(null)
    setDidManuallyChange(true)
    setIsReset(true)
  }

  useEffect(() => {
    if (!didManuallyChange) {
      return;
    }
    if (isFirstChange){
      setIsFirstChange(false);
      setShadowValue(pre => (pre ? pre : defaultForKey('opacity')))
      setDistanceValue(pre => (pre ? pre : defaultForKey('distance')))
      setAngleValue(pre => (pre ? pre : defaultForKey('angle')))
      setBlurValue(pre => (pre ? pre : defaultForKey('blur')))
      setColorValue(colorValue ? colorValue : defaultForKey('color'))
      return
    }
    (async () => {
      await applyEffectShadow()
    })()
  }, [colorValue, distanceValue, angleValue, blurValue, shadowValue])

  const applyEffectShadow = async () => {
    await applyEffectLayerShadow(isReset, shadowValue, distanceValue, angleValue, colorValue, blurValue)
    if (isColor) {
      updateEffect(true)
      setIsColor(false)
    }
    if (isReset) {
      setIsReset(false)
      updateEffect(true)
      setIsFirstChange(true)
    }
  }

  
  const { t } = useTranslation()

  return (
    <>
          {/* Shadow */}
          <SliderCustom
            icon={<Icons.Shadow size={24} />}
            name={t("Shadow")}
            minValue={0}
            maxValue={100}
            logicMaximum={calcMaxForKey("opacity")}
            logicMinimum={calcMinForKey("opacity")}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(value, logicalValue) => {
              setDidManuallyChange(true)
              updateShadow(logicalValue)
              updateEffect(false)
            }}
            value={shadowValue}
            onFinalChangeValue={(value, logicalValue) => {
              updateEffect(true)
            }}
            style={{marginTop: 0}}
          />
          {/* Distance */}
          <SliderCustom
            icon={<Icons.Distance size={24} />}
            name={t("Distance")}
            minValue={0}
            maxValue={100}
            logicMaximum={calcMaxForKey("distance")}
            logicMinimum={calcMinForKey("distance")}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(value, logicalValue) => {
              setDidManuallyChange(true)
              setDistanceValue(logicalValue)
              updateEffect(false)
            }}
            value={distanceValue}
            onFinalChangeValue={(value, logicalValue) => {
              updateEffect(true)
            }}
          />
          {/* Angle */}
          <SliderCustom
            icon={<Icons.Angle size={24} />}
            name={t("Angle")}
            minValue={0}
            maxValue={360}
            logicMaximum={calcMaxForKey("angle")}
            logicMinimum={calcMinForKey("angle")}
            listValues={[
              0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
              210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360,
            ]}
            onChangeValue={(value, logicalValue) => {
              setDidManuallyChange(true)
              setAngleValue(logicalValue)
              updateEffect(false)
            }}
            value={angleValue}
            onFinalChangeValue={(value, logicalValue) => {
              updateEffect(true)
            }}
          />
          {/* Blur */}
          <SliderCustom
            icon={<Icons.Blur size={24} />}
            name={t("Blur")}
            minValue={0}
            maxValue={100}
            logicMaximum={calcMaxForKey("blur")}
            logicMinimum={calcMinForKey("blur")}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(value, logicalValue) => {
              setDidManuallyChange(true)
              setBlurValue(logicalValue)
              updateEffect(false)
            }}
            value={blurValue}
            onFinalChangeValue={(value, logicalValue) => {
              updateEffect(true)
            }}
          />
          <ColorsPackContainer
            style={{
              boxSizing: 'border-box',
              padding: '0',
              width: '300px',
              paddingRight: '8px',
            }}
            onItemSelected={value => updateColor(value)}
            colorSelected={colorValue}
          ></ColorsPackContainer>
          <div className="mt-50">
            <ButtonCustom
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              style={{ marginBottom: '300px' }}
              onClick={() => {
                reset()
              }}
            >
              <LabelLarge>{t("Reset")}</LabelLarge>
            </ButtonCustom>
          </div>
        {/* <Accordion>
          <Panel
            title={
              <Button size={SIZE.default} kind={KIND.tertiary} shape={SHAPE.square}>
                Shadow
              </Button>
            }
          >
            <div
              style={{
                background: '#ffffff',
                fontSize: '14px',
              }}
            >
              <div
                style={{
                  display: 'grid',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div>
                  <div>Distance</div>
                  <Slider
                    overrides={{
                      InnerThumb: () => null,
                      ThumbValue: () => null,
                      TickBar: () => null,
                      Thumb: {
                        style: {
                          height: '12px',
                          width: '12px',
                        },
                      },
                    }}
                    min={0.01}
                    max={0.99}
                    step={0.002}
                    marks={false}
                    value={distanceValue}
                    onChange={({ value }) => updateDistance(value)}
                  />
                </div>
                <div>
                  <div>Angle</div>
                  <Slider
                    overrides={{
                      InnerThumb: () => null,
                      ThumbValue: () => null,
                      TickBar: () => null,
                      Thumb: {
                        style: {
                          height: '12px',
                          width: '12px',
                        },
                      },
                    }}
                    min={-1.56}
                    max={1.56}
                    step={0.05}
                    marks={false}
                    value={angleValue}
                    onChange={({ value }) => updateAngle(value)}
                  />
                </div>
                <div>
                  <div>Blur</div>
                  <Slider
                    overrides={{
                      InnerThumb: () => null,
                      ThumbValue: () => null,
                      TickBar: () => null,
                      Thumb: {
                        style: {
                          height: '12px',
                          width: '12px',
                        },
                      },
                    }}
                    min={0.0}
                    max={0.3}
                    step={0.001}
                    marks={false}
                    value={blurValue}
                    onChange={({ value }) => updateBlur(value)}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </Panel>
        </Accordion> */}
    </>
  )
}

export default Shadow