import { initializeApp } from 'firebase/app';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import {
    onAuthStateChanged,
    signOut,
    signInWithCustomToken,
    NextOrObserver,
    User,
    Auth,
    getAuth,
} from 'firebase/auth';

import { fblogin, fblogout } from '@/store/slices/firebaseAuth/actions';
import { AppDispatch } from '@/store/store'
import { getFirebaseConfig } from './firebase-config';


export namespace FirebaseWrapper {
    export type EventCallback = (...args: any[]) => void;

    export enum Events {
        Login = "login",
        Logout = "logout"
    }
    
    export class FirebaseBaseWrapper {
        firebaseAuth: Auth
        storage: FirebaseStorage

        constructor() {
            const app = initializeApp(getFirebaseConfig());
            this.firebaseAuth = getAuth(app);
            this.storage = getStorage(app);
            this.storage.maxUploadRetryTime = 30000; // 30,000 milliseconds = 30 seconds. though this doesn't seem to have any effect =\
        }
    
        private static _shared: FirebaseBaseWrapper;
        static get shared(): FirebaseBaseWrapper {
            if (!this._shared) {
                this._shared = new FirebaseBaseWrapper();
            }
            return this._shared;
        }
    
        signInUser = async (token: string) => {
            if (!token) return;
            try {
                const userCredential = await signInWithCustomToken(this.firebaseAuth, token)
                this.emit(Events.Login, userCredential.user.uid)
                return userCredential
            } catch  (error){
                console.error(`failed to signin with custom token with error ${error}`)
                return null;
            }
            
        }
        
        signOutUser = async () => {
            try {
                await signOut(this.firebaseAuth)
                this.emit(Events.Logout)
              } catch (error) {
                console.error("Error signing out:", error);
              }
        }
        
        listenToAuthChanges = (dispatch: AppDispatch) => (user: User | null) => {
            if (user) {
                dispatch(fblogin(user));
                this.emit(Events.Login, user.uid)
            }
            // this is calld to often unfortunately even for temp unstable states 
            //  else {
            //     dispatch(fblogout());
            //     this.emit(Events.Logout)
            // }
        };
    
        private subscribers: Map<string, EventCallback[]> = new Map();
    
        subscribe(event: Events, callback: EventCallback): void {
            if (!this.subscribers.has(event)) {
                this.subscribers.set(event, []);
            }
            this.subscribers.get(event)?.push(callback);
        }
    
        unsubscribe(event: Events, callback: EventCallback): void {
            const callbacks = this.subscribers.get(event);
            if (!callbacks) return;
    
            const index = callbacks.indexOf(callback);
            if (index !== -1) {
                callbacks.splice(index, 1);
            }
    
            // Clean up if no more callbacks are left for the event
            if (callbacks.length === 0) {
                this.subscribers.delete(event);
            }
        }
    
        emit(event: Events, ...args: any[]): void {
            const callbacks = this.subscribers.get(event);
            if (callbacks) {
                callbacks.forEach(callback => callback(...args));
            }
        }
    }
}


//required if you want to keep logged in after user exits the browser or closes tab
// setPersistence(firebaseAuth, browserLocalPersistence);
