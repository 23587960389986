import useAppContext from '@/hooks/useAppContext'
import Loading from '../Loading/Loading'
import { lightTheme } from '@/customTheme'
import { useTranslation } from 'react-i18next'
import { SAVE_PROJECT_STATE } from '@/scenes/engine/common/constants'
import Icons from '@/scenes/Editor/components/Icons'

function LoadingSaveProject() {
  const { isOpenInspector, saveProjectState } = useAppContext()
  const { t } = useTranslation()
  return (
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        background: 'rgba(255, 255, 255, 0.85)',
        backdropFilter: 'blur(4px)',
        zIndex: 10000,
        cursor: 'auto',
      }}
      id="overlay-saving-project"
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% + 32px)',
          transform: 'translateY(-50%)',
          left: isOpenInspector ? '88px' : 0,
          right: isOpenInspector ? '356px' : 0,
          zIndex: 1000,
          transition: 'all .4s ease-in',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {saveProjectState === SAVE_PROJECT_STATE.LOADING ? <Loading /> : <Icons.SavedIcon />}
        <p
          style={{
            width: '225px',
            height: '48px',
            ...lightTheme.typography.Small14Semibold,
            color: lightTheme.colors.blackGray,
            textAlign: 'center',
          }}
          id="saving-text"
        >
          {saveProjectState === SAVE_PROJECT_STATE.LOADING
            ? t('Please keep this window open while saving is in progress.')
            : t('Saved')}
        </p>
      </div>
    </div>
  )
}

export default LoadingSaveProject
