import { Filter } from '@pixi/core'
import { ExposureFilter } from '@scenes/engine/utils/PixijsFilters/Exposure/ExposureFilter'
import { TemperatureFilter } from '@scenes/engine/utils/PixijsFilters/Temperature/TemperatureFilter'
import { VibranceFilter } from '@scenes/engine/utils/PixijsFilters/Viberance/VibranceFilter'
import { HighlightShadowFilter } from '@scenes/engine/utils/PixijsFilters/HighlightShadow/HighlightShadowFilter'
import { SharpenFilter } from '@scenes/engine/utils/PixijsFilters/Sharpen/SharpenFilter'
import { FadeFilter } from '@scenes/engine/utils/PixijsFilters/Fade/FadeFilter'
import { IAdjustmentFilter } from '@scenes/engine/utils/PixijsFilters/IAdjustmentFilter'
import { HdBlurFilter } from '@scenes/engine/utils/PixijsFilters/HdBlurFilter'
import { ColorControlFilter } from '@scenes/engine/utils/PixijsFilters/ColorControlFilter'
import { FilterRangeValues } from '@scenes/engine/utils/PixijsFilters/FilterRangeValues'

enum AdjustmentPresetType {
  exposure,
  colorControls, //brightness, contrast, saturation
  temperatureTint,
  vibrance,
  highlightShadows,
  fade, // CIColorClamp
  sharpness,
  blur
}

class FilterBuilder {
    defaultPlaceholder = -101;
    filterForType: Map<AdjustmentPresetType, IAdjustmentFilter>;

    constructor(filterForType: Map<AdjustmentPresetType, IAdjustmentFilter>) {
      this.filterForType = filterForType;
    }

    placeholderCheck (values: number[]): boolean {
        let isPlaceholder = values.some((v) => v == this.defaultPlaceholder || [undefined, null].includes(v));
        return isPlaceholder;
    }

    allDefaultCheck (filter, input: any): boolean {
      let keys = Object.keys(input);
      return keys.every((key) => [undefined, null].includes(filter.defaultForKey(key)) || filter.defaultForKey(key) == input[key]);
    }

    build(type: AdjustmentPresetType, ...input: any): Filter | null {
      let flatInput = Object.assign({}, ...input);
      if (this.placeholderCheck(Object.values(flatInput))) {
        return null
      }
      let filter = this.filterForType.get(type);
      if (this.allDefaultCheck(filter, flatInput)) {
        return null;
      }
      filter.buildFilter(...input);
      return filter.pixijsFilter;
  }
}

class AdjustmentFilterFactory
{
    readonly rangeValueForFilter: Map<AdjustmentPresetType, Map<string, FilterRangeValues>>;
    readonly rangeValueForProperty: Map<string, FilterRangeValues>;

    private readonly filters: IAdjustmentFilter[];
    private readonly filterForType: Map<AdjustmentPresetType, IAdjustmentFilter>;
    private readonly builder: FilterBuilder;

    constructor() {
      this.filterForType = new Map<AdjustmentPresetType, IAdjustmentFilter>();
      this.filterForType.set(AdjustmentPresetType.exposure, new ExposureFilter());
      this.filterForType.set(AdjustmentPresetType.colorControls, new ColorControlFilter());
      this.filterForType.set(AdjustmentPresetType.sharpness, new SharpenFilter());
      this.filterForType.set(AdjustmentPresetType.fade, new FadeFilter());
      this.filterForType.set(AdjustmentPresetType.highlightShadows, new HighlightShadowFilter());
      this.filterForType.set(AdjustmentPresetType.temperatureTint, new TemperatureFilter());
      this.filterForType.set(AdjustmentPresetType.vibrance, new VibranceFilter());
      this.filterForType.set(AdjustmentPresetType.blur, new HdBlurFilter());

      this.filters = Array.from(this.filterForType.values());
      this.builder = new FilterBuilder(this.filterForType);
      this.rangeValueForFilter = this.getRangeValueForFilter();
      this.rangeValueForProperty = this.getRangeValueForProperty();
    }

    getFilter(type: AdjustmentPresetType): any | null {
      let builder = this.builder;
      switch (type) {
        case AdjustmentPresetType.exposure: {
            return ({exposure}) => {
              return builder.build(type, {exposure});
            }
          }
          case AdjustmentPresetType.colorControls: {
            return ({brightness, contrast, saturation}) => {
              return builder.build(type, { brightness}, {contrast}, {saturation});
            }
          }
          case AdjustmentPresetType.temperatureTint: {
            return ({temperature, tint}) => {
              return builder.build(type, {temperature}, {tint});
            }
          }
          case AdjustmentPresetType.vibrance: {
            return ({vibrance}) => {
              return builder.build(type, {vibrance});
            }
          }
          case AdjustmentPresetType.highlightShadows: {
            return ({highlights, shadows}) => {
              return builder.build(type, {highlights}, {shadows});
            }
          }
          case AdjustmentPresetType.fade: {
            return ({fade}) => {
              return builder.build(type, {fade});
            }
          }
          case AdjustmentPresetType.sharpness: {
            return ({imageHeight , imageWidth, sharpness}) => {
              return builder.build(type, {imageHeight}, {imageWidth}, {sharpness});
            }
          }
          case AdjustmentPresetType.blur: {
            return ({blur}) => {
              return builder.build(type, {blur});
            }
          }
          default:
            return () => {
              return null;
            }
      }
    }

    parse(adjustments: any): Filter[] {
      const adjustmentTypes: AdjustmentPresetType[] = Array.from(this.filterForType.keys());
      let filters = adjustmentTypes.map(t => this.getFilter(t)(adjustments)).filter((f) => !!f);
      return filters;
    }

    private getRangeValueForProperty(): Map<string, FilterRangeValues> {
        let map = new Map<string, FilterRangeValues>();
        for (let filtersProperties of Array.from(this.rangeValueForFilter.values())) {
          for (let propertyName of Array.from(filtersProperties.keys())) {
            map.set(propertyName, filtersProperties.get(propertyName));
          }
      }
      return map
    }

    private getRangeValueForFilter(): Map<AdjustmentPresetType, Map<string, FilterRangeValues>> {
      // default values are taken from here - https://github.com/BradLarson/GPUImage2/blob/master/examples/Mac/FilterShowcase/FilterShowcase/FilterOperations.swift#L113

      let mapping: Map<AdjustmentPresetType, Map<string, FilterRangeValues>> = new Map();
      for (let filterTypeStr of Array.from(this.filterForType.keys())) {
        let filterType = filterTypeStr as unknown as AdjustmentPresetType;
        if (!mapping.get(filterType)) {
          mapping.set(filterType, new Map<string, FilterRangeValues>());
        }

        let filter = this.filterForType.get(filterType);
        let allKeys = Object.keys(filter);
        for (let key of allKeys){

          let minVal = filter.minimumForKey(key);
          let maxVal = filter.maximumForKey(key);
          let defaultVal = filter.defaultForKey(key);

          let hasMissingValues = [minVal, maxVal, defaultVal].some((v) => [undefined, null].includes(v));
          if (!hasMissingValues){
            mapping.get(filterType).set(key, {
              minimumValue: minVal,
              maximumValue: maxVal,
              defaultValue: defaultVal
            });
          }
        }
      }
      return mapping;
    }

    isValidValue(value: any): boolean {
      return !(value == undefined || value == null || value == this.builder.defaultPlaceholder);
    }
}

export { AdjustmentFilterFactory, AdjustmentPresetType };