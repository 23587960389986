import { Point } from "./point";

export class Size {
    width: number;
    height: number

    constructor(width: number, height: number) {
        this.width = width;
        this.height= height;
    }

    multiply(scalar: number): Size {
        let newSize = new Size(Math.ceil(this.width * scalar), Math.ceil(this.height * scalar));
        return newSize;
    }

    center(): Point {
        return new Point(this.width / 2, this.height / 2);
    }

    aspectRatio(): number {
        return (this.height / this.width);
    }

    isEmpty(): boolean {
        return this.width == 0 && this.height == 0;
    }

    maxEdge(): number {
        return Math.max(this.width, this.height);
    }

    minEdge(): number {
        return Math.min(this.width, this.height);
    }
}
