export class AsyncTaskGroup {
    private pendingTasks: number;
    private resolve: (() => void) | null;
    private promise: Promise<void>;

    constructor() {
        this.pendingTasks = 0;
        this.resolve = null;
        this.promise = new Promise<void>((resolve) => {
            this.resolve = resolve;
        });
    }

    enter(): void {
        this.pendingTasks += 1;
    }

    leave(): void {
        this.pendingTasks -= 1;
        if (this.pendingTasks === 0 && this.resolve) {
            this.resolve();
        }
    }

    async done(): Promise<void> {
        if (this.pendingTasks === 0) {
            return;
        }
        await this.promise;
    }
}
