import { StaticImageObject } from '@/scenes/engine/objects';
import Outline from './Outline';
import { useEditorContext } from '@/scenes/engine';
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer';
import { selectImageElements } from '@/store/slices/imageElement/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const OutlineImage = () => {
  const canvasImageRenderer = CanvasImageRenderer.getInstance()
  const outlineFilter = canvasImageRenderer.outlineFilter

  const [scaleXBeforeFilter, setScaleXBeforeFilter] = useState(null)
  const [scaleYBeforeFilter, setScaleYBeforeFilter] = useState(null)
  const [filterScalingXBeforeFilter, setFilterScalingXBeforeFilter] = useState(null)
  const [filterScalingYBeforeFilter, setFilterScalingYBeforeFilter] = useState(null)

  const [preEffectsValue, setPreEffectsValue] = useState(null)
  const [preFiltersValue, setPreFiltersValue] = useState([])



  const updatedProperties = ['effects', 'filters', 'scaleX', 'scaleY', '_filterScalingX', '_filterScalingY', 'isLatest']

  const { activeObject, editor, canvas } = useEditorContext()  as unknown as any;
  const filterPacks = useSelector(selectImageElements)
  const [isLatest, setIsLatest] = useState(false)

  const setScaleBeforeFilter = (e)=>{
    if(activeObject){
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
    }
  }

  useEffect(() => {
    if(activeObject){
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
    }
  }, [JSON.stringify(activeObject)])

  const updateOptions = (object: StaticImageObject) => {
    let outlineState = object.effects?.outline
    setScaleXBeforeFilter(object.scaleX)
    setScaleYBeforeFilter(object.scaleY)
    setFilterScalingXBeforeFilter(object._filterScalingX)
    setFilterScalingYBeforeFilter(object._filterScalingY)
    
    // @ts-ignore
    let assign = Object.assign({}, object.effects)
    setPreEffectsValue(assign)
    //@ts-ignore
    let filters = Object.assign([], object.filters)
    setPreFiltersValue(filters)
    
    activeObject.on('modified', setScaleBeforeFilter)

    if (!outlineState) {
      return {
        thickness: null,
        color: null,
        promise: () => { 
          activeObject.off('modified', setScaleBeforeFilter)
        }
      }
    }

    return {
      thickness: outlineState?.thickness,
      color: outlineState?.color,
      promise: () => { 
        activeObject.off('modified', setScaleBeforeFilter)
      }
    }
  }

  const updateEffect = (finalValue = false) => {
    let sliderOptions: any = { isSliderUpdate: finalValue }
    if(activeObject.isLatest) {
      setIsLatest(activeObject.isLatest)
    }
    if (finalValue) {
      const updatedPropertiesValues = [preEffectsValue, preFiltersValue, scaleXBeforeFilter, scaleYBeforeFilter, filterScalingXBeforeFilter, filterScalingYBeforeFilter, isLatest]
      //@ts-ignore
      sliderOptions = {
        isSliderUpdate: finalValue,
        property: updatedProperties,
        value: updatedPropertiesValues
      }
      //@ts-ignore
      let assign = Object.assign({}, activeObject.effects)
      setPreEffectsValue(assign)
      //@ts-ignore
      let filters = Object.assign([], activeObject.filters)
      setPreFiltersValue(filters)
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
      setFilterScalingXBeforeFilter(activeObject._filterScalingX)
      setFilterScalingYBeforeFilter(activeObject._filterScalingY)
    }
    //@ts-ignore
    editor.handlers.objectsHandler.updateActive(
      { effects: activeObject.effects, filters: activeObject.filters },
      undefined,
      sliderOptions
    )
  }

  
  let queue: Array<() => Promise<void>> = [];
  let processing = false;

  const processQueue = async () => {
    if (processing) return; // Prevent multiple executions

    processing = true;
    while (queue.length > 0) {
      const action = queue.shift(); // Get the first action from the queue
      if (action) await action(); // Process the action if it exists
    }
    processing = false;
  }

  const addActionToQueue = (action: () => Promise<void>): void => {
    queue.push(action); // Add the action to the queue
    processQueue(); // Start processing if not already processing
  }

  const applyEffectOutline = async (isReset, thickness, color) => {
    addActionToQueue(async () => {
      let newOutlineState = {
        thickness: thickness,
        color: color || '#000',
      }
      let isUpdateObjectBorder = activeObject.isLatest
      //@ts-ignore
      if (isReset || activeObject.isLatest) {
        await canvasImageRenderer.render(activeObject, editor.handlers.frameHandler.getSize(), filterPacks);
      }
      activeObject.effects.outline = newOutlineState; 

      await canvasImageRenderer.render(
        activeObject,
        editor.handlers.frameHandler.getSize(),
        filterPacks
      );
      if(isUpdateObjectBorder) {
        editor.handlers.personalizationHandler.updateDrawBorder(activeObject)
      }
      canvas.requestRenderAll()
    });
  };

  const calcMinForKey = (key: string): number => {
    return outlineFilter.minimumForKey(key)
  }

  const calcMaxForKey = (key: string): number => {
    return outlineFilter.maximumForKey(key)
  }
  
  const defaultForKey = (key: string): any => {
    if (key == 'thickness') {
      return outlineFilter.defaultForKey(key);
    }
    if (key == 'color') {
      return '#000000';
    }
  }

  return (
    <Outline
      applyEffectLayerOutline={applyEffectOutline}
      updateOptions={updateOptions}
      updateEffect={updateEffect}
      calcMinForKey={calcMinForKey}
      calcMaxForKey={calcMaxForKey}
      defaultForKey={defaultForKey}
    />
  );
};

export default OutlineImage;
