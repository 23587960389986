// Import necessary modules

import { CloudManagerDelegate, CloudManager } from "./CloudManager";
import { DraftSyncItem, MergeStatus } from "./DraftSyncItem";
import { DraftSyncVersion } from "./DraftSyncVersion";
import { SyncManagerDelegate } from "./SyncManagerDelegate";
import { SyncStatus } from "./SyncStatus";
import { UserDevice } from "./UserDevice";
import { TemplateConfig } from "@/interfaces/editor";
import { FolderObject } from "./FolderObject";
import { CloudResponse, EmptyResponse } from "./CloudResponse";
import { SingleAccessGate } from "./dataTypes/SingleAccessGate";
import { DeviceCloudMetadata } from "./DeviceCloudMetadata";
import { FirebaseWrapper } from "@/firebase/FirebaseBaseWrapper";
import { RecycleBinManager } from "./RecycleBinManager";
import { Graph } from "./dataTypes/Graph";
import { AsyncTaskGroup } from "./dataTypes/AsyncTaskGroup";
import { customAmplitude } from "@/utils/customAmplitude";
import { CloudTempProject } from "./CloudTempProject";
import { BtDraftsManager } from "./BtDraftsManager";
import NetworkStatusService from "@/services/NetworkStatusService";

// Enums representing different sync states
export enum ShortSyncState {
    Connecting = 'connecting',
    Off = 'off',
    Paused = 'paused',
    On = 'on'
}

export enum SyncState {
    Connecting = 'connecting',
    NotSubscribed = 'notSubscribed',
    NotLoggedIn = 'notLoggedIn',
    NoConnection = 'noConnection',
    Uploading = 'uploading',
    Synced = 'synced'
}

// Interface representing the project state
export interface ProjectsState {
    total: number;
    localOnly: number;
    synced: number;
    uploading: number;
    notSynced: number;
}

// SyncManager class implementing CloudManagerDelegate interface
export class SyncManager implements CloudManagerDelegate {
    static shared = new SyncManager();
    didCompleteSetup = false;

    private delegates: Set<SyncManagerDelegate> = new Set();
    private isRegisterToCloud = false;
    
    idDeleteProject: any
    idDownloadProject: any

    private didCheckOtherDevices = false;
    private deviceById = new Map<string, UserDevice>();
    currentUserId: string | null = null;

    draftSyncItems: DraftSyncItem[] = [];
    private uploadingGate = new SingleAccessGate();
    private networkStatusService: NetworkStatusService;


    // Private constructor to enforce singleton pattern
    private constructor() {
        CloudManager.shared.delegate = this;
        this.currentUserId = CloudManager.shared.userId;
        this.networkStatusService = NetworkStatusService.getInstance();
        //TODO: find the right place to remove listener
        this.networkStatusService.addListener(this.networkStatusChanged.bind(this));
        // this.networkStatusService.removeListener(this.networkStatusChanged.bind(this));

        FirebaseWrapper.FirebaseBaseWrapper.shared.subscribe(FirebaseWrapper.Events.Login, (userId) => {
            this.login();
        })

        FirebaseWrapper.FirebaseBaseWrapper.shared.subscribe(FirebaseWrapper.Events.Logout, () => {
            this.logout();
        })
    }

    // Computed properties for current state and projects state
    static get currentState(): SyncState {
        const syncManager = SyncManager.shared;

        if (syncManager.isConnecting) {
            return SyncState.Connecting;
        } else if (!syncManager.isSubscribed) {
            return SyncState.NotSubscribed;
        } else if (!syncManager.isLoggedIn) {
            return SyncState.NotLoggedIn;
        } else if (!syncManager.hasConnection || syncManager.hasLimitedConnection) {
            return SyncState.NoConnection;
        }

        const isUploading = syncManager.getFilteredDraftSyncItems().some(item => item.hasLocal() && !item.isOutDated() && item.isCurrentVersionIsUploading());
        return isUploading ? SyncState.Uploading : SyncState.Synced;
    }

    static get currentStateShort(): ShortSyncState {
        switch (SyncManager.currentState) {
            case SyncState.Connecting:
                return ShortSyncState.Connecting;
            case SyncState.NotSubscribed:
            case SyncState.NotLoggedIn:
                return ShortSyncState.Off;
            case SyncState.NoConnection:
                return ShortSyncState.Paused;
            case SyncState.Uploading:
            case SyncState.Synced:
                return ShortSyncState.On;
        }
    }

    private canPotentiallySync(): boolean { return true }


    static get projectsState(): ProjectsState {
        const syncItems = SyncManager.shared.getFilteredDraftSyncItems();
        let total = syncItems.length;
        let localOnly = 0;
        let synced = 0;
        let uploading = 0;
        let notSynced = 0;

        syncItems.forEach(item => {
            if (item.hasLocal() && !item.isOutDated()) {
                if (item.isLocalOnly()) {
                    localOnly += 1;
                }

                if (item.isCurrentVersionIsUploading()) {
                    uploading += 1;
                } else if (item.isSynched()) {
                    synced += 1;
                } else if (item.isLocalOnly()) {
                    notSynced += 1;
                }
            }
        });

        return { total, localOnly, synced, uploading, notSynced };
    }

    // Method to add a delegate
    addDelegate(delegate: SyncManagerDelegate) {
        this.delegates.add(delegate);
    }

    removeDelegate(delegate: SyncManagerDelegate) {
        this.delegates.delete(delegate);
    }

    // Private setup method
    async setup() {
        CloudManager.shared.logWithTimestamp('sync: setup sync manager');
        this.loadLocalSavedItems();

        await this.reload()
    }

    logout(): void {
        CloudManager.shared.logWithTimestamp("sync: logout");
        this.currentUserId = null;
        this.clearSavedCloudMetaData();
        RecycleBinManager.shared.didLoggedOutFromCloud();
        this.isRegisterToCloud = false;
        this.setup();
        this.delegates.forEach(delegate => delegate.syncManagerHasUpdates());
    }
      
    reset(){
        this.currentUserId = null;
        this.isRegisterToCloud = false;
    }
      
    async login(): Promise<void> {
        CloudManager.shared.logWithTimestamp(`sync: --> did login with user: ${CloudManager.shared.userId}, device: ${UserDevice.current.id}, device type: ${UserDevice.current.typeName}`);
      
        // TODO Dror: yedidya can I do this?
        
        if (CloudManager.shared.userId == this.currentUserId && this.didCompleteSetup) {
          CloudManager.shared.logWithTimestamp("sync: same id as before, doesn't need to reload after login");
          return;
        }
        
        this.currentUserId = CloudManager.shared.userId;

        await this.reload();
      
        this.delegates.forEach(delegate => delegate.syncManagerHasStateChange());
      }
      

    // Computed properties for various states
    get isSyncEnabled(): boolean {
        // return SplitTesting.shared.sync.isActive;
        // TODO Dror: implement this.
        return true;
    }

    get useWifiOnly(): boolean {
        // TODO Dror: implement this.
        // return !BtLocalSettings.sharedInstance().syncWithCellularData;
        return false;
    }

    set useWifiOnly(value: boolean) {
        // TODO Dror: implement this.
        // BtLocalSettings.sharedInstance().syncWithCellularData = !value;
    }

    get isLoggedIn(): boolean {
        return CloudManager.shared.isLoggedIn;
    }

    get isSubscribed(): boolean {
        // TODO Dror: implement this.
        // return SubscriptionManager.shared.isSubscribedTo(SubscriptionManager.BtSubscriptionSync);
        return true;
    }

    get hasConnection(): boolean {
        // TODO Dror: implement this.
        // return AFNetworkReachabilityManager.shared().isReachable;
        let isOnline = this.networkStatusService.getStatus()
        return isOnline;
    }

    get hasLimitedConnection(): boolean {
        // TODO Dror: implement this.
        return this.useWifiOnly && this.hasConnection;
    }

    get  canRegisterToCloud(): boolean {
        // TODO Dror: implement this.
        return this.hasConnection && this.shouldSync;
    }
    

    get isConnecting(): boolean {
        // TODO Dror: implement this.
        return this.canRegisterToCloud && !this.isRegisterToCloud;
    }

    get shouldSync(): boolean {
        return this.isSyncEnabled && this.isSubscribed && this.isLoggedIn;
    }

    private createLocalProjectItems(): DraftSyncItem[] {
        const drafts = BtDraftsManager.getDrafts();
        return drafts.map(d => this.createSyncItemFromProject(d));
    }

    private createLocalProjectVersions(): DraftSyncVersion[] {
        const drafts = BtDraftsManager.getDrafts();
        return drafts.map(d => DraftSyncVersion.fromDraft(d));
    }

    private createSyncItemFromProject(project: TemplateConfig): DraftSyncItem {
        const version = DraftSyncVersion.fromDraft(project);
        const val = new DraftSyncItem(version);
        val.merge();
        return val;
    }

    private getSavedSyncItems(): DraftSyncItem[] | null {
        CloudManager.shared.logWithTimestamp("sync: loading saved sync items");
        const syncItemsData = localStorage.getItem("kCloudSyncItems");
        
        if (!syncItemsData) {
            return null;
        }
        try {
            const parsedData: any[] = JSON.parse(syncItemsData);
            const syncItems = parsedData.map(itemData => {
                const item = new DraftSyncItem(itemData);
                return item;
            });
            return syncItems;
        } catch (error) {
            console.error("Error parsing saved sync items:", error);
            return null;
        }
    }

    private loadLocalSavedItems(): void {
        CloudManager.shared.logWithTimestamp("sync: loading local saved data");
        const dict = new Map(SyncManager.getSavedCloudDevices().map(d => [d.id, d]));

        const array: DraftSyncItem[] = (() => {
            const cached = this.getSavedSyncItems();
            if (cached) {
                cached.forEach(item => item.clearLocalVersionBeforeAppendingNewData());
                const items = this.doMerge(cached, [this.createLocalProjectVersions()]);
                items.forEach(item => item.merge());
                return items;
            } else {
                return this.createLocalProjectItems();
            }
        })();

        this.deviceById = new Map(dict);
        this.draftSyncItems = array;
    }

    private checkOtherDevices() {
        if (this.didCheckOtherDevices || this.shouldSync || !this.canPotentiallySync() || !this.hasConnection) {
            return;
        }

        (async () => {
            const res = await CloudManager.shared.didHaveSync();
            this.didCheckOtherDevices = true;

            if (res && !this.shouldSync) {
                CloudManager.shared.logWithTimestamp('sync: enabling sync split test since the user has it on other device');
                await this.reload();
            }
        })();
    }

    async reload(): Promise<DraftSyncItem[]> {
        if (!this.canRegisterToCloud) {
            this.checkOtherDevices();
            this.isRegisterToCloud = false;
            RecycleBinManager.shared.cleanup(true);
            this.didCompleteSetup = false;
            return this.draftSyncItems;
        }

        CloudManager.shared.logWithTimestamp('sync: reloading data from server');

        const devicesMetadataResponse = await CloudManager.shared.downloadCloudMetadata();
        if (!devicesMetadataResponse || !devicesMetadataResponse.succeed) {
            this.isRegisterToCloud = false;
            return this.draftSyncItems;
        }
        let devicesMetadata = (devicesMetadataResponse as CloudResponse<DeviceCloudMetadata[]>).value;
        this.isRegisterToCloud = true;

        const dict = new Map(devicesMetadata.map(meta => [meta.device.id, meta.device]));
        const currentDevice = UserDevice.current;
        dict.set(currentDevice.id, currentDevice);

        this.deviceById = dict;

        const versions = devicesMetadata.map(meta => meta.draftSyncVersions);
        const unsafeCloudFeedCloudRes = await CloudManager.shared.downloadCloudUnsafeFeed()
        let unsafeCloudFeed = unsafeCloudFeedCloudRes.succeed ? (unsafeCloudFeedCloudRes as CloudResponse<DraftSyncVersion[]>).value : []
        await this.merge(versions, unsafeCloudFeed);

        // Call async update method
        await this.updateDeviceNeededProjects();

        // Cleanup recycle bin
        RecycleBinManager.shared.cleanup(false);

        this.didCompleteSetup = true;

        return this.draftSyncItems;
    }

    private hasUpdates(prevSavedDevices: Map<string, UserDevice>, cloudUpdatedDevices: Map<string, UserDevice>): boolean {
        const currentId = UserDevice.current.id;

        if (prevSavedDevices.size !== cloudUpdatedDevices.size) return true;

        for (const [key, prevDevice] of prevSavedDevices) {
            const newDevice = cloudUpdatedDevices.get(key);
            if (!newDevice || prevDevice.lastUpdate.getTime() !== newDevice.lastUpdate.getTime()) {
                return true;
            }
        }

        return false;
    }

    private async merge(versions: DraftSyncVersion[][], unsafeFeed: DraftSyncVersion[] = []): Promise<void> {
        CloudManager.shared.logWithTimestamp("sync: merging versions");
        if (!this.draftSyncItems) {
            throw new Error("Assertion failure");
        }
    
        const currentItems = this.draftSyncItems;
        const prevFeedCount = this.getFilteredDraftSyncItems().length;
        currentItems.forEach(item => item.clearCloudVersionBeforeAppendingNewData());
    
        const items = this.doMerge(currentItems, versions, unsafeFeed);
    
        let feedHasChange = false;
    
        const screenshotGroup = new AsyncTaskGroup();
        let loadingScreenshots = 0;
        const maxPrefetch = 15;
    
        for (const item of items) {
            const prefetchGroup = loadingScreenshots < maxPrefetch ? screenshotGroup : undefined;
            const res = await item.merge(prefetchGroup);
    
            if (MergeStatus.hasFeedChange(res)) {
                feedHasChange = true;
            }
    
            if (MergeStatus.shouldLoadScreenshot(res)) {
                loadingScreenshots++;
            }
        }
    
        if (!feedHasChange) {
            // Check if some were removed from old list
            const updatedFeedCount = this.getFilteredDraftSyncItems(items).length;
            feedHasChange = prevFeedCount !== updatedFeedCount;
        }
    
        this.draftSyncItems = items;
    
        this.saveLocallyCloudMetaData();
    
        if (feedHasChange) {
            await screenshotGroup.done();
            this.delegates.forEach(delegate => delegate.syncManagerHasUpdates());
        }
    }
    

    private doMerge(items: DraftSyncItem[], devicesVersions: DraftSyncVersion[][], unsafeFeed: DraftSyncVersion[] = []) {
        let itemById = new Map(items.map(item => [item.draftId, item]));

        let dateOfItems: Map<number, ProjectDuplicationPerDevice> = new Map();
        let duplicationById: Map<string, ProjectDuplicationPerDevice> = new Map();

        function addItemToOrderCalculation(item: DraftSyncItem, deviceIndex: number) {
            const date = item.createDate ? Math.round(item.createDate.getTime() / 10) * 10 : undefined;
            if (date === undefined) return;

            let duplications: ProjectDuplicationPerDevice;

            if (duplicationById.has(item.draftId)) {
                duplications = duplicationById.get(item.draftId)!;
            } else if (dateOfItems.has(date)) {
                duplications = dateOfItems.get(date)!;
            } else {
                const arraysCount = 1 + devicesVersions.length + (unsafeFeed.length > 0 ? 1 : 0);
                duplications = new ProjectDuplicationPerDevice(date, arraysCount);
            }

            duplicationById.set(item.draftId, duplications);
            dateOfItems.set(duplications.date, duplications);

            duplications.itemsArray[deviceIndex].push(item);
        }

        items.forEach(item => addItemToOrderCalculation(item, 0));

        let deviceIndex = 0;
        devicesVersions.forEach(versions => {
            deviceIndex += 1;
            versions.forEach(version => {
                const key = version.draftId;
                if (itemById.has(key)) {
                    const existing = itemById.get(key)!;
                    existing.append(version);
                    addItemToOrderCalculation(existing, deviceIndex);
                } else {
                    if (version.isCurrentDeviceVersion || (version.syncStatus !== SyncStatus.Uploading && version.syncStatus !== SyncStatus.WaitingForUpload)) {
                        const item = new DraftSyncItem(version);
                        itemById.set(key, item);
                        addItemToOrderCalculation(item, deviceIndex);
                    }
                }
            });
        });

        unsafeFeed.forEach(version => {
            if (itemById.has(version.draftId)) {
                const item = itemById.get(version.draftId)!;
                addItemToOrderCalculation(item, deviceIndex + 1);
            }
        });

        const sortedDates = Array.from(dateOfItems.keys()).sort((a, b) => b - a);
        const sortedProjects = sortedDates.flatMap(date => dateOfItems.get(date)?.sortedItems() || []);
        const res = sortedProjects.filter(item => !item.shouldRemoveFromMetadata());

        return res;
    }


    private getCurrentDeviceSyncItems(): DraftSyncItem[] {
        return this.draftSyncItems.filter(item => item.local || item.deviceCloudVersion);
    }

    private notifyDelegates(method: keyof SyncManagerDelegate) {
        this.delegates.forEach(delegate => {
            if (typeof delegate[method] === 'function') {
                delegate[method]();
            }
        });
    }

    async didSaveProject(project: TemplateConfig) {
        let item = this.getSyncItem(project.draftGuid)
        item?.updateFromDraft(project)
        await this.updateProjectStatus(project.draftGuid, SyncStatus.WaitingForUpload, null)
    }

    async didDuplicateLocalProject(project: TemplateConfig) {
        console.log(`sync: did duplicate local project: ${project.draftGuid}`)
        if (this.shouldSync) {
            await this.uploadProject(project, null)
        }
    }
    
    async downloadProject(version: DraftSyncVersion, updateVersion: boolean = false, completion?: (draft: TemplateConfig | null, objects: any[]) => void): Promise<void> {
        if (!this.isRegisterToCloud) {
            completion?.(null, null);
            return;
        }
        
        const item = this.getSyncItem(version.draftId);
        if (!item) {
            completion?.(null, null);
            return;
        }
        
        CloudManager.shared.logWithTimestamp(`sync: download version: ${JSON.stringify(version)}`);
        
        const device = this.getDevice(version.deviceOwnerId);
        if (!device) {
            completion?.(null, null);
            return;
        }
        
        if (updateVersion && item.deviceCloudVersion) {
            item.deviceCloudVersion.isInConflict = false;
        }
        
        await CloudManager.shared.cancelFetchingProject(version.draftId);
        
        item.didStartDownloading();
        
        
        const res = await CloudManager.shared.fetchProject(version.draftId, device);
    
        if (res.succeed) {
            customAmplitude('sync - download project', {})
        } else {
            customAmplitude('sync - failed to download project', {})
        }
        
        if (!res.succeed){
            completion?.(null, null);
            return;
        }

        const tempProject = (res as CloudResponse<CloudTempProject>).value 
        if (!tempProject) {
            completion?.(null, null);
            return;
        }

        let localProjectBefore: TemplateConfig | null = null;
        this.draftSyncItems.find(item => {
            if (item.local?._draft) {
                localProjectBefore = item.local._draft;
            }
            return item.draftId === version.draftId;
        });
        
        const draftResponse = await tempProject.saveToProjects(updateVersion, localProjectBefore);
        if (!draftResponse.succeed){
            completion?.(null, null);
            return;
        }
        
        const [newDraft, layers] = (draftResponse as CloudResponse<[TemplateConfig, any[]]>).value
        if (newDraft) {
            CloudManager.shared.logWithTimestamp(`sync: updating fetched version from fetched draft: ${newDraft.draftGuid}, modified date: ${newDraft.draftModificationDate ?? newDraft.draftCreationDate}`);
            item.updateFromDraft(newDraft);
            completion?.(newDraft, layers);
            CloudManager.shared.logWithTimestamp(`sync: upload project after fetching was complete: ${version.draftId}`);
            if (version.isCurrentDeviceVersion !== true) {
                // await this.uploadProject(newDraft, version.deviceOwnerId);
            }
            tempProject.clear();
        }
    }

    private async updateDeviceNeededProjects() {
        let uploadingProjects: Map<string, DraftSyncItem> = new Map();
        let deletingProjects: Map<string, DraftSyncItem> = new Map();
        const deviceItems = this.getCurrentDeviceSyncItems();
        let changed = false;

        deviceItems.forEach(item => {
            if (item.shouldUploadToCloud()) {
                if (!item.isUploading()) {
                    changed = true;
                    item.updateStatus(SyncStatus.Uploading);
                }
                uploadingProjects.set(item.draftId, item);
            } else if (item.shouldDeleteFromCloud()) {
                changed = true;
                item.updateStatus(SyncStatus.Deleting, null, false);
                deletingProjects.set(item.draftId, item);
            }
        });

        if (changed) {
            this.notifyDelegates('syncManagerHasProjectStateChange');
        }

        const localVersions = deviceItems.map(item => item.deviceCloudVersion).filter(Boolean);

        if (uploadingProjects.size === 0 && deletingProjects.size === 0) {
            CloudManager.shared.logWithTimestamp('sync: device cloud data is up to date');
            return;
        }

        CloudManager.shared.logWithTimestamp(`sync: update devices-cloud projects. added: ${uploadingProjects.size}, deleted: ${deletingProjects.size}`);

        if (changed && !(await this.uploadProjectsMetaDataArray(localVersions, false)).succeed) {
            return;
        }

        const uploadProjects = Array.from(uploadingProjects.values()).map(item => item.local?._draft).filter(Boolean);

        uploadProjects.forEach(project => {
            if (project) {
                this.uploadProject(project);
            }
        });

        await CloudManager.shared.deleteProjects(Array.from(deletingProjects.keys()), async (projectId) => {
            const item = deletingProjects.get(projectId);
            if (item) {
                item.updateStatus(SyncStatus.Deleted, null, false);
                CloudManager.shared.logWithTimestamp(`sync: update project - did delete project: ${projectId}`);
                await this.uploadProjectsMetaDataArray(localVersions, true);
                CloudManager.shared.notifyStatusChange();
            }
        });
    }

    private async uploadProjectsMetaDataArray(projects: DraftSyncVersion[], updateAll: boolean): Promise<EmptyResponse> {
        this.saveLocallyCloudMetaData();

        const versions = projects ?? this.getCurrentDeviceSyncItems().map(item => item.deviceCloudVersion).filter(Boolean);

        CloudManager.shared.logWithTimestamp(`sync: will upload device metadata of projects: ${versions.length}`);

        if (!this.shouldSync) {
            EmptyResponse.sendError("sync: user has no sync feature - can't upload metadata");
        }

        if (!this.hasConnection) {
            EmptyResponse.sendError("sync: no connection - can't upload metadata");
        }

        if (!this.isRegisterToCloud) {
            EmptyResponse.sendError("sync: can't upload metadata, manager isn't register to cloud");
        }

        const allFeed = updateAll ? this.draftSyncItems.filter(item => item.hasCloudVersion()).map(item => item.mostUpdatedVersion) : undefined;

        const res = await CloudManager.shared.uploadProjectsMetaDataArray(versions, allFeed);

        if (updateAll) {
            CloudManager.shared.notifyStatusChange();
        }

        return res;
    }

    private saveLocallyCloudMetaData(): void {
        CloudManager.shared.logWithTimestamp("sync: saving data locally");
        if (this.draftSyncItems.length === 0) {
            CloudManager.shared.logWithTimestamp("sync: saving empty cloud metadata");
        }
        UserDevice.current.save();
        const jsonDevices = JSON.stringify(Array.from(this.deviceById.values()));
        const items = this.draftSyncItems;
        const jsonItems = JSON.stringify(items);
        CloudManager.shared.logWithTimestamp(`sync: save devices. current device: ${UserDevice.current.id}`);
        localStorage.setItem("kCloudDevices", jsonDevices);
        localStorage.setItem("kCloudSyncItems", jsonItems);
    }

    private clearSavedCloudMetaData(): void {
        CloudManager.shared.logWithTimestamp("sync: clear locally saved data");
        localStorage.removeItem("kCloudDevices");
        localStorage.removeItem("kCloudSyncItems");
        this.loadLocalSavedItems();
    }

    private static getSavedCloudDevices(): UserDevice[] {
        CloudManager.shared.logWithTimestamp("sync: loading locally cloud devices metadata");
        CloudManager.shared.logWithTimestamp(`sync: get saved devices. current device: ${UserDevice.current.id}`);
        const devicesData = localStorage.getItem("kCloudDevices");
        const devices: UserDevice[] = devicesData ? JSON.parse(devicesData) : [];
        // Use always updated data on current device, and not the saved one (for example, the app version may change)
        return [UserDevice.current, ...devices.filter(d => !d.isCurrentDevice)];
    }


    // Methods for handling project sync
    getFilteredDraftSyncItems(items: DraftSyncItem[] = this.draftSyncItems, device?: UserDevice): DraftSyncItem[] {
        return items.filter(item => !RecycleBinManager.shared.itemExists(item.draftId) && !item.shouldRemoveFromList() && item.hasDevice(device));
    }

    getSyncItem(byId: string): DraftSyncItem | undefined {
        return this.draftSyncItems.find(item => item.draftId === byId);
    }

    async deleteProjects(projectIds: string[]) {
        if (projectIds.length === 0) return;
        console.log(`sync: delete projects: ${projectIds}`);
    
        RecycleBinManager.shared.didDeleteProjects(projectIds);
    
        const items = projectIds.map(id => this.getSyncItem(id)).filter(item => item !== undefined);
        if (items.length === 0) return;
    
        let localOnlyItems: DraftSyncItem[] = [];
        let cloudItems: DraftSyncItem[] = [];
    
        items.forEach(item => {
            if (item.mostUpdatedVersion.syncStatus === 'none') {
                localOnlyItems.push(item);
            } else {
                cloudItems.push(item);
            }
        });
    
        if (localOnlyItems.length > 0) {
            const localIds = localOnlyItems.map(item => item.draftId);
            this.draftSyncItems = this.draftSyncItems.filter(item => !localIds.includes(item.draftId));
            this.saveLocallyCloudMetaData();
            this.delegates.forEach(delegate => delegate.syncManagerHasUpdates());
        }
    
        if (cloudItems.length === 0) return;
    
        let shouldUpdateOtherDevices = false;
        let deleteItemIds: string[] = [];
    
        cloudItems.forEach(item => {
            if (!item.deviceCloudVersion) {
                const version = item.local?.deviceCloudVersionCopy() || item.mostUpdatedVersion.deviceCloudVersionCopy();
                item.append(version);
            }
    
            if (item.deviceCloudVersion && item.deviceCloudVersion.syncStatus !== 'deleted') {
                item.updateStatus(SyncStatus.Deleting, null, false);
                deleteItemIds.push(item.draftId);
            } else {
                shouldUpdateOtherDevices = true;
                item.updateStatus(SyncStatus.Deleted, null, false);
            }
        });
    
        // Async task for handling metadata updates and deletion
        await (async () => {
            const uploadSuccess = await this.uploadProjectsMetaDataArray(null, shouldUpdateOtherDevices);
            if (!uploadSuccess) return;
    
            this.delegates.forEach(delegate => delegate.syncManagerHasUpdates());
    
            // Delete cloud projects asynchronously
            await this.asyncForEach(deleteItemIds, async (projectId) => {
                await CloudManager.shared.deleteProject(projectId);
            });
        })();
    }
    
    // Utility function to handle async operations concurrently with limited concurrency
    async asyncForEach(array: any[], callback: (item: any) => Promise<void>, maxConcurrent: number = 3) {
        const executing: Promise<void>[] = [];
        for (const item of array) {
            const p = Promise.resolve().then(() => callback(item));
            executing.push(p);
    
            if (executing.length >= maxConcurrent) {
                await Promise.race(executing);
                executing.splice(executing.indexOf(p), 1);
            }
        }
        await Promise.all(executing);
    }
    
    getDevice(id: string): UserDevice | null {
        if (id == UserDevice.current.id) {
            return UserDevice.current
        }
        return this.deviceById.get(id)
    }

    // CloudManagerDelegate methods
    cloudManagerDidUploadMetaData() {
        CloudManager.shared.logWithTimestamp('sync: cloud did upload metadata');
    }

    cloudManagerDidDetectMetaDataChange() {
        if (this.shouldSync) {
            setTimeout(async () => await this.reload(), 100);
        }
    }

    cloudManagerDidReceivedCloudFolders(folders: FolderObject[]) {
        if (this.shouldSync) {
            CloudManager.shared.logWithTimestamp('sync: updating with cloud folders. count: ', folders.length);
            // Implementation for handling cloud folders
        }
    }

    // Notification handlers
    networkStatusChanged() {
        // if (!this.didCompleteSetup) return;
        this.delegates.forEach(delegate => delegate.syncManagerHasStateChange());
        if (!this.hasConnection) {
            this.isRegisterToCloud = false;
        } else if (this.hasLimitedConnection && this.useWifiOnly) {
            CloudManager.shared.logWithTimestamp('sync: wifi isn\'t available, canceling all uploads');
            CloudManager.shared.cancelAllUploads();
        }
        setTimeout(async () => await this.reload(), 0);
    }

    subscriptionStateChanged() {
        this.delegates.forEach(delegate => delegate.syncManagerHasStateChange());
        if (!this.shouldSync) {
            this.isRegisterToCloud = false;
        } else {
            setTimeout(async () => await this.reload(), 0);
        }
    }

    async uploadProject(project: TemplateConfig, otherDeviceId: string = null, force: boolean = false) : Promise<boolean> {
        const projectId = project.draftGuid;
        if (!projectId) return;

        if (!this.uploadingGate.enterIfPossible(projectId)) {
            CloudManager.shared.logWithTimestamp(`sync: uploading project is already active: ${projectId}`);
            return;
        }

        RecycleBinManager.shared.uploadingProject(projectId);
        CloudManager.shared.logWithTimestamp(`sync: uploading project files: ${projectId}`);

        try {
            return await this.runUploadProjectFlow(project, otherDeviceId, force);
        } finally {
            this.uploadingGate.leave(projectId);
        }
    }

    private async runUploadProjectFlow(project: TemplateConfig, otherDeviceId: string = null, force: boolean = false): Promise<boolean> {
        const projectId = project.draftGuid;
        if (!projectId) {
            return false;
        }

        if (!this.didCompleteSetup) {
            console.error(`sync: didCompleteSetup is fasle - failed to upload of project: ${project}`);
            return false;
        }

        const updateTime = this.getProjectTime(project);
        if (!updateTime) {
            console.error(`sync: failed to get update time of project: ${project}`);
            return false;
        }

        CloudManager.shared.logWithTimestamp(`sync: running project uploading of project: ${projectId}, modified date: ${updateTime}`);

        if (!(await this.updateProjectStatus(projectId, SyncStatus.Uploading, otherDeviceId)).succeed) {
            return false;
        }

        if (!force && this.hasLimitedConnection) {
            return false;
        }

        const syncItem = this.getSyncItem(projectId);

        try {
            const didStart = () => {
                if (syncItem) syncItem.didStartUploading();
            };

            const uploadResult = await CloudManager.shared.uploadProject(project, didStart);
            if (uploadResult.error) {
                console.warn(uploadResult.error);
                return false;
            }

            const url = await CloudManager.shared.getScreenshotUrl(syncItem?.deviceCloudVersion);
            if (syncItem?.deviceCloudVersion) {
                syncItem.deviceCloudVersion.screenshotUrl = url;
            }

            const updateTimeAfterUpload = this.getProjectTime(project);
            if (!updateTimeAfterUpload) {
                console.error(`sync: failed to get update time after upload of project: ${project}`);
                return false;
            }

            if (updateTimeAfterUpload.getTime() > updateTime.getTime()) {
                CloudManager.shared.logWithTimestamp(`sync: upload finished, but another change should be uploaded again. project: ${projectId}`);
                await this.runUploadProjectFlow(project, otherDeviceId, force);
                return false;
            }

            await this.updateProjectStatus(projectId, SyncStatus.Updated, otherDeviceId);
            
            return true
        } finally {
            this.uploadingGate.leave(projectId);
        }
    }

    private getProjectTime(project: TemplateConfig): Date | null {
        return project.draftModificationDate || project.draftCreationDate || null;
    }

    private async updateProjectStatus(projectId: string, status: SyncStatus, otherDeviceId: string = null): Promise<EmptyResponse> {
        const item = this.getSyncItem(projectId);
        if (!item) {
            return EmptyResponse.sendError(`sync: change project status: can't find the project on this device: ${projectId}`);
        }

        if (item.deviceCloudVersion?.syncStatus === status && !otherDeviceId) {
            return EmptyResponse.success()
        }

        CloudManager.shared.logWithTimestamp(`sync: update project status: ${status}, project: ${projectId}`);
        item.updateStatus(status, otherDeviceId, !SyncStatus.isDeleted(status));

        const localVersions = this.getCurrentDeviceSyncItems().map(item => item.deviceCloudVersion).filter(Boolean);

        if (status === SyncStatus.WaitingForUpload) {
            this.saveLocallyCloudMetaData();
            return EmptyResponse.success()
        }

        let result = await this.uploadProjectsMetaDataArray(localVersions, status === SyncStatus.Deleted || status === SyncStatus.Updated)
        if (result.succeed) {
            return EmptyResponse.success()
        }

        return EmptyResponse.sendError("Failed to update project status");
    }
}

// Placeholder class for ProjectDuplicationPerDevice used in merge
class ProjectDuplicationPerDevice {
    date: number;
    itemsArray: DraftSyncItem[][];

    constructor(date: number, arrays: number) {
        this.date = date;
        this.itemsArray = Array.from({ length: arrays }, () => []);
    }

    sortedItems(): DraftSyncItem[] {
        return Graph.sortByKahnsAlgorithm(this.itemsArray, item => item.draftId);
    }
}

