import { useState } from 'react';
import Outline from './Outline';
import { useEditorContext } from '@/scenes/engine';
import { StaticText } from 'fabric/fabric-impl';
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer';

const OutlineText = () => {
  const [preStrokeValue, setPreStrokeValue] = useState(null)
  const [preStrokeWidthValue, setPreStrokeWidthValue] = useState(0)

  const updatedProperties = ['stroke', 'strokeWidth']
  const canvasImageRenderer = CanvasImageRenderer.getInstance()
  const outlineFilter = canvasImageRenderer.outlineFilter

  const { activeObject, canvas } = useEditorContext() as unknown as any;

  const updateOptions = (object: StaticText) => {
    return {
      thickness: object.strokeWidth / Math.max(activeObject.width, activeObject.height),
      color: object.stroke,
      promise: () => { }
    }
  }


  const updateEffect = (finalValue = false) => {
    let sliderOptions: any = { isSliderUpdate: finalValue }
    if (finalValue) {
      const updatedPropertiesValues = [preStrokeValue, preStrokeWidthValue]
      //@ts-ignore
      sliderOptions = {
        isSliderUpdate: finalValue,
        property: updatedProperties,
        value: updatedPropertiesValues
      }
      setPreStrokeValue(activeObject.stroke)
      setPreStrokeWidthValue(activeObject.strokeWidth)
    }
  }

  let queue: Array<() => Promise<void>> = [];
  let processing = false;

  const processQueue = async () => {
    if (processing) return; // Prevent multiple executions

    processing = true;
    while (queue.length > 0) {
      const action = queue.shift(); // Get the first action from the queue
      if (action) await action(); // Process the action if it exists
    }
    processing = false;
  }

  const addActionToQueue = (action: () => Promise<void>): void => {
    queue.push(action); // Add the action to the queue
    processQueue(); // Start processing if not already processing
  }

  const applyEffectOutline = async (isReset, thickness, color) => {
    addActionToQueue(async () => {
      const defaultColor = defaultForKey('color');
      const defaultThickness = defaultForKey('thickness');
      const maxDimension = Math.max(activeObject.width, activeObject.height);

      activeObject.stroke = color || defaultColor;
      activeObject.strokeWidth = Math.floor((color && thickness === 0 ? defaultThickness : thickness) * maxDimension);
      canvas.requestRenderAll()
    })
  };

  const calcMinForKey = (key: string): number => {
    return outlineFilter.minimumForKey(key)
  }

  const calcMaxForKey = (key: string): number => {
    return outlineFilter.maximumForKey(key)

  }

  const defaultForKey = (key: string): any => {
    if (key == 'thickness') {
      return outlineFilter.defaultForKey(key);
    }
    if (key == 'color') {
      return '#ffc900';
    }
  }
  
  return (
    <Outline
      applyEffectLayerOutline={applyEffectOutline}
      updateOptions={updateOptions}
      updateEffect={updateEffect}
      calcMinForKey={calcMinForKey}
      calcMaxForKey={calcMaxForKey}
      defaultForKey={defaultForKey}
    />
  );
};

export default OutlineText;