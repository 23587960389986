export function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    // Remove the hash at the start if it’s there
    hex = hex.replace('/^#/', '');
    // Parse the r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return { r, g, b };
}
export function isColorBright(hex: string): boolean {
    const rgb = hexToRgb(hex);
    if (!rgb) {
        throw new Error('Invalid hex color');
    }
    // Calculate brightness (standard formula)
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    // Return true if the color is bright, false if dark
    return brightness > 128;
}

export function normalize(
    value: number, 
    minInputValue: number, 
    maxInputValue: number, 
    minOutputValue: number, 
    maxOutputValue: number
): number {
    let normalizedBzrtValue = (value - minInputValue) / Math.abs(maxInputValue - minInputValue);
    let normalizedFilterValue = normalizedBzrtValue * (maxOutputValue - minOutputValue) + minOutputValue;
    return normalizedFilterValue;
}


export interface FontMetrics {
    ascent: number, 
    descent: number,
    width: number,
    leading: number,
    lineHeight: number
};
  
export  interface FabricFontMetrics extends FontMetrics {
    leading: number,
    textHeight: number,
    lineHeightRatio: number
};
  
// https://stackoverflow.com/questions/1134586/how-can-you-find-the-height-of-text-on-an-html-canvas
function getFontMetrics(text: string, font: string): FontMetrics {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;

    // Measure the text
    const textMetrics = context.measureText(text);
    // https://chatgpt.com/share/e/6739f859-44b0-8009-b614-31b646a04af9
    let webDPI = 96;
    let mobilePrintDpi = 72;
    let dpiRatio = mobilePrintDpi / webDPI;
    let ascent = textMetrics.fontBoundingBoxAscent * dpiRatio;
    let descent = textMetrics.fontBoundingBoxDescent * dpiRatio;
    let lineHeight = (ascent + descent) 

    return {
      ascent: ascent,
      descent: descent,
      width: textMetrics.width,
      lineHeight: lineHeight,
      leading: 0
    }
  }
// Function to get Fabric font metrics including lineHeight
export function getFabricFontMetrics(fontSize: number, fontFamily: string, text: string): FabricFontMetrics {
    const font = `${fontSize}pt ${fontFamily}`;
    const textMetrics = getFontMetrics(text, font);
    const fontMetrics = getFontMetrics('Hg', font);

    return {
        ascent: fontMetrics.ascent,
        descent: fontMetrics.descent,
        width: textMetrics.width,
        leading: textMetrics.leading,
        lineHeight: fontMetrics.lineHeight,
        lineHeightRatio: fontMetrics.lineHeight / fontSize,
        textHeight: fontMetrics.ascent + fontMetrics.descent
    };
}