const firebaseConfig = {
    apiKey: "AIzaSyAEQoe9BI6h8zYn3p_WKxY4hEb-QNoJM6Y",
    authDomain: "bazaart-app.firebaseapp.com",
    databaseURL: "https://bazaart-app.firebaseio.com",
    projectId: "bazaart-app",
    storageBucket: "bazaart-app.appspot.com",
    messagingSenderId: "391062239788",
    appId: "1:391062239788:web:8ece97ae017ba8e8cc2f89",
    measurementId: "G-YJPGJ6TLV7"
};

export function getFirebaseConfig() {
    if (!firebaseConfig || !firebaseConfig.apiKey) {
        throw new Error('No Firebase configuration object provided.' + '\n' +
            'Add your web app\'s configuration object to firebase-config.ts');
    } else {
        return firebaseConfig;
    }
}