import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} x={0.5} fill="#0DB08C" rx={20} />
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth={1.302}
      d="M27.121 14.131c.278.21.329.601.112.872L19.22 25.047a.649.649 0 0 1-.94.072l-4.579-4.185a.61.61 0 0 1-.029-.879.65.65 0 0 1 .902-.028l4.069 3.718 7.584-9.505a.649.649 0 0 1 .894-.109Z"
    />
  </svg>
)
export default SvgComponent
