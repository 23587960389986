import Icons from '../../../Icons'
import { KIND } from 'baseui/button'
import { useEditorContext } from '@/scenes/engine'
import { useEffect, useState } from 'react'
import ColorsPackContainer from '@/ColorsPackContainer'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import SliderCustom from '@/components/SliderCustom'
import { LabelLarge } from 'baseui/typography'
import { lightTheme } from '@/customTheme'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { ObjectType } from '@/scenes/engine/common/constants'

function Outline({ applyEffectLayerOutline, updateOptions, updateEffect, calcMaxForKey, calcMinForKey, defaultForKey}) {
  const [thicknessValue, setThicknessValue] = useState(calcMinForKey('thickness'))
  const [colorValue, setColorValue] = useState(null)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const [didManuallyChange, setDidManuallyChange] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [isColor, setIsColor] = useState(false)
  const [isFirstChange, setIsFirstChange] = useState(true)

  const activeObject = useEditorContext().activeObject as unknown as any
  const editor = useEditorContext().editor

  const handleObjectOutline = () => {
    let {thickness, color, promise} = updateOptions(activeObject);
    
    if (thickness !== null && color !== null ) {
      setIsFirstChange(false)
      setThicknessValue(thickness)
      setColorValue(color)
    } else {
      reset()
      setIsReset(false)
      setDidManuallyChange(false)
      setIsColor(false)
      setIsFirstChange(true)
    }
    return promise
  }
  
  useEffect(() => {
    if(activeObject){
      return handleObjectOutline()
    }
  }, [activeObject]);

  useEffect(() => {
    if (!editor) return
    const handleChanges = (e) => {
      if(e.afterUndo) {
        setDidManuallyChange(false)
      }
    }
    editor.on('history:changed', handleChanges)
    return () => {
      editor.off('history:changed', handleChanges)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])
  
  const updateThickness = (value: number) => {
    setDidManuallyChange(true)
    setThicknessValue(value)
  }

  const updateColor = (color: string) => {
    setDidManuallyChange(true)
    setColorValue(color)
    setIsColor(true)
  }

  const reset = () => {
    setDidManuallyChange(true)
    setThicknessValue(calcMinForKey('thickness'))
    setColorValue(null)
    setIsReset(!isFirstChange)
  }

  const applyEffectOutline = async () => {
    if(isColor || isReset) {
      updateEffect(true)
      if (isColor) { setIsColor(false) }
    }
    await applyEffectLayerOutline(isReset, thicknessValue, colorValue)
    if (isReset) {
      setIsReset(false)
      setIsFirstChange(true)
    }
  }

  useEffect(() => {
    if (!didManuallyChange) {
      return;
    }
    if (isFirstChange){
      setIsFirstChange(false);
      setThicknessValue(pre => (pre ? pre : defaultForKey('thickness')))
      setColorValue(pre => (pre ? pre : defaultForKey('color')))
      return
    }
    (async () => {
      await applyEffectOutline()
    })()
  }, [thicknessValue, colorValue])

  const { t } = useTranslation()

  return (
    <>

          <SliderCustom
            icon={<Icons.Outline size={24} />}
            name={t("Outline")}
            minValue={0}
            maxValue={100}
            listValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            onChangeValue={(uiValue, logicValue) => {
              updateThickness(logicValue)
              updateEffect(false)
            }}
            value={thicknessValue}
            logicMaximum={calcMaxForKey('thickness')}
            logicMinimum={calcMinForKey('thickness')}
            onFinalChangeValue={(uiValue, logicValue) => {
              updateEffect(true)
            }}
            style={{marginTop: 0}}
          />
          <ColorsPackContainer
            style={{
              boxSizing: 'border-box',
              padding: '0',
              width: '300px',
              paddingRight: '8px',
            }}
            onItemSelected={updateColor}
            colorSelected={colorValue}
          ></ColorsPackContainer>
          <div className="mt-50">
            <ButtonCustom
              kind={KIND.tertiary}
              type={SizeButton.LARGE}
              style={{ marginBottom: '50px' }}
              onClick={() => {
                reset()
              }}
            >
              <LabelLarge>{t("Reset")}</LabelLarge>
            </ButtonCustom>
          </div>
        {/* <Accordion>
        <Panel
          title={
            <Button size={SIZE.default} kind={KIND.tertiary} shape={SHAPE.square}>
              Outline
            </Button>
          }
        >
          <div
            style={{
              background: '#ffffff',
              fontSize: '14px',
            }}
          >
            <div
              style={{
                display: 'grid',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div>
                <div>Width</div>
                <Slider
                  overrides={{
                    InnerThumb: () => null,
                    ThumbValue: () => null,
                    TickBar: () => null,
                    Thumb: {
                      style: {
                        height: '12px',
                        width: '12px',
                      },
                    },
                  }}
                  min={0}
                  max={0.2}
                  step={0.001}
                  marks={false}
                  value={widthValue}
                  onChange={({ value }) => updateWidth(value)}
                />
              </div>
              <div style={{ height: '60px' }}>
                <ColorsPackContainer style={{}} onItemSelected={updateColor}></ColorsPackContainer>
              </div>
              <div></div>
            </div>
          </div>
        </Panel>
      </Accordion> */}
    </>
  )
}

export default Outline
