import { createReducer } from '@reduxjs/toolkit'
import { User } from 'firebase/auth'; 
import { fblogin, fblogout } from './actions';

export interface FirebseAuthState {
  user: null | User;
}

const initialState: FirebseAuthState = {
  user: null,
};

export const firebaseAuthReducer = createReducer(initialState, (builder) => {
  builder.addCase(fblogin, (state, action) => {
    state.user = action.payload;
  });
  builder.addCase(fblogout, (state) => {
    state.user = null;
  });
});
