export class UserDevice {

    id: string;
    name?: string;
    type: string;
    osVersion: string;
    appVersion: string;
    language: string;
    notificationToken?: string;
    private _updateTimestamp: number;

    constructor(id: string, name: string | undefined, type: string, osVersion: string, appVersion: string, updateTimestamp: number, language: string, notificationToken: string | undefined) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.osVersion = osVersion;
        this.appVersion = appVersion;
        this._updateTimestamp = updateTimestamp;
        this.language = language;
        this.notificationToken = notificationToken;
    }

    get updateTimestamp(): number {
        return this._updateTimestamp;
    }

    get lastUpdate(): Date {
        return new Date(this._updateTimestamp * 1000);
    }

    save() {
        if (!this.isCurrentDevice()) {
            return;
        }
        console.log("sync: save current device locally");
        this._updateTimestamp = Math.round(Date.now() / 1000);

        localStorage.setItem("kCurrentDeviceId", this.id);
        localStorage.setItem("kCurrentDeviceName", this.name ?? '');
        localStorage.setItem("kCurrentDeviceUpdateTimestamp", this._updateTimestamp.toString());
    }

    isCurrentDevice(): boolean {
        return UserDevice.current.id === this.id;
    }

    static current: UserDevice = UserDevice.getDevice();

    static getDevice(): UserDevice {
        const savedId = localStorage.getItem("kCurrentDeviceId");
        const savedName = localStorage.getItem("kCurrentDeviceName");
        const savedTimestamp = localStorage.getItem("kCurrentDeviceUpdateTimestamp");

        const id = savedId ?? "web";
        const timestamp = savedTimestamp ? parseFloat(savedTimestamp) : Math.round(Date.now() / 1000);
        const type = 'web';
        const name = savedName || '';
        const osVersion = navigator.userAgent;
        const appVersion = '1.0.0'; // Replace with actual app version
        const lang = navigator.language;

        const device = new UserDevice(id, name, type, osVersion, appVersion, timestamp, lang, undefined);

        if (!savedId) {
            console.info(`sync: get device: creating new device id: ${id}`);
        }
        if (!savedTimestamp) {
            console.info("sync: get device: updating last update value");
        }

        console.log(`sync: current device: ${device}`);
        return device;
    }

    static recreateCurrentDevice() {
        console.log("sync: recreating new cloud device");
        localStorage.removeItem("kCurrentDeviceId");
        localStorage.removeItem("kCurrentDeviceName");
        localStorage.removeItem("kCurrentDeviceUpdateTimestamp");
        UserDevice.current = UserDevice.getDevice();
    }

    get typeName(): string {
        return "web"
    }

    toString(): string {
        return `device id: ${this.id}, type: ${this.type}, type name: ${this.typeName}, last update: ${this.lastUpdate}`;
    }

    toJSON(): any {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            osVersion: this.osVersion,
            appVersion: this.appVersion,
            updateTimestamp: this._updateTimestamp,
            language: this.language,
            notificationToken: this.notificationToken
        };
    }

    static fromJSON(data: any): UserDevice {
        const json = (typeof data === 'string') ? JSON.parse(data) : data;
        return new UserDevice(
            json.id,
            json.name,
            json.type,
            json.osVersion,
            json.appVersion,
            json.updateTimestamp,
            json.language,
            json.notificationToken
        );
    }

}
