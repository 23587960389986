import { MediaImageRepository } from "@/scenes/engine/objects/media-repository/media_image_repository";
import { DraftSyncVersion } from "./DraftSyncVersion";

export class VersionScreenshotInfo {
    projectId: string;
    deviceId: string;
    timestamp: number;
    url: string;
    
    private base64: string | null;

    constructor(projectId: string, deviceId: string, timestamp: number, url: string) {
        this.projectId = projectId;
        this.deviceId = deviceId;
        this.timestamp = timestamp;
        this.url = url;
        this.base64 = null;
    }

    static fromVersion(version: DraftSyncVersion, url: string): VersionScreenshotInfo {
        return new VersionScreenshotInfo(version.draftId, version.updateDeviceId, version.roundedTimestamp, url);
    }

    isMatching(version: DraftSyncVersion): boolean {
        return this.projectId === version.draftId && this.deviceId === version.updateDeviceId && this.timestamp === version.roundedTimestamp;
    }

    static versionId(version: DraftSyncVersion): string {
        return `project: ${version.draftId}, device: ${version.updateDeviceId}`;
    }

    static fromJSONDictionary(data: any): {[key: string]: VersionScreenshotInfo} {
        let parsed = JSON.parse(data)
        let result = {};
        for (let key in parsed) {
            result[key] = this.fromJSON(parsed[key])
        }
        return result
    }

    static fromJSON(data: any): VersionScreenshotInfo {
        const json = (typeof data === 'string') ? JSON.parse(data) : data;
        let url = json.url
        if (json.base64) {
            url = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.base64ToBlobUrl(json.base64);
        }
        return new VersionScreenshotInfo(
            json.projectId,
            json.deviceId,
            json.timestamp,
            url
        );
    }

    async persistLocalBlobs(){
        // if there's no underlying base64 and the url if of blob type
        if (this.url.indexOf('blob:') === -1 || this.base64) {
            return;
        }
        let base64Image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.blobUrlToBase64(this.url)
        this.base64 = base64Image
    }

    toJSON(): any {
        return {
            projectId: this.projectId,
            deviceId: this.deviceId,
            timestamp: this.timestamp,
            url: this.url,
            base64: this.base64
        };
    }

    toString(): string {
        return `project: ${this.projectId}, device: ${this.deviceId}, timestamp: ${this.timestamp}, url: ${this.url}`;
    }
}