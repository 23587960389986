import Shadow from './Shadow';
import { useEditorContext } from '@/scenes/engine';
import { selectImageElements } from '@/store/slices/imageElement/selectors';
import { useSelector } from 'react-redux';
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { useEffect, useState } from 'react';
import { ShadowConfig } from '@/scenes/engine/common/interfaces';

const ShadowImage = () => {
  const canvasImageRenderer = CanvasImageRenderer.getInstance()
  let shadowFilter = canvasImageRenderer.shadowFilter

  const { activeObject, editor, canvas } = useEditorContext();

  const [scaleXBeforeFilter, setScaleXBeforeFilter] = useState(null)
  const [scaleYBeforeFilter, setScaleYBeforeFilter] = useState(null)
  const [filterScalingXBeforeFilter, setFilterScalingXBeforeFilter] = useState(null)
  const [filterScalingYBeforeFilter, setFilterScalingYBeforeFilter] = useState(null)

  const [preEffectsValue, setPreEffectsValue] = useState(null)
  const [preFiltersValue, setPreFiltersValue] = useState([])

  const updatedProperties = ['effects', 'filters', 'scaleX', 'scaleY', '_filterScalingX', '_filterScalingY', 'isLatest']

  const filterPacks = useSelector(selectImageElements)
  const [isLatest, setIsLatest] = useState(false)
  
  const radianToDegrees = (radians: number): number => {
    let degrees = (radians * 180) / Math.PI
    return degrees
  }

  let queue: Array<() => Promise<void>> = [];
  let processing = false;

  const processQueue = async () => {
    if (processing) return; // Prevent multiple executions

    processing = true;
    while (queue.length > 0) {
      const action = queue.shift(); // Get the first action from the queue
      if (action) await action(); // Process the action if it exists
    }
    processing = false;
  }

  const addActionToQueue = (action: () => Promise<void>): void => {
    queue.push(action); // Add the action to the queue
    processQueue(); // Start processing if not already processing
  }

  
  const applyEffectLayerShadow = async (isReset: boolean, shadowValue: number, distanceValue: number, angleValue: number, colorValue: string, blurValue: number) => {
    addActionToQueue(async () => {
      //@ts-ignore
      if (activeObject.isLatest) {
        await canvasImageRenderer.render(activeObject, editor.handlers.frameHandler.getSize(), filterPacks);
        editor.handlers.personalizationHandler.updateDrawBorder(activeObject)
      }
      
      const newShadowState = {
        distance: distanceValue,
        angle: (angleValue * Math.PI) / 180,
        blur: blurValue,
        opacity: shadowValue,
        color: colorValue,
        is3D: false,
      };
      // @ts-ignore
      activeObject.effects.shadow = isReset ? null : newShadowState;
      await canvasImageRenderer.render(activeObject, editor.handlers.frameHandler.getSize(), filterPacks);
      canvas.requestRenderAll()
    });
  };

  const updateOptions = (object: any): any => {
    activeObject.on('modified', setScaleBeforeFilter)

    let shadowState: ShadowConfig = object.effects?.shadow
    if (!shadowState) {
      return {
        opacity: null,
        distance: null,
        angle: null,
        blur: null,
        color: null,
        promise: () => { 
          activeObject.off('modified', setScaleBeforeFilter)
        }
      }
    }

    let opacity = shadowState.opacity;
    let distance = shadowState.distance;
    let color = shadowState.color;
    let blur = shadowState.blur;
    let angle = radianToDegrees(shadowState.angle)
    if (angle < 0) { // dont let negative values
      angle += 360;
    }

    // @ts-ignore
    let assign = Object.assign({}, object.effects)
    setPreEffectsValue(assign)
    // @ts-ignore
    let assignObj = Object.assign({}, object.effects)
    setPreEffectsValue(assignObj)
    //@ts-ignore
    let filters=Object.assign([], object.filters);
    setPreFiltersValue(filters)
    setScaleXBeforeFilter(activeObject.scaleX)
    setScaleYBeforeFilter(activeObject.scaleY)
    //@ts-ignore
    setFilterScalingXBeforeFilter(activeObject._filterScalingX)
    //@ts-ignore
    setFilterScalingYBeforeFilter(activeObject._filterScalingY)

    return {
      opacity: opacity,
      distance: distance,
      angle: angle,
      blur: blur,
      color: color,
      promise: () => { 
        activeObject.off('modified', setScaleBeforeFilter)
      }
    }
  }

  const setScaleBeforeFilter = (e)=>{
    if(activeObject){
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
    }
  }

  useEffect(() => {
    if(activeObject){
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
    }
  }, [JSON.stringify(activeObject)])

  const updateEffect = (finalValue = false) => {
    let sliderOptions: any = { isSliderUpdate: finalValue }
    // @ts-ignore
    if(activeObject.isLatest) {
      // @ts-ignore
      setIsLatest(activeObject.isLatest)
    }
    if (finalValue) {
      const updatedPropertiesValues = [preEffectsValue, preFiltersValue, scaleXBeforeFilter, scaleYBeforeFilter, filterScalingXBeforeFilter, filterScalingYBeforeFilter, isLatest]
      //@ts-ignore
      sliderOptions = {
        isSliderUpdate: finalValue,
        property: updatedProperties,
        value: updatedPropertiesValues
      }
      //@ts-ignore
      let assignObj = Object.assign({}, activeObject.effects)
      setPreEffectsValue(assignObj)
      //@ts-ignore
      let filters = Object.assign([], activeObject.filters)
      setPreFiltersValue(filters)
      setScaleXBeforeFilter(activeObject.scaleX)
      setScaleYBeforeFilter(activeObject.scaleY)
      //@ts-ignore
      setFilterScalingXBeforeFilter(activeObject._filterScalingX)
      //@ts-ignore
      setFilterScalingYBeforeFilter(activeObject._filterScalingY)
    }
    //@ts-ignore
    editor.handlers.objectsHandler.updateActive(
      //@ts-ignore
      { effects: activeObject.effects, filters: activeObject.filters },
      undefined,
      sliderOptions
    )
  }

  const calcMinForKey = (key: string): number => {
    return shadowFilter.minimumForKey(key)
  }

  const calcMaxForKey = (key: string): number => {
    return shadowFilter.maximumForKey(key)
  }
  
  const defaultForKey = (key: string): any => {
    if (key === 'color') {
      return "#000000";
    }
    return shadowFilter.defaultForKey(key);
  }

  return <Shadow
    applyEffectLayerShadow={applyEffectLayerShadow}
    updateOptions={updateOptions} 
    updateEffect={updateEffect} 
    calcMinForKey={calcMinForKey}
    calcMaxForKey={calcMaxForKey}
    defaultForKey={defaultForKey}
     />;
};

export default ShadowImage;
