class Throttle<T> {
    private delay: number;
    private active: boolean;
    private timeoutId: ReturnType<typeof setTimeout> | null = null;

    constructor(delay: number) {
        this.delay = delay;
        this.active = false;
    }

    public async throttle(action: () => Promise<T>): Promise<T> {
        if (this.active) {
            return new Promise<T>((resolve) => {
                this.timeoutId = setTimeout(async () => {
                    this.active = true;
                    resolve(await action());
                    this.active = false;
                }, this.delay);
            });
        } else {
            this.active = true;
            const result = await action();
            this.active = false;
            return result;
        }
    }

    public get isActive(): boolean {
        return this.active;
    }
}

export class ThrottleManager<T> {
    private lastThrottle: Throttle<T> | null = null;

    public async throttle(delay: number, action: () => Promise<T>): Promise<T> {
        if (this.lastThrottle && this.lastThrottle.isActive) {
            // If the last throttle is still active, create a new one for this action
            this.lastThrottle = new Throttle<T>(delay);
        }
        if (!this.lastThrottle) {
            this.lastThrottle = new Throttle<T>(delay);
        }
        return this.lastThrottle.throttle(action);
    }

    private queue: (() => Promise<T>)[] = [];
    private running: boolean = false;

    constructor() {}

    public add(task: () => Promise<T>): void {
        this.queue.push(task);
        this.runNext();
    }

    private async runNext(): Promise<void> {
        if (this.running || this.queue.length === 0) {
            return;
        }

        this.running = true;
        const task = this.queue.shift();
        if (task) {
            try {
                await task();
            } catch (error) {
                console.error("Task failed", error);
            }
        }
        this.running = false;
        this.runNext();
    }
}
