import { TemplateConfig } from "@/interfaces/editor";
import { SyncStatus } from "./SyncStatus";
import { UserDevice } from "./UserDevice";
import { DateProvider } from "../DateProvider";

export class DraftSyncVersion {
    draftId: string;
    createDate: Date;
    private _modifiedDate: Date;
    size: { width: number, height: number };
    canvasSizeIsCircular: boolean;
    duration: number;
    scenes: number;
    minVersion: number;
    title: string;
    customFonts: string[];
    isInConflict: boolean;
    updateDeviceId: string;
    screenshotUrl?: string;
    
    _draft?: TemplateConfig;

    public _syncStatus: SyncStatus;
    private _statusUpdateDate: Date;
    private _deviceOwnerId: string;

    constructor(id: string, title: string, createDate: Date, modifiedDate: Date, size: { width: number, height: number }, canvasSizeIsCircular: boolean, duration: number, scenes: number, customFonts: string[], minVersion: number, updateDeviceId: string, statusUpdateDate: Date, status: SyncStatus, deviceOwner: string, isInConflict: boolean, screenshotUrl?: string) {
        this.draftId = id;
        this.title = title;
        this.createDate = createDate;
        this._modifiedDate = modifiedDate;
        this.size = size;
        this.duration = duration;
        this.scenes = scenes;
        this.customFonts = customFonts;
        this.minVersion = minVersion;
        this._syncStatus = status;
        this.updateDeviceId = updateDeviceId;
        this._statusUpdateDate = statusUpdateDate;
        this._deviceOwnerId = deviceOwner;
        this.canvasSizeIsCircular = canvasSizeIsCircular;
        this.isInConflict = isInConflict;
        this.screenshotUrl = screenshotUrl;
    }

    static fromDraft(draft: TemplateConfig): DraftSyncVersion {
        const duration = draft.scenes?.reduce((acc, scene) => acc + scene.duration, 0);
        const deviceId = UserDevice.current.id;
        const isCircular = draft.canvasSizeIsCircular;
        let modificationDateOptional = draft.draftModificationDate || draft.draftCreationDate;
        const modificationDate = (modificationDateOptional instanceof Date) ? modificationDateOptional : new Date(modificationDateOptional);
        const customFonts = draft.fonts?.filter(font => font.isCustomFont)
            .map(font => font.fileURL.split('/').pop() || "");

        const draftSyncVersion = new DraftSyncVersion(
            draft.draftGuid,
            draft.draftTitle,
            draft.draftCreationDate,
            modificationDate,
            draft.originalCanvasSize,
            isCircular,
            duration,
            draft.scenes?.length ?? 0,
            customFonts,
            draft.minDraftVersion,
            deviceId,
            DateProvider.getUTCDate(),
            SyncStatus.None,
            deviceId,
            false,
            undefined
        );

        let copiedDraft = JSON.parse(JSON.stringify(draft));
        draftSyncVersion._draft = copiedDraft as TemplateConfig; // Assuming BtDraftObject extends TemplateConfig
        return draftSyncVersion;
    }

    get modifiedDate(): Date {
        return this._modifiedDate;
    }

    get isLocal(): boolean {
        return !!this._draft || false;
    }

    get isCurrentDeviceVersion(): boolean {
        return this.isLocal || this._deviceOwnerId === UserDevice.current.id;
    }

    get statusUpdateDate(): Date {
        return this._statusUpdateDate;
    }

    get deviceOwnerId(): string {
        return this._deviceOwnerId;
    }

    set syncStatus(status: SyncStatus) {
        this._syncStatus = status;
        this._statusUpdateDate =  DateProvider.getUTCDate();
        this._deviceOwnerId = UserDevice.current.id;
    }

    get syncStatus(): SyncStatus {
        return this._syncStatus;
    }

    isSupportedOnWeb(): boolean {
        if (this._draft?.isAnimatedDraft == true) {
            return false;
        }
        if (this._draft?.scenes?.length > 1) {
            return false;
        }
        if (this._draft?.hasMusicFile == true) {
            return false;
        }
        return true;
    }

    static withDraft(draft: TemplateConfig): DraftSyncVersion {
        const duration = draft.scenes?.reduce((acc: number, scene: any) => acc + scene.duration, 0);
        const deviceId = UserDevice.current.id;
        const isCircular = draft.canvasSizeIsCircular;
        const modificationDate = draft.draftModificationDate || draft.draftCreationDate;
        const customFonts = draft.fonts?.filter((font: any) => font.isCustomFont).map((font: any) => font.fileURL.split('/').pop());
        return new DraftSyncVersion(draft.draftGuid, draft.draftTitle, draft.draftCreationDate, modificationDate, { width: draft.originalCanvasSize.width, height: draft.originalCanvasSize.height }, isCircular, duration, draft.scenes?.length ?? 0, customFonts, draft.minDraftVersion, deviceId,  DateProvider.getUTCDate(), SyncStatus.None, deviceId, false, undefined);
    }

    deviceCloudVersionCopy(): DraftSyncVersion {
        return new DraftSyncVersion(this.draftId, this.title, this.createDate, this.modifiedDate, this.size, this.canvasSizeIsCircular, this.duration, this.scenes, this.customFonts, this.minVersion, this.updateDeviceId, this.statusUpdateDate, this.syncStatus, UserDevice.current.id, this.isInConflict, this.screenshotUrl);
    }

    get roundedTimestamp(): number {
        try {
            return this.modifiedDate.getTime();
        } catch {
            return 0;
        }
    }

    static compare(lhs: DraftSyncVersion, rhs: DraftSyncVersion): boolean {
        if (lhs.roundedTimestamp === rhs.roundedTimestamp) {
            return rhs.isCurrentDeviceVersion && !lhs.isCurrentDeviceVersion;
        }
        return lhs.roundedTimestamp < rhs.roundedTimestamp;
    }

    // Method to convert the instance to JSON
    toJSON(): any {
        return {
            draftId: this.draftId,
            createDate: this.createDate.toISOString(),
            modifiedDate: this._modifiedDate.toISOString(),
            size: this.size,
            canvasSizeIsCircular: this.canvasSizeIsCircular,
            duration: this.duration,
            scenes: this.scenes,
            minVersion: this.minVersion,
            title: this.title,
            customFonts: this.customFonts,
            isInConflict: this.isInConflict,
            updateDeviceId: this.updateDeviceId,
            screenshotUrl: this.screenshotUrl ? this.screenshotUrl.toString() : undefined,
            syncStatus: this._syncStatus,
            statusUpdateDate: this._statusUpdateDate.toISOString(),
            deviceOwnerId: this._deviceOwnerId
        };
    }

    // Static method to create an instance from JSON
    static fromJSON(data: any): DraftSyncVersion {
        const json = (typeof data === 'string') ? JSON.parse(data) : data;
        return new DraftSyncVersion(
            json.draftId,
            json.title,
            new Date(json.createDate),
            new Date(json.modifiedDate),
            json.size,
            json.canvasSizeIsCircular,
            json.duration,
            json.scenes,
            json.customFonts,
            json.minVersion,
            json.updateDeviceId,
            new Date(json.statusUpdateDate),
            json.syncStatus,
            json.deviceOwnerId,
            json.isInConflict,
            json.screenshotUrl ? json.screenshotUrl : undefined
        );
    }
}
