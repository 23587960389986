export type ActionBlock = () => void;

export class BtUtils {
    private static actionIds: Map<string, string> = new Map();

    private static defaultQueue(callback: () => void): void {
        // Default implementation using setTimeout for immediate execution
        setTimeout(callback, 0);
    }

    public static throttle(
        delay: number,
        actionBlock: ActionBlock | null,
        identifier: string
    ): void {
        if (!identifier) {
            throw new Error("Identifier must be non-nil");
        }

        const currentGuid = this.generateUUID();
        this.actionIds.set(identifier, actionBlock === null ? '' : currentGuid);

        if (actionBlock === null) {
            return;
        }

        const copiedActionBlock: ActionBlock = () => actionBlock();
        const newAction = () => {
            if (this.actionIds.get(identifier) === currentGuid) {
                this.actionIds.delete(identifier);
                copiedActionBlock();
            }
        };

        const executeQueue = this.defaultQueue;
        setTimeout(() => executeQueue(newAction), delay);
    }

    private static generateUUID(): string {
        // Generates a random UUID
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
