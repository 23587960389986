import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#666"
      fillRule="evenodd"
      d="M3 16c.552 0 1 .31 1 1v3h16v-3c0-.69.448-1 1-1s1 .31 1 1v3.125C22 21.16 21.16 22 20.125 22H3.875A1.875 1.875 0 0 1 2 20.125V17c0-.69.448-1 1-1ZM12 2c.552 0 1 .482 1 1.077v11.846c0 .595-.448 1.077-1 1.077s-1-.482-1-1.077V3.077C11 2.482 11.448 2 12 2Z"
      clipRule="evenodd"
    />
    <path
      fill="#666"
      fillRule="evenodd"
      d="M12 14 7.508 9.319A1.045 1.045 0 0 0 6 10.765l5.15 5.376c.222.23.522.36.835.36.313 0 .613-.13.834-.36l5.219-5.446a1.027 1.027 0 0 0-1.482-1.424L12 14Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
