import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { useEditorContext } from '@/scenes/engine'
import { KIND } from 'baseui/button'
import Icons from '../../../Icons'
import { ObjectType } from '@/scenes/engine/common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
import { LabelLarge } from 'baseui/typography'
import { t } from 'i18next'



function Locked() {

  const editor = useEditorContext().editor
  const activeObject = useEditorContext().activeObject

  return (
    <>
      {activeObject?.type === ObjectType.GROUP ? (
        <ButtonCustom
          onClick={() => {
            customAmplitude('Selected tool', {
              Tool: 'bazaart.ungroup',
            })
            editor.handlers.objectsHandler.ungroup()
          }}
          kind={KIND.tertiary}
          type={SizeButton.LARGE}
          startEnhancer={<Icons.UnGroupIcon />}
        >
          <LabelLarge>{t("Ungroup")}</LabelLarge>
        </ButtonCustom>
      ) : (
        <ButtonCustom
          onClick={() => {
            customAmplitude('Selected tool', {
              Tool: 'bazaart.group',
            })
            editor.handlers.objectsHandler.group()
          }}
          kind={KIND.tertiary}
          type={SizeButton.LARGE}
          startEnhancer={<Icons.GroupIcon />}
        >
          <LabelLarge>{t("Group")}</LabelLarge>
        </ButtonCustom>
      )}
    </>
  )
}

export default Locked
