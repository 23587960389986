import { useCallback, useEffect, useRef, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import { useSelector } from 'react-redux'
import {
  selectTemplateId,
  selectTemplatesBySuperCategory,
  selectTemplatesSuperCategories,
} from '@/store/slices/templates/selectors'
import { ThemeProvider } from 'baseui'
import { lightTheme } from '@/customTheme'
import { Button } from 'baseui/button'
import AutoScroll from '@/components/AutoScroll'
import Search from '../../Toolbox/ToolboxItems/components/Search'
import Icons from '../../Icons'
import { ParagraphXSmall } from 'baseui/typography'
import { debounce } from 'lodash'
import { useStyletron } from 'baseui'
import api from '@services/api'
import {
  getTemplateDetails,
  setTemplateConfig,
  setTemplatesBySuperCategory,
} from '@/store/slices/templates/actions'
import { useAppDispatch } from '@/store/store'
import useAppContext from '@/hooks/useAppContext'
import HeadingInspector from '@/components/HeadingInspector'
import Loading from '@/components/Loading'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { selectCallbackFromCreationPage } from '@/store/slices/editor/selector'
import { ActionType } from '@/store/slices/editor/reducer'
import { PromiseResolver } from '@/scenes/engine/objects/asset-providers/PromiseResolver'
import { loadImageFromURL } from '@/scenes/engine/utils/image-loader'
import ModalCustom from '../../Modal/Modal'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { findSizeId } from '../../Navbar/components/Resize'
import { PopoverType } from '@/constants/app-options'

const ITEM_WIDTH = 92;

function Templates({ isOpen, setIsOpen, idFromDeeplink }) {
  const [css] = useStyletron()
  const templateId = useSelector(selectTemplateId)
  const templatesSuperCategories = useSelector(selectTemplatesSuperCategories)
  const templatesBySuperCategory = useSelector(selectTemplatesBySuperCategory)
  const [currentCategories, setCurrentCategories] = useState<any>([])
  
  const [currentSuperCategoryId, setCurrentSuperCategoryId] = useState(null)
  const [searchValue, setSearchValue] = useState(null)

  const editor = useEditorContext().editor
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  // const [defaultSection, setDefaultSection] = useState(5)
  const listSectionRef = useRef(null)
  const [resultSearch, setResultSearch] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [isLoadedCategories, setIsLoadedCategories] = useState(true)

  const dispatch = useAppDispatch()
  const hasPremium = useSelector(selectUserIsPremium)
  const callbackFromCreationPage = useSelector(selectCallbackFromCreationPage)
  const [isOpenPopupDiscard, setIsOpenPopupDiscard] = useState(false)
  const searchAbortController = useRef(null);
  const { popoverActive } = useAppContext()
  const [userLanguages, setUserLanguages] = useState(navigator.languages || [navigator.language])

  const [historyStatus, setHistoryStatus] = useState({ hasUndo: false, hasRedo: false })
  useEffect(() => {
    const handleHistoryChange = (data: any) => {
      setHistoryStatus({ ...historyStatus, hasUndo: data.hasUndo, hasRedo: data.hasRedo })
    }
    if (editor) {
      editor.on('history:changed', handleHistoryChange)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleHistoryChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])
  

  class TemplateAborter {
    id: string = "";
    abortController: AbortController;

    constructor(){
      
    }

    loadTemplate(id: string){
      if (this.id !== id) {
        this.abortController?.abort();
        editor.handlers.objectsHandler.clear()
        this.abortController = new AbortController();
      }
      this.id = id
    }
  }
  
  const aborter = new TemplateAborter();

  useEffect(() => {
    if (callbackFromCreationPage && callbackFromCreationPage.action === ActionType.ADD_TEMPLATE) {
      setCurrentSuperCategoryId(callbackFromCreationPage.value.superCategoryId)
      return
    }
    if (templatesBySuperCategory) {
      setCurrentSuperCategoryId(templatesBySuperCategory.superCategoryId)
      return
    }
    if (!currentSuperCategoryId) {
      setCurrentSuperCategoryId(templatesSuperCategories[0]?.id)
    }
  }, [templatesSuperCategories])

  useEffect(() => {
    async function getTemplates() {
      if (templatesBySuperCategory && templatesBySuperCategory.superCategoryId === currentSuperCategoryId) {
        if (!currentCategories.length) {
          setCurrentCategories(templatesBySuperCategory.values)
          return
        }
      }
      setCurrentCategories([])
      setIsLoadedCategories(false)
      await api.getCategoriesBySuperCategory(currentSuperCategoryId).then(async data => {
        let localCategories = data.filter(category =>
          !category.locale ||
          userLanguages.some(lang => lang.startsWith(category.locale))
        );
        let promise = localCategories.map(async category => {
          return await api.getTemplatesByCategory(category.id).then(value => {
            return { ...category, templates: value}
          })
        })
        let response = await Promise.all(promise)
        dispatch(
          setTemplatesBySuperCategory({
            superCategoryId: currentSuperCategoryId,
            values: response,
          })
        )
        setIsLoadedCategories(true)
        setCurrentCategories(response)
      })
    }

    currentSuperCategoryId && getTemplates()
  }, [currentSuperCategoryId])

  // const handleLoadTemplate = async template => {
  //   console.log('LOAD TEMPLATE')
  //   const fonts = []
  //   template.objects.forEach(object => {
  //     if (object.type === 'StaticText' || object.type === 'DynamicText') {
  //       fonts.push({
  //         name: object.metadata.fontFamily,
  //         url: object.metadata.fontURL,
  //         options: { style: 'normal', weight: 400 },
  //       })
  //     }
  //   })
  //   const filteredFonts = fonts.filter(f => !!f.url)
  //   if (filteredFonts.length > 0) {
  //     await loadFonts(filteredFonts)
  //   }
  //   console.log('TODO: Templates importFromJSON')
  //   // editor.importFromJSON(template)
  // }

  const handleSearch = async value => {
    if (!value) {
      setResultSearch(null)
      setIsSearching(false)
      return;
    }
    setIsSearching(true)

    if (searchAbortController.current) {
      searchAbortController.current.abort()
    }

    searchAbortController.current = new AbortController()
    const { signal } = searchAbortController.current

    try {
      const data = await api.searchTemplate(value, signal);
      setResultSearch(data)
      
      const eventProperties = {
        Query: value,
        results_count: data.length,
      }
      if(value && value.length > 1) {
        customAmplitude('Template Search', eventProperties)
      }

    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error("Error during search:", error);
        setResultSearch([]);
      }
    } finally {
      setIsSearching(false);
    }
  }
  const debounceHandleSearch = useCallback(debounce(value => {
    handleSearch(value)
  }, 300), [])

  const handleReset = () => {
    if (searchAbortController.current) {
      searchAbortController.current.abort()
    }
    setResultSearch(null);
    handleSearch('')
    setSearchValue('')
  }

  const preloadAssetManager = new PromiseResolver<number, any, any>();
  
  const preLoadTemplate = (templateId) =>{
    // preloadAssetManager.add(templateId, {}, () => api.getCreationById(templateId))
  }

  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [selectedTemplateCategory, setSelectedTemplateCategory] = useState(null)
  const [selectedTemplateThumbnail, setSelectedTemplateThumbnail] = useState(null)

  const selectTempalte = (templateId?, templateCategory?, thumbnail?) => {
    if(templateId){
      setSelectedTemplateId(templateId)
      setSelectedTemplateCategory(templateCategory)
      setSelectedTemplateThumbnail(thumbnail)
      
      if(historyStatus.hasUndo){
        setIsOpenPopupDiscard(true)
      } else {
        updateTemplate(templateId, templateCategory,thumbnail)
      }
    }
    else{
      updateTemplate(selectedTemplateId, selectedTemplateCategory, selectedTemplateThumbnail)
    }
    
  }

  const updateTemplate = (templateId, templateCategory?, thumbnail?) => {
    if (aborter.id === templateId) {
      return;
    }
    editor.handlers.frameHandler.removePlaceholderBgImage();
    aborter.loadTemplate(templateId)
    let abortController = aborter.abortController;

    if (templateId) {
      // setIsOpen()
      // setIsLoadedJsonSuccess(false)
      
      const thumbnailProvider = () => {
        if (!thumbnail) {
         return Promise.resolve(null);
        }
        return loadImageFromURL(thumbnail).then((loadedThumbnail) =>
        {
          let loadedThumbnailImage = loadedThumbnail as unknown as HTMLImageElement
          if (abortController.signal.aborted) {
            return;
          }
          const frameParams = { width: loadedThumbnailImage.width, height: loadedThumbnailImage.height }
          editor.handlers.frameHandler.update({ ...frameParams, sizeId: findSizeId(frameParams.width, frameParams.height) }, false)
          console.log('setting thumbnail');
          return editor.handlers.frameHandler.setPlaceholderBgImage(loadedThumbnailImage);
        });
      }
      
      thumbnailProvider().then(() => {
        api.getCreationById(templateId, abortController.signal).then(async data => {
          if (!data) {
            return
          } 
          if (abortController.signal.aborted) return;
          if (templateCategory) {
            data.config.templateCategory = templateCategory
          }
          data.config['templateId'] = templateId
          dispatch(getTemplateDetails(templateId))
          dispatch(setTemplateConfig(data.config))
          
          // @ts-ignore
          window.dataLayer.push({ event: 'template_added' });
          return editor.importFromJSON(data, abortController.signal, true)
          // customAmplitude('Editor Open', {
          //   Source: 'Template',
          //   'Template id': templateId,
          //   'Category id': 0,
          //   'Category Name': 'none',
          // })
          // const eventProperties = {
          //   'Template ID': templateId,
          // }
          // if (templateCategory) {
          //   eventProperties['Template Category'] = templateCategory
          // }
          // customAmplitude('Template Click', eventProperties)
          // setIsLoadedJsonSuccess(true)
        })
      })

      

      const eventProperties = {
        Tool: 'bazaart.add.template',
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  useEffect(() => {
    if (templateId) {
      selectTempalte(templateId)
    }
  }, [templateId])

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if(userLanguages && !userLanguages.includes(i18n.language)) {
      setUserLanguages(pre => [...pre, i18n.language])
    }
  }, [i18n.language])

  const [categoryIdByDeepLink, setCategoryIdByDeepLink] = useState(null)
  
  useEffect(() => {
    if(popoverActive === PopoverType.TEMPLATES && idFromDeeplink) {
      setCategoryIdByDeepLink(idFromDeeplink)
    }
    
    return () => {
      setCategoryIdByDeepLink(null)
    }
  }, [isOpen, idFromDeeplink])
  
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-template"
        style={{
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 180px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
        }}
      >
        <div style={{ boxShadow: isBoxShadow ? '0px 0px 6px rgba(0, 0, 0, 0.1)' : 'none'}}>
          <HeadingInspector
            hasBoxShadow={false}
            hasNavigation={false}
            title={t('Templates')}
            hasClose={true}
            handleClose={() => setIsOpen()}
          ></HeadingInspector>
          <div
            style={{
              padding: '0 24px 12px 24px',
              paddingTop: 0,
              width: '100%',
              boxSizing: 'border-box',
              background: '#fff',
              zIndex: 1000,
            }}
          >
            <Search
              handleValueChange={debounceHandleSearch}
              size="standard"
              valueExternal={searchValue}
              setValueExternal={setSearchValue}
              handleReset={() => handleReset()}
            />
          </div>
          {!resultSearch && !isSearching && (
            <SuperCategories
              superCategories={templatesSuperCategories}
              onSuperCategorySelected={item => {
                listSectionRef.current.scrollTo(0, 0)
                setCurrentSuperCategoryId(item.id)
              }}
              selectedId={currentSuperCategoryId}
            />
          )}
        </div>
        <AutoScroll
          style={{
            height: resultSearch && resultSearch.length ? 'calc(100% - 144px)' : 'calc(100% - 224px)',
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            paddingBottom: '20px',
            paddingTop: resultSearch && resultSearch.length ? '12px' : '0px'
          }}
          ref={listSectionRef}
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }

            // if (
            //   Math.round(listSectionRef.current.scrollTop) + listSectionRef.current.clientHeight ===
            //   listSectionRef.current.scrollHeight
            // ) {
            //   // setDefaultSection(pre => pre + 10)
            // }
          }}
        >
          {!resultSearch && !isSearching ? (
            <>
              {currentCategories.map((categoy, index) => {
                return categoy.templates.length ? (
                  <CategorySection
                    key={categoy.id}
                    category={categoy}
                    onTemplateSelected={item => {
                      selectTempalte(item.id, categoy.name, getResizeUrl({ size: `${ITEM_WIDTH}x0`, url: item.image.slice(8) }))
                    }}
                    close={setIsOpen}
                    onTemplateHover={templateId=>{
                      preLoadTemplate(templateId)
                    }}
                    openByDeepLink={Number(categoryIdByDeepLink) === Number(categoy.id)}
                    setCategoryIdByDeepLink={setCategoryIdByDeepLink}
                  />
                ) : null
              })}
            </>
          ) : null}
          {resultSearch && resultSearch.length > 0 && (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '8px',
              }}
            >
              {resultSearch.map((item, index) => {
                if (index < 50) {
                  return (
                    <div
                      className={css({
                        cursor: 'pointer',
                        borderRadius: '6px',
                        position: 'relative',
                        gridRowEnd: `span ${Math.ceil((item.height * 10) / item.width)}`,
                        overflow: 'hidden',
                        ':hover div': {
                          display: 'block !important',
                        },
                        ':active div': {
                          display: 'block !important',
                          background: 'rgba(0,0,0,.4) !important',
                        },
                      })}
                      key={item.id}
                    >
                      <WrapImage
                        item={item}
                        handleOnMouseEnter={preLoadTemplate(item.id)}
                        handleOnClick={template => {
                          if (!hasPremium && index >= 5) {
                            // close()
                            const eventProperties = {
                              Source: 'BtSubscriptionTemplates',
                              Type: 'Standard',
                            }
                            customAmplitude('Premium Prompt', eventProperties)
                            // @ts-ignore
                            window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
                            dispatch(
                              setOpenModalTryPremium({
                                isOpen: true,
                                source: 'BtSubscriptionTemplates',
                                callback: () => {
                                  selectTempalte(template.id)
                                },
                              })
                            )
                          } else {
                            selectTempalte(template.id)
                          }
                        }}
                      />
                      {!hasPremium ? (
                        <div style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 10, width: '18px', height: '18px', borderRadius: '50%' }}>
                          {index >= 5 ? (
                            <Icons.PremiumIcon />
                          ) : (
                            null
                          )}
                        </div>
                      ) : null}
                    </div>
                  )
                }
                return null
              })}
            </div>
          )}
          {resultSearch && resultSearch.length === 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                flexDirection: 'column',
              }}
            >
              <p style={{ ...lightTheme.typography.LabelLarge, color: lightTheme.colors.blackGray, margin: 0 }}>
                {t('No results')}
              </p>
              <p
                style={{
                  ...lightTheme.typography.Small12regular,
                  color: 'rgba(153, 153, 153, 1)',
                  margin: 0,
                }}
              >
                {t('for \"%@\"', { arg: searchValue })}
              </p>
            </div>
          )}

          {isSearching || !isLoadedCategories ? (
            <div style={{ position: 'absolute', inset: 0, top: '224px', background: '#fff' }}>
              <div
                style={{
                  position: 'absolute',
                  top: 'calc(50% - 80px)',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  zIndex: 1000,
                  paddingLeft: '12px',
                }}
              >
                <Loading />
              </div>
            </div>
          ) : null}
        </AutoScroll>
      </div>
      <ModalCustom
        isOpen={isOpenPopupDiscard}
        onClose={setIsOpenPopupDiscard}
        content={{ header: t('Replace your design?'), desc: t('Your design will be replaced by the selected template. This can’t be undone.') }}
        action={{
          primary: {
            name: t('Replace'),
            handle: () => {
              setIsOpenPopupDiscard(false)
              selectTempalte()
            },
          },
          secondary: {
            name: t('Cancel'),
            handle: () => {
              setIsOpenPopupDiscard(false)
            },
          },
        }}
      />
    </ThemeProvider>
  )
}

export default Templates

function SuperCategories({
  superCategories,
  selectedId,
  onSuperCategorySelected,
}: {
  superCategories: any[]
  selectedId: number
  onSuperCategorySelected: Function
}) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        flexWrap: 'wrap',
        padding: '12px 24px 24px 24px',
      }}
    >
      {superCategories.map(
        superCategory =>
          <CategoryButton
            key={superCategory.id}
            isSelected={superCategory.id === selectedId}
            onClick={() => {
              onSuperCategorySelected(superCategory)
            }}
          >
            <>{t(superCategory.name)}</>
          </CategoryButton>
      )}
    </div>
  )
}

function CategoryButton({
  onClick,
  children,
  isSelected,
}: {
  onClick: Function
  children: JSX.Element
  isSelected: boolean
}) {
  return (
    <Button
      kind="secondary"
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            padding: '0 12px',
            borderRadius: '12px',
            height: '24px',
            ...lightTheme.typography.Small12medium,
            color: isSelected ? lightTheme.colors.white : lightTheme.colors.blackGray,
            background: isSelected ? lightTheme.colors.blackGray : lightTheme.colors.grayScale50,
            ':hover': {
              background: isSelected ? lightTheme.colors.blackGray : lightTheme.colors.grayScale100,
            },
          }),
        },
      }}
      onClick={() => {
        onClick()
      }}
    >
      {children}
    </Button>
  )
}

function CategorySection({
  onTemplateSelected,
  onTemplateHover,
  category,
  // templates,
  close,
  openByDeepLink = false,
  setCategoryIdByDeepLink
}: {
  onTemplateSelected: Function
  onTemplateHover?: Function
  category: any
  // templates: any[]
  close: Function
  openByDeepLink
  setCategoryIdByDeepLink?: Function
}) {
  //   {
  //   category,
  // }: {
  //   category: { id: number; name: string; thumbnail:string }
  // }
  const [css] = useStyletron()
  const { isLoadedJsonSuccess } = useAppContext()
  // const dispatch = useAppDispatch()

  // dispatch(getTemplates(category))

  // const templates = useSelector(selectTemplates)
  // const [categoryTemplates, setCategoryTemplates] = useState<Template[]>([])
  // api.getTemplatesByCategory(category).then(value=>{
  //   setCategoryTemplates(value)
  // })
  const [isSeeAll, setIsSeeAll] = useState(false)
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const templates = category.templates
  const isPremiumCategory = category.premium

  useEffect(() => {
    if(openByDeepLink) {
      setIsSeeAll(true)
    }

    return () => {
      setIsSeeAll(false)
      setCategoryIdByDeepLink(null)
    }
  }, [openByDeepLink])

  return isSeeAll ? (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          overflow: 'hidden',
          height: '100%',
          position: 'absolute',
          inset: 0,
          backgroundColor: '#fff',
          zIndex: 10000,
          borderRadius: '16px',
        }}
      >
        <div style={{ boxShadow: isBoxShadow ? '0px 0px 6px rgba(0, 0, 0, 0.1)' : 'none' }}>
          <HeadingInspector
            hasNavigation={true}
            handleNavigation={() => setIsSeeAll(false)}
            title={category.name}
            hasBoxShadow={isBoxShadow}
          ></HeadingInspector>
        </div>
        <AutoScroll
          style={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            paddingBottom: '20px',
            height: 'calc(100% - 80px)',
          }}
          handleScroll={e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
            }}
          >
            {templates.map((template, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    borderRadius: '6px',
                    overflow: 'hidden',
                    border: '0.5px solid rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                    width: `${ITEM_WIDTH}px`,
                    height: `${(template.height * ITEM_WIDTH) / template.width}px`,
                  }}
                  key={template.id}
                  className={css({
                    position: 'relative',
                    ':hover div': {
                      display: 'block !important',
                    },
                    ':active div': {
                      display: 'block !important',
                      background: 'rgba(0,0,0,.4) !important',
                    },
                  })}
                >
                  <WrapImage
                    item={template}
                    handleOnMouseEnter={onTemplateHover}
                    handleOnClick={() => {
                      if (isPremiumCategory && !hasPremium && index >= 5) {
                        // close()
                        const eventProperties = {
                          Source: 'BtSubscriptionTemplates',
                          Type: 'Standard',
                        }
                        customAmplitude('Premium Prompt', eventProperties)
                        // @ts-ignore
                        window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
                        dispatch(
                          setOpenModalTryPremium({
                            isOpen: true,
                            source: 'BtSubscriptionTemplates',
                            callback: () => {
                              onTemplateSelected(template)
                            },
                          })
                        )
                      } else {
                        onTemplateSelected(template)
                      }
                    }}
                  />
                  {isPremiumCategory && !hasPremium ? (
                    <div style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 10, width: '18px', height: '18px', borderRadius: '50%' }}>
                      {index >= 5 ? (
                        <Icons.PremiumIcon />
                      ) : (
                        null
                      )}
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </AutoScroll>
      </div>
    </ThemeProvider>
  ) : (
    <div>
      <div
        style={{
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <p style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title, cursor: 'pointer' }} onClick={() => setIsSeeAll(true)}>{t(category.name)}</p>
        <ParagraphXSmall
          onClick={() => setIsSeeAll(true)}
          $style={{
            boxSizing: 'border-box',
            cursor: 'pointer',
            padding: '0 8px',
            borderRadius: '12px',
            ':hover': {
              background: 'rgb(229, 229, 229)',
            },
            ...lightTheme.typography.Small12medium
          }}
        >
          {t('See all')}
        </ParagraphXSmall>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: '8px',
          rowGap: 0,
        }}
      >
        {templates.length &&
          templates
            .filter(t => getResizeUrl({ size: `${ITEM_WIDTH}x0`, url: t.image.slice(8) }))
            .slice(0, 3)
            .map(template => {
              return (
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    borderRadius: '6px',
                    overflow: 'hidden',
                    border: isLoadedJsonSuccess ? '0.5px solid rgba(0, 0, 0, 0.1)' : '',
                    cursor: 'pointer',
                  }}
                  key={template.id}
                  className={css({
                    position: 'relative',
                    width: `${ITEM_WIDTH}px`,
                    height: `${(template.height * ITEM_WIDTH) / template.width}px`,
                    ':hover div': {
                      display: 'block !important',
                    },
                    ':active div': {
                      display: 'block !important',
                      background: 'rgba(0,0,0,.4) !important',
                    },
                  })}
                >
                  <WrapImage item={template} handleOnClick={onTemplateSelected} handleOnMouseEnter={onTemplateHover}/>
                </div>
              )
            })}
      </div>
    </div>
  )
}

function WrapImage({ item, handleOnClick, handleOnMouseEnter}) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <>
      <LazyLoadImage
        crossOrigin="anonymous"
        onClick={() => handleOnClick(item)}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '6px',
          transition: 'all 3s ease-in-out',
          objectFit: 'fill',
        }}
        src={api.prepareUrl(getResizeUrl({ size: `${ITEM_WIDTH}x0`, url: item.image.slice(8) }))}
        alt={item.name}
        afterLoad={() => {
          setIsLoaded(true)
        }}
        draggable={false}
        onMouseEnter={() => {
        }}
        // onMouseLeave={() => {
        //   onMouseLeave(item)
        // }}
      // onDrag={e => {
      //   if (!objDragging.item || objDragging?.item.id !== item.id) {
      //     setObjDragging({
      //       item: item,
      //       type: 'Template',
      //     })
      //   }
      // }}
      />
      <div
        style={{
          position: 'absolute',
          inset: 0,
          background: !isLoaded ? lightTheme.colors.grayScale50 : 'rgba(0, 0, 0, .25)',
          display: isLoaded ? 'none' : 'block',
          zIndex: 10,
          borderRadius: '6px',
          pointerEvents: 'none',
        }}
      ></div>
    </>
  )
}