import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke={lightTheme.colors.blackGray}
      strokeWidth={1.778}
      d="M4.893 11.725c0-2.24 0-3.36.435-4.216A4 4 0 0 1 7.077 5.76c.855-.436 1.975-.436 4.216-.436h4.977c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748c.436.855.436 1.975.436 4.216v4.977c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748c-.855.436-1.976.436-4.216.436h-4.977c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.749-1.748c-.435-.855-.435-1.976-.435-4.216v-4.978Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      stroke={lightTheme.colors.blackGray}
      strokeWidth={1.975}
      d="M21.855 17.034 19.594 15.8c-.755-.411-1.132-.617-1.532-.708a2.963 2.963 0 0 0-1.082-.043c-.406.058-.799.233-1.585.583-3.52 1.566-5.16 2.294-8.891 3.924a.87.87 0 0 0-.522.796c0 .96.777 1.737 1.736 1.737h10.905c.881 0 1.322 0 1.685-.093a2.963 2.963 0 0 0 2.133-2.132c.093-.363.093-.804.093-1.686 0-.181 0-.272-.014-.356a.987.987 0 0 0-.36-.605c-.066-.053-.146-.096-.305-.183Z"
    />
    <path
      fill={lightTheme.colors.blackGray}
      stroke={lightTheme.colors.blackGray}
      strokeWidth={1.778}
      d="M22.639 17c-1.428-.799-3.034-2.086-5.075-2.086-1.243 0-2.456.38-3.313.721-2.935 1.165-6.314 2.638-7.92 3.347a1.584 1.584 0 0 0-.943 1.453c0 .881.715 1.596 1.597 1.596H20.21c.17 0 .256 0 .328-.003 1.592-.065 2.101-3.746 2.101-5.027Z"
    />
    <circle cx={10.818} cy={11.255} r={2.469} fill={lightTheme.colors.blackGray} />
    <path
      stroke={lightTheme.colors.blackGray}
      strokeWidth={1.778}
      d="M9.33 5.562c0-.618 0-.926.034-1.186A4 4 0 0 1 12.809.931c.26-.034.568-.034 1.186-.034h6.713c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748c.436.856.436 1.976.436 4.216v7.067c0 .289 0 .433-.008.554a4 4 0 0 1-3.749 3.75c-.122.007-.266.007-.554.007"
    />
  </svg>
)
export default SvgComponent
